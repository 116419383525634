import { CommonModule } from '@angular/common';
import { Component, effect } from '@angular/core';
import { FecProvider } from '@pages/fec/fec.provider';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';
import { TableModule } from 'primeng/table';
import { FecYearProvider } from '../../fecYear.provider';

@Component({
  selector: 'app-comparative-table',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ChileanPesoPipe,
    CustomPercentPipe
  ],
  templateUrl: './comparative-table.component.html',
  styleUrl: './comparative-table.component.scss'
})
export class ComparativeTableComponent {
  records: any[] = [];

  constructor(
    public fecProvider: FecProvider,
    public fecYearProvider: FecYearProvider
  ){
    effect(() => {

      let  previousFecSummary = this.fecProvider.getPreviousFecSummary() ? 
        {...this.fecProvider.getPreviousFecSummary(), description: 'Cierre Anterior'} : null;
      let currentFecSummary = this.fecProvider.getCurrentFecSummary() ? 
        {...this.fecProvider.getCurrentFecSummary(), description: 'Situación Actual'} : null;
      this.records = this.fecYearProvider.getList().map(fecYear => {
        return {
          ...fecYear.summary,
          description: fecYear.description
        };
      });

      if (currentFecSummary){
        if (previousFecSummary){
          this.setPreviousCloseDifference(previousFecSummary, currentFecSummary);
          this.records = [previousFecSummary, currentFecSummary, ...this.records];
          this.records.slice(2).forEach(record => this.setPreviousCloseDifference(currentFecSummary, record));
        }
        else {
          this.records = [currentFecSummary, ...this.records];
          this.records.slice(1).forEach(record => this.setPreviousCloseDifference(currentFecSummary, record));
        }
      }
      else if (previousFecSummary){
        this.records = [previousFecSummary, ...this.records];
        this.records.slice(1).forEach(record => this.setPreviousCloseDifference(previousFecSummary, record));
      }
    });
  }

  setPreviousCloseDifference(previous:any ,record: any): void{
    const previousRealIncome =  previous?.realIncome ?? 0;
    const currentRealIncome = record.realIncome ?? 0;
    record.previousCloseDifference = currentRealIncome - previousRealIncome;
  }
}
