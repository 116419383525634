import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { GeneratorComponent } from '../generator/generator.component';
import { ClientAdminComponent } from '../client-admin/client-admin.component';
import { TabViewModule } from 'primeng/tabview';
import { FecAdminComponent } from '../fec-admin/fec-admin.component';
import { ApiOticAdminComponent } from '../api-otic-admin/api-otic-admin.component';
import { MailAdminComponent } from '../mail-admin/mail-admin.component';
import { SimulatedLoginComponent } from '@shared/components/simulated-login/simulated-login.component';
import { UserAdminComponent } from '../user-admin/user-admin.component';


@Component({
  selector: 'app-dev-admin',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    GeneratorComponent,
    ClientAdminComponent,
    FecAdminComponent,
    ApiOticAdminComponent,
    MailAdminComponent,
    SimulatedLoginComponent,
    UserAdminComponent
  ],
  templateUrl: './dev-admin.component.html',
  styleUrl: './dev-admin.component.scss'
})
export class DevAdminComponent {

}
