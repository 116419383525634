import { Injectable } from '@angular/core';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable, map } from 'rxjs';
import { ServiceBase } from './serviceBase';
import { Parameter } from '@shared/interfaces/parameter';
import { ParameterFilter } from '@shared/interfaces/parameterFilter';
    
@Injectable({
    providedIn: 'root'
})
export class ParameterService extends ServiceBase {

    constructor(httpProvider: HttpProvider) { 
        super(httpProvider);
    }

    getParameter(parameterName: string): Observable<Parameter>{
        return this.get<any>(`parameter/${parameterName}`).pipe(
            map(resp => resp['data'])
        );
    }

    findParameter(filter: ParameterFilter): Observable<Parameter[]>{
        return this.post<any>(`parameter/find`, filter).pipe(
            map(resp => resp['data'])
        );
    }

    updateParameter(parameter: Parameter): Observable<Parameter>{
        return this.put<any>(`parameter/${parameter.parameterName}`, parameter).pipe(
            map(resp => resp['data'])
        );
    }

};
