import { Injectable } from '@angular/core';
import { Client } from '@shared/interfaces/client';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable, map } from 'rxjs';
import { ServiceBase } from './serviceBase';
import { Otic } from '@shared/interfaces/otic';
import { BusinessArea } from '@shared/interfaces/businessArea';
import { Executive } from '@shared/interfaces/executive';
import { ContactType } from '@shared/interfaces/contactType';

@Injectable({
  providedIn: 'root'
})
export class OticService extends ServiceBase {

  constructor(httpProvider: HttpProvider) {
    super(httpProvider);
  }

  getCurrentClientByRut(rut: string): Observable<Client> {
    return this.get<any>(`currentClient/rut/${rut}`).pipe(
      map(resp => resp['data'])
    );
  }

  getCurrentClientByName(name: string): Observable<Client[]> {
    return this.get<any>(`currentClient/name/${name}`).pipe(
      map(resp => resp['data'])
    );
  }

  getCurrentComertialClientByName(name: string): Observable<Client[]> {
    return this.get<any>(`currentComertialClient/name/${name}`).pipe(
      map(resp => resp['data'])
    );
  }

  getListOtic(): Observable<Otic[]>{
    return this.get<any>(`oticApi/getListOtic`).pipe(
      map(resp => resp['data'])
    );
  }

  getListBusinessArea(): Observable<BusinessArea[]>{
    return this.get<any>(`oticApi/getListBusinessArea`).pipe(
      map(resp => resp['data'])
    );
  }

  getExecutiveByRut(rut: string): Observable<Executive> {
    return this.get<any>(`oticApi/getExecutiveByRut/${rut}`).pipe(
      map(resp => resp['data'])
    );
  }

  getHunterByRut(rut: string): Observable<Executive> {
    return this.get<any>(`oticApi/getHunterByRut/${rut}`).pipe(
      map(resp => resp['data'])
    );
  }

  getListContactType(): Observable<ContactType[]>{
    return this.get<any>(`oticApi/getListContactType`).pipe(
      map(resp => resp['data'])
    );
  }

}
