import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';

@Component({
  selector: 'app-text-readonly',
  standalone: true,
  imports: [
    CommonModule,
    ChileanPesoPipe,
    CustomPercentPipe
  ],
  templateUrl: './text-readonly.component.html',
  styleUrl: './text-readonly.component.scss'
})
export class TextReadonlyComponent {
  @Input() 
  public label?: string;
  @Input() 
  public value?: string | number = "";
  @Input() 
  public width: string | number = 200;
  @Input()
  public type: "string" | "amount" | "percentage" = "string";

  getWidth(){
    return typeof this.width === 'string' ? this.width : (this.width + 'px');
  }
}

