<app-modal
    (hide)="onHide()"
    title="Datos Empresa"
    #modal
>
    <app-fake-steps
        [type]="2"
        [steps]="steps" 
    >
    </app-fake-steps>

    <div class="grid align-items-center">
        <div class="col">
            <h3>Agregar Empresa</h3>
        </div>
    </div>

    <form [formGroup]="form" autocomplete="off">
        <div class="formgrid grid">
            <div class="flex align-items-center">
                <div class="formgrid grid" style="padding-left: 10px;">
                    <div class="flex flex-column field otic-form-input" style="margin-right: 116px;">
                        <app-field-container
                            [form]="form"
                            fieldName="clientName"
                            label="Nombre Empresa *"
                        >
                            <input 
                                type="text" 
                                pInputText
                                [maxlength]="50" 
                                placeholder="Nombre Empresa" 
                                formControlName="clientName"
                            />
                        </app-field-container>
                    </div>
                    <div class="flex flex-column field otic-form-input">
                        <app-field-container
                            [form]="form"
                            fieldName="rut"
                            label="RUT*"
                        >
                            <input 
                                type="text" 
                                pInputText 
                                placeholder="1.234.567-8" 
                                [maxlength]="50" 
                                formControlName="rut"
                                mask="0.000.000-A || 00.000.000-A"
                                
                            />
                        </app-field-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="col">
        <div class="flex align-items-center justify-content-end gap-2">
            <app-button
                label="Cancelar" 
                (clickButton)="closeModal()" 
                type="secondary"
                [width]="140" 
                [height]="46" 
                [fontWeight]="700"
            />
            @if(selectedIndexForDetail === -1){
                <app-button 
                    label="Confirmar" 
                    (clickButton)="saveCompanies()" 
                    [width]="140" 
                    [height]="46" 
                    [fontWeight]="700"
                />
            }
            @else {
                <app-button 
                    label="Confirmar" 
                    (clickButton)="updateCompanies()" 
                    [width]="140" 
                    [height]="46" 
                    [fontWeight]="700"
                />
            }
            
        </div>
    </div>
</app-modal>



<app-grid-container
        title="Listado Empresas"
        addTitle="Agregar Empresa"
        (add)="addNewCompany()"
        [showAddButton]="mode === ModeEnum.EDIT"
    >
    <p-table [value]="comercialChildren" [tableStyle]="{ 'min-width': '50rem', 'padding': '12px' }">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col">Nombre</th>
                <th scope="col">RUT</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr>
                <td>{{ item.clientName }}</td>
                <td>{{ item.rut | rut }}</td>
            </tr>
        </ng-template>
    </p-table>
</app-grid-container>




