import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { UtilService } from '@shared/services/util.service';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-mail-admin',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    FieldContainerComponent,
    ButtonComponent,
    ToastModule
  ],
  providers: [MessageService],
  templateUrl: './mail-admin.component.html',
  styleUrl: './mail-admin.component.scss'
})
export class MailAdminComponent {
  public form: FormGroup = this.fb.group({
    to: ['', [Validators.required, Validators.email]],
    subject: ['', [Validators.required]],
    body: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private messageService: MessageService,
    private utilService: UtilService
  ) { 

  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  send(){
    if (!this.validate()) return;
    this.loadingProvider.show();
    let data = this.form.value;
    this.utilService.sendMail({
      to: [ data.to ],
      subject: data.subject,
      body: data.body
    }).subscribe({
      next: (resp) => {
        this.loadingProvider.hide();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Enviado!!!' });
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: msg });
      }
    });
  }

}
