import { Client } from "@shared/interfaces/client";
import { isNotEmpty } from "./stringUtils";
import { Fec } from "@shared/interfaces/fec";
import { arrayIsEmpty, arrayIsNotEmpty } from "./listUtil";

export const tabClientDataIsComplete = (client: Client): boolean => {
  if (client.isComercial){
    if (isNotEmpty(client.clientName) && arrayIsNotEmpty(client.comercialChildren))
      return true;
  }
  else {
    if (isNotEmpty(client.clientName) && isNotEmpty(client.rut))
      return true;
  }
  return false;
} 

export const tabOticContactsIsComplete = (client: Client): boolean => {
    if (client.isPotential 
        && isNotEmpty(client.hunterContact?.rut) 
        && isNotEmpty(client.followUpContact?.rut)
      )
      return true;
    if (!client.isPotential 
        && isNotEmpty(client.hunterContact?.rut) 
        && isNotEmpty(client.maintainerContact?.rut)
        && isNotEmpty(client.affiliatorContact?.rut)
      )
      return true;  
    return false;
}

export const clientIsComplete = (client: Client): boolean => {
    return tabClientDataIsComplete(client) && tabOticContactsIsComplete(client);
}

export const fecYearsSelectedComplete = (fec: Fec): boolean => {
  if (arrayIsEmpty(fec.periodYears) || arrayIsEmpty(fec.fecYears)){
    return false;
  }
  for (const periodYear of fec.periodYears!) {
    let fecYears = fec.fecYears?.filter(
      fecYear => fecYear.selected && periodYear.year === fecYear.year) ?? [];
    if (fecYears.length != 1){
      return false;
    }
  }
  return true;
  
}
