@if(fec && fec.approval && fec.summary){
    <div class="grid col-12">
        <div class="col">
            <app-text-readonly
                label="effectiveRate"
                [value]="fec.summary.effectiveRate"
                width="100%"
                type="percentage"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="finalAdministrationFee"
                [value]="fec.summary.finalAdministrationFee"
                width="100%"
                type="percentage"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="hasCostDo"
                [value]="hasCostDo()"
                width="100%"
            ></app-text-readonly>
        </div>
    </div>
    <div class="grid col-12">
        <div class="col">
            <app-text-readonly
                label="isPotential"
                [value]="fec.isPotential?.toString()"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="fecStatus"
                [value]="fec.fecStatus"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="userCreated"
                [value]="fec.userCreated"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="userRolesCreated"
                [value]="fec.userRolesCreated?.join(', ')"
                width="100%"
            ></app-text-readonly>
        </div>
    </div>
    <div class="grid col-12">
        <div class="col">
            <app-text-readonly
                label="followUpContact"
                [value]="fec.client?.followUpContact?.email"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="followUpOffice"
                [value]="fec.client?.followUpContact?.office"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="affiliatorContact"
                [value]="fec.client?.affiliatorContact?.email"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="affiliatorOffice"
                [value]="fec.client?.affiliatorContact?.office"
                width="100%"
            ></app-text-readonly>
        </div>
    </div>
    <div class="grid col-12">
        <div class="col">
            <app-text-readonly
                label="descriptionApprovalFlow"
                [value]="fec.descriptionApprovalFlow"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="userApprovalFlow"
                [value]="fec.userApprovalFlow"
                width="100%"
            ></app-text-readonly>
        </div>
    </div>
    <div class="grid col-12">
        <div class="col">
            <app-text-readonly
                label="officeApprovalFlow"
                [value]="fec.officeApprovalFlow"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="roleCodeApprovalFlow"
                [value]="fec.roleCodeApprovalFlow"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="roleApprovalFlow"
                [value]="fec.roleApprovalFlow"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="col">
            <app-text-readonly
                label="rolesApprovalFlow"
                [value]="fec.rolesApprovalFlow?.join(', ')"
                width="100%"
            ></app-text-readonly>
        </div>
    </div>

    <p-table [value]="fec.approval" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
            <tr>
                <th>roleCode</th>
                <th>roles</th>
                <th>users</th>
                <th>evaluator</th>
                <th>evaluatorRoles</th>
                <th>status</th>
                <th>date</th>
                <th>observation</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.roleCode }}</td>
                <td>{{ item.roles?.join(', ') }}</td>
                <td>{{ item.users?.join(', ') }}</td>
                <td>{{ item.evaluator }}</td>
                <td>{{ item.evaluatorRoles?.join(', ') }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.date | epochtodatetime  }}</td>
                <td>{{ item.observation }}</td>
            </tr>
        </ng-template>
    </p-table>
}
