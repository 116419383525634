<app-page-container
    title="Aprobación FEC"
    [paths]="paths"
>

<div class="header flex grid justify-content-between w-full">
    @for (item of headerInfo; track $index;) {
        <p class="m-0">
            <span class="font-nunito-s16-w600-color1">{{item.label}}: </span>
            {{item.value}}
        </p>
    }
</div>

<div class="grid" style="margin-top: 30px;">
    <div class="col-8">
        <app-fec-summary [fec]="fec"></app-fec-summary>
        <div class="download-container font-nunito-s16-w600-color4" (click)="getPdf()">
            <i class="otic-download"></i> Descargar
        </div>
    </div>
    <div class="col-4">
        <div class="action-panel flex flex-column" [ngStyle]="{'margin-top': '52px'}">
            <p class="font-nunito-s16-w600-color1 my-2">Aprobación</p>
            <div class="flex flex-row justify-content-between">
                <p class="m-0 font-nunito-s16-w400-color2">{{fec?.fecStatus ?? '' | status}}</p>
            </div>
        </div>
        <div class="observations-container">
            <div class="font-nunito-s16-w600-color1" style="margin-top: 50px;">Observaciones</div>
            <textarea
                ngClass="observations-container-input"
                disabled
                class="w-full"
                pInputTextarea
                rows="5"
                cols="50"
                [autoResize]="true"
                [(ngModel)]="observations"
                placeholder="Ingrese observaciones"
                [maxlength]="255">
            </textarea>
            <small id="observations-characters" class="flex justify-content-end" style="color: #ABA7AF">
                {{ observations?.length ?? 0 }} / 255
            </small>
        </div>
    </div>
</div>

</app-page-container>
