import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren, effect } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CostType1Component } from '../cost-type-1/cost-type-1.component';
import { CostType2Component } from '../cost-type-2/cost-type-2.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { CostItemScenario } from '@shared/interfaces/costItemScenario';
import { ScenarioDescriptionComponent } from '../scenario-description/scenario-description.component';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { ScenarioSubtitleComponent } from '../scenario-subtitle/scenario-subtitle.component';
import { CostProvider } from '@pages/fec/cost.provider';
import { ListFileuploadComponent } from '@shared/components/list-fileupload/list-fileupload.component';
import { CostScenario } from '@shared/interfaces/costScenario';
import { valueToInteger } from '@shared/util/numberUtil';
import { CostManager } from '@shared/util/CostManager';
import { cloneObject } from '@shared/util/objectUtil';

@Component({
  selector: 'app-scenario-detail-cost',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputNumberModule,
    FieldContainerComponent,
    ButtonComponent,
    CostType1Component,
    CostType2Component,
    TextReadonlyComponent,
    ScenarioDescriptionComponent,
    ScenarioSubtitleComponent,
    ListFileuploadComponent
  ],
  templateUrl: './scenario-detail-cost.component.html',
  styleUrl: './scenario-detail-cost.component.scss'
})
export class ScenarioDetailCostComponent {
  @ViewChildren(CostType1Component) costItems1Component!: QueryList<CostType1Component>;
  @ViewChildren(CostType2Component) costItems2Component!: QueryList<CostType2Component>;
  @ViewChild('listFileuploadComponent') listFileuploadComponent!: ListFileuploadComponent;
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public next = new EventEmitter();
  type: number = 1;
  costItemScenario: CostItemScenario[] = [];
  processCost: CostScenario = {};

  public form: FormGroup = this.fb.group({
    provision: [0, [Validators.min(0)]]
  });

  constructor(
    private fb: FormBuilder,
    public scenarioProvider: ScenarioProvider,
    public costProvider: CostProvider
  ){
    effect(() => {
      this.reset();
    });
  }

  addItem(){
    this.costItemScenario.push({
      value: 0,
      quantity: 0
    });
  }

  validate(){
    let resultItem = true;
    for (let item of this.getComponents()){
      if (!item.validate()){
        resultItem = false;
      }
    }
    this.form.markAllAsTouched();
    if (!this.listFileuploadComponent.validate()){
      return false;
    }
    return this.form.valid && resultItem;
  }

  onCancel(){
    this.reset();
    this.clickCancel.emit();
  }

  confirm(){
    if (!this.validate())
      return;
    let cost = {
      ...this.costProvider.getCurrent(),
      items: this.getCostItemScenario(),
      provision: valueToInteger(this.form.value['provision']),
      files: this.listFileuploadComponent.getData()
    };
    this.costProvider.updateCurrentAndList({
      ...new CostManager(
        this.scenarioProvider.getCurrent(), 
        cost
      ).getProcessed()
    });
    this.next.emit();
  }

  change(){
    this.processCost = new CostManager(
      this.scenarioProvider.getCurrent(), 
      { items: this.getCostItemScenario() }
    ).getProcessed();
  }

  private getComponents(){
    if (this.type === 1){
      return this.costItems1Component.toArray();
    }
    else{
      return this.costItems2Component.toArray();
    }
  }

  private getCostItemScenario(){
    return this.getComponents().map(component => component.getData());
  }

  reset(){
    let current = cloneObject(this.costProvider.getCurrent());
    this.processCost =  new CostManager(
      this.scenarioProvider.getCurrent(), 
      current
    ).getProcessed();
    this.costItemScenario = current.items ?? [];
    this.form.reset(current);
    if (this.costItemScenario.length === 0){
      this.addItem();
    }
    if (current.costType === 'do'){
      this.type = 2;
    }
  }


}
