import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { FecStatusEnum } from '@shared/enums/fecStatusEnum';
import { Fec } from '@shared/interfaces/fec';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FecService } from '@shared/services/fec.service';
import { generateFecs, generateNumberArray, generateRandomNumber } from '@shared/util/generatorUtil';
import { JsonEditorComponent, JsonEditorOptions, NgJsonEditorModule } from 'ang-jsoneditor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-generator',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    FieldContainerComponent,
    ButtonComponent,
    MultiSelectModule,
    NgJsonEditorModule,
    ToastModule
  ],
  templateUrl: './generator.component.html',
  styleUrl: './generator.component.scss',
  providers: [provideNgxMask(), MessageService],
})
export class GeneratorComponent implements OnInit {
  public editorOptions: JsonEditorOptions;
  @ViewChild(JsonEditorComponent, { static: false }) editor?: JsonEditorComponent;
  public fecs: Fec[];

  userCreatedList: string[] = ["pruebasrelease@oticsofofa.cl", "joseperez@oticsofofa.cl", "mauromina@oticsofofa.cl"];
  comercialList: string[] = ["nonComercial", "comercial"];
  statusList: string[] = [FecStatusEnum.DRAFT, FecStatusEnum.APPROVED, FecStatusEnum.REJECTED, FecStatusEnum.PENDING];
  yearList: string[] = generateNumberArray(4);
  childrenList: string[] = generateNumberArray(8);
  scenarioList: string[] = generateNumberArray(4);
  costTypeList: string[] = ["dedicated_staff", "salon", "consulting", "it_costs", "other_expenses"];//"do", 
  costList: string[] = generateNumberArray(4);
  costItemList: string[] = generateNumberArray(4);
  scenarioYearList: string[] = generateNumberArray(4);

  public form: FormGroup = this.fb.group({
    quantity: ['', [Validators.required]],
    userCreated: ['', [Validators.required]],
    comercial: ['', [Validators.required]],
    children: ['', [Validators.required]],
    status: ['', [Validators.required]],
    year:  ['', [Validators.required]],
    scenario:  ['', [Validators.required]],
    costType:  ['', [Validators.required]],
    cost:  ['', [Validators.required]],
    costItem:  ['', [Validators.required]],
    scenarioYear:  ['', [Validators.required]]
  });

  public scenarioForm: FormGroup = this.fb.group({
    quantity: ['', [Validators.required]],
    cost: ['', [Validators.required]],
    itemCost: ['', [Validators.required]],
    incomeFiles:  ['', [Validators.required]],
    costFiles:  ['', [Validators.required]],
  });

  public fecYearForm: FormGroup = this.fb.group({
    scenario: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private loadingProvider: LoadingProvider,
    private fecService: FecService
  ) { 
    this.editorOptions = new JsonEditorOptions()
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
    this.editorOptions.mode = 'code'; //set only one mode
    this.fecs = [];
  }

  ngOnInit() {
    this.form.reset({
      quantity: generateRandomNumber(1,5),
      userCreated: ["pruebasrelease@oticsofofa.cl"],
      comercial: [...this.comercialList],
      status: [...this.statusList],
      year: [...this.yearList],
      children: [...this.childrenList],
      scenario:  [...this.scenarioList],
      costType:  [...this.costTypeList],
      cost:  [...this.costList],
      costItem:  [...this.costItemList],
      scenarioYear:  [...this.scenarioYearList]
    });
  }

  generate(){
    let config = this.form.value;
    console.log('GroupFecGenerateConfig', config);
    this.fecs = generateFecs(config);
    console.log(this.fecs);
  }

  insert(){
    for (const fec of this.fecs) {
      this.loadingProvider.show();
      this.fecService.createFecByGenerator(fec).subscribe({
        next: (resp) => {
          this.loadingProvider.hide();
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Insertado!!!' });
        },
        error: (err) => {
          this.loadingProvider.hide();
          let msg = err.error?.message || 'Ocurrió un error';
          console.log(msg);
          this.messageService.add({ severity: 'error', summary: 'Error', detail: msg });
        }
      });
    }
  }

  getData(data: any){
    this.fecs = data;
  }
}
