@if(fec){
    @if(fec.isComercial){
        <p-tabView [scrollable]="true" [(activeIndex)]="activeIndex">
            <p-tabPanel [header]="fec.comercialName! ">
                <app-fec-summary-year
                    [summary] = "fec.summary"
                />
            </p-tabPanel>
            @for (childScenario of fec.summary?.childrenScenario; track $index;) {
                <p-tabPanel [header]="childScenario.clientName ?? ''">
                    <app-fec-summary-year
                        [summary] = "fec.summary"
                        [rut]="childScenario.rut"
                    />
                </p-tabPanel>
            }
        </p-tabView>
    }
    @else {
        <app-fec-summary-year
            [summary] = "fec.summary"
        />
    }
}


