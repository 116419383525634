<div class="fec-year-container" >
    <div class="fec-year-title">
        <div class="fec-year-scenario">
            <h3 class="m-0 pr-2">
                {{ fecYear.description }}
            </h3>
            <div class="otic-edit-small" (click)="openEdit()"></div>
            <div class="otic-view-small" (click)="openView()"></div>
        </div>
        <div class="otic-close" (click)="deleteBoard()"></div>
    </div>
    
    <span class="fec-year-subtitle" >
        {{ fecYear.startDate }} - {{ fecYear.endDate }}
    </span>

    <h4 class="fec-year-list-title" >
        Escenarios
    </h4>
    
    <div class="fec-year-scenarios-container" pDroppable (onDrop)="drop()">
        @for (item of fecYear.scenarios; track $index;) {
            <app-scenario-item
                [inFecYear]="true"
                [scenario]="item"
                (delete)="deleteScenario(item, $index)"
            >
            </app-scenario-item>
        }
    </div>
    @if(fecYear.scenarios && fecYear.scenarios.length > 0){
        <div class="flex flex-row">
            <app-label-value
                [config]="{
                    type: 'amount',
                    label: 'Ingreso:',
                    value: fecYear.summary?.realIncome,
                    labelClass: 'font-nunito-s18-w600-color1',
                    valueClass: 'font-nunito-s18-w400-color2',
                    width: 230,
                    valuePadding: '0px 0px 0px 5px'
                }"
            />
            <app-label-value
                [config]="{
                    type: 'percentage',
                    label: 'Tasa:',
                    value: fecYear.summary?.effectiveRate,
                    labelClass: 'font-nunito-s18-w600-color1 p-10px',
                    valueClass: 'font-nunito-s18-w400-color2',
                    width: 150,
                    valuePadding: '0px 0px 0px 5px'
                }"
            />
        </div>
    }
    <div class="fec-year-footer">
        <div class="fec-year-selection">
            <form [formGroup]="form">
                <p-checkbox
                    formControlName="selected"
                    [binary]="true"
                    (onChange)="onCheckboxChange($event)"
                >
                </p-checkbox>
            </form>
            <span class="fec-year-selection-text">Seleccionar</span>
        </div>
    </div>
</div>

<app-modal
    title="Editar Cuadro"
    #detailFecYear
    [width]="945"
    [height]="662"
>
    <app-detail-fec-year
        [isNew]="false"
        (clickCancel)="detailFecYear.closeModal()"
        (save)="detailFecYear.closeModal()"
    ></app-detail-fec-year>
</app-modal>

<app-modal
    [title]="fecYear.description ?? 'Cuadro'"
    #fecYearSummaryModal
    [width]="940"
>
    <app-fec-summary-comercial-scenario
        [scenario]="fecYear.summary"
    />
</app-modal>
