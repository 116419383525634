import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { StorageService } from '@shared/services/storage.service';
import { getRoleMatch } from '@shared/util/roleUtil';

export const roleGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {
  
  const storageService: StorageService = inject(StorageService);
  let userRoles = storageService.getUserRoles();
  if (next.data['expectedRoles']){
    let routeRoles = next.data['expectedRoles'] as any[];
    console.log('userRoles', userRoles, 'routeRoles', routeRoles);  
    let match = getRoleMatch(routeRoles, userRoles);
    if (match!=null) {
      console.log('permiso encomtrado', match);
      return true;
    }
  }
  else {
    return true;
  }
  console.log(`url no permitida '${state.url}'`);
  const router: Router = inject(Router);
  return router.createUrlTree(['home']);
}
