import { SummaryPdfTemplateConfig } from "@shared/interfaces/summaryPdfTemplate"

const summaryBaseTemplate: any = [
    { text: "Entradas #client_name#", style: "header" },
    {
        style: "tableExample",
        layout: "noBorders",
        table: {
            widths: ["*", "auto", 10, "*", "auto"],
            body: [
                [
                    { text: "Compromiso de aportes del año:", style: "label2" },
                    { text: "#contribution_commitment#" },
                    {},
                    { text: "Ingresos cuenta no franquiciable:", style: "label2" },
                    { text: "#non_franchisable_account_income#" },
                ],
                [
                    { text: "Tasa administración final:", style: "label2" },
                    { text: "#final_administration_fee#" },
                    {},
                    { text: "Provisión PD:", style: "label2" },
                    { text: "#provision_pd#" },
                ],
                [{}, {}, {}, { text: "Provisión Consultoría:", style: "label2" }, { text: "#consultancy_provision#" }],
                [{}, {}, {}, { text: "Facturas adicionales:", style: "label2" }, { text: "#additional_invoice#" }],
                [
                    { text: "Ingreso contra tasa:", style: "label3" },
                    { text: "#counter_rate_income#", style: "label3" },
                    { text: "", style: "label3" },
                    { text: "Ingresos Brutos:", style: "label3" },
                    { text: "#gross_income#", style: "label3" },
                ],
            ],
        },
    },
    { text: "Gastos", style: "header" },
    { text: "Consolidado", style: "header" },
    {
        style: "tableExample",
        layout: "noBorders",
        table: {
            widths: ["*", 50, "*"],
            body: [
                [
                    {
                        layout: "noBorders",
                        table: {
                            widths: ["*", "*"],
                            body: [],
                        },
                    },
                    {},
                    {
                        layout: "noBorders",
                        table: {
                            widths: ["*", "*"],
                            body: [
                                [
                                    {
                                        text: "Ingreso real:",
                                        style: "label4",
                                    },
                                    {
                                        text: "#real_income#",
                                        style: "label4",
                                    },
                                ],
                                [
                                    {
                                        text: "Tasa real:",
                                        style: "label4",
                                    },
                                    {
                                        text: "#effective_rate#",
                                        style: "label4",
                                    },
                                ],
                            ],
                        },
                    },
                ],
            ],
        },
    },
]
export const styles = {
    label1: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 0, 0],
    },
    label2: {
        bold: true
    },
    label3: {
        color: "#184D9B",
        bold: true,
        fillColor: "#e0f4ff"
    },
    label4: {
        color: "#184D9B",
        bold: true,
        fillColor: "#e0f4ff",
        fontSize: 14
    },
    header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        color: "#184D9B",
    },
    subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
    },
    tableExample: {
        margin: [0, 5, 0, 15],
    },
    tableHeader: {
        bold: true,
        fontSize: 13,
        color: "black",
    },
}

const costBase = [
    { text: "#cost_name#", style: "label2" }, { text: "#cost_ammount#" }
]

const summaryYearBase: any = {
    style: "tableExample",
    layout: "noBorders",
    table: {
        widths: ["*", "auto"],
        body: [
            [{ text: "#year#", fontSize: 14, style: "label2" }, {}],
            [
                { style: "label3", text: "Tasa real" },
                { style: "label3", text: "#cost_effective_rate#" },
            ],
            [
                { style: "label3", text: "Ingreso real" },
                { style: "label3", text: "#cost_real_income#" },
            ]
        ],
    }
}

export default (templateConfig: SummaryPdfTemplateConfig, childIndex?: number): any => {
    const years: any[] = []
    let summaryBase = JSON.parse(JSON.stringify(summaryBaseTemplate))
    if(childIndex) summaryBase[0] = {...summaryBase[0], pageBreak: 'before'}
    templateConfig.years.forEach((yearConfig, index) => {
        let costs: any[] = []
        for (let indexG = 0; indexG < yearConfig.costs.length; indexG++) {
            let costBaseText = JSON.stringify(costBase).replace(/#(\w+)#/g, (match, word) => {
                return `#${word}${index + 1}_${indexG + 1}#`;
            });
            costs.push(JSON.parse(costBaseText))
        }

        const yearBaseText = JSON.stringify(summaryYearBase).replace(/#(\w+)#/g, (match, word) => {
            return `#${word}${index + 1}#`;
        });
        const yearBase = JSON.parse(yearBaseText)
        for (let i = 0; i < costs.length; i++) {
            yearBase.table.body.splice(1 + i, 0, costs[i]);
        }
        years.push(yearBase)
    });

    let costsIndex = 4
    for (let i = 0; i < years.length; i++) {
        summaryBase.splice(3 + i, 0, years[i]);
        costsIndex += 1
    }

    for (let i = 0; i < templateConfig.costsCount; i++) {
        let conSpentItem = [
            { text: `#con_cost_name${i + 1}#:`, style: "label2" },
            { text: `#con_cost_value${i + 1}#` },
        ]
        summaryBase[costsIndex].table?.body[0][0].table.body.push(conSpentItem)
    }

    if (childIndex) {
        let chainText = `child${childIndex}_`;
        const childSummaryContent = JSON.stringify(summaryBase).replace(/#(.*?)#/g, (match: any, p1: any) => {
            return `#${chainText}${p1}#`;
        });
        summaryBase = JSON.parse(childSummaryContent)
    }
    return {
        content: summaryBase
    };
}