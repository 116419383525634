import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'custompercent',
  standalone: true
})
export class CustomPercentPipe implements PipeTransform {

  transform(value: number | string | undefined, ...args: unknown[]): unknown {
    if (!value)
      return '0%';
    if (typeof value === 'string')
      value = parseFloat(value);
    if (value === 0)
      return '0%';
    return value.toLocaleString('es-ES', { 
      minimumFractionDigits: 2, 
      maximumFractionDigits: 2 }) + '%';
  }
}
