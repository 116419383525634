<app-page-container
    title="Registro de Potenciales"
    [paths]="paths"
>
    <app-client-form
        #clientFormComponent
        (previous)="previous()"
        [showPrevious]="from!=null"
        (toOticContacts)="toOticContacts($event)"
        (toClientData)="toClientData($event)"
        (final)="final($event)"
        [client]="client"
        (navigate)="navigate($event)"
        [mode]="mode"
    />
</app-page-container> 

