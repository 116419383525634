import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { EventBusService } from '@shared/services/event-bus.service';
import { Subscription } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { NotificationModalComponent } from '../notification-modal/notification-modal.component';
import { KeycloakAuthenticationService } from '@shared/services/authentication.service';
import { StorageService } from '@shared/services/storage.service';
import { SimulatedLoginComponent } from '../simulated-login/simulated-login.component';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    SidebarComponent,
    NotificationModalComponent,
    SimulatedLoginComponent
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  eventBusSub?: Subscription;
  user: any = {};
  env: string = environment.env;

  constructor(
    private eventBusService: EventBusService,
    private authenticationService: KeycloakAuthenticationService,
    private storageService: StorageService,
    private loadingProvider: LoadingProvider
  ) {

  }

  ngOnInit() {
    const user = this.storageService.getUser();
    
    this.user = {
      realName: user!.firstName + ' ' + user!.lastName,
      rol: '',
      username: ''
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
  }

  async logout(): Promise<void> {
    this.loadingProvider.show();
    await this.authenticationService.logout();
   // this.loadingProvider.hide();
  }
}
