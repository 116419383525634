import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { UserService } from '@shared/services/user.service';
import { rutValidator } from '@shared/validators/validators';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { BlockUIModule } from 'primeng/blockui';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { User } from '@shared/interfaces/user';
import { LoadingProvider } from '@shared/providers/loading.provider';

@Component({
  selector: 'app-user-data',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    CheckboxModule,
    NgxMaskDirective,
    NgxMaskPipe,
    BlockUIModule,
    FieldContainerComponent,
    RadioButtonModule
  ],
  providers: [provideNgxMask()],
  templateUrl: './user-data.component.html',
  styleUrl: './user-data.component.scss'
})
export class UserDataComponent {

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private loadingProvider: LoadingProvider) { }

  selectedStatus: any = null

  @Input() set user(value: User) {
    const formLoad = {
      ...value,
      isntAdmin: !value?.isAdmin
    }
    this.form.reset(formLoad ?? {});
    this.form.get('rut')?.disable();
  }

  @Input() update: boolean = false

  @Input() records: any = {}

  @Input() selectedIndex: number = 0

  @Output()
  public closeModal = new EventEmitter();

  @Output()
  public userAlreadyExists = new EventEmitter<{name: string, rut: string}>();

  public form: FormGroup = this.fb.group({
    rut: ['', [Validators.required, rutValidator]],
    fullName: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    number: ['', [Validators.required]],
    isntAdmin: [false],
    status: ['active'],
    roles: [[]]
  });

  userStatus: any[] = [
    { name: 'Activar', key: 'A', value: 'active' },
    { name: 'Desactivar', key: 'D', value: 'disabled' }
  ];

  validate() {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  saveUser() {
    if (!this.validate()) return
    this.loadingProvider.show()
    this.form.get('rut')?.enable()
    const { ['isntAdmin']: removedKey, ...form } = this.form.value;
    form.isAdmin = !removedKey
    form.number = parseInt(form.number, 10)
    this.userService.createUser({
      ...form,
      roles: []
    }).subscribe({
      next: (res) => {
        this.loadingProvider.hide()
        this.records.push(res)
        this.form.reset()
        this.closeModal.emit()
      },
      error: (err) => {
        if (err.error.message = 'Usuario ya existe') {
          this.userAlreadyExists.emit({ name: form.fullName, rut: form.rut })
        }
        this.loadingProvider.hide()
        this.closeModal.emit()
      }
    });
  }

  updateUser() {
    if (!this.validate()) return
    this.loadingProvider.show()
    this.form.get('rut')?.enable()
    const { ['isntAdmin']: removedKey, ...form } = this.form.value;
    form.isAdmin = !removedKey
    form.number = parseInt(form.number, 10)
    this.userService.updateUser({
      ...form
    }).subscribe({
      next: (res) => {
        this.loadingProvider.hide()
        this.records[this.selectedIndex] = res
        this.closeModal.emit()
      },
      error: (err) => {
        console.log(err)
        this.loadingProvider.hide()
      }
    });
  }

  validarNumero(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
    }
  }
}
