<div class="layout-topbar">
    <div class="topbar-header">
        <div class="topbar-header-left">
            <img src="../../../../assets/logo.svg" alt="otic-logo" width="142" height="60">
            <h1 class="topbar-header-title">
                Módulo FEC
            </h1>

            <app-simulated-login/>
        
        </div>
        <div class="topbar-header-right">
            <app-notification-modal></app-notification-modal>
            <div class="topbar-header-username">
                <img src="../../../../assets/icons/avatar.svg" alt="user-icon" width="36" height="36">
                <p class="topbar-header-username-text">{{user?.realName}}</p>
            </div>
            <i class="topbar-header-right-logout" (click)="logout()">
                <img src="../../../../assets/icons/salir.svg" alt="logout-icon" width="24" height="24">
            </i>
        </div>
    </div>
    <div class="topbar-navbar">
        <app-sidebar></app-sidebar>
    </div>
</div>
