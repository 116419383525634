<form [formGroup]="form" autocomplete="off">
    <div class="flex flex-row justify-content-between p-2">
        <div class="flex flex-column">
                <p-dropdown 
                    styleClass="otic-form-s-input" 
                    formControlName="user" 
                    [options]="users" 
                    placeholder="Simular Login"
                    [dropdownIcon]="'fa fa-caret-down'"
                    [showClear]="false"
                    (onChange)="change($event)"
                >
                    <ng-template let-item pTemplate="selectedItem">
                        {{item.email}}
                    </ng-template>
                    <ng-template let-item pTemplate="item">
                        {{getItem(item)}}
                    </ng-template>
                </p-dropdown>
        </div>
    </div>
</form>
