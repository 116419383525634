
<div class="flex flex-column p-3">
    <form [formGroup]="form" autocomplete="off">
        <div class="grid">
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="quantity"
                    label="Cantidad"
                >
                    <input 
                        type="text" 
                        pInputText 
                        formControlName="quantity"
                    />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="userCreated"
                    label="userCreated"
                >
                    <p-multiSelect 
                        [options]="userCreatedList" 
                        formControlName="userCreated"
                        [filter]="false" 
                        styleClass="w-full"
                    />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="comercial"
                    label="Comercial o no Comercial"
                >
                    <p-multiSelect 
                        [options]="comercialList" 
                        formControlName="comercial"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="status"
                    label="Estados de FEC"
                >
                    <p-multiSelect 
                        [options]="statusList" 
                        formControlName="status"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="year"
                    label="Años por periodo"
                >
                    <p-multiSelect 
                        [options]="yearList" 
                        formControlName="year"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="children"
                    label="Cantidad Hijos Comercial"
                >
                    <p-multiSelect 
                        [options]="childrenList" 
                        formControlName="children"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="scenario"
                    label="Cantidad Escenarios"
                >
                    <p-multiSelect 
                        [options]="scenarioList" 
                        formControlName="scenario"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="costType"
                    label="Tipos de Costos"
                >
                    <p-multiSelect 
                        [options]="costTypeList" 
                        formControlName="costType"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="cost"
                    label="Costos por Escenarios"
                >
                    <p-multiSelect 
                        [options]="costList" 
                        formControlName="cost"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="costItem"
                    label="Items por Costos"
                >
                    <p-multiSelect 
                        [options]="costItemList" 
                        formControlName="costItem"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="scenarioYear"
                    label="Escenarios por año"
                >
                    <p-multiSelect 
                        [options]="scenarioYearList" 
                        formControlName="scenarioYear"
                        [filter]="false" 
                        styleClass="w-full"
                     />
                </app-field-container>
            </div>
        </div>
    </form>
    <div class="grid">
        <div class="col-3">
            <app-button 
                label="Generar" 
                (clickButton)="generate()" 
                [width]="140" 
                [height]="46" 
                [fontWeight]="700"
            ></app-button>
        </div>
        <div class="col-3">
            <app-button 
                label="Insertar" 
                (clickButton)="insert()" 
                [width]="140" 
                [height]="46" 
                [fontWeight]="700"
            ></app-button>
        </div>
    </div>
    <json-editor  [options]="editorOptions" [data]="fecs" (change)="getData($event)"></json-editor>
</div>
<p-toast />


