<app-page-container
    title="Evaluaciones Comerciales"
    [paths]="paths"
>
    <div class="mb-8">
        <app-steps
            [steps]="steps" 
        ></app-steps>
    </div>

    <router-outlet></router-outlet>
</app-page-container>    
