<div class="grid-container">
    <div class="grid align-items-center">
        <div class="col">
            <h3>{{title}}</h3>
        </div>
        @if(addTitle && showAddButton){
            <div class="col">
                <div class="flex align-items-center justify-content-end">
                    <app-button 
                        [label]="addTitle"
                        icon="otic-plus" 
                        (clickButton)="onAdd()" 
                        [width]="181" 
                        [height]="48" 
                        [fontWeight]="500"
                    ></app-button>
                </div>
            </div>
        }
    </div>
    <ng-content></ng-content>
</div>
