import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common'
import { StatusLabelEnum } from '@shared/enums/statusLabelEnum';
import { FecStatusEnum } from '@shared/enums/fecStatusEnum';

@Component({
  selector: 'app-status-chip',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './status-chip.component.html',
  styleUrl: './status-chip.component.scss'
})
export class StatusChipComponent {

  @Input() status: string = "";

  private commonStatusColors: Record<string, string> = {
    [FecStatusEnum.DRAFT]: '#266FE0',
    [FecStatusEnum.REJECTED]: '#ED475B',
  };

  private fecStatusColors: Record<string, string> = {
    [FecStatusEnum.PENDING]: '#FF8B10',
    [FecStatusEnum.APPROVED]: '#00C56B',
  };

  private clientStatusColors: Record<string, string> = {
    'registered': '#FFFF'
  };

  private userStatusColors: Record<string, string> = {
    'active': '#00C56B',
    'disabled': '#FF8B10',
  };

  private statusColors: Record<string, string> = {
    ...this.commonStatusColors,
    ...this.fecStatusColors,
    ...this.clientStatusColors,
    ...this.userStatusColors
  };

  statusObject: any = Object.entries(StatusLabelEnum).reduce((result: any, [key, value]) => {
    result[key] = value;
    return result;
  }, {});

  getStatusLabel(): any {
    return this.statusObject[this.status];
  }

  getStatusColor(): { [key: string]: string } {
    if (this.status === 'new' || this.status === 'registered') {
        return {
            'color': '#266FE0',
            'border': '1px solid #266FE0'
        }
    }
    return { 'background-color': this.statusColors[this.status] || 'blue' };
  }

} 

