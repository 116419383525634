import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { Client } from '@shared/interfaces/client';
import { stringToFullRutFormat } from '@shared/util/rutUtil';
import { MessageProvider } from '@shared/providers/message.provider';
import { ModeEnum } from '@shared/enums/modeEnum';
import { ClientFormComponent } from '@pages/client/client-form/client-form.component';
import { FecProvider } from '../fec.provider';
import { FecService } from '@shared/services/fec.service';
import { Fec } from '@shared/interfaces/fec';
import { isBlank } from '@shared/util/stringUtils';

@Component({
  selector: 'app-client-detail',
  standalone: true,
  imports: [
    CommonModule,
    ClientFormComponent
  ],
  templateUrl: './client-detail.component.html'
})
export class ClientDetailComponent implements OnInit {
  @ViewChild('clientFormComponent') clientFormComponent!: ClientFormComponent;
  client: Client = {};
  id?: string;
  mode: ModeEnum = ModeEnum.EDIT;
  from: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private loadingProvider: LoadingProvider,
    private fecService: FecService,
    private messageProvider: MessageProvider,
    public fecProvider: FecProvider,
  ){}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (!isBlank(data['mode'])){
        this.mode = data['mode'];
      }
    });
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.from = params['from'];
      if (this.id){
        this.loadingProvider.show();
        this.fecService.getFec(this.id).subscribe({
          next: (resp) => {
            this.setFec(resp);
            this.loadingProvider.hide();
          },
          error: (err) => {
            this.loadingProvider.hide();
            let msg = err.error?.message || 'Ocurrió un error';
            console.error(msg);
            this.messageProvider.showError("Error", msg, ()=> {
              this.router.navigate(['/fec-list']);
            });
          }
        });
      }
      else {
        if (params['type'] === 'comercial')//1: noncomercial, 2: comercial
          this.client = { isPotential: true, isComercial: true };
        else
          this.client = { isPotential: true, isComercial: false };
        this.fecProvider.updateCurrent({client: this.client});
      }
    });
  }

  previous(){
    this.router.navigate([`/${this.from}`]);
  }

  toOticContacts(client: Client){
    if (this.mode === ModeEnum.VIEW){
      this.clientFormComponent.showOticContacts();
      return;
    }
    if (this.id){
      this.updateFec(this.getFecForSave(client), () => {
        this.clientFormComponent.showOticContacts();
      });
    }
    else{
      this.loadingProvider.show();
      this.fecService.createFec(this.getFecForSave(client)).subscribe({
        next: (resp) => {
          this.setFec(resp);
          this.loadingProvider.hide();
          this.router.navigate(
            ['/fec-detail'], 
            { queryParams: {id: resp.id, index: 1}}
          );
        },
        error: (err) => {
          this.loadingProvider.hide();
          let msg = err.error?.message || 'Ocurrió un error';
          console.error(msg);
          if(msg === 'El rut corresponde a cliente existente') {
            const rut = stringToFullRutFormat(client.rut!)
            const unsafeHtml = `Estimado usuario: <br> El cliente RUT <b>${rut}</b> es un cliente vigente, ingrese uno diferente.`;
            this.messageProvider.showErrorHtml("Cliente vigente", unsafeHtml);
          } else {
            this.messageProvider.showError("Error", msg);
          }
        }
      });
    }
  }

  toClientData(client: Client){
    this.clientFormComponent.showClientData();
  }

  final(client: Client){
    if (this.mode === ModeEnum.VIEW){
      this.router.navigate(
        ['/fec-detail/economic-evaluation'],
        { queryParams: {id: this.id}}
      );
      return;
    }
    this.updateFec(this.getFecForSave(client), () => {
      this.router.navigate(
        ['/fec-detail/economic-evaluation'],
        { queryParams: {id: this.id}}
      );
    });
  }

  private updateFec(fec: Fec, redirectFn: () => void){
    this.loadingProvider.show();
    this.fecService.updateFec(fec).subscribe({
      next: (resp) => {
        this.setFec(resp);
        this.loadingProvider.hide();
        redirectFn();
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  navigate(index: number){
 
  }

  getFecForSave(client: Client){
    return {
      ...this.fecProvider.getCurrent(),
      id: this.id,
      client: {
        ...this.client,
        ...client
      }
    };
  }

  setFec(fec: Fec){
    this.id = fec.id;
    this.client = fec.client ?? {};
    this.fecProvider.updateCurrent(fec);
  }

}
