<div style="min-height: 485px;">
    <h4 class="fec-year-name-edit-content-title">Identificación Cuadro</h4>
    <form [formGroup]="form" autocomplete="off">
        <div class="formgrid grid col-12">
            <div class="flex flex-column field otic-form-input col-12 p-0">
                <app-field-container
                    [form]="form"
                    fieldName="description"
                    label="Nombre Cuadro"
                >
                    <input 
                        type="text" 
                        pInputText 
                        [maxlength]="50"
                        placeholder="Ingrese Nombre de Cuadro" 
                        formControlName="description"
                    />
                </app-field-container>
            </div>
            <h4 class="col-12 p-0 m-0 mb-3">Periodicidad del Cuadro</h4>
            <div class="flex flex-column field otic-form-input col-12 p-0">
            @if(isNew && !fecProvider.getCurrent().inOneTaxYear){
                <app-field-container
                    [form]="form"
                    fieldName="periodYear"
                >
                    <p-dropdown 
                        class="w-full"
                        formControlName="periodYear" 
                        [options]="periods" 
                        placeholder="Seleccione Periodo"
                        [dropdownIcon]="'fa fa-caret-down'"
                    >
                        <ng-template let-item pTemplate="item">
                            {{ item.startDate + ' - ' + item.endDate }}
                        </ng-template>
                        <ng-template let-item pTemplate="selectedItem">
                            {{ item.startDate + ' - ' + item.endDate }}
                        </ng-template>
                    </p-dropdown>     
                </app-field-container> 
            }
            @else {
                <app-text-readonly
                    [value]="period"
                    [width]="380"
                ></app-text-readonly>
            }
            </div>
        </div>
    </form>
</div>
<div class="flex flex-row justify-content-end gap-2">
    <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
    <app-button label="Confirmar" (clickButton)="onSave()"></app-button>
</div>

