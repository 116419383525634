import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TabViewModule } from 'primeng/tabview';
import { FecSummaryScenarioComponent } from '../fec-summary-scenario/fec-summary-scenario.component';
import { Scenario } from '@shared/interfaces/scenario';

@Component({
  selector: 'app-fec-summary-comercial-scenario',
  standalone: true,
  imports: [
    CommonModule,
    TabViewModule,
    FecSummaryScenarioComponent
  ],
  templateUrl: './fec-summary-comercial-scenario.component.html',
  styleUrl: './fec-summary-comercial-scenario.component.scss'
})
export class FecSummaryComercialScenarioComponent {
  @Input() scenario?: Scenario;
}
