import { Component, Input } from '@angular/core';
import { BreadcrumbComponent } from '../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-page-container',
  standalone: true,
  imports: [
    BreadcrumbComponent
  ],
  templateUrl: './page-container.component.html',
  styleUrl: './page-container.component.scss'
})
export class PageContainerComponent {
  @Input()
  public title: string|null = null;
  @Input()
  public paths: string[] = [];
}
