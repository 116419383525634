<div class="scenario-container">
    @if(!fecProvider.getCurrent().isPotential && (fecProvider.getPreviousFecSummary() || fecProvider.getCurrentFecSummary())){
        <div class="grid align-items-center scenario-section-header">
            <div class="col">
                <div class="scenario-section-header-title">Antecedentes</div>
            </div>
            <div class="col">
                <div class="flex align-items-center justify-content-end">
                    <app-button label="Comparar" 
                        (clickButton)="openComparative()" 
                        [width]="110" 
                        [height]="40" 
                        [fontWeight]="500"
                    ></app-button>
                </div>
            </div>
        </div>
        <div class="scenario-section-body">
            @if(fecProvider.getPreviousFecSummary()){
                <app-scenario-item
                    label="Cierre Anterior"
                    type="antecedent"
                ></app-scenario-item>
            }
            @if(fecProvider.getCurrentFecSummary()){
                <app-scenario-item
                    label="Situación Actual"
                    type="actual"
                ></app-scenario-item>
            }
        </div>
    }
    <div class="grid align-items-center scenario-section-header">
        <div class="col">
            <div class="scenario-section-header-title">Escenarios</div>
        </div>
        <div class="col">
            <div class="flex align-items-center justify-content-end">
                <app-button label="Agregar" 
                    icon="otic-plus"
                    (clickButton)="openAddScenario()" 
                    [width]="110" 
                    [height]="40" 
                    [fontWeight]="500"
                ></app-button>
            </div>
        </div>
    </div>
    <div class="scenario-section-body" >
        @for (item of scenarioProvider.getList(); track $index;) {
            <app-scenario-item pDraggable (onDragStart)="onDragStartScenario(item)"
                [scenario]="item"
                (delete)="deleteScenario(item, $index)"
                (edit)="openEditScenario(item, $index)"
                (view)="openViewScenario(item, $index)"
            ></app-scenario-item>
        }
    </div>
</div>

<app-modal
    (hide)="onScenarioHide()"
    title="Agregar Escenario"
    #scenarioModal
>
    <app-scenario 
        #scenarioItem
        (clickCancel)="closeScenarioModal()"
        (finish)="saveScenario()"
    ></app-scenario>
</app-modal>

<app-modal
    title="Comparativa"
    #comparativeModal
    [width]="1100"
>
    <app-comparative-table/>
</app-modal>

<app-modal
    [title]="scenarioSelected.description ?? 'Escenario'"
    #scenarioSummaryModal
    [width]="940"
>
    <app-fec-summary-comercial-scenario
        [scenario]="scenarioSelected"
    />
</app-modal>

