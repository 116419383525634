import summaryTemplate, { styles } from './summary.template'
import yearsTemplate from './years.template'
import defaultTemplate from './test.template'

interface DocumentDefinition {
    content: any[],
    styles: any
}

export default (content: any): DocumentDefinition => {
    switch (content?.contentId) {
        case 'consolidado&periodos':
            const documentDefinition: DocumentDefinition = summaryTemplate(content.templateConfig)
            const yearsDocumentDefinition = yearsTemplate(content.templateConfig)
            documentDefinition.content.push(yearsDocumentDefinition.content)
            content.templateConfig.childrenTemplateConfig.forEach((childTemplateConfig: any, childIndex: number) => {
                const summaryChildDocumentDefinition: DocumentDefinition = summaryTemplate(childTemplateConfig, childIndex + 1)
                const yearsChildDocumentDefinition = yearsTemplate(childTemplateConfig)
                summaryChildDocumentDefinition.content.push(yearsChildDocumentDefinition.content)
                documentDefinition.content.push(summaryChildDocumentDefinition.content)
            })
            
            documentDefinition.styles = styles
            return documentDefinition
        default:
            return defaultTemplate
    }
}