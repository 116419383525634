import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, effect } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { CostScenario } from '@shared/interfaces/costScenario';
import { ScenarioDescriptionComponent } from '../scenario-description/scenario-description.component';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { ScenarioSubtitleComponent } from '../scenario-subtitle/scenario-subtitle.component';
import { CostProvider } from '@pages/fec/cost.provider';
import { Scenario } from '@shared/interfaces/scenario';
import { ScenarioManager } from '@shared/util/ScenarioManager';

@Component({
  selector: 'app-scenario-summary-costs',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TextReadonlyComponent,
    ScenarioDescriptionComponent,
    ScenarioSubtitleComponent
  ],
  templateUrl: './scenario-summary-costs.component.html',
  styleUrl: './scenario-summary-costs.component.scss'
})
export class ScenarioSummaryCostsComponent {
  processScenario: Scenario = {};
  addButtonDisabled: boolean = false;
  @Output()
  public previous = new EventEmitter();
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public next = new EventEmitter();
  @Output()
  public viewDetail = new EventEmitter();

  constructor(
    public scenarioProvider: ScenarioProvider,
    public costProvider: CostProvider
    ){
    effect(() => {
      this.processScenario = new ScenarioManager(
        {
          ...this.scenarioProvider.getCurrent(),
          costs: this.costProvider.getList()
        }
      ).getProcessed();
      this.addButtonDisabled = this.costProvider.hasEmptyCost();
    });
  }

  validate(){
    return true;
  }

  onPrevious(){
    this.previous.emit();
  }

  onCancel(){
    this.clickCancel.emit();
  }

  onViewDetail(item: CostScenario){
    this.costProvider.updateCurrent(item);
    this.viewDetail.emit();
  }

  onNext(){
    if (!this.validate())
      return;
    this.scenarioProvider.updateCurrentWithCosts();
    this.next.emit();
  }

}
