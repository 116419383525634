import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';
import { Fec } from '@shared/interfaces/fec';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FecService } from '@shared/services/fec.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { generatePdf, buildSummaryContent } from '@shared/util/pdfUtil';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { EpochDatePipe } from '@shared/pipes/epoch-date.pipe';
import { FecSummaryComponent } from '@pages/fec/fec-summary/fec-summary.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { StatusPipe } from '@shared/pipes/status.pipe';
import { MappedObservation } from '@shared/interfaces/mappedObservations';
import { getApprovalRoleLabel, getRoleLabel } from '@shared/util/roleDictionaryUtils';

@Component({
  selector: 'app-read-only-fec',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ConfirmComponent,
    FecSummaryComponent,
    InputTextareaModule,
    StepbarComponent,
    PageContainerComponent,
    ButtonComponent,
    StatusPipe
  ],
  providers: [EpochDatePipe],
  templateUrl: './read-only-fec.component.html',
  styleUrl: './read-only-fec.component.scss'
})
export class ReadOnlyFecComponent implements OnInit {

  @ViewChild('warning') warning?: ConfirmComponent;
  @ViewChild('confirm') confirm?: ConfirmComponent;
  fec?: Fec;
  id?: string;
  observations?: string;
  paths: string [] = ['HOME', 'Aprobación FEC', 'Mis FEC'];
  headerInfo: { label: string; value: unknown; }[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private fecService: FecService,
    private epochDatePipe: EpochDatePipe,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if (!this.id) {
        this.router.navigate(['/fec-tracking']);
        return;
      }
      this.loadingProvider.show();
      this.fecService.getFec(this.id).subscribe((resp) => {
        this.fec = resp;
        this.observations = this.formatObservations(this.mapObservations(resp));
        this.loadingProvider.hide();
        this.setValueHeaderInfo(resp);
      });
    });
  }

  getPdf() {
    const content = buildSummaryContent(this.fec!)
    generatePdf('open', content)
  }

  setValueHeaderInfo(item: Fec) {
    const companyName = item?.clientName;
    const rut = item?.rut;
    const fecDate = item?.createdAt;
    const fecNumber = item?.correlative;
    const executive = item?.client?.followUpContact?.name;

    this.headerInfo = [
      {
        label: 'Nombre Empresa',
        value: companyName ?? '',
      },
      ...(!item.isComercial ? [{
        label: 'RUT',
        value: rut ?? '',
      }] : []),
      {
        label: 'Fecha realización',
        value: this.epochDatePipe.transform(fecDate ?? 0),
      },
      {
        label: 'N° FEC',
        value: fecNumber ?? '',
      },
      {
        label: 'Ejecutivo',
        value: executive ?? '',
      }
    ];
  }

  mapObservations(fec: Fec): MappedObservation[]{
    const observations: MappedObservation[] = [];

      if (fec.roleApprovalFlow && fec.observation && fec.observation !== 'Sin observaciones') {
          observations.push({
              role: getRoleLabel(fec.roleApprovalFlow),
              observation: fec.observation
          });
      }

      if (fec.approval) {
          for (const approval of fec.approval) {
              if (approval.observation && approval.observation !== 'Sin observaciones') {
                  observations.push({
                      role: getRoleLabel(getApprovalRoleLabel(approval.roleCode)),
                      observation: approval.observation
                  });
              }
          }
      }

      return observations;
  }

  formatObservations(observations: MappedObservation[]): string {
    return observations.map(obs => `${obs.role}: ${obs.observation}`).join('\n');
  }

}
