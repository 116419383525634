import { Pipe, PipeTransform } from '@angular/core';
import { dateToStringDDMMYYYYHHmmss } from '@shared/util/dateUtil';

@Pipe({
  name: 'epochtodatetime',
  standalone: true
})
export class EpochDateTimePipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): unknown {
    if (!value) return '';
    return dateToStringDDMMYYYYHHmmss(new Date(value));
  }

}
