@if((scenario?.childrenScenario?.length ?? 0) > 0){
    <p-tabView [scrollable]="true">
        <p-tabPanel header="Consolidado">
            <app-fec-summary-scenario
                    [scenario] = "scenario"
            />
        </p-tabPanel>
        @for (item of scenario?.childrenScenario; track $index;) {
            <p-tabPanel [header]="item.clientName ?? ''">
                <app-fec-summary-scenario
                    [scenario] = "item"
                />
            </p-tabPanel>
        }
    </p-tabView>
}
@else {
    <app-fec-summary-scenario
        [scenario] = "scenario"
    />
}
