import { Component, Input, ViewChild, effect } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators  } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { DragDropModule } from 'primeng/dragdrop';
import { CheckboxModule } from 'primeng/checkbox';
import { FecYearProvider } from '@pages/fec/fecYear.provider';
import { FecYear } from '@shared/interfaces/fecYear';
import { ScenarioItemComponent } from '../scenario-item/scenario-item.component';
import { FecProvider } from '@pages/fec/fec.provider';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { DetailFecYearComponent } from '../detail-fec-year/detail-fec-year.component';
import { Scenario } from '@shared/interfaces/scenario';
import { LabelValueComponent } from '@shared/components/label-value/label-value.component';
import { FecSummaryComercialScenarioComponent } from '@pages/fec/fec-summary/fec-summary-comercial-scenario/fec-summary-comercial-scenario.component';

@Component({
  selector: 'app-fec-year',
  standalone: true,
  imports: [
    ButtonModule,
    ReactiveFormsModule,
    CommonModule,
    FieldContainerComponent,
    DragDropModule,
    CheckboxModule,
    ScenarioItemComponent,
    ModalComponent,
    DetailFecYearComponent,
    FecSummaryComercialScenarioComponent,
    LabelValueComponent
  ],
  templateUrl: './fec-year.component.html',
  styleUrl: './fec-year.component.scss'
})

export class FecYearComponent {
  @ViewChild('detailFecYear') detailFecYear!: ModalComponent;
  @ViewChild('fecYearSummaryModal') fecYearSummaryModal!: ModalComponent;
  @Input() fecYear: FecYear = {};

  public form: FormGroup = this.fb.group({
    selected: [false, [Validators.required]],
  });

  constructor(
    private fb: FormBuilder,
    public fecProvider: FecProvider,
    private fecYearProvider: FecYearProvider
  ) { 
    effect(() => {
      this.form.reset(this.fecYear); 
    });
  }

  onCheckboxChange(event: any) {
    this.fecYearProvider.updateCurrentAndList({
      ...this.fecYear,
      ...this.form.value
    });
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  drop() {
    this.fecYearProvider.updateWithScenarioSelected(this.fecProvider.isComercial(), this.fecYear);
  }

  deleteBoard(): void {
    this.fecYearProvider.delete(this.fecYear);
  }

  openEdit(){
    this.fecYearProvider.updateCurrent(this.fecYear);
    this.detailFecYear.openModal();
  }

  openView(){
    this.fecYearSummaryModal.openModal();
  }

  deleteScenario(item: Scenario, index: number): void {
    this.fecYearProvider.deleteScenarioInFecYear(this.fecProvider.isComercial(), this.fecYear, item);
  }

}
