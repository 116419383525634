import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { StatusChipComponent } from '@shared/components/status-chip/status-chip.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { Client } from '@shared/interfaces/client';
import { ClientFilter } from '@shared/interfaces/clientFilter';
import { ClientService } from '@shared/services/client.service';
import { StorageService } from '@shared/services/storage.service';
import { Menu, MenuModule } from 'primeng/menu';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';

@Component({
  selector: 'app-grid-potential-client',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    GridContainerComponent,
    StatusChipComponent,
    MenuModule
  ],
  templateUrl: './grid-potential-client.component.html',
  styleUrl: './grid-potential-client.component.scss'
})
export class GridPotentialClientComponent implements OnInit {
  records!: Client[];
  totalRecords: number = 0;
  loading: boolean = false;
  @ViewChild('menu') menu?: Menu; 
  itemSelected: any = null;
  gridActions: any[] = [];
  @Output()
  public openEdit = new EventEmitter<Client>();
  @Output()
  public openView = new EventEmitter<Client>();
  @Output()
  public openNew = new EventEmitter();
  @Output()
  public delete = new EventEmitter<Client>();
  @Input() 
  public filter: ClientFilter = {};
  @Input()
  mode: ModeEnum = ModeEnum.EDIT;
  viewAction = {
    label: 'Ver',
    icon: 'otic-view',
    command: () => {
      this.onOpenView(this.itemSelected); 
    }
  };
  editAction = {
    label: 'Editar',
    icon: 'otic-edit',
    command: () => {
      this.onOpenEdit(this.itemSelected); 
    }
  };

  constructor(
    private storageService: StorageService,
    private clientService: ClientService
  ) {}

  ngOnInit(): void {
    
  }

  onSearch(params: TableLazyLoadEvent){
    this.loading = true;
    this.clientService.pagedFindClient({
      filters: this.filter,
      first: params.first ?? 0,
      rows: params.rows ?? 10
    }).subscribe((resp) => {
      this.records = resp.list;
      this.totalRecords = resp.total;
      this.loading = false;
    });
  }

  onOpenNew(){
    this.openNew.emit();
  }

  onOpenEdit(item: Client) {
    this.openEdit.emit(item);
  }

  onOpenView(item: Client) {
    this.openView.emit(item);
  }

  /* Se quita por solicitud del cliente
  onDelete(item: Client) {
    this.delete.emit(item);
  }*/

  openGridActions(event: any, client: Client){
    this.itemSelected = client;
    if (this.mode === ModeEnum.VIEW){
      this.gridActions = [this.viewAction];
    }
    else if (this.mode === ModeEnum.ONLY_MY_RECORDS){
      let hisClient = client.userCreated === this.storageService.getUsername();
      if (hisClient){
        this.gridActions = [this.viewAction, this.editAction]; 
      }
      else {
        this.gridActions = [this.viewAction]; 
      }
    }
    else if (this.mode === ModeEnum.EDIT) {
      this.gridActions = [this.viewAction, this.editAction];
    }
    this.menu?.toggle(event);  
  }
}
