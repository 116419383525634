import { Injectable, signal } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root'
})
export class MessageProvider {
    private type: 'warning' | 'success' | 'info'| 'error' | 'default' = 'error';
    private title: string = '';
    private message: string = '';
    private messageHtml: SafeHtml = '';
    private visible = signal<boolean>(false);
    private finishFn?: () => void;
    private confirmFn?: () => void;
    private cancelFn?: () => void;
    private rejectLabel?: string = undefined;
    private acceptLabel?: string = undefined;

    constructor(private sanitizer: DomSanitizer){}

    isVisible(){
        return this.visible();
    }

    getType(){
        return this.type;
    }

    getTitle(){
        return this.title;
    }

    getMessage(){
        return this.message;
    }

    getMessageHtml(){
        return this.messageHtml;
    }

    getRejectLabel(){
        return this.rejectLabel;
    }

    getAcceptLabel(){
        return this.acceptLabel;
    }

    private reset(){
        this.finishFn = undefined;
        this.confirmFn = undefined;
        this.cancelFn = undefined;
    }

    showError(title: string, msg: string, finishFn?: () => void ){
        this.reset();
        this.type = 'error';
        this.title = title;
        this.message = msg;
        this.finishFn = finishFn;
        this.visible.set(true);
    }

    showErrorHtml(title: string, messageHtml: string, finishFn?: () => void ){
        this.reset();
        this.type = 'error';
        this.title = title;
        this.message = '';
        this.messageHtml = this.sanitizer.bypassSecurityTrustHtml(messageHtml)
        this.finishFn = finishFn;
        this.visible.set(true);
    }

    showSuccess(title: string, msg: string, finishFn?: () => void ){
        this.reset();
        this.type = 'success';
        this.title = title;
        this.message = msg;
        this.finishFn = finishFn;
        this.visible.set(true);
    }

    showWarningConfirm(title: string, msg: string, confirmFn?: () => void, 
        cancelFn?: () => void, acceptLabel?: string, rejectLabel?: string){
        this.reset();
        this.type = 'warning';
        this.title = title;
        this.message = msg;
        this.confirmFn = confirmFn;
        this.cancelFn = cancelFn;
        this.rejectLabel = rejectLabel;
        this.acceptLabel = acceptLabel;
        this.visible.set(true);
    }

    hide(){
        this.visible.set(false);
    }

    executeFinish(){
        if (this.finishFn)
            this.finishFn();
    }

    executeConfirmFn(){
        if (this.confirmFn)
            this.confirmFn();
    }

    executeCancelFn(){
        if (this.cancelFn)
            this.cancelFn();
    }
}
