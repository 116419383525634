import { Component, OnInit, ViewChild } from '@angular/core';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { FilterContainerComponent } from '@shared/components/filter-container/filter-container.component';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { StatusChipComponent } from '@shared/components/status-chip/status-chip.component';
import { Menu, MenuModule } from 'primeng/menu';
import { StatusLabelEnum } from '@shared/enums/statusLabelEnum';
import { FecService } from '@shared/services/fec.service';
import { Fec } from '@shared/interfaces/fec';
import { FecFilter } from '@shared/interfaces/fecFilter';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { FecSummaryComponent } from '../fec-summary/fec-summary.component';
import { Content, generatePdf, buildSummaryContent } from '@shared/util/pdfUtil'
import { MessageProvider } from '@shared/providers/message.provider';
import { FecStatusEnum } from '@shared/enums/fecStatusEnum';
import { arrayIsEmpty } from '@shared/util/listUtil';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { getModeMatch } from '@shared/util/roleUtil';
import { StorageService } from '@shared/services/storage.service';
import { isNotBlank } from '@shared/util/stringUtils';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';
import { FecApprovalDetailComponent } from '@pages/dev-tools/fec-approval-detail/fec-approval-detail.component';

export enum FecListComponentModeEnum {
  EDIT = "edit",
  NOT_MY_FECS = "not_my_fecs",
  VIEW_AND_NOT_MY_FECS = "view_and_not_my_fecs"
}

@Component({
  selector: 'app-fec-list',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    RouterOutlet,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    PageContainerComponent,
    FilterContainerComponent,
    GridContainerComponent,
    StatusChipComponent,
    MenuModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ModalComponent,
    FecSummaryComponent,
    CustomPercentPipe,
    ChileanPesoPipe,
    FecApprovalDetailComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './fec-list.component.html',
  styleUrl: './fec-list.component.scss'
})
export class FecListComponent implements OnInit {
  mode: FecListComponentModeEnum = FecListComponentModeEnum.EDIT;
  @ViewChild('fecSummaryModal') fecSummaryModal!: ModalComponent;
  @ViewChild('menu') menu?: Menu;
  @ViewChild('approvalModal') approvalModal!: ModalComponent; 

  paths: string[] = ["HOME", "Evaluaciones Comerciales"];
  records!: Fec[];
  totalRecords: number = 0;
  loading: boolean = false;
  useAuthData: boolean = false;
  filterTabs: Array<{ label: string; active: boolean }> = [];
  itemSelected: any = null;
  
  gridActionsBase = [
    {
      label: 'Ver',
      icon: 'otic-view',
      command: () => {
        this.onRowView(this.itemSelected);
      },
      statuses: []
    },
    {
      label: 'Editar',
      icon: 'otic-edit',
      command: () => {
        this.onRowEdit(this.itemSelected);
      },
      statuses: [FecStatusEnum.DRAFT]
    },
    {
      label: 'Editar',
      icon: 'otic-edit',
      command: () => {
        this.generateNewVersion(this.itemSelected);
      },
      statuses: [FecStatusEnum.REJECTED]
    },
    {
      label: 'Eliminar',
      icon: 'otic-delete',
      command: () => {
        this.onRowDelete(this.itemSelected);
      },
      statuses: [FecStatusEnum.DRAFT]
    },
    {
      label: 'Descargar',
      icon: 'pi pi-download',
      command: () => {
        this.onRowDonwload(this.itemSelected);
      },
      statuses: []
    }
  ];
  gridActions: any[] = [];
  statusList = Object.entries(StatusLabelEnum)
    .map(([key, value]) => ({ name: value, value: key }));

  public form: FormGroup = this.fb.group({
    rut: [''],
    clientName: [''],
    comercialName: ['']
  });

  constructor(private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private storageService: StorageService,
    private fecService: FecService) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data['expectedRoles']){
        let mode = getModeMatch(data['expectedRoles'], this.storageService.getUserRoles());
        if (isNotBlank(mode))
          this.mode = mode;
      }
    });
    this.filterTabs = [];
    if (this.mode === FecListComponentModeEnum.NOT_MY_FECS 
      || this.mode === FecListComponentModeEnum.VIEW_AND_NOT_MY_FECS){
      this.filterTabs = [
        {
          label: 'FEC Aprobadas',
          active: true
        },
        {
          label: 'FEC Rechazadas',
          active: false
        }
      ]
    }
    else {
      this.filterTabs = [
        {
          label: 'Mis evaluaciones',
          active: true
        },
        {
          label: 'FEC Aprobadas',
          active: false
        },
        {
          label: 'FEC Rechazadas',
          active: false
        }
      ]
    }
    this.onSearch({ first: 0, rows: 10 });
  }

  onSearch(params: TableLazyLoadEvent) {
    this.loading = true;
    this.fecService.pagedFindFec({
      filters: this.getFilters(),
      first: params.first ?? 0,
      rows: params.rows ?? 10
    }).subscribe((resp) => {
      this.records = resp.list;
      this.totalRecords = resp.total;
      this.loading = false;
    });
  }

  onRowEdit(item: Fec) {
    this.router.navigate(['/fec-detail'], { queryParams: {id: item.id, from: 'fec-list'}});
  }

  onRowDelete(item: Fec) {
    this.messageProvider.showWarningConfirm(
      "Eliminar la FEC", 
      "¿Está seguro de eliminar esta FEC?",
      ()=> {
        this.fecService.deleteFec({
          id: item.id
        }).subscribe((data) => {
          this.onSearch({ first: 0, rows: 10 });
        })
      }
    );
  }


  generateNewVersion(fec: Fec){
    this.loadingProvider.show();
    this.fecService.generateNewVersion(fec).subscribe({
      next: (resp) => {
        this.loadingProvider.hide();
        this.router.navigate(
          ['/fec-detail'], 
          { queryParams: {id: resp.id, from: 'fec-list'}}
        );
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  openGridActions(event: any, fec: Fec) {
    this.itemSelected = fec;
    let fecStatus = fec.fecStatus as FecStatusEnum;
    if (this.mode === FecListComponentModeEnum.VIEW_AND_NOT_MY_FECS){
      this.gridActions = [
        {
          label: 'Ver',
          icon: 'otic-view',
          command: () => {
            this.onRowView(this.itemSelected);
          }
        }
      ];
    }
    else {
      this.gridActions = this.gridActionsBase.filter(
        action => {
          if (arrayIsEmpty(action.statuses))
            return true;
          return action.statuses.includes(fecStatus) 
            && fec.userCreated === this.storageService.getUsername();
        });
    }
    this.menu?.toggle(event);
  }

  onRowView(fec: Fec) {
    this.router.navigate(
      ['/read-only-fec'], 
      { queryParams: {id: fec.id}}
    );
  }

  onRowDonwload(item: Fec) {
    const content: Content = buildSummaryContent(item)
    generatePdf('open', content)
  }

  changeActiveTab(selectedTab: { label: string; active: boolean }) {
    this.filterTabs.forEach(tab => {
      tab.active = false;
    });
    selectedTab.active = true;
    this.onSearch({ first: 0, rows: 10 });
  }

  getActiveTab() {
    return this.filterTabs.find(tab => tab.active);
  }

  getFilters() {
    let selectedTab = this.getActiveTab();
    let filter = this.form.value as FecFilter;
    if (selectedTab?.label === 'Mis evaluaciones') 
      filter = { ...filter, 
        useUserSession: true, 
        fecStatuses:  [FecStatusEnum.DRAFT, FecStatusEnum.PENDING]};
    if (selectedTab?.label === 'FEC Aprobadas') filter = { ...filter, fecStatus: FecStatusEnum.APPROVED };
    if (selectedTab?.label === 'FEC Rechazadas') filter = { ...filter, fecStatus: FecStatusEnum.REJECTED };
    return filter;
  }

  viewApproval(fec: Fec){
    this.itemSelected = fec;
    this.approvalModal.openModal();
  }

}
