export const cleanRut = (rut: string): string => {
  return typeof rut === 'string'
    ? rut
      .replace(/^(0+)/g, '')
      .replace(/([^0-9kK]+)/g, '')
      .toUpperCase()
    : '';
};

export const validateRut = (rut: string): boolean => {
  if (typeof rut !== 'string') {
    return false;
  }

  if (/^0+/.test(rut)) {
    return false;
  }

  if (!/^([1-9]\d{0,2}(\.\d{3})*|\d{1,8})-?[\dkK]$/.test(rut)) {
    return false;
  }

  const cleanedRut = cleanRut(rut);

  const rutFormatRegex = /^\d+-?[0-9kK]$/;
  if (!rutFormatRegex.test(cleanedRut)) {
    return false;
  }

  let t = parseInt(cleanedRut.slice(0, -1), 10);
  let m = 0;
  let s = 1;

  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }

  const v = s > 0 ? '' + (s - 1) : 'K';
  return v === cleanedRut.slice(-1);
};

export const stringToRutFormat = (rut: string): string => {
  const cleanedRut = cleanRut(rut);
  return `${cleanedRut.slice(0, -1)}-${cleanedRut.slice(-1)}`;
};

export const generateRut = () => {
  let randomNumber = Math.floor(Math.random() * 100000000);
  return randomNumber + "-" + generateRutVerifierDigit(randomNumber.toString());
}

export const generateValidRut = (): string => {
  let formattedRut = generateRut();
  while (!validateRut(formattedRut)) {
    formattedRut = generateRut();
  }
  return formattedRut;
}

export const generateRutVerifierDigit = (rutWithoutVerifier: string): string => {
  let t = parseInt(rutWithoutVerifier, 10);
  let m = 0;
  let s = 1;
  while (t > 0) {
    s = (s + (t % 10) * (9 - (m++ % 6))) % 11;
    t = Math.floor(t / 10);
  }
  return s > 0 ? String(s - 1) : 'K';
}

export const stringToFullRutFormat = (rut: string): string => {
  rut = rut.replace(/[^\dkK]/g, '').toUpperCase(); // Elimina caracteres no válidos y convierte a mayúsculas
  if (rut.length < 2) return rut;
  var rutLimpio = rut.slice(0, -1);
  var dv = rut.slice(-1);
  rutLimpio = rutLimpio.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return rutLimpio + "-" + dv;
}
