import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators  } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { TableModule } from 'primeng/table';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { FakeStepsComponent } from '@shared/components/fake-steps/fake-steps.component';
import { Menu, MenuModule } from 'primeng/menu';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { RutPipe } from '@shared/pipes/rut.pipe';
import { ModeEnum } from '@shared/enums/modeEnum';

@Component({
  selector: 'app-grid-company-list',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    CardModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    FormsModule,
    StepbarComponent,
    DropdownModule,
    TableModule,
    ModalComponent,
    GridContainerComponent,
    FakeStepsComponent,
    MenuModule,
    FieldContainerComponent,
    InputMaskModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    RutPipe
  ],
  providers: [provideNgxMask()],
  templateUrl: './grid-company-list.component.html',
  styleUrl: './grid-company-list.component.scss'
})
export class GridCompanyListComponent implements OnInit {
  ModeEnum = ModeEnum;
  @ViewChild('menu') menu?: Menu; 
  @ViewChild('modal') modal!: ModalComponent; 
  @Input() comercialChildren: any[] = [];
  selectedIndexForDetail: number = -1;
  indexSelected: number = -1;
  itemSelected: any = null;
  visible: boolean = false;
  @Input()
  mode: ModeEnum = ModeEnum.EDIT;
  steps = [{label: 'Datos Empresa'},{label: 'Contactos OTIC'}];
  
  public form: FormGroup = this.fb.group({
    clientName: ['', [Validators.required]],
    rut: ['', [Validators.required]]
  });

  contactType: any = [];

  constructor(
    private fb: FormBuilder
  ){}

  ngOnInit(): void {
    this.form.reset({});
  }

  onHide(){
    this.selectedIndexForDetail = -1;
  }

  openModal(): void {
    this.modal.openModal();
  }

  closeModal(): void {
    this.modal.closeModal();
  }

  addNewCompany(): void {
    this.form.reset();
    this.modal.openModal();
  }

  saveCompanies(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.comercialChildren.push({...this.form.value});
    this.closeModal();
  }

  updateCompanies(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) return;
    this.comercialChildren[this.selectedIndexForDetail] =  this.form.value;
    this.closeModal();
  }

  getCompanyList(){
    return this.comercialChildren;
  }

}
