import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scenario-subtitle',
  standalone: true,
  imports: [],
  templateUrl: './scenario-subtitle.component.html',
  styleUrl: './scenario-subtitle.component.scss'
})
export class ScenarioSubtitleComponent {
  @Input() 
  public label?: string;
  @Input() 
  public fontWeight: number = 600;
  @Input() 
  public fontSize: number = 18;
}
