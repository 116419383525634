import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { S3Service } from '@shared/services/s3.service';
import { v4 as uuid } from 'uuid';
import { ButtonComponent } from '../button/button.component';
import { CommonModule } from '@angular/common';
import { finalize } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { isBlank } from '@shared/util/stringUtils';
import { donwloadFileGet } from '@shared/util/fileUtil';
import { ItemFile } from '@shared/interfaces/itemFile';
import { arrayIsEmpty } from '@shared/util/listUtil';
import { ProgressBarModule } from 'primeng/progressbar';

@Component({
  selector: 'app-list-fileupload',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    InputTextModule,
    FormsModule,
    ProgressBarModule
  ],
  templateUrl: './list-fileupload.component.html',
  styleUrl: './list-fileupload.component.scss'
})
export class ListFileuploadComponent {
  loading: boolean = false;
  progress: number = 0;
  indexSelected: number = -1;
  itemSelected?: ItemFile;
  errorMessage?: string;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() 
  public itemFileList: ItemFile[] = [];
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  
  constructor(
    private s3Service: S3Service
  ) {
  }

  donwloadFile(item: ItemFile){
    this.s3Service.generateGetObjectSignedUrl(item.id).subscribe((presignedUrl) => {
      let filename = item.description.endsWith(item.extension) 
        ? item.description : `${item.extension}.${item.extension}`;
      donwloadFileGet(presignedUrl, filename, item.type);
    })
  }

  editFile(item: ItemFile, index: number){
    this.indexSelected = index;
    this.itemSelected = {...item};
  }

  deleteFile(item: ItemFile, index: number){
    this.itemFileList.splice(index, 1);
  }

  finishEdit(item: ItemFile, index: number){
    this.indexSelected = -1;
    if (isBlank(item.description)){
      item.description = this.itemSelected?.description ?? '';
    }
  }

  putWithProgress(presignedUrl: string, file: File, itemFile: ItemFile) {
    return this.s3Service.getHttpClientClean().put(presignedUrl, file, {
      reportProgress: true,
      observe: 'events'
    })
    .pipe(
      finalize(() => {
        this.itemFileList.push(itemFile);
        this.fileUpload.nativeElement.value = "";
      })
    );
  }

  onFileCancel(event: Event): void {
    
  }

  onFileSelected(event: Event): void {
    this.errorMessage = undefined;
    const input = event.target as HTMLInputElement;
    const files = input.files;
    
    if (!files || files.length === 0 || !files[0]){
      return;
    }
    const file: File = files[0];
    const extension = file.name.split('.').pop();
    const fileId = `${uuid()}.${extension}`;
    const itemFile = {
      id: fileId,
      description: file.name,
      extension,
      type: file.type,
      size: file.size
    };
    
    this.s3Service.generatePutObjectSignedUrl(fileId).subscribe(signedUrl => {
      this.putWithProgress(signedUrl, file, itemFile).subscribe(event => {
        if (event.type == HttpEventType.UploadProgress) {
          if (event.loaded === event.total){
            this.loading = false;
            this.errorMessage = undefined;
          }
          else {
            this.loading = true;
            if (event.total && event.total > 0){
              this.progress = Math.round((event.loaded / event.total) * 100);
            }
          }
        }
      })
    });
  }

  getData(): ItemFile[]{
    return [
      ...this.itemFileList
    ];
  }

  validate(){
    if (this.loading){
      this.errorMessage = 'Espere a que termine la carga';
      return false;
    }
    if (this.required && arrayIsEmpty(this.itemFileList)){
      this.errorMessage = 'Este campo es requerido';
      return false;
    }
    return true;
  }

}



