import { Component, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Client } from '@shared/interfaces/client';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { FilterContainerComponent } from '@shared/components/filter-container/filter-container.component';
import { ClientFilter } from '@shared/interfaces/clientFilter';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GridPotentialClientComponent } from '../grid-potential-client/grid-potential-client.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { getModeMatch } from '@shared/util/roleUtil';
import { StorageService } from '@shared/services/storage.service';
import { isNotBlank } from '@shared/util/stringUtils';
import { ClientStatusLabelEnum } from '@shared/enums/clientStatusEnum';

@Component({
  selector: 'app-potential-base',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterOutlet,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    PageContainerComponent,
    FilterContainerComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
    GridPotentialClientComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './potential-base.component.html',
  styleUrl: './potential-base.component.scss'
})
export class PotentialBaseComponent implements OnInit  {
  ModeEnum = ModeEnum;
  mode: ModeEnum = ModeEnum.EDIT;
  @ViewChild('grid') grid?: GridPotentialClientComponent; 
  paths: string[] = ["HOME", "Potenciales", "Base Potenciales"];
  public form: FormGroup = this.fb.group({
    rut: [''],
    clientName: [''],
    clientStatus: ['']
  });

  statusList = Object.entries(ClientStatusLabelEnum)
  .map(([key, value]) => ({ name: value, value: key }));

  constructor(
    private router: Router,
    private route: ActivatedRoute, 
    private fb: FormBuilder,
    private storageService: StorageService
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data['expectedRoles']){
        let mode = getModeMatch(data['expectedRoles'], this.storageService.getUserRoles());
        if (isNotBlank(mode))
          this.mode = mode;
      }
    });
  }

  openView(item: Client) {
    this.router.navigate(['/view-potential-client'], { queryParams: {id: item.id, from: 'potential-base'}});
  }

  openEdit(item: Client) {
    this.router.navigate(['/potential-client'], { queryParams: {id: item.id, from: 'potential-base'}});
  }

  getFilter(){
    return this.form.value as ClientFilter;
  }

}
