import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'rut',
})
export class RutPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return "";
    }
    const cleanedValue = value.replace(/[^0-9kK]/g, "");
    const lastChar = cleanedValue.slice(-1);
    const remainingChars = cleanedValue.slice(0, -1);
    const formattedValue = remainingChars.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formattedValue + "-" + lastChar;
  }
}
