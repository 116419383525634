import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { CostItemScenario } from '@shared/interfaces/costItemScenario';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-cost-type-1',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    FieldContainerComponent
  ],
  templateUrl: './cost-type-1.component.html',
  styleUrl: './cost-type-1.component.scss'
})
export class CostType1Component implements OnInit {
  public form: FormGroup = this.fb.group({
    description: ['', [Validators.required]],
    value: [0, [Validators.required, Validators.min(1)]],
    quantity: [0, [Validators.required, Validators.min(1)]]
  });
  @Input() 
  public showLabel?: boolean = false;
  @Input() 
  public data: CostItemScenario = {};
  @Output()
  public change = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form.reset(this.data);
    this.form.valueChanges.subscribe(values => { 
      this.change.emit();
    });
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  getData(): CostItemScenario {
    return {
      ...this.form.value
    }
  }

}
