<div class="notification">
    <img (click)="openDialog()" src="../../../../assets/icons/notificacion.svg" alt="notify-icon" width="100%" height="100%">
    <p-dialog
        header="Centro Notificaciones"
        [(visible)]="visible"
        [modal]="true"
        [style]="{ width: '734px', height: '595px'}"
        [closable]="false"
    >
        <ng-template pTemplate="header">
            <div class="notification-header">
                <h3 class="m-0">Centro Notificaciones</h3>
                <img 
                    src="../../../../assets/icons/x.svg"
                    alt="close-modal"
                    width="18"
                    height="18"
                    style="cursor: pointer; position: relative;"
                    (click)="closeDialog()"
                >
            </div>
        </ng-template>
        <div>
            <h3 class="mt-0 notification-title">Mensaje</h3>
            <app-notification
                type="success"
                message="La FEC N° 12345 tiene un nuevo cambio de estado, el estado final es aprobado."></app-notification>
            <app-notification
                type="info"
                message="La FEC N° 12346 se encuentra emitida y pendiente de aprobación."></app-notification>
            <app-notification
                type="rejected"
                message="La FEC N° 12355 tiene un nuevo cambio de estado, el estado final es rechazado."></app-notification>
            <app-notification
                type="success"
                message="La FEC N° 12336 se encuentra emitida y pendiente de aprobación."></app-notification>
        </div>
        <ng-template pTemplate="footer">
            <p-button (click)="visible = false" label="Cerrar" pAutoFocus [autofocus]="true"></p-button>
        </ng-template>
    </p-dialog>
</div>