import { ScenarioBase } from "@shared/interfaces/scenarioBase";
import { IncomeScenarioManager } from './IncomeScenarioManager';

export class ScenarioBaseManager {
    static createScenarioBase() : ScenarioBase {
        return {
            ...IncomeScenarioManager.createIncomeScenario(),
            costs: [],
            totalCost: 0,
            realIncome: 0,
            effectiveRate: 0
        };
    }
}

