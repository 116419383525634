import { Component } from '@angular/core';

@Component({
  selector: 'app-api-otic-admin',
  standalone: true,
  imports: [],
  templateUrl: './api-otic-admin.component.html',
  styleUrl: './api-otic-admin.component.scss'
})
export class ApiOticAdminComponent {

}
