@if(label){
    <div class="field p-0 m-0">
        <label for="item">{{label}}</label>
        <div 
            class="text-readonly"
            [style.width]="getWidth()"
        >
            @if(type === 'amount'){
                {{value | chileanpeso}}
            }
            @else if(type === 'percentage'){
                {{value | custompercent}}
            }
            @else {
                {{value}}
            }
        </div>
    </div>
}
@else {
    <div 
        class="text-readonly"
        [style.width]="getWidth()"
    >
        @if(type === 'amount'){
            {{value | chileanpeso}}
        }
        @else if(type === 'percentage'){
            {{value | custompercent}}
        }
        @else {
            {{value}}
        }
    </div>
}

