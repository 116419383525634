
<div class="flex flex-column p-3">
    <form [formGroup]="form" autocomplete="off" class="flex flex-row justify-content-between mb-20px">
            <div class="flex flex-column">
                <label for="rut">RUT</label>
                <input
                    class="otic-form-s-input" 
                    type="text" 
                    pInputText 
                    placeholder="Todos"
                    [maxlength]="50" 
                    formControlName="rut"
                    mask="0.000.000-A || 00.000.000-A"
                />
            </div>
            <div class="flex flex-column">
                <label for="clientName">Nombre Cliente</label>
                <input type="text" pInputText placeholder="Todos" formControlName="clientName" class="otic-form-s-input"/>
            </div>
            <div class="flex flex-column">
                <label for="clientStatus">Estado</label>
                <p-dropdown 
                    styleClass="otic-form-s-input" 
                    formControlName="clientStatus" 
                    [options]="statusList" 
                    placeholder="Todos"
                    [dropdownIcon]="'fa fa-caret-down'"
                    [showClear]="true"
                >
                </p-dropdown>
            </div>
            <div class="flex flex-column">
                <label for="recordStatus">Estado Registro</label>
                <p-dropdown 
                    styleClass="otic-form-s-input" 
                    formControlName="recordStatus" 
                    [options]="recordStatusList" 
                    [dropdownIcon]="'fa fa-caret-down'"
                >
                </p-dropdown>
            </div>
    </form>
    <div class="col-12 p-0 pb-2">
        <div class="flex align-items-center justify-content-end" style="gap: 5px;">
            <app-button label="Buscar" (clickButton)="onSearch({})"/>
            <app-button label="Eliminar Seleccionados" (clickButton)="deleteSelected()"/>
        </div>
    </div>
    <p-table 
        [paginator]="true"
        [lazy]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        [rowsPerPageOptions]="[10, 25, 20]"
        [rows]="10"
        [showCurrentPageReport]="true"
        (onLazyLoad)="onSearch($event)"
        [totalRecords]="totalRecords"
        [loading]="loading"
        [value]="records" 
        [tableStyle]="{ 'min-width': '50rem' }" 
        styleClass="p-datatable-striped"
        [(selection)]="selected" 
        dataKey="id" 
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
                <th scope="col">ID</th>
                <th scope="col">Registro</th>
                <th style="width:130px" scope="col">RUT</th>
                <th scope="col">Nombre</th>
                <th scope="col">Nombre Comercial</th>
                <th scope="col" class="text-center">Comercial</th>
                <th scope="col" class="text-center">InOneTaxYear</th>
                <th scope="col" class="text-center">Years</th>
                <th scope="col" class="text-center">Estado</th>
                <th scope="col" class="text-center">Creado</th>
                <th scope="col" class="text-center">Creado Por</th>
                <th scope="col" class="text-center">Modificado</th>
                <th scope="col" class="text-center">Modificado Por</th>
                <th scope="col" class="text-center">Eliminado</th>
                <th scope="col" class="text-center">Eliminado Por</th>
                <th scope="col" class="text-center">Opciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr>
                <td>
                    <p-tableCheckbox [value]="item" />
                </td>
                <td>{{ item.id }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.rut }}</td>
                <td>{{ item.clientName }}</td>
                <td>{{ item.comercialName }}</td>
                <td class="text-center">{{item.isComercial ?? 'false'}}</td>
                <td class="text-center">{{item.inOneTaxYear}}</td>
                <td class="text-center">{{item.startYear + '-' + item.finishYear}}</td>
                <td class="text-center">{{item.clientStatus}}</td>
                <td class="text-center">{{item.createdAt | epochtodatetime}}</td>
                <td class="text-center">{{item.userCreated}}</td>
                <td class="text-center">{{item.updatedAt | epochtodatetime}}</td>
                <td class="text-center">{{item.userUpdated}}</td>
                <td class="text-center">{{item.deletedAt | epochtodatetime}}</td>
                <td class="text-center">{{item.userDeleted}}</td>
                <td>
                    <div class="grid align-items-center">
                        <div class="col">
                            <div class="flex align-items-center justify-content-center" style="gap: 5px;">
                                <i class="pi pi-eye cursor-pointer" (click)="view(item)"></i>
                                <i class="pi pi-sitemap cursor-pointer" (click)="viewApproval(item)"></i>
                                <i class="pi pi-download cursor-pointer" (click)="download(item)"></i>
                                <i class="pi pi-times cursor-pointer" (click)="delete(item)"></i>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-toast />
<app-modal [title]="recordSelected.clientName ?? 'Detalle'" [height]="610" [width]="679" #viewModal>
    <json-editor  [options]="editorOptions" [data]="recordSelected"></json-editor>
</app-modal>
<app-modal [title]="recordSelected.clientName ?? 'Flujo de Aprobación'" [height]="800" [width]="950" #approvalModal>
    <app-fec-approval-detail [fec]="recordSelected"  />
</app-modal>
