<form [formGroup]="form" autocomplete="off">
    <app-field-container
        [form]="form"
        fieldName="searchTerm"
        [label]="label"
    >
        <div class="grid">
            <div class="col">
                @if(mask){
                    <input
                        type="text"
                        [style]="{ width: '648px' }"
                        pInputText
                        [placeholder]="label"
                        formControlName="searchTerm"
                        (keyup.enter)="onSearch()"
                        [mask]="mask"
                    />
                }
                @else {
                    <input
                        type="text"
                        [style]="{ width: '648px' }"
                        pInputText
                        [placeholder]="label"
                        formControlName="searchTerm"
                        (keyup.enter)="onSearch()"
                    />
                }
            </div>    
            <div class="col align-content-end">
                <app-button 
                    label="Buscar" 
                    (clickButton)="onSearch()"
                    [width]="140"
                    [height]="40"
                />
            </div> 
        </div> 
    </app-field-container>
</form>
