<app-page-container
    title="Admistrador de Parámetros"
    [paths]="paths"
>
    <app-grid-container
        title="Listado de Parámetros"
        addTitle="Generar FEC"
        [showAddButton]="false"
    >
        <p-table 
            [paginator]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[10, 25, 20]"
            [rows]="10"
            [showCurrentPageReport]="true"
            [value]="parameters" 
            [tableStyle]="{ 'min-width': '50rem' }" 
            styleClass="p-datatable-striped"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Descripción</th>
                    <th scope="col" class="text-center">Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>{{ item.parameterName }}</td>
                    <td>{{ item.description }}</td>
                    <td>
                        <div class="grid align-items-center">
                            <div class="col">
                                <div class="flex align-items-center justify-content-center" style="gap: 5px;">
                                    <i class="pi pi-pencil cursor-pointer" (click)="openEdit(item)"></i>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </app-grid-container>

</app-page-container>
