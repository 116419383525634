import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-field-container',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './field-container.component.html',
  styleUrl: './field-container.component.scss'
})
export class FieldContainerComponent {
  @Input() 
  public fieldName: string = '';

  @Input() 
  public label?: string;

  @Input() 
  public form?: FormGroup;

  @Input() 
  public errorMessage?: string;

  getControl(){
    return this.form?.controls[this.fieldName];
  }

  getError(){
    let ctrl = this.getControl();
    if (!ctrl?.errors || !ctrl.touched){
      return null;
    }
    let errors = ctrl?.errors || {};
    if (errors['required'])
      return `Este campo es requerido`;
    else if (errors['email'])
      return `El formato de email es incorrecto`;
    else if (errors['min'])
      return `Debe ser mayor a ${errors['min']['min']}`;
    else if (errors['max'])
      return `Debe ser menor o igual a ${errors['max']['max']}`;
    else if (errors['minlength'])
      return `Debe tener como mínimo ${errors['minlength']['requiredLength']} caracteres`;
    else if (errors['rutInvalid'])
      return 'El rut no es válido';
    else if (errors['mask'])
      return 'El valor es incorrecto';
    console.log(errors);
    return 'Otro Error';
  }

}
