import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-notification',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss'
})
export class NotificationComponent {
  @Input()
  public message: string = "";

  @Input()
  public type: string = "";

  get borderStyle() {
    switch (this.type) {
      case 'success':
        return { 'border-left': '6px solid rgba(0, 197, 107, 0.2)' };
      case 'info':
        return { 'border-left': '6px solid rgba(38, 111, 224, 0.2)' };
      case 'rejected':
        return { 'border-left': '6px solid rgba(237, 71, 91, 0.2)' };
      default:
        return {};
    }
 }
}
