import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { ClientService } from '@shared/services/client.service';
import { TabViewModule } from 'primeng/tabview';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { Client } from '@shared/interfaces/client';
import { SearchBoxComponent } from '@shared/components/search-box/search-box.component';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { Menu, MenuModule } from 'primeng/menu';
import { isNotBlank } from '@shared/util/stringUtils';
import { FecService } from '@shared/services/fec.service';
import { LoadingProvider } from '../../../shared/providers/loading.provider';
import { FecSummaryComponent } from '../fec-summary/fec-summary.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Fec } from '@shared/interfaces/fec';
import { ButtonModule } from 'primeng/button';
import { MessageProvider } from '@shared/providers/message.provider';
import { getModeMatch } from '@shared/util/roleUtil';
import { StorageService } from '@shared/services/storage.service';
import { ModeEnum } from '@shared/enums/modeEnum';

@Component({
  selector: 'app-search-potential-client',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    PageContainerComponent,
    TabViewModule,
    StepbarComponent,
    SearchBoxComponent,
    GridContainerComponent,
    TableModule,
    MenuModule,
    ModalComponent,
    FecSummaryComponent,
    ButtonModule
  ],
  templateUrl: './search-potential-client.component.html',
  styleUrl: './search-potential-client.component.scss'
})
export class SearchPotentialClientComponent {
  mode: ModeEnum = ModeEnum.EDIT;
  paths: string[] = ["HOME", "Control de Gestión", "Generar FEC"];
  expandedRows: any = {};
  @ViewChild('menu') menu?: Menu;
  @ViewChild('fecSummaryModal') fecSummaryModal!: ModalComponent;
  activeIndexTab: number = 0;
  itemSelected: Client = {};
  fecSelected?: Fec;
  records: Client[] = [];
  totalRecords: number = 0;
  loading: boolean = false;
  filter: any = {};
  from: string = 'search-potential-client';
  gridActions: any[] = [];
  viewAction = {
    label: 'Ver FEC',
    icon: 'otic-edit',
    command: () => {
      this.onView(this.itemSelected);
    }
  };
  createAction = {
    label: 'Crear FEC',
    icon: 'otic-plus-blue',
    command: () => {
      this.onCreateFec(this.itemSelected);
    }
  };

  constructor(private router: Router,
    private route: ActivatedRoute,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private storageService: StorageService,
    private clientService: ClientService,
    private fecService: FecService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      if (data['expectedRoles']){
        let mode = getModeMatch(data['expectedRoles'], this.storageService.getUserRoles());
        if (isNotBlank(mode))
          this.mode = mode;
      }
    });
  }

  onSearchByRut(value: string) {
    this.filter = { rut: value };
    this.onSearch({});
  }

  onSearchByName(value: string) {
    this.filter = { clientName: value };
    this.onSearch({});
  }

  onSearchByComercialName(value: string) {
    this.filter = { comercialName: value };
    this.onSearch({});
  }


  accept() {
    if (isNotBlank(this.filter.rut)) {
      this.router.navigate(['/fec-detail'], { queryParams: {from: this.from}});
    }
    else if (isNotBlank(this.filter.clientName)) {
      this.router.navigate(['/fec-detail'], { queryParams: {from: this.from}});
    }
    else if (isNotBlank(this.filter.comercialName)) {
      this.router.navigate(
        ['/fec-detail'], 
        { queryParams: {type: 'comercial',from: this.from}}
      );
    }
  }

  onSearch(params: TableLazyLoadEvent, checkRecords: boolean = true) {
    this.loading = true;
    this.clientService.pagedFindClient({
      filters: this.filter,
      first: params?.first ?? 0,
      rows: params?.rows ?? 5
    }).subscribe((potentialResp) => {
      this.records = potentialResp.list;
      this.loading = false;
      if (checkRecords)
        this.checkRecords(potentialResp.total)
    });
  }

  onLazySearch(params: TableLazyLoadEvent) {
    this.onSearch(params, false)
  }

  checkRecords(totalRecords: number) {
    this.totalRecords = totalRecords;
    if (this.totalRecords === 0) {
      this.messageProvider.showWarningConfirm(this.getTitleConfirm(), this.getMessageConfirm(), () => {
        this.accept();
      });
    }
  }

  openGridActions(event: any, client: Client) {
    this.itemSelected = client;
    if (this.mode === ModeEnum.VIEW){
      this.gridActions = [this.viewAction];
    }
    else if (this.mode === ModeEnum.ONLY_MY_RECORDS){
      let hisClient = client.userCreated === this.storageService.getUsername();
      if (hisClient){
        this.gridActions = [this.viewAction, this.createAction]; 
      }
      else {
        this.gridActions = [this.viewAction]; 
      }
    }
    else if (this.mode === ModeEnum.EDIT){
      this.gridActions = [this.viewAction, this.createAction];
    }
    this.menu?.toggle(event);
  }

  onView(item: Client) {
    this.loadingProvider.show();
    this.fecService.pagedFindFec({
      filters: {
        clientId: item.id
      },
      first: 0,
      rows: 5
    }).subscribe((resp) => {
      console.log(resp);
      this.loadingProvider.hide();
      if (resp.list && resp.list.length > 0) {
        this.fecSelected = resp.list[0];
      }
      else {
        this.fecSelected = undefined;
      }
      this.fecSummaryModal.openModal();
    });
  }

  onCreateFec(client: Client) {
    if(!client.isPotential) client.id = `VIG_` + client.rut;
    this.loadingProvider.show();
    this.fecService.createFec({client}).subscribe({
      next: (resp) => {
        this.loadingProvider.hide();
        this.router.navigate(
          ['/fec-detail'], 
          { queryParams: {id: resp.id, from: this.from}}
        );
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  getTitleConfirm() {
    let type = 'RUT';
    if (isNotBlank(this.filter.clientName)) {
      type = 'Nombre Empresa';
    }
    else if (isNotBlank(this.filter.comercialName)) {
      type = 'Nombre Cliente Comercial';
    }
    return `El ${type} no se ha encontrado`;
  }

  getMessageConfirm() {
    let type = 'RUT';
    if (isNotBlank(this.filter.clientName)) {
      type = 'Nombre Empresa';
    }
    else if (isNotBlank(this.filter.comercialName)) {
      type = 'Nombre Cliente Comercial';
    }
    return `El ${type} no ha sido encontrado en nuestra base de Clientes, por lo que se registrará como un
      Potencial.`;
  }

}
