import { Injectable } from '@angular/core';
import { ServiceBase } from './serviceBase';
import { HttpProvider } from '@shared/providers/http.provider';
import { environment } from '../../../environments/environment';
import { Observable, map } from 'rxjs';
import { User } from '@shared/interfaces/user';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root',
})
export class AuthService extends ServiceBase {

    constructor(
        httpProvider: HttpProvider,
        private storageService: StorageService
    ) { 
        super(httpProvider);
    }

    login(username: string): Observable<User> {
        return this.get<any>(`auth/login/${username}`).pipe(
            map(resp => resp['data'])
        );
    }

    refreshToken() {
        let user = this.storageService.getUser();
        return this.post<any>('token', {
            clientId: environment.API_CLIENT_ID,
            clientSecret: environment.API_CLIENT_SECRET,
            username: user?.username
        });
    }
}

