<div class="contacts-contianer flex flex-column">
    <h3>Contactos OTIC</h3>
    <app-search-hunter 
        #hunterComponent 
        [value]="client.hunterContact" 
        label="Cazador" 
        [readonly]="mode === ModeEnum.VIEW"
    />
    <app-search-executive 
        #followUpComponent 
        [value]="client.followUpContact" 
        label="Ejecutivo Seguimiento" 
        [readonly]="mode === ModeEnum.VIEW"
    />
</div>
