import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import templates from '@shared/pdf-templates/index'
import { Fec } from '@shared/interfaces/fec';
import { FecSummary } from '@shared/interfaces/fecSummary';
import { Scenario } from '@shared/interfaces/scenario';

export interface Content {
    type: 'canvas' | 'template'
    contentId: string // id de template/elemento a resolver
    params?: any, // objeto sin profundidad
    templateConfig?: any // configuración para construcción dinámica del template
    childrenTemplateConfig?: any[] // configuración de template para empresas de cliente comercial
}

export const generatePdf = async (action: 'open' | 'print' | 'download' = 'open', content: Content) => {
    
    let documentDefinition
    if (content.type === 'template') {
        documentDefinition = await getDocumentDefinitionFromTemplate(content);
    } else { // canvas
        documentDefinition = await getDocumentDefinitionFromCanvas(content.contentId);
    }

    switch (action) {
        case 'open': pdfMake.createPdf(documentDefinition).open(); break;
        case 'print': pdfMake.createPdf(documentDefinition).print(); break;
        case 'download': pdfMake.createPdf(documentDefinition).download(); break;
        default: pdfMake.createPdf(documentDefinition).open(); break;
    }

}

const getDocumentDefinitionFromCanvas = async (captureId: any) => {
    const element = document.getElementById(captureId)!
    const canvas = await html2canvas(element, {
        scale: 1
    });

    const data = canvas.toDataURL();
    return {
        content: [{
            image: data
        }]
    };
}

const getDocumentDefinitionFromTemplate = async (content: Content) => {
    const template = loadTemplate(content)
    let textTemplate = JSON.stringify(template)
    const resultado: string = reemplazarValores(textTemplate, content.params);
    return JSON.parse(resultado)
}

const loadTemplate = (content: Content) => {
    return templates(content)
}

function reemplazarValores(texto: string, params: { [key: string]: any }): string {
    const regex = /#(\w+)#/g;
    const resultado = texto.replace(regex, (match, atributo) => {
        if (params.hasOwnProperty(atributo)) {
            return params[atributo];
        } else {
            return match;
        }
    });

    return resultado;
}

export const buildSummaryContent = (item: Fec): Content => {
    let { params, years } = buildSummaryParams(item)
    let childrenTemplateConfig: any[]= []

    if (item.isComercial) {
        let childrenParams = {}
        item.summary!.childrenScenario!.forEach((childScenario, index) => {
            const childSummary = processSummary(item.summary!, childScenario.rut)
            const { params, years } = buildSummaryParams(childSummary, index + 1)
            childrenParams = {
                ...params,
                ...childrenParams
            }
            childrenTemplateConfig.push({
                costsCount: childScenario?.costs?.length,
                years: years,
            })
        })
        params = {
            ...params,
            ...childrenParams
        }
    }

    return {
        contentId: 'consolidado&periodos',
        type: 'template',
        params: params,
        templateConfig: {
            costsCount: item.summary?.costs?.length,
            years: years,
            childrenTemplateConfig: childrenTemplateConfig
        }
    };

}

function buildSummaryParams(item: Fec, childIndex?: number): any {
    const params: any = {};
    const years: any[] = [];
    // Summary params
    if(childIndex) item.summary = item
    const summaryParams = {
        contribution_commitment: formatCurrency(item.summary?.contributionCommitment),
        consultancy_provision: formatCurrency(item.summary?.consultancyProvision),
        final_administration_fee: formatPercentage(item.summary?.finalAdministrationFee),
        non_franchisable_account_income: formatCurrency(item.summary?.nonFranchisableAccountIncome),
        provision_pd: formatCurrency(item.summary?.provisionPd),
        counter_rate_income: formatCurrency(item.summary?.counterRateIncome),
        gross_income: formatCurrency(item.summary?.grossIncome),
        real_income: formatCurrency(item.summary?.realIncome),
        effective_rate: formatPercentage(item.summary?.effectiveRate),
        additional_invoice: formatCurrency(item.summary?.additionalInvoice),
        client_name: item.clientName
    };
    Object.assign(params, summaryParams);

    // Year-wise params
    item.fecYears?.forEach((year, indexYear) => {
        const yearParams: any = {
            [`year${indexYear + 1}`]: year.year,
            [`contribution_commitment_year${indexYear + 1}`]: formatCurrency(year.summary?.contributionCommitment),
            [`consultancy_provision_year${indexYear + 1}`]: formatCurrency(year.summary?.consultancyProvision),
            [`final_administration_fee_year${indexYear + 1}`]: formatPercentage(year.summary?.finalAdministrationFee),
            [`non_franchisable_account_income_year${indexYear + 1}`]: formatCurrency(year.summary?.nonFranchisableAccountIncome),
            [`provision_pd_year${indexYear + 1}`]: formatCurrency(year.summary?.provisionPd),
            [`counter_rate_income_year${indexYear + 1}`]: formatCurrency(year.summary?.counterRateIncome),
            [`gross_income_year${indexYear + 1}`]: formatCurrency(year.summary?.grossIncome),
            [`cost_real_income${indexYear + 1}`]: formatCurrency(year.summary?.realIncome),
            [`effective_rate_year${indexYear + 1}`]: formatPercentage(year.summary?.effectiveRate),
            [`additional_invoice_year${indexYear + 1}`]: formatCurrency(year.summary?.additionalInvoice)
        };

        year.summary?.costs?.forEach((cost, indexCost) => {
            Object.assign(yearParams, {
                [`cost_name${indexYear + 1}_${indexCost + 1}`]: cost.costTypeDescription,
                [`cost_ammount${indexYear + 1}_${indexCost + 1}`]: formatCurrency(cost.total),
                [`cost_type_description${indexYear + 1}_${indexCost + 1}`]: cost.costTypeDescription,
                [`cost_total_rate${indexYear + 1}_${indexCost + 1}`]: formatPercentage(cost.rate),
                [`cost_effective_rate${indexYear + 1}`]: formatPercentage(year.summary?.effectiveRate),
                [`provision_year_cost${indexYear + 1}_${indexCost + 1}`]: formatCurrency(cost.provision)
            });

            cost.items?.forEach((item, indexItem) => {
                Object.assign(params, {
                    [`value_year_cost_item${indexYear + 1}_${indexCost + 1}_${indexItem + 1}`]: formatCurrency(item.value),
                    [`name_year_cost_item${indexYear + 1}_${indexCost + 1}_${indexItem + 1}`]: item.description,
                    [`ammount_year_cost_item${indexYear + 1}_${indexCost + 1}_${indexItem + 1}`]: item.quantity
                });
            });
        });

        Object.assign(params, yearParams);
        years.push({
            costs: year.summary?.costs?.map((cost) => ({ itemsCount: cost.items?.length }))
        });
    });

    // Additional costs
    item.summary?.costs?.forEach((cost, indexCost) => {
        Object.assign(params, {
            [`con_cost_name${indexCost + 1}`]: cost.costTypeDescription,
            [`con_cost_value${indexCost + 1}`]: formatCurrency(cost.total)
        });
    });

    if (childIndex) {
        let childParams: any = {}
        for (let key in params) {
            if (params.hasOwnProperty(key)) {
                childParams[`child${childIndex}_${key}`] = params[key];
            }
        }
        return { params: childParams, years }
    } else {
        return { params, years }
    }
}

function getScenarioByRut(scenario: Scenario, rut: string) {
    return scenario.childrenScenario?.find(item => item.rut === rut) ?? {};
}

function processSummary(summary: FecSummary, rut?: string) {
    let processed: FecSummary = {};
    if (!rut) {
        return summary;
    }
    processed = getScenarioByRut(summary, rut);
    processed.inOneTaxYear = summary.inOneTaxYear;
    processed.fecYears = summary.fecYears?.map(fecYear => {
        return {
            year: fecYear.year,
            summary: getScenarioByRut(fecYear.summary ?? {}, rut)
        };
    }) ?? [];
    return processed;
}

function formatCurrency(value: number | undefined): string {
    if(value == 0) return '$0'
    return value ? '$' + thousands(value) : '';
}

function formatPercentage(value: number | undefined): string {
    return value ? value.toFixed(2) + '%' : '';
}

function thousands(value: number = 0, showDecimals: boolean = false): string {
    const formattedValue = showDecimals ? value.toFixed(2) : value.toFixed(0);
    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return parts.join(',');
}