import { PagedList } from "@shared/interfaces/pagedList";
import { PagedListFilter } from "@shared/interfaces/pagedListFilter";

export const listToPagedList = (list: any[], pagedListFilter: PagedListFilter<any>): PagedList<any> => {
    if (list.length === 0){
        return {
            list: [],
            total: 0
        }
    }
    let rowEnd = pagedListFilter.first + pagedListFilter.rows; 
    rowEnd = rowEnd > list.length ? list.length: rowEnd;
    return {
        list: list.slice(pagedListFilter.first, rowEnd),
        total: list.length
    }
}

export const replaceOrInsertElementToList = <T>(
    list: T[] | undefined, 
    element: T, 
    condition: (item: T) => boolean ) => {

    let updated: boolean = false;
    let temporalList = list ?? [];
    const newList = temporalList.map((item : T) => {
      if (condition(item)) {
          updated = true;
          return element;
      }
      return item;
    });
    if (!updated) {
      newList.push(element);
    }

    return newList;
}

export const replaceElementToList = <T>(
  list: T[] | undefined, 
  element: T, 
  condition: (item: T) => boolean ) => {

  let temporalList = list ?? [];
  return temporalList.map((item : T) => {
    if (condition(item)) {
        return element;
    }
    return item;
  });
}

export const arrayIsEmpty = (array: any[] | undefined) => {
  return array === undefined || array === null || array.length === 0; 
} 

export const arrayIsNotEmpty = (array: any[] | undefined) => {
  return !arrayIsEmpty(array); 
} 
