import { Component, OnInit, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepsComponent } from '@shared/components/steps/steps.component';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { FecProvider } from '../fec.provider';

@Component({
  selector: 'app-fec-detail',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    StepsComponent,
    PageContainerComponent
  ],
  templateUrl: './fec-detail.component.html',
  styleUrl: './fec-detail.component.scss'
})
export class FecDetailComponent implements OnInit {
  id?: string;
  paths: string[] = ["HOME", "Control de Gestión", "Evaluación Comercial"];
  steps: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public fecProvider: FecProvider 
  ){
    effect(() => {
      this.steps = this.generateStepsItems(this.id);
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.steps = this.generateStepsItems(this.id);
    });
  }

  generateStepsItems(id: string | undefined){
    return [
      {
        label: 'Identificación Empresa',
        routerLink: `client-detail`, 
        extras: { queryParams: {id}},
        allowedNavigation: () => true
      },
      {
        label: 'Evaluación Económica',
        routerLink: `economic-evaluation`,
        extras: { queryParams: {id}},
        allowedNavigation: () => this.fecProvider.clientComplete()
      }
    ];
  }

}
