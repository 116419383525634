import { Pipe, PipeTransform } from '@angular/core';
import { StatusLabelEnum } from '@shared/enums/statusLabelEnum';

@Pipe({
  name: 'status',
  standalone: true
})
export class StatusPipe implements PipeTransform {

  transform(value: string): string {
    const statusLabels: { [key: string]: string } = StatusLabelEnum;
    return statusLabels[value] || value;
  }

}
