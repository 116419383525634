import { Injectable } from '@angular/core';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable, map } from 'rxjs';
import { ServiceBase } from './serviceBase';
import { User } from '@shared/interfaces/user';
import { UserFilter } from '@shared/interfaces/userFilter';
import { PagedListFilter } from '@shared/interfaces/pagedListFilter';
import { PagedList } from '@shared/interfaces/pagedList';
    
@Injectable({
    providedIn: 'root'
})
export class UserService extends ServiceBase {

    constructor(httpProvider: HttpProvider) { 
        super(httpProvider);
    }

    createUser(user: User){
        return this.post<any>('user', user).pipe(
            map(resp => resp['data'])
        );
    }

    updateUser(user: User){
        return this.put<any>(`user`, user).pipe(
            map(resp => resp['data'])
        );
    }

    getUser(rut: string): Observable<User>{
        return this.get<any>(`user/${rut}`).pipe(
            map(resp => resp['data'])
        );
    }

    findUser(filter: UserFilter): Observable<User[]>{
        return this.post<any>('user/find', filter).pipe(
            map(resp => resp['data'])
        );
    }

    deleteByGenerator(filter: UserFilter){
        return this.post<any>('user/deleteByGenerator', filter).pipe(
            map(resp => resp['data'])
        );
    }

    pagedFindUser(filter: PagedListFilter<UserFilter>): Observable<PagedList<User>>{
        return this.post<any>('user/pagedFind', filter).pipe(
            map(resp => resp['data'])
        );
    }
};
