import { Fec } from "@shared/interfaces/fec";
import { FecYearSummary } from "@shared/interfaces/fecYearSummary";
import { arrayIsEmpty } from "./listUtil";
import { FecSummary } from "@shared/interfaces/fecSummary";
import { FecYearManager } from "./FecYearManager";
import { IncomeScenarioManager } from "./IncomeScenarioManager";
import { ScenarioManager } from "./ScenarioManager";
import { ChildScenarioManager } from "./ChildScenarioManager";

export class FecManager {
    private processed: Fec;
    constructor(private fec: Fec){
        this.processed = this.process(fec);
    }

    private process(fec: Fec){
        if (arrayIsEmpty(fec.fecYears)){
            return fec;
        }
        let processed: Fec = {...fec};
        processed.scenarios = processed.scenarios?.map(scenario => (new ScenarioManager(scenario)).getProcessed());
        processed.fecYears = processed.fecYears?.map(fecYear => (new FecYearManager(fec, fecYear)).getProcessed());
        processed.summary = this.processSummary(fec);
        return processed;
    }

    private processSummary(fec: Fec): FecSummary {
        let fecYears = fec.fecYears?.filter(fecYear => fecYear.selected) ?? [];
        if (arrayIsEmpty(fecYears)){
            return {};
        }
        let summaries = fecYears.map(fecYear => fecYear.summary ?? {});
        let processed: FecSummary = {
            isComercial: fec.isComercial,
            inOneTaxYear: fec.inOneTaxYear,
            ...IncomeScenarioManager.processSummaryFromListIncome(summaries),
            childrenScenario: ChildScenarioManager.processListSummaryFromListChildIncome(
                fec.client?.comercialChildren ?? [],
                summaries
            )
        };
        processed.fecYears = fecYears;
        processed.costs = summaries.reduce((list: FecYearSummary[], summary) => {
            return list.concat(summary.costs ?? []);
        }, []);
        return (new ScenarioManager(processed).getProcessed());
    }


    getProcessed(){
        return this.processed;
    }
}



