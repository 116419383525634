import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { User } from '@shared/interfaces/user';
import { UserService } from '@shared/services/user.service';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { StorageService } from '../../services/storage.service';
import { processRoleMenu } from '@shared/util/roleUtil';

@Component({
  selector: 'app-simulated-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule
  ],
  templateUrl: './simulated-login.component.html',
  styleUrl: './simulated-login.component.scss'
})
export class SimulatedLoginComponent implements OnInit {
  users?: User[] = [];
  public form: FormGroup = this.fb.group({
    user: ['']
  });

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.userService.findUser({fullName:""}).subscribe((resp) => {
      this.users = resp.filter(u => u.roles && u.roles.length > 0);
      let user = this.storageService.getUser();
      this.form.reset({
        user: this.users.find(u => u.email === user?.username) 
      });
    })
  }

  getItem(item: User){
    let roles = item.roles?.join(',');
    let userLabel = item.email + (roles ? '(' + roles + ')': '')
    return userLabel;
  }

  change(event: DropdownChangeEvent){
    let newUSer: User = event.value;
    let user = this.storageService.getUser();
    if (user){
      this.storageService.saveToken('');
      this.storageService.saveUser(processRoleMenu({
        firstName: newUSer.fullName ?? '',
        lastName:  '',
        userId: user.userId,
        username: newUSer.email ?? '',
        roles: newUSer.roles ?? [],
        menu: [],
        isAdmin: newUSer.isAdmin === true
      }));
      location.reload();
    }
  }

}
