<app-simulated-login/>
<p-tabView>
    <p-tabPanel header="Generator"> 
        <app-generator/>
    </p-tabPanel>
    <p-tabPanel header="Client">
        <app-client-admin/>
    </p-tabPanel>
    <p-tabPanel header="Fec">
        <app-fec-admin/>
    </p-tabPanel>
    <p-tabPanel header="User">
        <app-user-admin/>
    </p-tabPanel>
    <p-tabPanel header="Api Otic">
        <app-api-otic-admin/>
    </p-tabPanel>
    <p-tabPanel header="Mail">
        <app-mail-admin/>
    </p-tabPanel>
</p-tabView>
