<div class="layout-footer">
  <div class="footer-rrss-container">
    <span class="footer-social-left"></span>
      <div class="footer-rrss-icons">
        <a class="otic-meta" href="https://www.facebook.com/profile.php?id=100088518497393" title="Facebook OTIC">
          <img src="../../../../assets/icons/facebook.svg" alt="rrss-meta-icon">
        </a>
        <a class="otic-x" href="https://twitter.com/OTICSOFOFA" title="Twitter OTIC">
          <img src="../../../../assets/icons/xtwitter.svg" alt="rrss-x-icon">
        </a>
        <a class="otic-instagram" href="https://www.instagram.com/oticsofofa/" title="Instagram OTIC">
          <img src="../../../../assets/icons/instagram.svg" alt="rrss-instagram-icon">
        </a>
        <a class="otic-linkedin" href="https://www.linkedin.com/company/otic-sofofa-capital-humano/" title="Linkedin OTIC">
          <img src="../../../../assets/icons/linkedIn.svg" alt="rrss-linkedin-icon">
        </a>
      </div>
    <span class="footer-social-right"></span>
  </div>
  <img class="mx-auto" src="../../../../assets/logo.svg" alt="otic-logo" width="160" height="68">
  <h5 class="footer-info">
    Casa Matriz: Agustinas 1357. Pisos 11 y 12 - Santiago. Teléfono/Fax: (+562) 2336 28 00.
    <br>
    Agencias Regionales: Iquique - Antofagasta - Calama - Viña del Mar - Concepción - Temuco - Puerto Montt - Punta Arenas.
  </h5>
  <div class="footer-copyright-container">
    <h5 class="footer-copyright">
      2024 OTIC Sofofa | Capital Humano. Todos los derechos reservados
    </h5>
  </div>
</div>
