import { environment } from '@environments/environment';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable } from 'rxjs';
    
export class ServiceBase {

    baseUrl: string = '';
    constructor(private httpProvider: HttpProvider) { 
      this.baseUrl = environment.API_URL;
    }

    getHttpClient(){
      return this.httpProvider.getHttpClient();
    }

    get<T>(action: string): Observable<T> {
      return this.httpProvider.get<T>(`${this.baseUrl}/${action}`);
    }

    post<T>(action: string, data: any = {}): Observable<T> {
        return this.httpProvider.post<T>(`${this.baseUrl}/${action}`, data);
    }

    put<T>(action: string, data: any = {}): Observable<T> {
      return this.httpProvider.put<T>(`${this.baseUrl}/${action}`, data);
    }


};
