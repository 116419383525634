@if (fecYear) {
    <div class="fec-summary-one-year-cost">
        <div style="padding-left: 5px;" class="font-nunito-s16-w700-color1">Periodo {{fecYear.year}}</div>
        <div class="flex flex-column">
            @for (cost of fecYear.summary?.costs; track $index;) {
                <app-label-value
                    [config]="{
                        type: 'amount',
                        label: cost.costTypeDescription,
                        value: cost.total,
                        labelClass: 'font-nunito-s16-w600-color1',
                        valueClass: 'font-nunito-s16-w400-color1',
                        width: 273,
                        labelWidth: 120,
                        labelPadding: '0px 0px 0px 5px',
                        valuePadding: '0px 0px 0px 5px'
                    }"
                />
            }
        </div>
    </div>
}
