export const valueToInteger = (value: string | number | undefined | null) => {
    if (typeof value === 'string') {
        if (isNaN(parseInt(value))) 
            return 0;
        return parseInt(value); 
    } else if (typeof value === 'number') {
        return value; 
    }
    else {
        return 0;
    }
}

export const valueToFloat = (value: string | number | undefined | null) => {
    if (typeof value === 'string') {
        if (isNaN(parseFloat(value))) 
            return 0;
        return parseFloat(value); 
    } else if (typeof value === 'number') {
        return value; 
    }
    else {
        return 0;
    }
}
