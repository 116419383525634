import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { Parameter } from '@shared/interfaces/parameter';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { MessageProvider } from '@shared/providers/message.provider';
import { ParameterService } from '@shared/services/parameter.service';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-parameter-detail',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    PageContainerComponent,
    GridContainerComponent,
    FormsModule,
    ButtonModule,
    InputTextModule
  ],
  templateUrl: './parameter-detail.component.html',
  styleUrl: './parameter-detail.component.scss'
})
export class ParameterDetailComponent implements OnInit {
  paths: string[] = ["HOME", "Detalle de Parámetro"];
  id?: string;
  parameter?: Parameter;
  items: any[] = [];
  clonedItems: { [s: string]: any } = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private parameterService: ParameterService
  ){
    
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.parameterService.getParameter(this.id ?? '').subscribe({
        next: (resp) => {
          console.log(resp)
          this.parameter = resp;
          this.items = resp.data;
          this.loadingProvider.hide();
        },
        error: (err) => {
          this.loadingProvider.hide();
          let msg = err.error?.message || 'Ocurrió un error';
          console.error(msg);
          this.messageProvider.showError("Error", msg, ()=> {
            this.router.navigate(['/parameter-admin']);
          });
        }
      });
    });
  }

  onEdit(event: any) {
    if (!this.isPositiveInteger(event.target.value)) {
        event.stopPropagation();
    }
}

isPositiveInteger(val: any) {
    let str = String(val);

    str = str.trim();

    if (!str) {
        return false;
    }

    str = str.replace(/^0+/, '') || '0';
    var n = Math.floor(Number(str));

    return n !== Infinity && String(n) === str && n >= 0;
}


}
