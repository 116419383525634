import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Fec } from '@shared/interfaces/fec';
import { FecSummaryOneYearCostComponent } from '../fec-summary-one-year-cost/fec-summary-one-year-cost.component';
import { LabelValueComponent } from '@shared/components/label-value/label-value.component';
import { FecSummary } from '@shared/interfaces/fecSummary';

@Component({
  selector: 'app-fec-summary-multi-year-cost',
  standalone: true,
  imports: [
    CommonModule,
    LabelValueComponent,
    FecSummaryOneYearCostComponent
  ],
  templateUrl: './fec-summary-multi-year-cost.component.html',
  styleUrl: './fec-summary-multi-year-cost.component.scss'
})
export class FecSummaryMultiYearCostComponent {
  @Input() summary?: FecSummary;
}
