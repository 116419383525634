import { Component, ViewChild } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { DetailFecYearComponent } from '../detail-fec-year/detail-fec-year.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { FecProvider } from '@pages/fec/fec.provider';

@Component({
  selector: 'app-new-fec-year',
  standalone: true,
  imports: [
    ButtonComponent,
    ModalComponent,
    DetailFecYearComponent,
  ],
  templateUrl: './new-fec-year.component.html',
  styleUrl: './new-fec-year.component.scss'
})
export class NewFecYearComponent {
  @ViewChild('detailFecYear') detailFecYear!: ModalComponent;

  constructor(
    public fecProvider: FecProvider
  ){}

  add(){
    this.detailFecYear.openModal();
  }
}
