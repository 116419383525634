import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, effect } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FecProvider } from '@pages/fec/fec.provider';
import { FecYearProvider } from '@pages/fec/fecYear.provider';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-detail-fec-year',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FieldContainerComponent,
    ButtonComponent,
    InputTextModule,
    TextReadonlyComponent,
    DropdownModule
  ],
  templateUrl: './detail-fec-year.component.html',
  styleUrl: './detail-fec-year.component.scss'
})
export class DetailFecYearComponent implements OnInit {
  period: string = '';
  periods: any[] = [];
  public form: FormGroup = this.fb.group({});
  @Input() 
  public isNew: boolean = false;
  @Output()
  public save = new EventEmitter();
  @Output()
  public clickCancel = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public fecProvider: FecProvider,
    private fecYearProvider: FecYearProvider
  ) { 
    effect(() => {
      let fec = this.fecProvider.getCurrent();
      this.periods = fec.periodYears ?? [];
      let fecYear = this.fecYearProvider.getCurrent();
      if (!this.isNew && fecYear.id){
        this.period = `${fecYear.startDate} - ${fecYear.endDate}`;
        this.form.reset({
          description: fecYear.description
        }); 
      }
      else if (this.isNew && fec.inOneTaxYear) {
        let period = this.periods[0];
        this.period = `${period.startDate} - ${period.endDate}`;
      }
    });
  }

  ngOnInit() {
    let fec = this.fecProvider.getCurrent();
    if (this.isNew && !fec.inOneTaxYear){
      this.form = this.fb.group({
        description: ['', [Validators.required]],
        periodYear: ['', [Validators.required]]
      });
    }
    else{
      this.form = this.fb.group({
        description: ['', [Validators.required]]
      });
    }
  }

  onCancel(): void {
    this.form.reset({});
    this.clickCancel.emit();
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  onSave(){
    if (!this.validate()) return;
    if (this.isNew){
      let fec = this.fecProvider.getCurrent();
      /* Cuando es un periodo por defecto se toma este */
      let periodYear = fec.inOneTaxYear ?  this.periods[0]: this.form.value['periodYear'];
      this.fecYearProvider.updateCurrentAndList({
        id: uuid(),
        description: this.form.value['description'],
        ...periodYear
      });
    }
    else{
      this.fecYearProvider.updateCurrentAndList({
        ...this.fecYearProvider.getCurrent(),
        description: this.form.value['description']
      });
    }
    this.form.reset({});
    this.save.emit();
  }

}
