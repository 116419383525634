<div class="steps-container">
    @for( item of steps; track idx; let idx = $index  ) {
        <div class="step">
            @if (type == 1) {
                <div 
                    (click)="navigate(item, idx)"
                    class="step-circle"
                     [ngClass]="{
                        'step-circle-active': activeIndex === idx,
                        'step-allowed-navigation': allowedNavigation(item, idx)
                    }"
                ></div>
            }
            @else {
                <div 
                    (click)="navigate(item, idx)"
                    [ngClass]="{
                        'otic-check-active': activeIndex === idx,
                        'otic-check-inactive': activeIndex < idx,
                        'otic-check-complete': activeIndex > idx,
                        'step-allowed-navigation': allowedNavigation(item, idx)
                    }"
                ></div>
            }
            <div class="step-title"
                (click)="navigate(item, idx)" 
                 [ngClass]="{
                    'step-title-inactive': activeIndex !== idx,
                    'step-title-type2-inactive': type === 2 && activeIndex < idx,
                    'step-allowed-navigation': allowedNavigation(item, idx)
                  }"
            >{{item.label}}</div>
        </div>
    }
    @if (type == 2) {
        <div class="step-line-container">
            @for( item of steps; track idx; let idx = $index  ) {
                @if (idx > 0) {
                    <div class="step-line"
                        [ngClass]="{'step-line-complete': activeIndex >= idx}"
                    ></div>
                }
            }
        </div>
    }
</div>
