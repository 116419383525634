import { ChildScenario } from "@shared/interfaces/childScenario";
import { Scenario } from "@shared/interfaces/scenario";
import { CostScenario } from "@shared/interfaces/costScenario";
import { valueToInteger } from "./numberUtil";
import { ScenarioBaseManager } from './ScenarioBaseManager';
import { Client } from "@shared/interfaces/client";
import { IncomeScenarioManager } from "./IncomeScenarioManager";

export class ChildScenarioManager {
    private processed: ChildScenario;
    constructor(private scenario: Scenario, private child: ChildScenario){
        this.processed = this.process(scenario, child);
    }

    private process(scenario: Scenario, child: ChildScenario){
        let processed: ChildScenario = {...child};
        let numberChildren = scenario.childrenScenario?.length ?? 0;
        processed.costs = scenario.costs?.map(cost => this.processCost(cost, numberChildren));
        return processed;
    }

    private processCost(cost: CostScenario, numberChildren: number){
        let items = cost.items?.map(item => {
            return {
             ...item,
             value: valueToInteger(item.value)/numberChildren
            };
         }) ?? [];
         return {
            costType: cost.costType,
            costTypeDescription: cost.costTypeDescription,
            items
         }
    }

    static createChildScenario(rut: string) : ChildScenario {
        return {
            rut,
            ...ScenarioBaseManager.createScenarioBase()
        };
    }

    /**
     * Recibe una lista de clientes hijos de un comercial y una lista de escenarios 
     * toma los escenarios hijos dentro de cada escenario y los suma por rut
     * calculando el 'Ingreso contra Tasa' e 'Ingresos Brutos'
     * @param scenarios
     * @returns 
     */
    static processListSummaryFromListChildIncome(children: Client[], scenarios: Scenario[]): ChildScenario[] {
        /* Juntamos todas las listas de las empresas hijas en una sola  */
        const childrenScenario = scenarios.reduce((list: ChildScenario[], scenario) => {
            return list.concat(scenario.childrenScenario ?? []);
        }, []);
        /* Las filtramos por rut y totalizamos  */
        return children.map(child => {
            let childrendByRut = childrenScenario.filter(childScenario => childScenario.rut === child.rut);
            return {
                ...IncomeScenarioManager.processSummaryFromListIncome(childrendByRut),
                ...child
            };
        });
    };

    getProcessed(){
        return this.processed;
    }
}

