import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { EventBusService } from '@shared/services/event-bus.service';
import { EventData } from '@shared/models/event.class';
import { StorageService } from '@shared/services/storage.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '@shared/services/auth.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(
    private storageService: StorageService,
    private authService: AuthService,
    private eventBusService: EventBusService
  ) { }

  private cloneWithBearerToken(req: HttpRequest<any>) {
    const token = this.storageService.getToken();
    const isApiUrl = req.url.startsWith(environment.API_URL);
    if (token && isApiUrl) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` }
      });
    }
    return req;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = this.cloneWithBearerToken(req);
    return next.handle(req).pipe(
      catchError((error) => {
        if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/signin') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        }

        return throwError(() => error);
      })
    );
  }

  private handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      return this.authService.refreshToken().pipe(
        switchMap((resp: any) => {
          this.storageService.saveToken(resp.token);
          this.isRefreshing = false;
          req = this.cloneWithBearerToken(req);
          return next.handle(req);
        }),
        catchError((error) => {
          this.isRefreshing = false;
          if (error.status == '403') {
            this.eventBusService.emit(new EventData('logout', null));
          }
          return throwError(() => error);
        })
      );
    }

    return next.handle(req);
  }
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
];

