import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { Parameter } from '@shared/interfaces/parameter';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { MessageProvider } from '@shared/providers/message.provider';
import { ParameterService } from '@shared/services/parameter.service';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-parameter-admin',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    PageContainerComponent,
    GridContainerComponent
  ],
  templateUrl: './parameter-admin.component.html',
  styleUrl: './parameter-admin.component.scss'
})
export class ParameterAdminComponent implements OnInit {
  paths: string[] = ["HOME", "Administrar Parámetros"];
  parameters: Parameter[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private parameterService: ParameterService) {
  }

  ngOnInit() {
    this.parameterService.findParameter({}).subscribe((data) => {
      this.parameters = data;
    })
  }

  openEdit(parameter: Parameter){
    this.router.navigate(['/parameter-detail'], { queryParams: {id: parameter.parameterName}});
  }
}
