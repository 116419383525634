<div #targetEl class="sidebar-otic">
    <app-overlay-panel #op>
        <ng-template pTemplate="headless">
            <div class="flex flex-column  sidebar-menu-container">
                <div class="flex align-items-center justify-content-between px-4 pt-3 flex-shrink-0">
                    <div
                        (click)="closeCallback($event)"
                        class="sidebar-menu-button"
                        aria-label="MENÚ"
                    >
                        <span class="sidebar-button-label">MENÚ</span>
                        <i class="otic-sidebar-menu"></i>
                    </div>
                </div>
                <div class="overflow-y-auto">
                    <ul class="list-none p-3 m-0">
                        @for (item of menuItems; track $index; let last = $last;) {
                            @if (item.routerLink) {
                                <li class="sidebar-item">
                                    <a
                                        pRipple
                                        class="flex 
                                            align-items-center
                                            border-round transition-duration-150 
                                            transition-colors p-ripple text-decoration-none"
                                        routerLink="{{item.routerLink}}"
                                        (click)="closeCallback($event)"
                                    >
                                        <div [class]="item.icon"></div>
                                        <p class="sidebar-menu-option">{{item.label}}</p>
                                    </a>
                                </li>
                            }
                            @else {
                                <li class="sidebar-item">
                                    <div
                                        pRipple
                                        class="flex align-items-center cursor-pointer border-round transition-duration-150 transition-colors p-ripple text-decoration-none"
                                        (click)="clickItem(item)"
                                    >
                                        <div [class]="item.icon"></div>
                                        <p class="sidebar-menu-option" >{{item.label}}</p>
                                        <i class="pi pi-chevron-down ml-auto"></i>
                                    </div>
                                </li>
                                @if (item.active) {
                                    <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                                    @for (subItem of item.items; track $index; let lastActiveMenu = $last;) {
                                        <li class="sidebar-subitem">
                                            <a
                                                pRipple
                                                class="flex align-items-center cursor-pointer border-round
                                                    transition-duration-150 transition-colors p-ripple"
                                                routerLink="{{subItem.routerLink}}"
                                                (click)="closeCallback($event)"
                                            >
                                                <p class="sidebar-menu-option">{{subItem.label}}</p>
                                            </a>
                                        </li>
                                        @if(lastActiveMenu && !last) {
                                            <hr class="mb-3 mx-3 border-top-1 border-none surface-border" />
                                        }
                                    }
                                }
                            }
                        }
                    </ul>
                </div>
            </div>
        </ng-template>
    </app-overlay-panel>
    <div (click)="op.show($event, targetEl)" (keypress)="op.show($event, targetEl)" class="sidebar-button" aria-label="MENÚ">
        <span class="sidebar-button-label">MENÚ</span>
        <i class="otic-menu"></i>
    </div>
</div>
