<div class="layout-wrapper">
    <app-topbar></app-topbar>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
<app-loading
    [visible]="loadingProvider.isVisible()"
></app-loading>
<app-confirm #messageConfirm
    [visible]="messageProvider.isVisible()"
    [type]="messageProvider.getType()"
    [title]="messageProvider.getTitle()"
    [message]="messageProvider.getMessage()"
    [htmlContent]="messageProvider.getMessageHtml()"
    (closeClick)="closeMessageConfirm()"
    (acceptClick)="acceptMessageConfirm()"
    (rejectClick)="rejectMessageConfirm()"
    [rejectLabel]="messageProvider.getRejectLabel()"
    [acceptLabel]="messageProvider.getAcceptLabel()"
></app-confirm>
