import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FieldContainerComponent } from '../field-container/field-container.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { OticService } from '@shared/services/otic.service';
import { rutValidator } from '@shared/validators/validators';
import { InputTextModule } from 'primeng/inputtext';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { isBlank, isEmpty } from '@shared/util/stringUtils';
import { Executive } from '@shared/interfaces/executive';
import { validateRut } from '@shared/util/rutUtil';
import { disableAllField } from '@shared/util/formUtil';

@Component({
  selector: 'app-search-hunter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    FieldContainerComponent,
    NgxMaskDirective, 
    NgxMaskPipe
  ],
  providers: [provideNgxMask()],
  templateUrl: './search-hunter.component.html',
  styleUrl: './search-hunter.component.scss'
})
export class SearchHunterComponent implements OnInit, OnChanges {
  @Input() readonly: boolean = false;
  @Input()
  label: string = "";
  executive: Executive = {};
  searchNotFoundError?: string;
  @Input() value?: Executive;

  public form: FormGroup = this.fb.group({
    rut: ['', [Validators.required, rutValidator]],
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: ['']
  });

  constructor(
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private oticService: OticService
  ){}

  ngOnInit(): void {
    this.form.get('rut')?.valueChanges.subscribe((value) => {
      if (this.executive.rut !== value){
        this.reset();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.executive = this.value ?? {};
    this.form.reset(this.executive);
    if (this.readonly){
      disableAllField(this.form);
    }
  }

  reset(){
    this.executive = {};
    this.form.patchValue({
      name: '',
      email: '',
      phone: ''
    }, { emitEvent: false });
  }

  findExecutive(){
    this.searchNotFoundError = undefined;
    let rut = this.form.value['rut'];
    if (this.loadingProvider.isVisible() 
      || isEmpty(rut) 
      || !validateRut(rut) 
    )
      return;
    this.loadingProvider.show();
    this.oticService.getHunterByRut(rut).subscribe({
      next: (data: Executive) => {
        let executive = {
          ...data,
          rut
        };
        this.executive = executive;
        this.form.patchValue(executive);
        this.loadingProvider.hide();
      },
      error: (err) => {
        this.reset();
        this.loadingProvider.hide();
        let msg = err.error?.message || err.error || 'Ocurrió un error';
        this.searchNotFoundError = msg;
      }
    });
  }

  validate(){
    this.form.markAllAsTouched();
    if (isBlank(this.executive.name)){
      this.searchNotFoundError = `Debe ingresar un '${this.label}' válido`;
      return false;
    }
    return this.form.valid;
  }

  getData(): Executive | undefined{
    if (!this.validate())
      return undefined;
    return this.form.value;
  }


}
