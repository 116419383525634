import { Injectable, signal } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class LoadingProvider {
    private visible = signal<boolean>(false);

    isVisible(){
        return this.visible();
    }

    show(){
        this.visible.set(true);
    }

    hide(){
        this.visible.set(false);
    }
}
