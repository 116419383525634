import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { ParameterEnum } from '@shared/enums/parameterEnum';
import { CostItemScenario } from '@shared/interfaces/costItemScenario';
import { ParameterService } from '@shared/services/parameter.service';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-cost-type-2',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    FieldContainerComponent,
    DropdownModule,
    TextReadonlyComponent
  ],
  templateUrl: './cost-type-2.component.html',
  styleUrl: './cost-type-2.component.scss'
})
export class CostType2Component {
  public form: FormGroup = this.fb.group({
    costSubType: ['', [Validators.required]],
    quantity: [0, [Validators.required, Validators.min(1)]]
  });
  costSubTypeList: any[] = [];
  costSubTypeSelected: any = {};
  @Input() 
  public showLabel?: boolean = false;
  @Input() 
  public data: CostItemScenario = {};
  @Output()
  public change = new EventEmitter();

  constructor(private fb: FormBuilder,
    private parameterService: ParameterService
  ) {}

  ngOnInit(): void {
    this.parameterService.getParameter(ParameterEnum.CostSubType).subscribe(resp => {
      this.costSubTypeList = resp.data;
      this.form.reset(this.data);
      if (this.data.costSubType){
        this.costSubTypeSelected = this.costSubTypeList.find(item => item.description === this.data.costSubType);
        this.change.emit();
      }
    });
    
    this.form.valueChanges.subscribe(values => { 
      this.change.emit();
    });
  }

  changeCostSubType(event: DropdownChangeEvent){
    this.costSubTypeSelected = this.costSubTypeList.find(item => item.description === event.value);
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  getData(): CostItemScenario {
    return {
      ...this.form.value,
      value: this.costSubTypeSelected.amount
    }
  }
}
