import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-fake-steps',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './fake-steps.component.html',
  styleUrl: './fake-steps.component.scss'
})
export class FakeStepsComponent {
  @Input() type: number = 1;
  @Input() steps: FakeStep[] = [];
  @Input() activeIndex: number = 0;
  @Output()
  public navigate = new EventEmitter<FakeStep>();

  allowedNavigation(step: FakeStep, index: number){
    if (!step.allowedNavigation)
      return;
    return (this.activeIndex !== index) && (step && step.allowedNavigation());
  }

  onNavigate(step: FakeStep, index: number){
    if (!this.allowedNavigation(step, index))
      return;
    this.navigate.emit({
      ...step,
      index
    });
  }

}

export interface FakeStep {
  label: string;
  index?: number;
  allowedNavigation?: () => boolean;
}
