import { Component, effect } from '@angular/core';
import { TopbarComponent } from '../topbar/topbar.component';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { CommonModule } from '@angular/common';
import { LoadingComponent } from '../loading/loading.component';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MessageProvider } from '@shared/providers/message.provider';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    TopbarComponent,
    RouterOutlet,
    FooterComponent,
    RouterLink,
    RouterLinkActive,
    LoadingComponent,
    ConfirmComponent
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent { 
  
  constructor(
    public loadingProvider: LoadingProvider,
    public messageProvider: MessageProvider
  ){

  }

  closeMessageConfirm(){
    this.messageProvider.executeFinish();
    this.messageProvider.hide();
  }

  acceptMessageConfirm(){
    this.messageProvider.executeConfirmFn();
    this.messageProvider.hide();
  }

  rejectMessageConfirm(){
    this.messageProvider.executeCancelFn();
    this.messageProvider.hide();
  }
}
