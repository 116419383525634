@if (paths.length > 0) {
    <div class="breadcrumb">
        @for (item of paths; track $index;) {
            <span [ngClass]="{'breadcrumb-last': $index + 1 === paths.length}">{{item}}</span>
            @if ($index + 1 < paths.length) {
                -
            }
        }
    </div>
}

