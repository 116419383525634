import { CostItemScenario } from "@shared/interfaces/costItemScenario";
import { CostScenario } from "@shared/interfaces/costScenario";
import { Scenario } from "@shared/interfaces/scenario";
import { valueToInteger } from "./numberUtil";
import { CostTypeEnum } from "@shared/enums/costTypeEnum";

export class CostManager{
    private processed: CostScenario;
    constructor(private scenario: Scenario, private cost: CostScenario){
        this.processed = this.process(scenario, cost);
    }

    private process(scenario: Scenario, cost: CostScenario){
        let processed: CostScenario = {...cost};
        processed.total = this.calculateTotal(cost);
        processed.rate = this.calculateRate(valueToInteger(scenario.contributionCommitment), processed.total);
        return processed;
    }

    private calculateTotal(cost: CostScenario): number {
        return cost.items?.reduce((sum: number, costItem: CostItemScenario) => {
            let value = valueToInteger(costItem.value);
            let quantity = valueToInteger(costItem.quantity);
            return sum + value * quantity;
        }, 0) ?? 0;
    };

    private calculateRate(contributionCommitment: number, costTotal: number): number {
        if (costTotal === 0 || contributionCommitment === 0)
          return 0;
        return costTotal/contributionCommitment*100;
    }

    static createCostScenario(costType: string) : CostScenario {
        return {
            costType,
            costTypeDescription: this.getDescriptionCostType(costType),
            total: 0,
            rate: 0,
            provision: 0,
            items: []
        };
    }

    static getDescriptionCostType(code?: string): string {
        return CostTypeEnum[code as keyof typeof CostTypeEnum] ?? '';
    }

    getProcessed(){
        return this.processed;
    }
}
