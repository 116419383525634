import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Fec } from '@shared/interfaces/fec';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FecService } from '@shared/services/fec.service';
import { FecSummaryComponent } from '../fec-summary/fec-summary.component';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { generatePdf, buildSummaryContent } from '@shared/util/pdfUtil';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { EpochDatePipe } from '@shared/pipes/epoch-date.pipe';
import { MessageProvider } from '@shared/providers/message.provider';

@Component({
  selector: 'app-fec-preview',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    FecSummaryComponent,
    InputTextareaModule,
    StepbarComponent,
    EpochDatePipe
  ],
  templateUrl: './fec-preview.component.html',
  styleUrl: './fec-preview.component.scss'
})
export class FecPreviewComponent {
  fec?: Fec;
  id?: string;
  observation?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private fecService: FecService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if (!this.id) {
        this.router.navigate(['/fec-list']);
        return;
      }
      this.loadingProvider.show();
      this.fecService.getFec(this.id).subscribe((resp) => {
        this.fec = resp;
        this.loadingProvider.hide();
      });
    });
  }

  clickSend() {
    this.messageProvider.showWarningConfirm(
      "Enviar propuesta a evaluación",
      "¿Está seguro de enviar la propuesta de Evaluación Comercial a evaluación interna?",
      () => {
        this.confirmSend();
      }
    );
  }

  confirmSend() {
    this.loadingProvider.show()
    const observation = this.observation && this.observation.length > 0 ? this.observation : 'Sin observaciones';
    this.fecService.sendFec({ id: this.fec?.id, observation }).subscribe({
      next: (resp) => {
        this.loadingProvider.hide();
        this.messageProvider.showSuccess(
          "Confirmación en envío",
          "Su propuesta de Evaluación comercial fue enviada a evaluación interna.",
          () => {
            this.router.navigate(['/fec-list']);
          }
        );
      },
      error: (err) => {
        this.loadingProvider.hide()
        const error = err.error.message
        this.messageProvider.showError("Error", error);
      }
    })
  }

  getPdf() {
    const content = buildSummaryContent(this.fec!)
    generatePdf('open', content)
  }

  clickPrevious() {
    this.router.navigate(
      ['/fec-detail/economic-evaluation'], 
      { queryParams: {id: this.id}}
    );
  }
}
