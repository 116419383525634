import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Client } from '@shared/interfaces/client';
import { ClientFilter } from '@shared/interfaces/clientFilter';
import { EpochDateTimePipe } from '@shared/pipes/epoch-datetime.pipe';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { ClientService } from '@shared/services/client.service';
import { saveTextFile } from '@shared/util/fileUtil';
import { arrayIsEmpty } from '@shared/util/listUtil';
import { isNotBlank } from '@shared/util/stringUtils';
import { JsonEditorOptions, NgJsonEditorModule } from 'ang-jsoneditor';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MessageService } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-client-admin',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    ToastModule,
    ButtonComponent,
    ModalComponent,
    NgJsonEditorModule,
    EpochDateTimePipe
  ],
  providers: [provideNgxMask(), MessageService],
  templateUrl: './client-admin.component.html',
  styleUrl: './client-admin.component.scss'
})
export class ClientAdminComponent {
  @ViewChild('viewModal') viewModal!: ModalComponent; 
  public editorOptions: JsonEditorOptions;
  selected: Client[] = [];
  records: Client[] = [];
  recordSelected: Client = {};
  statusList = ["draft", "registered"];
  recordStatusList = ["active", "deleted"];
  totalRecords: number = 0;
  loading: boolean = false;
  public form: FormGroup = this.fb.group({
    rut: [''],
    clientName: [''],
    clientStatus: [''],
    recordStatus: ['active'],
  });

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private fb: FormBuilder, 
    private messageService: MessageService,
    private loadingProvider: LoadingProvider,
    private clientService: ClientService) {
      this.editorOptions = new JsonEditorOptions()
      this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
      this.editorOptions.mode = 'code'; //set only one mode
  }

  onSearch(params: TableLazyLoadEvent){
    this.loading = true;
    let filter = this.form.value as ClientFilter;
    this.clientService.pagedFindClient({
      filters: filter,
      first: params.first ?? 0,
      rows: params.rows ?? 10
    }).subscribe((resp) => {
      this.records = resp.list;
      this.totalRecords = resp.total;
      this.loading = false;
      this.selected = [];
    });
  }

  deleteBase(filter: ClientFilter){
    this.loadingProvider.show();
    this.clientService.deleteByGenerator(filter).subscribe({
      next: (resp) => {
        let ids = isNotBlank(filter.id) ? filter.id : filter.ids?.join(',');
        this.loadingProvider.hide();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `eliminado(s) ${ids}` });
        this.selected = [];
        this.onSearch({});
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.log(msg);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: msg });
      }
    });
  }

  deleteSelected(){
    if (!arrayIsEmpty(this.selected)){
      let ids: string[] = this.selected.map(item => item.id!);
      this.deleteBase({
        ids
      });
    }
  }

  delete(client: Client){
    this.deleteBase({
      id: client.id
    });
  }

  view(client: Client){
    this.recordSelected = client;
    this.viewModal.openModal();
  }

  download(client: Client){
    saveTextFile(JSON.stringify(client, null, 2), `${client.id}.json`);
  }
}
