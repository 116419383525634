import { CommonModule } from '@angular/common';
import { Component, ViewChild , OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelComponent } from '../overlay-panel/overlay-panel.component';
import { RouterLink } from '@angular/router';
import { StorageService } from '@shared/services/storage.service';
import { MenuItem } from '@shared/interfaces/menuItem';
import { MENU } from '@shared/util/roleUtil';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    OverlayPanelComponent,
    RouterLink
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit {
  @ViewChild('op') op!: OverlayPanelComponent;
  sidebarVisible: boolean = false;

  public menuItems: MenuItem[] = [];

  constructor(
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.menuItems = this.storageService.getUser()?.menu ?? [];
  }

  closeCallback(e: Event): void {
    this.op.hide();
  }

  clickItem(item: MenuItem) {
    item.active = !item.active; 
  }
}





