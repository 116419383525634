import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GridClientContactsComponent } from '@pages/client/grid-client-contacts/grid-client-contacts.component';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { FINAL_ADMINISTRATION_FEE_MAX } from '@shared/constants/otic.constants';
import { ModeEnum } from '@shared/enums/modeEnum';
import { ParameterEnum } from '@shared/enums/parameterEnum';
import { BusinessArea } from '@shared/interfaces/businessArea';
import { Client } from '@shared/interfaces/client';
import { Otic } from '@shared/interfaces/otic';
import { OticService } from '@shared/services/otic.service';
import { ParameterService } from '@shared/services/parameter.service';
import { disableAllField } from '@shared/util/formUtil';
import { normalizeForSearch } from '@shared/util/stringUtils';
import { rutValidator } from '@shared/validators/validators';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
  selector: 'app-data-non-commercial-form',
  standalone: true,
  imports: [
    CommonModule,
    GridClientContactsComponent,
    ReactiveFormsModule,
    InputTextModule,
    InputNumberModule,
    RadioButtonModule,
    DropdownModule,
    FieldContainerComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
    AutoCompleteModule,
    TextReadonlyComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './data-non-commercial-form.component.html'
})
export class DataNonCommercialFormComponent implements OnInit, OnChanges {
  @ViewChild('clientContacts') clientContacts!: GridClientContactsComponent;
  
  public form: FormGroup = this.fb.group({});

  @Input()
  mode: ModeEnum = ModeEnum.EDIT;
  @Input() client: Client = {};
  region: any = [];
  proveniencia: any = [];
  currentOticName: Otic[] = [];
  businessAreas: BusinessArea[] = [];
  filteredBusinessAreas: BusinessArea[] = [];

  constructor(
    private fb: FormBuilder, 
    private parameterService: ParameterService,
    private oticService: OticService
  ){}

  ngOnInit(): void {
    this.parameterService.getParameter(ParameterEnum.Region).subscribe(resp => this.region = resp.data);
    this.parameterService.getParameter(ParameterEnum.Proveniencia).subscribe(resp => this.proveniencia = resp.data);
    this.oticService.getListOtic().subscribe(data => this.currentOticName = data);
    this.oticService.getListBusinessArea().subscribe(data => this.businessAreas = data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['client']){
      if(this.client.isPotential === true) {
        this.form = this.initClientDataPotentialForm();
      } else {
        this.form = this.initClienteDataNonPotentialForm();
      }
      const isRelatedCompaniesControl = this.form.get('isRelatedCompanies');
      const relatedCompanyNameControl = this.form.get('relatedCompanyName');
      isRelatedCompaniesControl?.valueChanges.subscribe((value: string) => {
        if (value === 'Si') {
          isRelatedCompaniesControl?.setValue('Si', { emitEvent: false });
          relatedCompanyNameControl?.enable();
        } else {
          isRelatedCompaniesControl?.setValue('No', { emitEvent: false });
          relatedCompanyNameControl?.disable();
        }
      });
      if (!this.client.isPotential){
        disableAllField(this.form, ['observations', 'proveniencia']);
      }
      this.form.reset(this.client);
    }
    if (this.mode === ModeEnum.VIEW){
      disableAllField(this.form);
    }
  }

  initClientDataPotentialForm(): FormGroup {
    return this.fb.group({
      clientName: ['', [Validators.required]],
      rut: ['', [Validators.required, rutValidator]],
      category: [[]],
      region: [''],
      observations: [''],
      isRelatedCompanies: ['No'],
      relatedCompanyName: [''],
      proveniencia: [''],
      currentOticName: [''],
      currentOticServices: [''],
      percentageKnownAnnual: [''],
      finalAdministrationFee: ['', [Validators.max(FINAL_ADMINISTRATION_FEE_MAX)]],
    });
  }

  initClienteDataNonPotentialForm(): FormGroup {
    return this.fb.group({
      clientName: ['', [Validators.required]],
      rut: ['', [Validators.required, rutValidator]],
      category: [[]],
      region: [''],
      observations: [''],
      isRelatedCompanies: ['No'],
      relatedCompanyName: [''],
      proveniencia: ['']
    });
  }

  searchCategories(event: AutoCompleteCompleteEvent) {
    const filtered = this.businessAreas.filter(
      (item: BusinessArea) => normalizeForSearch(item.description).includes(normalizeForSearch(event.query)));
    this.filteredBusinessAreas = filtered.slice(0, 10);
  }

  validate(){
    if (this.mode != ModeEnum.EDIT) return true;
    this.form.markAllAsTouched();
    return this.form.valid; 
  }
  
  getData(){
    return {
      ...this.form.value,
      companyContacts: this.clientContacts.getCompanyContacts(),
      statusInPresenteYear: (this.client.isPotential ? 'Potencial' : 'Cliente Antiguo')
    };
  }

}
