import { Injectable } from '@angular/core';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable, map } from 'rxjs';
import { Fec } from '@shared/interfaces/fec';
import { ServiceBase } from './serviceBase';
import { FecFilter } from '@shared/interfaces/fecFilter';
import { PagedList } from '@shared/interfaces/pagedList';
import { PagedListFilter } from '@shared/interfaces/pagedListFilter';
import { ApprovalDecision } from '@shared/interfaces/approvalDecision';
    
@Injectable({
    providedIn: 'root'
})
export class FecService extends ServiceBase {

    constructor(httpProvider: HttpProvider) { 
        super(httpProvider);
    }

    createFecByGenerator(fec: Fec){
        return this.post<any>('generator/create', fec).pipe(
            map(resp => resp['data'])
        );
    }

    deleteByGenerator(filter: FecFilter){
        return this.post<any>('fec/deleteByGenerator', filter).pipe(
            map(resp => resp['data'])
        );
    }

    createFec(fec: Fec){
        return this.post<any>('fec', fec).pipe(
            map(resp => resp['data'])
        );
    }

    updateFec(fec: Fec){
        return this.put<any>(`fec/${fec.id}`, fec).pipe(
            map(resp => resp['data'])
        );
    }

    updatePeriod(fec: Fec){
        return this.post<any>(`fec/updatePeriod/${fec.id}`, fec).pipe(
            map(resp => resp['data'])
        );
    }

    getFec(id: string): Observable<Fec>{
        return this.get<any>(`fec/${id}`).pipe(
            map(resp => resp['data'])
        );
    }

    findFec(filter: FecFilter): Observable<Fec[]>{
        return this.post<any>('fec/find', filter).pipe(
            map(resp => resp['data'])
        );
    }

    pagedFindFec(filter: PagedListFilter<FecFilter>): Observable<PagedList<Fec>>{
        return this.post<any>('fec/pagedFind', filter).pipe(
            map(resp => resp['data'])
        );
    }

    deleteFec(fec: Fec){
        return this.post<any>('fec/delete', fec).pipe(
            map(resp => resp['data'])
        );
    }

    sendFec(fec: Fec){
        return this.post<any>(`fec/send/${fec.id}`, fec).pipe(
            map(resp => resp['data'])
        );
    }

    approvalFec(fecId: string, approvalDecision: ApprovalDecision){
        return this.post<any>(`fec/approval/${fecId}`, approvalDecision).pipe(
            map(resp => resp['data'])
        );
    }

    generateNewVersion(fec: Fec){
        return this.post<any>(`fec/generateNewVersion/${fec.id}`, fec).pipe(
            map(resp => resp['data'])
        );
    }
};
