import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export const isFormGroup = (control: AbstractControl): control is FormGroup => {
    return !!(<FormGroup>control).controls;
}

export const collectErrors = (control: AbstractControl): any | null => {
    if (isFormGroup(control)) {
        return Object.entries(control.controls)
        .reduce(
            (acc: any, [key, childControl]) => {
            const childErrors = collectErrors(childControl);
            if (childErrors) {
                acc = {...acc, [key]: childErrors};
            }
            return acc;
            },
            null
        );
    } else {
        return control.errors;
    }
}

export const disableAllField = (form: FormGroup, fieldNameExclude: string[] = []) => {
    let excludeArray = fieldNameExclude ?? [];
    Object.keys(form.controls).forEach(ctrl => {
        if (!excludeArray.includes(ctrl))
            form.controls[ctrl].disable();
    });
}

export const updateValidation = (form: FormGroup, fieldName: string, 
    validations: ValidatorFn | ValidatorFn[] | null) => {
    form.controls[fieldName].setValidators(validations);
    form.controls[fieldName].updateValueAndValidity();
}


