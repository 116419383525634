import { v4 as uuid } from 'uuid';
import { FecYear } from "@shared/interfaces/fecYear";
import { Fec } from "@shared/interfaces/fec";
import { Scenario } from "@shared/interfaces/scenario";
import { FecYearSummary } from "@shared/interfaces/fecYearSummary";
import { arrayIsEmpty } from "./listUtil";
import { CostScenario } from "@shared/interfaces/costScenario";
import { ScenarioManager } from "./ScenarioManager";
import { PeriodYear } from "@shared/interfaces/periodYear";
import { dateToStringDDMMYYYY } from "./dateUtil";

export class FecYearManager {
    private processed: FecYear;
    constructor(private fec: Fec, private fecYear: FecYear){
        this.processed = this.process(fec, fecYear);
    }

    private process(fec: Fec, fecYear: FecYear){
        let processed: FecYear = {...fecYear};
        processed.summary = this.processSummary(fec.scenarios ?? [], fecYear);
        return processed;
    }

    private processSummary(scenarios: Scenario[], fecYear: FecYear): FecYearSummary {
        if (arrayIsEmpty(fecYear.scenarios)){
            return {};
        }
        /* extraemos los escenarios del listado porque en el fecyear se manejan solo id's */
        let scenariosComplete = fecYear.scenarios?.map(item => scenarios.find(scenario => scenario.id === item.id)) ?? [];
        /* Copiamos los ingresos del primer escenario (solo los valores de ingreso), porque todos tienen
            los mismos ingresos */
        let processed: FecYearSummary = {
            ...scenariosComplete[0],
            id: undefined
        };
        /* juntamos los costos de todos los escenarios en un solo array */
        processed.costs = scenariosComplete.reduce((list: CostScenario[], scenario) => {
            return list.concat(scenario?.costs ?? []);
        }, []);
        /* Copiamos los ingresos del primer escenario (solo los valores de ingreso), porque todos tienen
            los mismos ingresos */
        return (new ScenarioManager(processed).getProcessed());
    }

    /**
     * Recibe un listado de escenarios y actualiza los fecyears segun los cambios
     * @param scenario
     * @returns 
     */
    static updateListFecYearByScenariosChange(scenarios: Scenario[], fecYears: FecYear[]) : FecYear[] {
        return fecYears.map(fecYear => this.updateFecYearByScenariosChange(scenarios, fecYear));
    }

    static deleteScenarioInFecYearById = (scenarios: Scenario[], fecYear: FecYear, deleteScenarioId: string) : FecYear => {
        if (arrayIsEmpty(fecYear.scenarios))
            return fecYear;
        let newFecYear: FecYear = {
            ...fecYear,
            scenarios: fecYear.scenarios?.filter((item: Scenario) => item.id !== deleteScenarioId)
        };
        return new FecYearManager({scenarios}, newFecYear).getProcessed();
    }

    static addScenarioToFecYear(scenarios: Scenario[], fecYear: FecYear, scenario: Scenario) : FecYear {
        let scenariosYear = fecYear.scenarios ?? [];
        if (scenariosYear.find(item => item.id === scenario.id)){
            return fecYear;
        }
        scenariosYear.push({
            id: scenario.id,
            description: scenario.description
        });
        return new FecYearManager({scenarios}, {
            ...fecYear,
            scenarios: scenariosYear
        }).getProcessed();
    }

    static generateFecYear(startDate: Date, finishDate: Date){
        let periodYears: PeriodYear[] = [];
        let fecYears: FecYear[] = [];
        const startYear = startDate.getFullYear();
        const finishYear = finishDate.getFullYear();
        for (let year = startYear; year <= finishYear; year++) {
            let periodYear = {
                year: year,
                startDate: year === startYear ? dateToStringDDMMYYYY(startDate) : `01/01/${year}`,
                endDate: year === finishYear ? dateToStringDDMMYYYY(finishDate) : `31/12/${year}`
            };
            periodYears.push(periodYear);
            fecYears.push({
                ...periodYear,
                id: uuid(),
                description: 'Cuadro ' + year,
            });
        }
        return {
            startYear,
            finishYear,
            periodYears,
            fecYears,
            inOneTaxYear: startYear == finishYear
        };
    }

    static updateFecYearByScenariosChange(scenarios: Scenario[], fecYear: FecYear) : FecYear {
        let scenariosYear = fecYear.scenarios ?? [];
        /* removemos los escenarios que no existan en la lista */
        scenariosYear = scenariosYear.filter((scenarioYear) => scenarios.find(scenario => scenarioYear.id === scenario.id));
        /* actualizamos los cambios en la descripción */
        scenariosYear = scenariosYear.map(scenarioYear => {
            let scenarioComplete = scenarios.find(scenario => scenarioYear.id === scenario.id);
            return {
                id: scenarioComplete?.id,
                description: scenarioComplete?.description
            }
        });
        return new FecYearManager({scenarios}, {
            ...fecYear,
            scenarios: scenariosYear
        }).getProcessed();
    }


    getProcessed(){
        return this.processed;
    }
}

