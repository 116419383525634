import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FecStatusEnum } from '@shared/enums/fecStatusEnum';

@Component({
  selector: 'approval-flow-chip',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './approval-flow-chip.component.html',
  styleUrl: './approval-flow-chip.component.scss'
})
export class ApprovalFlowChipComponent {

  @Input()
  public status: string = 'default';
  @Input()
  public label!: string;

  statusColors: Record<string, string> = {
    [FecStatusEnum.DRAFT]: '#FF8B10',
    [FecStatusEnum.PENDING]: '#FF8B10',
    [FecStatusEnum.APPROVED]: '#00C56B',
    [FecStatusEnum.REJECTED]: '#ED475B',
    'default': '#E5E0EB',
  };

  getStatusColor(status: string): string {
    if (this.statusColors.hasOwnProperty(status)) return this.statusColors[status];
    return this.statusColors['default'];
  }

}
