import { Component, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Client } from '@shared/interfaces/client';
import { SearchExecutiveComponent } from '@shared/components/search-executive/search-executive.component';
import { SearchHunterComponent } from '@shared/components/search-hunter/search-hunter.component';
import { ModeEnum } from '@shared/enums/modeEnum';

@Component({
  selector: 'app-otic-contacts-current-form',
  standalone: true,
  imports: [
    CommonModule,
    SearchExecutiveComponent,
    SearchHunterComponent
  ],
  templateUrl: './otic-contacts-current-form.component.html'
})
export class OticContactsCurrentFormComponent {
  ModeEnum = ModeEnum;
  @ViewChild('hunterComponent') hunterComponent!: SearchHunterComponent;
  @ViewChild('affiliatorComponent') affiliatorComponent!: SearchExecutiveComponent;
  @ViewChild('maintainerComponent') maintainerComponent!: SearchExecutiveComponent;
  @Input() client: Client = {};
  @Input() mode: ModeEnum = ModeEnum.EDIT;

  validate(){
    return this.hunterComponent.validate() && this.affiliatorComponent.validate() && this.maintainerComponent.validate();
  }

  getData(){
    return {
      hunterContact: this.hunterComponent.getData(),
      affiliatorContact: this.affiliatorComponent.getData(),
      maintainerContact: this.maintainerComponent.getData()
    }
  }
}
