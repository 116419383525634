<app-modal title="Agregar Usuario" [height]="620" [width]="679" (hide)="offModalContent()" #modalAdd>
    <app-user-data *ngIf="modalContent" [records]="records" (closeModal)="modalAdd.closeModal()" (userAlreadyExists)="userAlreadyExists($event)"></app-user-data>
</app-modal>

<app-modal title="Editar Usuario" [height]="620" [width]="679" (hide)="offModalContent()" #modalEdit>
    <app-user-data *ngIf="modalContent" [records]="records" [update]="true" [selectedIndex]="indexSelected"
        [user]=itemSelected (closeModal)="modalEdit.closeModal()"></app-user-data>
</app-modal>

<app-modal title="Gestionar Accesos" [height]="595" [width]="679" (hide)="offModalContent()" #modalRoles>
    <app-user-roles *ngIf="modalContent" [records]="records" [selectedIndex]="indexSelected" [user]=itemSelected
        (closeModal)="modalRoles.closeModal()"></app-user-roles>
</app-modal>

<app-confirm #confirmDelete type="warning" title="Está a punto de eliminar un Usuario"
    message="¿Esta seguro/a de eliminar este Usuario?" (acceptClick)="deleteUser()"></app-confirm>
<app-confirm #successDelete type="success" title="Usuario eliminado"
    message="El usuario ha sido eliminado con éxito"></app-confirm>
<app-confirm #userAlreadyExistsAlert type="warning" title="Usuario existente"
    [htmlContent]="this.userAlreadyExistsMessage"></app-confirm>

<app-page-container title="Mantenedor de Usuarios" [paths]="paths">
    <app-filter-container [title]="'Buscar Usuarios'" hideButton={{true}} (search)="onSearch()">
        <div>
            <form [formGroup]="form" autocomplete="off"
                class="col-10 col-offset-1 flex flex-row justify-content-between mb-20px">
                <div class="flex flex-column">
                    <app-field-container [form]="form" fieldName="fullName" label="Nombre Usuario">
                        <input type="text" pInputText placeholder="Todos" formControlName="fullName"
                            class="otic-form-s-input" />
                    </app-field-container>

                </div>

                <div class="flex flex-column">
                    <app-field-container [form]="form" fieldName="rut" label="Rut">
                        <input (keyup)="updateInput()" class="otic-form-s-input" type="text" pInputText
                            placeholder="Todos" [maxlength]="50" formControlName="rut"
                            mask="0.000.000-A || 00.000.000-A" />
                    </app-field-container>
                </div>
                <div class="flex align-items-center justify-content-end">
                    <p-button label="Buscar" (click)="onSearch()" class=""></p-button>
                </div>
            </form>
        </div>
    </app-filter-container>

    <app-grid-container title="Listado de Usuarios" addTitle="Agregar Usuario" (add)="onAdd()">
        <p-table #dt [paginator]="true" [lazy]="false"
            currentPageReportTemplate="{first} - {last} de {{records.length}}" [rowsPerPageOptions]="[10, 25, 20]"
            [rows]="10" [showCurrentPageReport]="true" (onLazyLoad)="onSearch($event)" [totalRecords]="records.length"
            [loading]="loading" [value]="records" [tableStyle]="{ 'min-width': '50rem' }"
            styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col">Nombre</th>
                    <th style="width:130px" scope="col">RUT</th>
                    <th scope="col" class="text-center">Email</th>
                    <th scope="col" class="text-center">Estado</th>
                    <th scope="col" class="text-center">Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>{{ item.fullName | uppercase }}</td>
                    <td>{{ item.rut | rut }}</td>
                    <td class="text-center">{{ item.email }}</td>
                    <td class="flex justify-content-center"><app-status-chip [status]="item.status"></app-status-chip>
                    </td>
                    <td>
                        <div class="grid align-items-center">
                            <div class="col">
                                <div class="flex align-items-center justify-content-center">
                                    <div (click)="openGridActions($event, item, index)" class="otic-actions">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </app-grid-container>

</app-page-container>
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>
