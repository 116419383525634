<div style="min-height: 576px;">
    <form [formGroup]="form" autocomplete="off">
        <app-scenario-subtitle label="Entradas"></app-scenario-subtitle>
        <div class="formgrid grid mb-2">
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="contributionCommitment"
                    label="Compromiso de aportes del año *"
                >
                    <p-inputNumber 
                        [style]="{ width: '380px' }"
                        formControlName="contributionCommitment" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    >
                    </p-inputNumber>
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="finalAdministrationFee"
                    label="Tasa Administración Final *"
                >
                    <input 
                        type="text" 
                        [style]="{ width: '380px' }"
                        pInputText 
                        [maxlength]="6" 
                        formControlName="finalAdministrationFee"
                        suffix="%"
                        [decimalMarker]="','" 
                        mask="percent.2"
                    />
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-text-readonly
                    label="Ingreso contra Tasa"
                    [value]="processScenario.counterRateIncome"
                    [width]="380"
                    type="amount"
                ></app-text-readonly>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="nonFranchisableAccountIncome"
                    label="Ingresos cta no franquiciable"
                >
                    <p-inputNumber 
                        [style]="{ width: '380px' }"
                        formControlName="nonFranchisableAccountIncome" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    >
                    </p-inputNumber>
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="provisionPd"
                    label="Provisión PD"
                >
                    <p-inputNumber 
                        [style]="{ width: '380px' }"
                        formControlName="provisionPd" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    >
                    </p-inputNumber>
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="consultancyProvision"
                    label="Provisión Consultoría"
                >
                    <p-inputNumber 
                        [style]="{ width: '380px' }"
                        formControlName="consultancyProvision" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    >
                    </p-inputNumber>
                </app-field-container>
            </div>
        </div>
        <app-scenario-subtitle label="Facturas"></app-scenario-subtitle>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="additionalInvoice"
                    label="Factura Adicional"
                >
                    <p-inputNumber 
                        [style]="{ width: '380px' }"
                        formControlName="additionalInvoice" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    >
                    </p-inputNumber>
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-6 mb-1">
                <app-field-container
                    [form]="form"
                    fieldName="paymentAgreement"
                    label="Pacto de pago"
                >
                    <p-calendar 
                        formControlName="paymentAgreement"
                        [style]="{ width: '380px' }"
                        dateFormat="dd/mm/yy"
                        placeholder="dd/mm/aaaa"
                    >
                    </p-calendar>
                </app-field-container>
            </div>
    
            <div class="field col-12 md:col-12 mb-1" >
                <label for="files">Documento Factura</label>
                <app-list-fileupload
                    id="files"
                    #listFileuploadComponent
                    [itemFileList]="itemFileList"
                ></app-list-fileupload>
            </div>
    
            <div class="field col-12 md:col-12 mb-1">
                <app-text-readonly
                    label="Ingresos Brutos"
                    [value]="processScenario.grossIncome"
                    [width]="380"
                    type="amount"
                ></app-text-readonly>
            </div>
        </div>
    </form>
</div>
