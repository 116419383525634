import { IncomeScenario } from "@shared/interfaces/incomeScenario";
import { valueToFloat, valueToInteger } from "./numberUtil";
import { arrayIsEmpty } from "./listUtil";

export class IncomeScenarioManager {

    static processIncomeScenario(scenario: IncomeScenario): IncomeScenario {
        scenario.contributionCommitment = valueToInteger(scenario.contributionCommitment);
        scenario.finalAdministrationFee = valueToFloat(scenario.finalAdministrationFee),
        scenario.nonFranchisableAccountIncome = valueToInteger(scenario.nonFranchisableAccountIncome),
        scenario.provisionPd = valueToInteger(scenario.provisionPd),
        scenario.consultancyProvision = valueToInteger(scenario.consultancyProvision),
        scenario.additionalInvoice = valueToInteger(scenario.additionalInvoice),
        scenario.counterRateIncome = this.calculateCounterRate(scenario),
        scenario.grossIncome = this.calculateGrossIncome(scenario)
        return scenario;
    };

    static calculateCounterRate(scenario: IncomeScenario): number {
        return valueToInteger(scenario.contributionCommitment) 
          * (valueToFloat(scenario.finalAdministrationFee)/100);
    }
    
    static calculateGrossIncome(scenario: IncomeScenario): number {
        return valueToInteger(scenario.counterRateIncome) + valueToInteger(scenario.additionalInvoice)
        + valueToInteger(scenario.provisionPd) + valueToInteger(scenario.nonFranchisableAccountIncome)
        + valueToInteger(scenario.consultancyProvision);
    };

    static processSummaryFromListIncome(scenarios: IncomeScenario[]): IncomeScenario {
        if (arrayIsEmpty(scenarios)){
            return {};
        }
        let first = scenarios[0];
        let summary = this.createIncomeScenario();
        /* Copiamos la tasa del primer elemento, se asume que todas son iguales */
        summary.finalAdministrationFee = first.finalAdministrationFee;
        /* sumamos todos los elementos */
        for (const child of scenarios) {
            summary.contributionCommitment! += child.contributionCommitment!;
            summary.nonFranchisableAccountIncome! += child.nonFranchisableAccountIncome!;
            summary.provisionPd! += child.provisionPd!;
            summary.consultancyProvision! += child.consultancyProvision!;
            summary.additionalInvoice! += child.additionalInvoice!;
        }
        return summary;
    };

    static processCompleteSummaryFromListIncome(scenarios: IncomeScenario[]): IncomeScenario {
        let summary = this.processSummaryFromListIncome(scenarios);
        return this.processIncomeScenario(summary);
    }

    static createIncomeScenario() : IncomeScenario {
        return {
          contributionCommitment: 0,
          finalAdministrationFee: 0,
          nonFranchisableAccountIncome:  0,
          provisionPd: 0,
          consultancyProvision: 0,
          additionalInvoice: 0,
          counterRateIncome: 0,
          grossIncome: 0
        };
    }
}

