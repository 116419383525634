import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';

import { MenuModule, Menu } from 'primeng/menu';
import { TableModule, TableLazyLoadEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';

import { StatusLabelEnum } from '@shared/enums/statusLabelEnum';
import { Fec } from '@shared/interfaces/fec';
import { FecFilter } from '@shared/interfaces/fecFilter';
import { FecService } from '@shared/services/fec.service';

import { ModalComponent } from '@shared/components/modal/modal.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { FilterContainerComponent } from '@shared/components/filter-container/filter-container.component';
import { StatusChipComponent } from '@shared/components/status-chip/status-chip.component';
import { FecSummaryComponent } from '../fec-summary/fec-summary.component';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';

@Component({
  selector: 'app-approval-management',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PageContainerComponent,
    FilterContainerComponent,
    ButtonModule,
    GridContainerComponent,
    TableModule,
    MenuModule,
    ModalComponent,
    FecSummaryComponent,
    InputTextModule,
    StatusChipComponent,
    DropdownModule,
    CustomPercentPipe,
    ChileanPesoPipe,
    RouterOutlet
  ],
  templateUrl: './approval-management.component.html',
  styleUrl: './approval-management.component.scss'
})
export class ApprovalManagementComponent implements OnInit {
  @ViewChild('approvalFlowView') approvalFlowView!: ModalComponent;
  @ViewChild('menu') menu?: Menu;

  paths = ['HOME', 'Aprobación FEC', 'Gestión Aprobaciones']
  records!: Fec[];
  totalRecords: number = 0;
  loading: boolean = false;

  statusList = Object.entries(StatusLabelEnum)
    .map(([key, value]) => ({ name: value, value: key }));

  public form: FormGroup = this.fb.group({
    rut: [''],
    clientName: [''],
    comercialName: [''],
    fecStatus: [''],
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private fecService: FecService
  ) {}

  ngOnInit() {
    this.onSearch({ first: 0, rows: 10 });
  }

  onSearch(params: TableLazyLoadEvent) {
    this.loading = true;
    this.fecService.pagedFindFec({
      filters: {...this.form.value, useInterested: true} as FecFilter,
      first: params.first ?? 0,
      rows: params.rows ?? 10
    }).subscribe((resp) => {
      this.records = resp.list;
      this.totalRecords = resp.total;
      this.loading = false;
    });
  }

  onRowCheckPreview(item: Fec, index: number) {
    return this.router.navigate(['/approval-fec'], { queryParams: {id: item.id}});
  }
}
