<div class="grid align-items-center mt-6">
    <div class="col">
        @if(showPrevious){
            <div class="flex align-items-center justify-content-start">
                <app-button label="Atrás" 
                    (clickButton)="onPrevious()" 
                    type="secondary"
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        }
    </div>
    <div class="col">
        @if(showNext){
            <div class="flex align-items-center justify-content-end">
                <app-button label="Siguiente" 
                    (clickButton)="onNext()" 
                    [disabled]="nextClickDisabled"
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        }
        @else if (showFinal) {
            <div class="flex align-items-center justify-content-end">
                <app-button label="{{finalLabel}}" 
                    (clickButton)="onClickFinal()" 
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        }
    </div>
</div>
