import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FilterContainerComponent } from '@shared/components/filter-container/filter-container.component';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { StatusChipComponent } from '@shared/components/status-chip/status-chip.component';
import { User } from '@shared/interfaces/user';
import { UserService } from '@shared/services/user.service';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Menu, MenuModule } from 'primeng/menu';
import { TableLazyLoadEvent, TableModule } from 'primeng/table';
import { UserDataComponent } from "./user-data/user-data.component";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { optionalRutValidator, rutValidator } from '@shared/validators/validators';
import { UserRolesComponent } from "./user-roles/user-roles.component";
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { DomSanitizer } from '@angular/platform-browser';
import { stringToFullRutFormat } from '@shared/util/rutUtil';
import { RutPipe } from '@shared/pipes/rut.pipe';

@Component({
  selector: 'app-user-admin',
  standalone: true,
  providers: [provideNgxMask()],
  templateUrl: './user-admin.component.html',
  styleUrl: './user-admin.component.scss',
  imports: [
    ModalComponent,
    CommonModule,
    PageContainerComponent,
    DropdownModule,
    ReactiveFormsModule,
    FilterContainerComponent,
    InputTextModule,
    ButtonModule,
    GridContainerComponent,
    TableModule,
    StatusChipComponent,
    MenuModule,
    UserDataComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    UserRolesComponent,
    ConfirmComponent,
    FieldContainerComponent,
    RutPipe
  ]
})
export class UserAdminComponent {

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private loadingProvider: LoadingProvider,
    private sanitizer: DomSanitizer
  ) { }

  @ViewChild('modalAdd') modalAdd!: ModalComponent;
  @ViewChild('modalEdit') modalEdit!: ModalComponent;
  @ViewChild('modalRoles') modalRoles!: ModalComponent;
  @ViewChild('menu') menu?: Menu;
  @ViewChild('confirmDelete') confirmDelete?: ConfirmComponent;
  @ViewChild('successDelete') successDelete?: ConfirmComponent;
  @ViewChild('userAlreadyExistsAlert') userAlreadyExistsAlert?: ConfirmComponent;

  paths: string[] = ["HOME", "Mantenedor de Usuarios"];
  loading: boolean = false;

  records: User[] = []

  indexSelected: number = -1;
  itemSelected: any = null;

  modalContent: boolean = false

  userAlreadyExistsMessage: any = null

  gridActions = [
    {
      label: 'Editar',
      icon: 'otic-edit',
      command: () => {
        this.onRowEdit(this.itemSelected, this.indexSelected);
      }
    },
    {
      label: 'Eliminar',
      icon: 'otic-delete',
      command: () => {
        this.onRowDelete(this.itemSelected, this.indexSelected);
      }
    },
    {
      label: 'Gestionar Accesos',
      icon: 'otic-gear',
      command: () => {
        this.onRowChangeStatus(this.itemSelected, this.indexSelected);
      }
    }
  ];


  public form: FormGroup = this.fb.group({
    rut: ['', [optionalRutValidator]],
    fullName: ['']
  });

  updateInput() {
    if (this.form.value.rut.length > 0) {
      this.form.get('fullName')?.disable();
    } else {
      this.form.get('fullName')?.enable();
    }
  }

  validate() {
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  onSearch(params?: TableLazyLoadEvent) {
    if (this.form.value.rut.length > 1) {
      if (!this.validate()) return
    }
    this.loadingProvider.show()
    this.loading = true
    if (this.form.value.rut) {
      this.userService.getUser(this.form.value.rut).subscribe((resp) => {
        if (resp) {
          this.records = [resp]
        } else {
          this.records = []
        }
      });
    } else {
      this.userService.findUser({ fullName: this.form.value.fullName }).subscribe((resp) => {
        this.records = resp;
      })
    }
    this.loadingProvider.hide()
    this.loading = false
  }

  onAdd() {
    this.modalContent = true
    this.modalAdd.openModal()
  }

  openGridActions(event: any, item: any, index: number) {
    this.indexSelected = index;
    this.itemSelected = item;
    this.menu?.toggle(event);
  }

  onRowEdit(item: User, index: number) {
    // console.log('onRowEdit', { item, index })
    this.modalContent = true
    this.modalEdit.openModal()
  }
  onRowChangeStatus(item: User, index: number) {
    // console.log('onRonRowChangeStatusowEdit', { item, index })
    this.modalContent = true
    this.modalRoles.openModal()
  }
  onRowDelete(item: User, index: number) {
    // console.log('onRowDelete', { item, index })
    this.confirmDelete?.showConfirm();
  }
  userAlreadyExists(userData: { name: string, rut: string }) {
    userData.rut = stringToFullRutFormat(userData.rut)
    const unsafeHtml = `Estimado usuario: <br> El usuario <b>${userData.name}</b> RUT <b>${userData.rut}</b> ya existe, ingrese uno diferente.`;
    const safeHtml = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
    this.userAlreadyExistsMessage = safeHtml
    this.userAlreadyExistsAlert?.showConfirm()
  }

  deleteUser() {
    this.userService.updateUser({
      rut: this.itemSelected.rut,
      status: 'deleted'
    }).subscribe({
      next: (res) => {
        this.records.splice(this.indexSelected, 1)
        this.successDelete?.showConfirm()
      },
      error: (err) => {
        console.log(err)
      }
    });
  }

  updateRecord(updatedUser: User) {
    this.records[this.indexSelected] = updatedUser
  }

  offModalContent() {
    this.modalContent = false
  }
}
