<app-page-container
    title="Aprobación FEC"
    [paths]="paths"
>

<div class="header flex grid justify-content-between w-full">
    @for (item of headerInfo; track $index;) {
        <p class="m-0">
            <span class="font-nunito-s16-w600-color1">{{item.label}}: </span>
            {{item.value}}
        </p>
    }
</div>

<div class="grid" style="margin-top: 30px;">
    <div class="col-8">
        <app-fec-summary [fec]="fec"></app-fec-summary>
        <div class="download-container" >
            <div class="otic-download cursor-pointer" (click)="getPdf()"></div>
            <div class="font-nunito-s16-w600-color4 cursor-pointer" (click)="getPdf()"> Descargar</div>
        </div>
    </div>
    <div class="col-4">
        <div class="action-panel flex flex-column">
            <p class="font-nunito-s16-w600-color1 my-2">Aprobación</p>
            <div class="flex flex-row justify-content-between">
                @if (pendingApproval){
                    <app-button
                        label="Aprobar"
                        [fontWeight]="400"
                        (clickButton)="approveFec()"
                        [customStyles]="{ 'background-color': '#00C56B'}"
                    />
                    <app-button
                        label="Rechazar"
                        [fontWeight]="400"
                        (clickButton)="rejectFec()"
                        [customStyles]="{ 'background-color': '#ED475B'}"
                    />
                }
                @else {
                    <div class="flex flex-row justify-content-between">
                        <p class="m-0 font-nunito-s16-w400-color2">{{fec?.fecStatus ?? '' | status}}</p>
                    </div>
                }
            </div>
        </div>
        <div class="observations-container">
            <div class="font-nunito-s16-w600-color1" style="margin-top: 50px;">Observaciones</div>
            <textarea
                ngClass="observations-container-input"
                [disabled]="!pendingApproval"
                pInputTextarea
                rows="5"
                cols="50"
                [autoResize]="true"
                [(ngModel)]="observation"
                [maxlength]="255"
                placeholder="Ingrese observaciones">
            </textarea>
            <small id="observations-characters" class="flex justify-content-end" style="color: #ABA7AF">
                {{ observation?.length ?? 0 }} / 255
            </small>
        </div>
    </div>
</div>
