import { SummaryPdfTemplateConfig } from "@shared/interfaces/summaryPdfTemplate";

const costsBaseTemplate = [
    {
        canvas: [
            {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 1,
                color: "#184D9B"
            }
        ]
    },
    { text: "#cost_type_description#", style: "subheader" },
    {
        style: "tableExample",
        layout: "noBorders",
        table: {
            widths: [150, 50, 40, "*", "*"],
            body: [
                [
                    { text: "Servicio", style: "label2" },
                    { text: "Cantidad", style: "label2" },
                    '',
                    { text: "Valor", style: "label2" },
                    ''
                ],
                [' ', '', '', '', ''],
                [
                    { text: "Provisión", style: "label2" },
                    '',
                    '',
                    { text: "Valor Total", style: "label3" },
                    { text: "#cost_ammount#", style: "label3" },
                ],
                [
                    { text: "#provision_year_cost#", style: "label2" },
                    '',
                    '',
                    { text: "Tasa #cost_type_description#", style: "label3" },
                    { text: "#cost_total_rate#", style: "label3" },
                ],
            ],
        },
    },
]

const yearsBase = [
    { text: "Entradas #year#", style: "header", pageBreak: 'before' },
    {
        style: "tableExample",
        layout: "noBorders",
        table: {
            widths: ["*", "auto", 10, "*", "auto"],
            body: [
                [
                    { text: "Compromiso de aportes del año", style: "label2" },
                    { text: "#contribution_commitment_year#" },
                    '',
                    { text: "Ingresos cuenta no franquiciable", style: "label2" },
                    { text: "#non_franchisable_account_income_year#" },
                ],
                [
                    { text: "Tasa administración final:", style: "label2" },
                    { text: "#final_administration_fee_year#" },
                    '',
                    { text: "Provisión PD:", style: "label2" },
                    { text: "#provision_pd_year#" },
                ],
                ['', '', '', { text: "Provisión Consultoría:", style: "label2" }, { text: "#consultancy_provision_year#" }],
                ['', '', '', { text: "Facturas adicionales:", style: "label2" }, { text: "#additional_invoice_year#" }],
                [
                    { text: "Ingreso contra tasa", style: "label3" },
                    { text: "#counter_rate_income_year#", style: "label3" },
                    { text: "", style: "label3" },
                    { text: "Ingresos Brutos", style: "label3" },
                    { text: "#gross_income_year#", style: "label3" },
                ],
            ],
        },
    },
    { text: "Gastos", style: "header" },
    {
        margin: [0, 10, 0, 10],
        canvas: [
            {
                type: 'line',
                x1: 0,
                y1: 0,
                x2: 515,
                y2: 0,
                lineWidth: 1,
                color: "#184D9B",
            }
        ]
    },
    {
        style: "tableExample",
        layout: "noBorders",
        table: {
            widths: [20, "*", "*", 10, "*", "*", 20],
            heights: [30],
            body: [
                ["", { text: "Ingreso Real:", style: "label4", }, { text: "#cost_real_income#", style: "label4", }, { text: "", style: "label4", }, { text: "Tasa Real:", style: "label4", }, { text: "#effective_rate_year#", style: "label4", }, ""]
            ]
        }
    }

]

export default (templateConfig: SummaryPdfTemplateConfig, childIndex?: number): any => {
    let years: any[] = []
    templateConfig.years.forEach((yearConfig, indexP) => {
        let costs: any[] = []
        let yearTextTemplate = JSON.stringify(yearsBase).replace(/#(\w+)#/g, (match, word) => {
            return `#${word}${indexP + 1}#`;
        });
        let currentYearTemplate = JSON.parse(yearTextTemplate)
        let costsContent: any[] = []
        yearConfig.costs.forEach((cost, indexCost) => {
            let costsBaseString = JSON.stringify(costsBaseTemplate).replace(/#(\w+)#/g, (match, word) => {
                return `#${word}${indexP + 1}_${indexCost + 1}#`;
            });
            let costsBase = JSON.parse(costsBaseString)

            costs = []

            for (let index = 0; index < cost.itemsCount; index++) {
                const costItemBase = [
                    { text: "#name_year_cost_item#" },
                    { text: "#ammount_year_cost_item#", alignment: "center" },
                    '',
                    { text: "#value_year_cost_item#" },
                    ''
                ]
                const costItem = JSON.stringify(costItemBase).replace(/#(\w+)#/g, (match, word) => {
                    return `#${word}${indexP + 1}_${indexCost + 1}_${index + 1}#`;
                });
                costs.push(JSON.parse(costItem));
            }

            for (let i = 0; i < costs.length; i++) {
                costsBase[2].table?.body.splice(1 + i, 0, costs[i]);
            }

            costsContent.push(costsBase)
        })

        for (let i = 0; i < yearConfig.costs.length; i++) {
            currentYearTemplate.splice(3 + i, 0, costsContent[i]);
        }

        years.push(currentYearTemplate);
    });

    if (childIndex) {
        let chainText = `child${childIndex}_`;
        const childSummaryContent = JSON.stringify(years).replace(/#(.*?)#/g, (match: any, p1: any) => {
            return `#${chainText}${p1}#`;
        });
        years = JSON.parse(childSummaryContent)
    }

    return {
        content: years
    };
}





