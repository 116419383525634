<div class="flex flex-column justify-content-between" style="height: 408px;">
    <form [formGroup]="form" autocomplete="off" class="flex">
        <app-field-container
            [form]="form"
            fieldName="description"
            label="Nombre Escenario"
        >
            <input 
                type="text" 
                [style]="{ width: '380px' }"
                pInputText 
                placeholder="Ingrese Nombre de Escenario" 
                formControlName="description" 
                maxlength="50"
            />
        </app-field-container>
    </form>
    <div class="flex flex-row justify-content-end gap-2">
        <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
        <app-button label="Confirmar" (clickButton)="onNext()"></app-button>
    </div>
</div>


