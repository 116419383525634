<div>
    <form [formGroup]="form" autocomplete="off">
        <div class="formgrid grid justify-content-between col">
            <div class="field">
                <app-field-container
                    [form]="form"
                    fieldName="costSubType"
                    label="Seleccione Servicio DO"
                >
                    <p-dropdown 
                        [style]="{ width: '380px' }"
                        class="w-full"
                        formControlName="costSubType" 
                        [options]="costSubTypeList" 
                        optionLabel="description" 
                        optionValue="description" 
                        placeholder=""
                        [dropdownIcon]="'fa fa-caret-down'"
                        (onChange)="changeCostSubType($event)"
                        
                    />
                </app-field-container>
            </div>
            <div class="field">
                <app-text-readonly
                    label="Valor"
                    type="amount"
                    [value]="costSubTypeSelected.amount"
                    [width]="220"
                ></app-text-readonly>
            </div>
            <div class="field" style="width: 140px;">
                <app-field-container
                    [form]="form"
                    fieldName="quantity"
                    label="Cantidad"
                >
                    <p-inputNumber 
                        formControlName="quantity" 
                        [maxFractionDigits]="0"
                        mode="decimal"
                        [inputStyle]="{ width: '100px' }"
                    > 
                    </p-inputNumber>
                </app-field-container>
            </div>
        </div>
    </form>
</div>
