import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Scenario } from '@shared/interfaces/scenario';
import { Menu, MenuModule } from 'primeng/menu';

@Component({
  selector: 'app-scenario-item',
  standalone: true,
  imports: [
    CommonModule,
    MenuModule
  ],
  templateUrl: './scenario-item.component.html',
  styleUrl: './scenario-item.component.scss'
})
export class ScenarioItemComponent implements OnInit  {
  @ViewChild('menu') menu?: Menu; 
  @Input() 
  public type: "normal" | "antecedent" | "actual" = "normal";
  @Input() 
  public inFecYear: boolean = false;
  @Input() 
  public scenario: Scenario = {};
  @Output()
  public view = new EventEmitter<Scenario>();
  @Output()
  public edit = new EventEmitter<Scenario>();
  @Output()
  public delete = new EventEmitter<Scenario>();
  gridActions: any[] = [];

  ngOnInit() {
    
    if (!this.inFecYear){
      this.gridActions.push({
        label: 'Ver',
        icon: 'otic-view',
        command: () => {
          this.view.emit(this.scenario);    
        }
      });
    }
    if (this.type === 'normal' && !this.inFecYear){
      this.gridActions.push({
        label: 'Editar',
        icon: 'otic-edit',
        command: () => {
          this.edit.emit(this.scenario); 
        }
      });
    }
    if (this.type === 'normal'){
      this.gridActions.push({
        label: 'Eliminar',
        icon: 'otic-delete',
        command: () => {
          this.delete.emit(this.scenario);          
        }
      });
    }
  }

  openGridActions(event: any){
    this.menu?.toggle(event);  
  }

  getDescription(){
    if (this.type === "actual"){
      return "Situación Actual";
    }
    else if (this.type === "antecedent"){
      return "Cierre Anterior";
    }
    return this.scenario.description;
  }
}
