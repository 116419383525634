@if(visible){
    <div class="loading">
        <div class="flex flex-column border-0 align-items-center" style="background-color: transparent;">
            <img src="../../../../../assets/loading.gif" style="height: 100px; width: 100px;" alt=""
            >
            <div style="color: #FFFFFF;">Por favor espere...</div>
        </div>
    </div>
}

