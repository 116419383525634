<form [formGroup]="form" autocomplete="off">
    <div class="formgrid grid col-8 m-auto justify-content-between p-0">
        <h3 class="col-12 p-0">Datos Empresa</h3>
        <div class="flex flex-column otic-form-input">
            <app-field-container
                [form]="form"
                fieldName="clientName"
                label="Nombre Cliente*"
            >
                <input 
                    type="text" 
                    pInputText 
                    [maxlength]="50"
                    placeholder="Nombre Cliente" 
                    formControlName="clientName"
                />
            </app-field-container>
            
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <app-field-container
                [form]="form"
                fieldName="rut"
                label="RUT*"
            >
                <input 
                    type="text" 
                    pInputText 
                    placeholder="1.234.567-8" 
                    [maxlength]="50" 
                    formControlName="rut"
                    mask="0.000.000-A || 00.000.000-A"
                    
                />
            </app-field-container>
        </div>
        <div class="flex flex-column field col-12 p-0">
            <label for="category">Rubro</label>
            <p-autoComplete 
                formControlName="category" 
                placeholder="Rubro"
                [suggestions]="filteredBusinessAreas"
                optionLabel="description"
                (completeMethod)="searchCategories($event)"
                [multiple]="true" 
                emptyMessage="No se encontraron resultados"
            />
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="region">Región</label>
            <p-dropdown 
                class="w-full"
                formControlName="region" 
                [options]="region"
                optionLabel="description"
                optionValue="description" 
                placeholder="Seleccione Región"
                [dropdownIcon]="'fa fa-caret-down'"
                
            />
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="observations">Observaciones</label>
            <div class="input-observations-container">
                <input
                    class="w-full"
                    type="text"
                    [maxlength]="255"
                    pInputText 
                    placeholder="Ingrese Observaciones" 
                    formControlName="observations"
                />
                <small id="observations-characters" class="flex justify-content-end" style="color: #ABA7AF">
                    {{ form.get('observations')?.value?.length ?? 0 }} / 255
                </small>
            </div>
        </div>
        <div class="flex flex-column gap-2 field col-12 p-0">
            <label for="relatedCompanies">Empresas Relacionadas dentro de la OTIC</label>
            <div class="flex flex-row gap-5">
                <div class="flex align-items-center">
                    <p-radioButton
                        name="isRelatedCompanies"
                        formControlName="isRelatedCompanies"
                        value="Si" />
                <label for="isRelatedCompaniesSi">Si</label>
                </div>
                <div class="flex align-items-center">
                    <p-radioButton
                        name="isRelatedCompanies"
                        formControlName="isRelatedCompanies"
                        value="No" />
                <label for="isRelatedCompaniesNo">No</label>
                </div>
            </div>
        </div>
        <div *ngIf="form.get('isRelatedCompanies')?.value === 'Si'" class="flex flex-column gap-2 field col-12 md:col-6">
            <label for="status">Nombre Empresa Relacionada</label>
            <input type="text" pInputText placeholder="Nombre Empresa Relacionada" formControlName="relatedCompanyName"/>
        </div>

        <h3 class="col-12 p-0">Condición Empresa</h3>
        <div class="flex flex-column gap-2 field otic-form-input">
            <app-text-readonly
                label="Status cliente durante el año"
                [value]="client.isPotential ? 'Potencial' : 'Cliente Antiguo'"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="proveniencia">Proveniencia</label>
            <p-dropdown 
                class="w-full"
                formControlName="proveniencia" 
                [options]="proveniencia" 
                optionLabel="description"
                optionValue="description" 
                placeholder="Seleccione Proveniencia"
                [dropdownIcon]="'fa fa-caret-down'"
                
            />
        </div>
    </div>

    <app-grid-client-contacts 
        #clientContacts
        [companyContacts] = "client.companyContacts ?? []"
        [mode]="mode"
    ></app-grid-client-contacts>

    <h3 class="col-12 p-0">Datos Financieros</h3>
    @if (client.isPotential){
        <div class="formgrid grid col-12 m-auto">
            <div class="flex flex-column field otic-form-input" style="margin-right: 97px;">
                <label for="currentOticName">Nombre OTIC actual</label>
                <p-dropdown 
                    class="w-full"
                    formControlName="currentOticName" 
                    [options]="currentOticName" 
                    optionLabel="description"
                    placeholder="Seleccione OTIC"
                    [dropdownIcon]="'fa fa-caret-down'"
                    
                />
            </div>
            <div class="flex flex-column field otic-form-input">
                <label for="currentOticServices">Servicios Vigentes OTIC Actual</label>
                <div class="input-current-otic-services-container">
                    <input
                        class="w-full"
                        type="text" 
                        [maxlength]="250"
                        pInputText 
                        placeholder="Indique Servicios Vigentes" 
                        formControlName="currentOticServices"
                    />
                    <small id="currentOticServices-characters" class="flex justify-content-end" style="color: #ABA7AF">
                        {{ form.get('currentOticServices')?.value?.length ?? 0 }} / 255
                    </small>
                </div>
            </div>
        </div>
        <div class="formgrid grid col-12 m-auto">
            <div class="flex flex-column field otic-form-input" style="margin-right: 97px;">
                <label for="percentageKnownAnnual">1% anual conocido</label>
                <p-inputNumber 
                    styleClass="w-full" 
                    formControlName="percentageKnownAnnual" 
                    mode="currency" 
                    currency="CLP"
                    locale="es-CL" 
                    [maxlength]="12"
                    [maxFractionDigits]="0"
                    placeholder="%"
                    >
                </p-inputNumber>
            </div>
            <div class="flex flex-column field otic-form-input" style="margin-right: 111px;">
                <app-field-container
                    [form]="form"
                    fieldName="finalAdministrationFee"
                    label="Tasa Administración Final"
                >
                    <input 
                        type="text" 
                        pInputText 
                        [maxlength]="6" 
                        formControlName="finalAdministrationFee"
                        suffix="%"
                        [decimalMarker]="','" 
                        mask="percent.2"
                    />
                </app-field-container>
            </div>
        </div>
    }
    @else {
        <div class="formgrid grid col-12 m-auto">
            <div class="flex flex-column field otic-form-input">
                <app-text-readonly
                    label="Servicios Vigentes"
                    [value]="client.currentOticServices"
                    width="100%"
                ></app-text-readonly>
                <small id="currentOticServices-characters" style="text-align: right;">
                    {{ client.currentOticServices?.length ?? 0 }} / 255
                 </small>
            </div>
        </div>
        <div class="formgrid grid col-12 m-auto">
            <div class="flex flex-column field otic-form-input" style="margin-right: 97px;">
                <app-text-readonly
                    label="1% actualizado"
                    [value]="client.percentageKnownAnnual"
                    type="amount"
                    width="100%"
                ></app-text-readonly>
            </div>
            <div class="flex flex-column field otic-form-input" style="margin-right: 111px;">
                <app-text-readonly
                    label="Tasa Administración Final"
                    [value]="client.finalAdministrationFee"
                    type="percentage"
                    width="100%"
                ></app-text-readonly>
            </div>
            <div class="flex flex-column field otic-form-input">
                <app-text-readonly
                    label="Tasa Ingreso mínimo por contrato"
                    [value]="client.minimalIncomeRatePerContract"
                    type="percentage"
                    width="100%"
                ></app-text-readonly>
            </div>
        </div>
    }
</form>
