<app-page-container
    title="Evaluaciones Comerciales"
    [paths]="paths"
>
    <div class="tabs-filters-container">
        @for (tab of filterTabs;track tab.active)
        {
            <div
                class="tab-filter"
                [class.tab-active]="tab.active"
                (click)="changeActiveTab(tab)"
                (keypress)="changeActiveTab(tab)"
            >
                <p class="m-0">{{ tab.label }}</p>
            </div>
        }
    </div>
    <app-filter-container (search)="onSearch({})" [hideButton]="true">
        <form [formGroup]="form" autocomplete="off" class="flex flex-column mb-20px gap-2">
            <div class="flex flex-column col-8">
                <label
                    id="comercialNameFilter"
                    for="comercialName"
                >
                Cliente Comercial
                </label>
                <input
                    type="text"
                    pInputText
                    placeholder="Todos"
                    formControlName="comercialName"
                    class="w-full"
                    (keyup.enter)="onSearch({})"
                />
            </div>
            <div class="flex flex-row">
                <div class="flex flex-column col-4">
                    <label for="rut">RUT</label>
                    <input
                        type="text" 
                        pInputText 
                        placeholder="Todos"
                        [maxlength]="50" 
                        formControlName="rut"
                        mask="0.000.000-A || 00.000.000-A"
                        (keyup.enter)="onSearch({})"
                    />
                </div>
                <div class="flex flex-column col-4">
                    <label for="clientName">Nombre Cliente</label>
                    <input
                        type="text"
                        pInputText
                        placeholder="Todos"
                        formControlName="clientName"
                        (keyup.enter)="onSearch({})"
                    />
                </div>
                <div class="flex flex-column col-2 align-items-center justify-content-end">
                    <p-button
                        label="Buscar"
                        (click)="onSearch({})"
                        (keypress)="onSearch({})"
                    >
                    </p-button>
                </div>
            </div>
        </form>
    </app-filter-container>

    <app-grid-container
        title="Listado Evaluaciones Comerciales"
        addTitle="Generar FEC"
        [showAddButton]="false"
    >
        <p-table 
            [paginator]="true"
            [lazy]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[10, 25, 20]"
            [rows]="10"
            [showCurrentPageReport]="true"
            (onLazyLoad)="onSearch($event)"
            [totalRecords]="totalRecords"
            [loading]="loading"

            [value]="records" 
            [tableStyle]="{ 'min-width': '50rem' }" 
            styleClass="p-datatable-striped"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:130px" scope="col">RUT</th>
                    <th scope="col">Nombre Cliente</th>
                    <th scope="col">Cliente Comercial</th>
                    <th style="width:100px" scope="col" class="text-center">N° FEC</th>
                    <th scope="col" class="text-center">Fecha Realización</th>
                    <th scope="col" class="text-center">Estado</th>
                    <th scope="col" class="text-center">Tasa de Administración</th>
                    <th scope="col" class="text-center">Tasa Real</th>
                    <th scope="col" class="text-center">Ingreso Real</th>
                    <th scope="col" class="text-center">Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>{{ item.rut }}</td>
                    <td>{{ item.clientName }}</td>
                    <td>{{ item.comercialName }}</td>
                    <td class="text-center">{{ item.correlative }}</td>
                    <td class="text-center">{{ item.createdAt | date: 'dd/MM/yy' }}</td>
                    <td class="flex justify-content-center"><app-status-chip [status]="item.fecStatus"></app-status-chip></td>
                    <td class="text-center">{{ item.summary?.finalAdministrationFee | custompercent }}</td>
                    <td class="text-center">{{ item.summary?.effectiveRate | custompercent }}</td>
                    <td class="text-center">{{ item.summary?.realIncome | chileanpeso }}</td>
                    <td>
                        <div class="grid align-items-center">
                            <div class="col">
                                <div class="flex align-items-center justify-content-center">
                                    <span
                                        (click)="openGridActions($event, item)" 
                                        (keydown)="openGridActions($event, item)"
                                        class="otic-actions"
                                    >
                                    </span>
                                    @if(itemSelected?.approval){
                                        <i class="pi pi-sitemap cursor-pointer" (click)="viewApproval(item)"></i>
                                    }
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </app-grid-container>

</app-page-container>
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>


<app-modal
    [title]="itemSelected?.clientName ?? itemSelected?.comercialName ?? 'FEC'"
    #fecSummaryModal
    [width]="930"
>
    @if(itemSelected){
        <app-fec-summary
            [fec]="itemSelected"
        ></app-fec-summary>
    }
</app-modal>


<app-modal [title]="itemSelected?.clientName ?? 'Flujo de Aprobación'" [height]="800" [width]="950" #approvalModal>
    <app-fec-approval-detail [fec]="itemSelected"  />
</app-modal>
