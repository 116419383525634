import * as fs from 'file-saver';

export const donwloadFileGet = async(url: string, filename: string, type: string) => {
    console.log(`DonwloadUtil.donwloadGet: ${filename}`);
    const response = await fetch(url, {
        method: "GET"
    })
    .catch(err=>{
        console.error(`Error descargando ${filename} => ${err}`);
    });
    if (response?.ok) {
        console.log(`DonwloadUtil.donwloadGet: descarga terminada => ${filename}`);
        const data = await response.blob();
        console.log(data);
        let blobTemp = new Blob([data],{ type: type});
        fs.saveAs(blobTemp, filename);
    }
    else{
      let err = `Error descargando ${filename}`;
      console.error(response, err);
    }
}

export const saveTextFile = async(data: string, filename: string) => {
    var blob = new Blob([data], { type: 'text/plain' });
    fs.saveAs(blob, filename);
}
