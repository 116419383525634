import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { TabViewModule } from 'primeng/tabview';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { Client } from '@shared/interfaces/client';
import { SearchBoxComponent } from '@shared/components/search-box/search-box.component';
import { TableModule } from 'primeng/table';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { Menu, MenuModule } from 'primeng/menu';
import { isBlank, isNotBlank } from '@shared/util/stringUtils';
import { FecService } from '@shared/services/fec.service';
import { LoadingProvider } from '../../../shared/providers/loading.provider';
import { FecSummaryComponent } from '../fec-summary/fec-summary.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Fec } from '@shared/interfaces/fec';
import { ButtonModule } from 'primeng/button';
import { OticService } from '@shared/services/otic.service';
import { MessageProvider } from '@shared/providers/message.provider';

@Component({
  selector: 'app-search-current-client',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    PageContainerComponent,
    TabViewModule,
    StepbarComponent,
    SearchBoxComponent,
    GridContainerComponent,
    TableModule,
    MenuModule,
    ModalComponent,
    FecSummaryComponent,
    ButtonModule
  ],
  templateUrl: './search-current-client.component.html',
  styleUrl: './search-current-client.component.scss'
})
export class SearchCurrentClientComponent {
  paths: string[] = ["HOME", "Control de Gestión", "Generar FEC"];
  expandedRows: any = {};
  @ViewChild('menu') menu?: Menu;
  @ViewChild('fecSummaryModal') fecSummaryModal!: ModalComponent;
  activeIndexTab: number = 0;
  itemSelected: Client = {};
  fecSelected?: Fec;
  records: Client[] = [];
  totalRecords: number = 0;
  loading: boolean = false;
  filter: any = {};
  from: string = 'search-current-client';
  gridActions = [
    {
      label: 'Ver FEC',
      icon: 'otic-edit',
      command: () => {
        this.onView(this.itemSelected);
      }
    },
    {
      label: 'Crear FEC',
      icon: 'otic-plus-blue',
      command: () => {
        this.onCreateFec(this.itemSelected);
      }
    }
  ];

  constructor(private router: Router,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    private fecService: FecService,
    private oticService: OticService
  ) {}

  onSearchByRut(value: string) {
    this.filter = { rut: value };
    this.onSearch();
  }

  onSearchByName(value: string) {
    this.filter = { clientName: value };
    this.onSearch();
  }

  onSearchByComercialName(value: string) {
    this.filter = { comercialName: value };
    this.onSearch();
  }

  onSearch() {
    this.loading = true;
    if (!isBlank(this.filter.rut)) {
      this.oticService.getCurrentClientByRut(this.filter.rut).subscribe((resp) => {
        this.records = []
        if(resp) this.records = [resp]
        this.checkRecords(this.records.length)
      })
    } else if (!isBlank(this.filter.clientName)) {
      this.oticService.getCurrentClientByName(this.filter.clientName).subscribe((resp) => {
        this.records = resp
        this.checkRecords(resp.length)
      })
    } else if (!isBlank(this.filter.comercialName)) {
      this.oticService.getCurrentComertialClientByName(this.filter.comercialName).subscribe((resp) => {
        this.records = resp
        this.checkRecords(resp.length)
      })
    } else {
      this.loading = false;
      this.messageProvider.showError("Error", "Ingrese filtro correspondiente");
    }
  }

  checkRecords(totalRecords: number) {
    this.totalRecords = totalRecords;
    this.loading = false;
    if (this.totalRecords === 0) {
      this.messageProvider.showWarningConfirm(this.getTitleConfirm(), this.getMessageConfirm(), () => {
        this.accept();
      });
    }
  }

  accept() {
    if (isNotBlank(this.filter.rut)) {
      this.router.navigate(['/fec-detail'], { queryParams: {from: this.from}});
    }
    else if (isNotBlank(this.filter.clientName)) {
      this.router.navigate(['/fec-detail'], { queryParams: {from: this.from}});
    }
    else if (isNotBlank(this.filter.comercialName)) {
      this.router.navigate(
        ['/fec-detail'], 
        { queryParams: {type: 'comercial',from: this.from}}
      );
    }
  }

  openGridActions(event: any, item: Client) {
    this.itemSelected = item;
    this.menu?.toggle(event);
  }

  onView(item: Client) {
    this.loadingProvider.show();
    this.fecService.pagedFindFec({
      filters: {
        rut: item.rut
      },
      first: 0,
      rows: 5
    }).subscribe((resp) => {
      console.log(resp);
      this.loadingProvider.hide();
      if (resp.list && resp.list.length > 0) {
        this.fecSelected = resp.list[0];
      }
      else {
        this.fecSelected = undefined;
      }
      this.fecSummaryModal.openModal();
    });
  }

  onCreateFec(client: Client) {
    if (isNotBlank(client.rut) && isNotBlank(client.comercialName) 
      && client.clientName?.toLowerCase() != client.comercialName?.toLowerCase()){
      let msg = `La empresa ${client.clientName} pertenece al Cliente Comercial ${client.comercialName}.
        ¿Quiere mantener los acuerdos comerciales del cliente Comercial?`;
      this.messageProvider.showWarningConfirm("Se ha encontrado una FEC existente", 
        msg,
        () => {
          this.createFecWithComercialClient(client.comercialName!, client.rut!);
        }, 
        () => {
          this.createFec(client);
        },
        "Aceptar",
        "Rechazar"
      );
    }
    else {
      this.createFec(client);
    }
  }

  private createFecWithComercialClient(clientName: string, childRut: string){
    this.loadingProvider.show();
    this.oticService.getCurrentComertialClientByName(clientName).subscribe((resp) => {
      if (resp && resp.length > 0){
        let client = resp.find(item => item.comercialName?.toLowerCase() === clientName.toLowerCase());
        if (client){
          this.createFec(client, childRut);
          return;
        }
      }
      this.messageProvider.showError("Error", `No se encontro el cliente comercial con el nombre: ${clientName}`);
    })
  }

  createFec(client: Client, childRut?: string){
    if(!client.isPotential) client.id = `VIG_` + client.rut;
    this.loadingProvider.show();
    this.fecService.createFec({client}).subscribe({
      next: (resp) => {
        this.loadingProvider.hide();
        this.router.navigate(
          ['/fec-detail'], 
          { queryParams: {id: resp.id, from: this.from}}
        );
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  getTitleConfirm() {
    let type = 'RUT';
    if (isNotBlank(this.filter.clientName)) {
      type = 'Nombre Empresa';
    }
    else if (isNotBlank(this.filter.comercialName)) {
      type = 'Nombre Cliente Comercial';
    }
    return `El ${type} no se ha encontrado`;
  }

  getMessageConfirm() {
    let type = 'RUT';
    if (isNotBlank(this.filter.clientName)) {
      type = 'Nombre Empresa';
    }
    else if (isNotBlank(this.filter.comercialName)) {
      type = 'Nombre Cliente Comercial';
    }
    return `El ${type} no ha sido encontrado en nuestra base de Clientes, por lo que se registrará como un
      Potencial.`;
  }

}
