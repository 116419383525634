import { Client } from '@shared/interfaces/client';
import { Fec } from '../interfaces/fec';
import { generateValidRut } from './rutUtil';
import { stringYYYYMMDDToDate } from './dateUtil';
import { IncomeScenario } from '@shared/interfaces/incomeScenario';
import { CostScenario } from '@shared/interfaces/costScenario';
import { CostItemScenario } from '@shared/interfaces/costItemScenario';
import { Scenario } from '@shared/interfaces/scenario';
import { CostManager } from './CostManager';
import { ScenarioManager } from './ScenarioManager';
import { FecYearManager } from './FecYearManager';
import { Executive } from '@shared/interfaces/executive';

export const generateNumberArray = (length: number) => {
    return Array.from({ length }, (_, index) => (index+1) + '');
}

export const generateRandomNumber = (minimum: number, maximum: number) => {
    // Generate a random number between 0 (inclusive) and 1 (exclusive)
    let randomNumber = Math.random();
    // Adjust the random number to the desired range
    randomNumber = Math.floor(randomNumber * (maximum - minimum + 1)) + minimum;
    return randomNumber;
}

export const getRandomValueFromArray = (values: string[]): string => {
    return values[generateRandomNumber(0, values.length - 1)];
}

export const generateCostItemScenario = (costType: string, index: number): CostItemScenario => {
    return {
        costType,
        description: `costo ${index}`,
        value: 1000000,
        quantity: 1,
    };
}

export interface CostScenarioGenerateConfig {
    costType: string;
    costItems: number;
}

export const generateCostScenario = (config: CostScenarioGenerateConfig): CostScenario => {
    let costBase: CostScenario = {
        ...CostManager.createCostScenario(config.costType),
        provision: 1000000
    };
    for (let i = 0; i < config.costItems; i++) {
        let costItem = generateCostItemScenario(config.costType, i);
        costBase.items?.push(costItem);
    }
    return costBase;
}

export const generateIncomeScenario = () : IncomeScenario => {
    return {
        contributionCommitment: 10000000,
        finalAdministrationFee: 15,
        nonFranchisableAccountIncome:  1000000,
        provisionPd: 1000000,
        consultancyProvision: 1000000,
        additionalInvoice: 1000000
    };
}

export interface ScenarioGenerateConfig {
    costTypes: string[];
    costs: number;
    costItem: string[];
}

export const generateScenario = (config: ScenarioGenerateConfig, index: number):Scenario => {
    let costTypes = [...config.costTypes];
    let scenario: Scenario = ScenarioManager.createScenarioWithId();
    scenario.description = `Escenario ${index}`
    scenario = {
        ...scenario,
        ...generateIncomeScenario()
    };

    for (let i = 0; i < config.costs; i++) {
        let costType: string = getRandomValueFromArray(costTypes);
        let cost = generateCostScenario({
            costType,
            costItems: parseInt(getRandomValueFromArray(config.costItem))
        });
        scenario.costs?.push(cost);
        //quitamos el costo seleccionado aleatoriamente
        costTypes = costTypes.filter(item => item !== costType);
    }

    return scenario;
}

export const generateExecutive = () : Executive => {
    let rut = generateValidRut();
    return {
        rut,
        name: `Nombre ${rut}`,
        email: `${rut}@oticsofofa.com`,
        phone: '99999999',
        zone: 'Zona 1',
        office: 'Oficina 1',
        offices: [
            {
                name: 'Oficina 1',
                zone: ""
            },
            {
                name: 'Oficina 2',
                zone: "" 
            }
        ]
    }
}


export interface ClientGenerateConfig {
    isPotential: boolean;
    isComercial: boolean;
    children: number;
}
  
export const generateClient = (config: ClientGenerateConfig): Client => {
    let rut = generateValidRut();
    let clientName = `${COMPANIES[generateRandomNumber(1,500)]} ${rut}`.toLowerCase();
    let client: Client = {
        clientName,
        comercialName: clientName,
        clientStatus: 'registered',
        isPotential: config.isPotential,
        isComercial: config.isComercial,
        hunterContact: generateExecutive(),
        followUpContact: generateExecutive(),
        maintainerContact: generateExecutive(),
        affiliatorContact: generateExecutive(),
    };
    if (config.isComercial){
        client.isComercial = true;
        let children: Client[] = [];
        for (let i = 0; i < config.children; i++) {
            children.push({
                rut: generateValidRut(),
                clientName: `${COMPANIES[generateRandomNumber(1,500)]} ${rut}`.toLowerCase()
            });
        }
        client.comercialChildren = children;
        client.clientRutConcatList = children.map(cli => `[${cli.rut}]`).join('');
        client.clientNameConcatList = children.map(cli => `[${cli.clientName}]`).join('');
    }
    else{
        client.rut = rut;
    }
    return client;
}

export interface FecGenerateConfig extends ClientGenerateConfig {
  userCreated: string;
  fecStatus: string;
  years: number;
  scenarios: number;
  costTypes: string[];
  costs: number;
  costItem: string[];
  scenarioYear: number;
}

export const generateFec = (config: FecGenerateConfig): Fec => {
    //client generate
    let client = generateClient(config);
    //period generate
    let startDate = `2024/01/01`;
    let finishDate = `${2024 + config.years - 1}/12/31`;
    let fecPeriod = FecYearManager.generateFecYear(stringYYYYMMDDToDate(startDate), stringYYYYMMDDToDate(finishDate));
    console.log(fecPeriod);
    let fec: Fec = {
        userCreated: config.userCreated,
        client: client,
        clientName: client.clientName,
        comercialName: client.comercialName,
        isComercial: client.isComercial,
        clientRutConcatList: client.clientRutConcatList,
        clientNameConcatList: client.clientNameConcatList,
        existingClient: false,
        fecStatus: config.fecStatus,
        lastVersion: true,
        startDate,
        finishDate,
        ...fecPeriod,
        scenarios : [],
    };

    if (client.isComercial){

    }
    else{
        fec.rut = client.rut;
    }

    //escenarios
    if (!fec.isComercial){
        for (let i = 0; i < config.scenarios; i++) {
            let scenario = generateScenario({
                costTypes: config.costTypes,
                costs: config.costs,
                costItem: config.costItem
            }, i);
            fec.scenarios?.push(scenario);
        }
    }
    return fec;
}

export interface GroupFecGenerateConfig {
    quantity: number;
    userCreated: string[];
    comercial: string[];
    status: string[];
    year: string[];
    children: string[];
    scenario: string[];
    costType: string[];
    cost: string[];
    costItem: string[];
    scenarioYear: string[];
}

export const generateFecs = (config: GroupFecGenerateConfig) => {
    let result: Fec[] = [];
    for (let i = 0; i < config.quantity; i++) {
        let optUserCreated = getRandomValueFromArray(config.userCreated);
        let optComercial = getRandomValueFromArray(config.comercial);
        let optStatus = getRandomValueFromArray(config.status);
        let optYear = getRandomValueFromArray(config.year);
        let optChildren = parseInt(getRandomValueFromArray(config.children));
        let optScenario = parseInt(getRandomValueFromArray(config.scenario));
        let optCost = parseInt(getRandomValueFromArray(config.cost));

        let fecConfig: FecGenerateConfig = {
            isPotential: true,
            isComercial: optComercial === "comercial",
            fecStatus: optStatus,
            years: parseInt(optYear),
            children: optComercial === "comercial" ? optChildren : 0,
            scenarios: optScenario,
            costTypes: config.costType,
            costs: optCost,
            costItem: config.costItem,
            scenarioYear: 0,
            userCreated: optUserCreated
        }
        result.push(generateFec(fecConfig));
    }
    return result;
}



const COMPANIES = [
    "3M",
    "Abbott Labs",
    "AbbVie",
    "Accenture",
    "Adobe",
    "ADP",
    "Aflac",
    "Agilent Technologies",
    "AIG",
    "Air Products",
    "Airbnb",
    "Akamai",
    "Albemarle",
    "Alexandria RE",
    "Align",
    "Allegion PLC",
    "Alliant Energy",
    "Allstate",
    "Alphabet A",
    "Alphabet C",
    "Altria",
    "Amazon.com",
    "Amcor PLC",
    "AMD",
    "Ameren",
    "American Airlines",
    "American Electric Power",
    "American Express",
    "American Tower",
    "American Water Works",
    "Ameriprise Financial",
    "Ametek",
    "Amgen",
    "Amphenol",
    "Analog Devices",
    "ANSYS",
    "AO Smith",
    "Aon",
    "APA Corp",
    "Apple",
    "Applied Materials",
    "Aptiv",
    "Arch Capital",
    "Archer-Daniels-Midland",
    "Arista Networks",
    "Arthur J Gallagher",
    "Assurant",
    "AT&T",
    "Atmos Energy",
    "Autodesk",
    "AutoZone",
    "AvalonBay",
    "Avery Dennison",
    "Axon Enterprise",
    "Baker Hughes",
    "Ball",
    "Bank of America",
    "Bank of NY Mellon",
    "Bath & Body Works",
    "Baxter",
    "Becton Dickinson",
    "Berkshire Hathaway B",
    "Best Buy",
    "Bio-Rad Labs",
    "Bio-Techne",
    "Biogen",
    "BlackRock",
    "Blackstone",
    "Boeing",
    "Booking",
    "BorgWarner",
    "Boston Properties",
    "Boston Scientific",
    "Bristol-Myers Squibb",
    "Broadcom",
    "Broadridge",
    "Brown Forman",
    "Brown&Brown",
    "Builders FirstSource",
    "Bunge",
    "Cadence Design",
    "Caesars",
    "Camden Property",
    "Campbell Soup",
    "Capital One Financial",
    "Cardinal Health",
    "CarMax",
    "Carnival Corp",
    "Carrier Global",
    "Catalent Inc",
    "Caterpillar",
    "Cboe Global",
    "CBRE A",
    "CDW Corp",
    "Celanese",
    "Cencora Inc",
    "Centene",
    "CenterPoint Energy",
    "CF Industries",
    "CH Robinson",
    "Charles River Laboratories",
    "Charter Communications",
    "Chevron",
    "Chipotle Mexican Grill",
    "Chubb",
    "Church&Dwight",
    "Cigna",
    "Cincinnati Financial",
    "Cintas",
    "Cisco",
    "Citigroup",
    "Citizens Financial Group Inc",
    "Clorox",
    "CME Group",
    "CMS Energy",
    "Coca-Cola",
    "Cognizant A",
    "Colgate-Palmolive",
    "Comcast",
    "Comerica",
    "Conagra Brands",
    "ConocoPhillips",
    "Consolidated Edison",
    "Constellation Brands A",
    "Constellation Energy",
    "Cooper",
    "Copart",
    "Corning",
    "Corpay",
    "Corteva",
    "CoStar",
    "Costco",
    "Coterra Energy",
    "Crown Castle",
    "CSX",
    "Cummins",
    "CVS Health Corp",
    "Danaher",
    "Darden Restaurants",
    "DaVita",
    "Dayforce",
    "Deckers Outdoor",
    "Deere&Company",
    "Delta Air Lines",
    "Dentsply",
    "Devon Energy",
    "DexCom",
    "Diamondback",
    "Digital",
    "Discover",
    "Dollar General",
    "Dollar Tree",
    "Dominion Energy",
    "Domino’s Pizza Inc",
    "Dover",
    "Dow",
    "DR Horton",
    "DTE Energy",
    "Duke Energy",
    "DuPont De Nemours",
    "Eastman Chemical",
    "Eaton",
    "eBay",
    "Ecolab",
    "Edison",
    "Edwards Lifesciences",
    "Electronic Arts",
    "Elevance Health",
    "Eli Lilly",
    "Emerson",
    "Enphase",
    "Entergy",
    "EOG Resources",
    "EPAM Systems",
    "EQT",
    "Equifax",
    "Equinix",
    "Equity Residential",
    "Essex Property",
    "Estee Lauder",
    "Etsy Inc",
    "Everest",
    "Evergy",
    "Eversource Energy",
    "Exelon",
    "Expedia",
    "Expeditors Washington",
    "Extra Space Storage",
    "Exxon Mobil",
    "F5 Networks",
    "FactSet Research",
    "Fair Isaac",
    "Fastenal",
    "Federal Realty",
    "FedEx",
    "Fidelity National Info",
    "Fifth Third",
    "First Solar",
    "FirstEnergy",
    "Fiserv",
    "FMC",
    "Ford Motor",
    "Fortinet",
    "Fortive",
    "Fox Corp A",
    "Fox Corp B",
    "Franklin Resources",
    "Freeport-McMoran",
    "Garmin",
    "Gartner",
    "GE HealthCare",
    "Gen Digital",
    "Generac",
    "General Dynamics",
    "General Electric",
    "General Mills",
    "General Motors",
    "Genuine Parts",
    "Gilead",
    "Global Payments",
    "Globe Life",
    "Goldman Sachs",
    "Halliburton",
    "Hartford",
    "Hasbro",
    "HCA",
    "Healthpeak Properties",
    "Henry Schein",
    "Hershey Co",
    "Hess",
    "Hewlett Packard",
    "Hilton Worldwide",
    "Hologic",
    "Home Depot",
    "Honeywell",
    "Hormel Foods",
    "Host Hotels Resorts",
    "Howmet",
    "HP Inc",
    "Hubbell",
    "Humana",
    "Huntington Bancshares",
    "Huntington Ingalls Industries",
    "IBM",
    "ICE",
    "IDEX",
    "IDEXX Labs",
    "IFF",
    "Illinois Tool Works",
    "Illumina",
    "Incyte",
    "Ingersoll Rand",
    "Insulet",
    "Intel",
    "International Paper",
    "Intuit",
    "Intuitive Surgical",
    "Invesco",
    "Invitation Homes",
    "IPG",
    "IQVIA Holdings",
    "Iron Mountain",
    "J&J",
    "Jabil Circuit",
    "Jack Henry&Associates",
    "Jacobs Engineering",
    "JB Hunt",
    "JM Smucker",
    "Johnson Controls",
    "JPMorgan",
    "Juniper",
    "Kellanova",
    "Kenvue",
    "Keurig Dr Pepper",
    "KeyCorp",
    "Keysight Technologies",
    "Kimberly-Clark",
    "Kimco Realty",
    "Kinder Morgan",
    "KLA Corp",
    "Kraft Heinz",
    "Kroger",
    "L3Harris Technologies",
    "Laboratory America",
    "Lam Research",
    "Lamb Weston Holdings",
    "Las Vegas Sands",
    "Leidos",
    "Lennar",
    "Linde PLC",
    "Live Nation Entertainment",
    "LKQ",
    "Lockheed Martin",
    "Loews",
    "Lowe’s",
    "Lululemon Athletica",
    "LyondellBasell Industries",
    "M&T Bank",
    "Marathon Oil",
    "Marathon Petroleum",
    "MarketAxesss",
    "Marriott Int",
    "Marsh McLennan",
    "Martin Marietta Materials",
    "Masco",
    "Mastercard",
    "Match Group",
    "McCormick&Co",
    "McDonald’s",
    "McKesson",
    "Medtronic",
    "Merck&Co",
    "Meta Platforms",
    "MetLife",
    "Mettler-Toledo",
    "MGM",
    "Microchip",
    "Micron",
    "Microsoft",
    "Mid-America Apartment",
    "Moderna",
    "Mohawk Industries",
    "Molina Healthcare",
    "Molson Coors Brewing B",
    "Mondelez",
    "Monolithic",
    "Monster Beverage",
    "Moody’s",
    "Morgan Stanley",
    "Mosaic",
    "Motorola",
    "MSCI",
    "Nasdaq Inc",
    "NetApp",
    "Netflix",
    "Newmont Goldcorp",
    "News Corp",
    "News Corp A",
    "NextEra Energy",
    "Nike",
    "NiSource",
    "Nordson",
    "Norfolk Southern",
    "Northern Trust",
    "Northrop Grumman",
    "Norwegian Cruise Line",
    "NRG",
    "Nucor",
    "NVIDIA",
    "NVR",
    "NXP",
    "Occidental",
    "Old Dominion Freight Line",
    "Omnicom",
    "ON Semiconductor",
    "ONEOK",
    "Oracle",
    "Otis Worldwide",
    "O’Reilly Automotive",
    "PACCAR",
    "Packaging America",
    "Palo Alto Networks",
    "Paramount Global B",
    "Parker-Hannifin",
    "Paychex",
    "Paycom Soft",
    "PayPal",
    "Pentair",
    "PepsiCo",
    "Pfizer",
    "PG E",
    "Philip Morris",
    "Phillips 66",
    "Pinnacle West",
    "Pioneer Natural",
    "PNC Financial",
    "Pool",
    "PPG Industries",
    "PPL",
    "Principal Financial",
    "Procter&Gamble",
    "Progressive",
    "Prologis",
    "Prudential Financial",
    "PTC",
    "Public Service Enterprise",
    "Public Storage",
    "PulteGroup",
    "Qorvo Inc",
    "Qualcomm",
    "Quanta Services",
    "Quest Diagnostics",
    "Ralph Lauren A",
    "Raymond James Financial",
    "Realty Income",
    "Regency Centers",
    "Regeneron Pharma",
    "Regions Financial",
    "Republic Services",
    "ResMed",
    "Revvity",
    "Robert Half",
    "Rockwell Automation",
    "Rollins",
    "Roper Technologies",
    "Ross Stores",
    "Royal Caribbean Cruises",
    "Rtx Corp",
    "S&P Global",
    "Salesforce Inc",
    "SBA Communications",
    "Schlumberger",
    "Seagate",
    "Sempra Energy",
    "ServiceNow Inc",
    "Sherwin-Williams",
    "Simon Property",
    "Skyworks",
    "Snap-On",
    "Southern",
    "Southwest Airlines",
    "Stanley Black Decker",
    "Starbucks",
    "State Street",
    "Steel Dynamics",
    "STERIS",
    "Stryker",
    "Super Micro Computer",
    "Synchrony Financial",
    "Synopsys",
    "Sysco",
    "T Rowe",
    "T-Mobile US",
    "Take-Two",
    "Tapestry",
    "Targa Resources",
    "Target",
    "TE Connectivity",
    "Teledyne Technologies",
    "Teleflex",
    "Teradyne",
    "Tesla",
    "Texas Instruments",
    "Textron",
    "The AES",
    "The Charles Schwab",
    "The Travelers",
    "Thermo Fisher Scientific",
    "TJX",
    "Tractor Supply",
    "Trane Technologies",
    "Transdigm",
    "Trimble",
    "Truist Financial Corp",
    "Tyler Technologies",
    "Tyson Foods",
    "U.S. Bancorp",
    "Uber Tech",
    "UDR",
    "Ulta Beauty",
    "Union Pacific",
    "United Airlines Holdings",
    "United Parcel Service",
    "United Rentals",
    "UnitedHealth",
    "Universal Health Services",
    "Valero Energy",
    "Ventas",
    "Veralto",
    "VeriSign",
    "Verisk",
    "Verizon",
    "Vertex",
    "VF",
    "Viatris",
    "VICI Properties",
    "Visa A",
    "Vulcan Materials",
    "Walgreens Boots",
    "Walmart",
    "Walt Disney",
    "Warner Bros Discovery",
    "Waste Management",
    "Waters",
    "WEC Energy",
    "Wells Fargo&Co",
    "Welltower",
    "West Pharmaceutical Services",
    "Western Digital",
    "Westinghouse Air Brake",
    "WestRock Co",
    "Weyerhaeuser",
    "Williams",
    "Willis Towers Watson",
    "WR Berkley",
    "WW Grainger",
    "Wynn Resorts",
    "Xcel Energy",
    "Xylem",
    "Yum! Brands",
    "Zebra",
    "Zimmer Biomet",
    "Zoetis Inc"
]
