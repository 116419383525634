import { FormControl, ValidationErrors } from '@angular/forms';
import { validateRut } from '@shared/util/rutUtil';

export const firstNameAndLastnamePattern: string = '([a-zA-Z]+) ([a-zA-Z]+)';
export const emailPattern: string = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";

export const rutValidator = ( control: FormControl ): ValidationErrors | null => {

  const value: string = control.value?.trim().toLowerCase();

  if ( !validateRut(value) ) {
    return {
        rutInvalid: true
    }
  }

  return null;
}

export const optionalRutValidator = ( control: FormControl ): ValidationErrors | null => {

  const value: string = control.value?.trim().toLowerCase();

  if(value?.length < 1) {
    return null
  }

  if ( !validateRut(value) ) {
    return {
        rutInvalid: true
    }
  }

  return null;
}
