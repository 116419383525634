
<div class="flex flex-column p-3">
    <form [formGroup]="form" autocomplete="off">
        <div class="grid">
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="to"
                    label="to"
                >
                    <input 
                        type="text" 
                        pInputText 
                        formControlName="to"
                    />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="subject"
                    label="subject"
                >
                    <input 
                        type="text" 
                        pInputText 
                        formControlName="subject"
                    />
                </app-field-container>
            </div>
            <div class="col-3">
                <app-field-container
                    [form]="form"
                    fieldName="body"
                    label="body"
                >
                    <input 
                        type="text" 
                        pInputText 
                        formControlName="body"
                    />
                </app-field-container>
            </div>
        </div>
    </form>
    <div class="grid">
        <div class="col-3">
            <app-button 
                label="Generar" 
                (clickButton)="send()" 
                [width]="140" 
                [height]="46" 
                [fontWeight]="700"
            ></app-button>
        </div>
    </div>
</div>
<p-toast />
