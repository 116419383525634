import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, effect } from '@angular/core';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { CostTypeEnum } from '@shared/enums/costTypeEnum';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { ScenarioDescriptionComponent } from '../scenario-description/scenario-description.component';
import { ScenarioSubtitleComponent } from '../scenario-subtitle/scenario-subtitle.component';
import { CostProvider } from '@pages/fec/cost.provider';

@Component({
  selector: 'app-scenario-select-costs',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FieldContainerComponent,
    ButtonComponent,
    CheckboxModule,
    ScenarioDescriptionComponent,
    ScenarioSubtitleComponent
  ],
  templateUrl: './scenario-select-costs.component.html',
  styleUrl: './scenario-select-costs.component.scss'
})
export class ScenarioSelectCostsComponent {
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public next = new EventEmitter();

  errorMessage: string = '';
  selectedCosts: string[] = [];
  costs = Object.entries(CostTypeEnum)
  .map(([key, value]) => ({ name: value, value: key }));

  constructor(
    public scenarioProvider: ScenarioProvider,
    public costProvider: CostProvider
  ){
    effect(() => {
      this.selectedCosts = this.costProvider.getCostTypes();
    });
  }

  validate(){
    if (this.selectedCosts.length === 0){
      this.errorMessage = 'Seleccione por lo menos 1 servicio';
      return false;
    }
    else{
      this.errorMessage = '';
    }
    return true;
  }

  onCancel(){
    this.clickCancel.emit();
  }

  onNext(){
    if (!this.validate())
      return;
    this.scenarioProvider.updateCurrentCostsWithSelection(this.selectedCosts);
    this.next.emit();
  }

  getError(){
    return this.errorMessage;
  }
}
