import { Component } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { NotificationComponent } from '../notification/notification.component';

@Component({
  selector: 'app-notification-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule,
    NotificationComponent
  ],
  templateUrl: './notification-modal.component.html',
  styleUrl: './notification-modal.component.scss'
})
export class NotificationModalComponent {

  visible: boolean = false;

  openDialog() {
      this.visible = true;
  }
  closeDialog() {
    this.visible = false;
}

}
