import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FakeStepsComponent } from '@shared/components/fake-steps/fake-steps.component';
import { ScenarioNameComponent } from './scenario-name/scenario-name.component';
import { ScenarioIncomeComponent } from './scenario-income/scenario-income.component';
import { ScenarioSelectCostsComponent } from './scenario-select-costs/scenario-select-costs.component';
import { ScenarioSummaryCostsComponent } from './scenario-summary-costs/scenario-summary-costs.component';
import { ScenarioDetailCostComponent } from './scenario-detail-cost/scenario-detail-cost.component';

@Component({
  selector: 'app-scenario',
  standalone: true,
  imports: [
    CommonModule,
    FakeStepsComponent,
    ScenarioNameComponent,
    ScenarioIncomeComponent,
    ScenarioSummaryCostsComponent,
    ScenarioSelectCostsComponent,
    ScenarioDetailCostComponent
  ],
  templateUrl: './scenario.component.html',
  styleUrl: './scenario.component.scss'
})
export class ScenarioComponent {
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public finish = new EventEmitter();
  @Input() 
  public mode: 'new' | 'edit' | 'view' = 'new';
  activeIndex: number = 0;
  activeContainer: number = 0;
  steps = [
    {
      label: 'Identificación Escenario',
    },
    {
        label: 'Entradas',
    },
    {
        label: 'Gastos',
    }
  ];

  reset(){
    this.activeIndex = 0;
    this.activeContainer = 0; 
  }

  onCancel(){
    this.reset();
    this.clickCancel.emit();
  }

  saveName(){
    this.activeIndex = 1;
    this.activeContainer= 1;
  }

  saveIncome(){
    this.activeIndex = 2;
    this.activeContainer= 2; 
  }

  saveCost(){
    this.activeIndex = 2;
    this.activeContainer= 3; 
  }

  saveSummary(){
    this.completeScenario();
  }

  onViewDetailCost(){
    this.activeIndex = 2;
    this.activeContainer= 4; 
  }

  saveCostItem(){
    this.activeIndex = 2;
    this.activeContainer= 3; 
  }

  completeScenario(){
    this.finish.emit();
  }

  toCost(){
    this.activeIndex = 2;
    this.activeContainer= 2; 
  }

  toSummary(){
    this.activeIndex = 2;
    this.activeContainer= 3;
  }
}
