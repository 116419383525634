import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FieldContainerComponent } from '../field-container/field-container.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { OticService } from '@shared/services/otic.service';
import { rutValidator } from '@shared/validators/validators';
import { InputTextModule } from 'primeng/inputtext';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { isBlank, isEmpty } from '@shared/util/stringUtils';
import { Executive } from '@shared/interfaces/executive';
import { disableAllField } from '@shared/util/formUtil';
import { validateRut } from '@shared/util/rutUtil';

@Component({
  selector: 'app-search-executive',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    FieldContainerComponent,
    NgxMaskDirective, 
    NgxMaskPipe
  ],
  providers: [provideNgxMask()],
  templateUrl: './search-executive.component.html',
  styleUrl: './search-executive.component.scss'
})
export class SearchExecutiveComponent implements OnInit, OnChanges {
  offices: any[] = [];
  zones: any[] = [];
  @Input() readonly: boolean = false;
  @Input()
  label: string = "";
  executive: Executive = {};
  searchNotFoundError?: string;
  @Input() value?: Executive;

  public form: FormGroup = this.fb.group({
    rut: ['', [Validators.required, rutValidator]],
    name: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    phone: [''],
    zone: [''],
    office: ['', [Validators.required]]
  });

  constructor(
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private oticService: OticService
  ){}

  ngOnInit(): void {
    this.form.get('rut')?.valueChanges.subscribe((value) => {
      if (this.executive.rut !== value){
        this.reset();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.executive = this.value ?? {};
    this.offices = this.executive.offices ?? [];
    this.form.reset(this.executive);
    disableAllField(this.form, ['rut', 'phone', 'office']);
    if (this.readonly){
      disableAllField(this.form);
    }
  }

  reset(){
    this.executive = {};
    this.offices = [];
    this.form.patchValue({
      name: '',
      email: '',
      phone: '',
      zone: '',
      office: '',
    }, { emitEvent: false });
  }

  findExecutive(){
    this.searchNotFoundError = undefined;
    let rut = this.form.value['rut'];
    if (this.loadingProvider.isVisible() 
      || isEmpty(rut) 
      || !validateRut(rut) 
    )
      return;
    this.loadingProvider.show();
    this.oticService.getExecutiveByRut(rut).subscribe({
      next: (data: Executive) => {
        let executive = {
          ...data,
          rut
        };
        this.executive = executive;
        this.offices = executive.offices ?? [];
        this.form.patchValue(executive);
        this.loadingProvider.hide();
      },
      error: (err) => {
        this.reset();
        this.loadingProvider.hide();
        let msg = err.error?.message || err.error || 'Ocurrió un error';
        this.searchNotFoundError = msg;
      }
    });
  }

  validate(){
    this.form.markAllAsTouched();
    if (isBlank(this.executive.name)){
      this.searchNotFoundError = `Debe ingresar un '${this.label}' válido`;
      return false;
    }
    return this.form.valid;
  }

  getData(): Executive | undefined{
    if (!this.validate())
      return undefined;
    let { office, phone } = this.form.value;
    let officeSelected = this.executive?.offices?.find(item => item.name === office);
    this.executive.office = office;
    this.executive.zone = officeSelected?.zone;
    this.executive.phone = phone;
    return this.executive;
  }

  changeOffice(event: DropdownChangeEvent){
    const { value } = event;
    let office = this.executive?.offices?.find(item => item.name === value);
    this.form.get('zone')?.setValue(office?.zone); 
  }
}
