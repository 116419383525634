<app-grid-container
        title="Listado de Potenciales"
        addTitle="Registrar Potencial"
        [showAddButton]="mode==='edit'"
        (add)="onOpenNew()"
>
    <p-table 
        [paginator]="true"
        [lazy]="true"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        [rowsPerPageOptions]="[10, 25, 20]"
        [rows]="10"
        [showCurrentPageReport]="true"
        (onLazyLoad)="onSearch($event)"
        [totalRecords]="totalRecords"
        [loading]="loading"

        [value]="records" 
        [tableStyle]="{ 'min-width': '50rem' }" 
        styleClass="p-datatable-striped"
    >
        <ng-template pTemplate="header">
            <tr>
                <th style="width:130px" scope="col">RUT</th>
                <th scope="col">Nombre Potencial</th>
                <th scope="col" class="text-center">Región</th>
                <th scope="col" class="text-center">Estado</th>
                <th scope="col" class="text-center">Entrega FEC</th>
                <th scope="col" class="text-center">Tiempo en Registro</th>
                <th scope="col" class="text-center">Opciones</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr>
                <td>{{ item.rut }}</td>
                <td>{{ item.clientName }}</td>
                <td class="text-center">{{ item.region | titlecase }}</td>
                <td class="flex justify-content-center"><app-status-chip [status]="item.clientStatus"></app-status-chip></td>
                <td class="text-center">{{ item.deliveryFec }}</td>
                <td class="text-center">{{ item.creationDate }}</td>
                <td>
                    <div class="grid align-items-center">
                        <div class="col">
                            <div class="flex align-items-center justify-content-center">
                                <div 
                                    (click)="openGridActions($event, item)" 
                                    class="otic-actions"
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</app-grid-container>
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>
