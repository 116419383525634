/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Verifica si un String es vacia ("") o undefined, ej:
 *
 * StringUtils.isEmpty(null)      = true
 * StringUtils.isEmpty("")        = true
 * StringUtils.isEmpty(" ")       = false
 * StringUtils.isEmpty("bob")     = false
 * StringUtils.isEmpty("  bob  ") = false
 *
 * @param str
 * @returns
 */
export function isEmpty(str: string = ''): boolean {
    return (str == undefined || 0 === str.length);
  }
  
  /**
   * Verifica lo contrario a isEmpty
   * @param str
   * @returns
   */
  export function isNotEmpty(str: string = ''): boolean {
    return !isEmpty(str);
  }
  
  /**
   * Verifica si un String es blanca, vacia ("") o undefined, ej:
   *
   * StringUtils.isBlank(null)      = true
   * StringUtils.isBlank("")        = true
   * StringUtils.isBlank(" ")       = true
   * StringUtils.isBlank("bob")     = false
   * StringUtils.isBlank("  bob  ") = false
   * @param str
   * @returns
   */
  export function isBlank(str: string = ''): boolean {
    return (str == undefined || /^\s*$/.test(str));
  }
  
  /**
   * Verifica lo contrario a isBlank
   * @param str
   * @returns
   */
  export function isNotBlank(str: string = ''): boolean {
    return !isBlank(str);
  }
  
  /**
   * Normaliza espacios en blanco, ej:
   *
   * 'hola    pepito' -> 'hola pepito'
   *
   * @param str
   * @returns
   */
  export function normalizeWhiteSpaces(str: string = ''): string {
    return str.toString().trim().replace(/\s+/gi, ' ');
  }
  
  /**
   * Transforma un texto que podria ser un boolean a un valor del tipo de dato boolean
   * @param value
   * @param defaultValue
   * @returns
   */
  export function toBoolean(value: any = '', defaultValue: boolean = false): boolean {
    if (typeof value == 'boolean') {
      return value;
    }
    const tmp = value.toString().trim();
    if (tmp == 'true') {
      return true;
    } else if (tmp == 'false') {
      return false;
    }
    return defaultValue;
  }
  
  /**
   * Une una colección de valores en un string, ej:
   *
   * const s = StringUtils.join(';',1,2,3);
   *
   * s -> '1;2;3'
   *
   * @param separator
   * @param args
   * @returns
   */
  export function join(separator: string = ' ', ...args: any): string {
    if (args == undefined || args.length == 0) {
      return '';
    }
    const arr = [];
    for (const s of args) {
      if (isNotBlank(s)) {
        arr.push(s.toString().trim());
      }
    }
    return arr.join(separator);
  }
  
  type TypeVal = string | number | boolean | undefined | null;
  
  /**
   * Retorna el string de un valor, por defecto es ''
   * @param value
   * @returns
   */
  export function val(value: TypeVal): string {
    return value != undefined && value != null ? value.toString() : '';
  }
  
  /**
   * Transforma un texto CamelCase a snake_case, ej:
   *
   * const s = StringUtils.camelToSnakeCase('miAtributoNuevo');
   *
   * s -> 'mi_atributo_nuevo'
   *
   * @param str
   * @returns
   */
  export function camelToSnakeCase(str: string = '') {
    if (isBlank(str)) {
      return str;
    }
    const tmp = str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    return tmp.startsWith('_') && !str.startsWith('_') ? tmp.substring(1) : tmp;
  }
  
  /**
   *  Transforma un texto snake_case a CamelCase, ej:
   *
   * const s = StringUtils.snakeToCamelCase('mi_atributo_nuevo');
   *
   * s -> 'miAtributoNuevo'
   *
   * @param str
   * @returns
   */
  export function snakeToCamelCase(str: string = '') {
    if (isBlank(str)) {
      return str;
    }
    return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
  }
  
  /**
   * Capitalizes first letters of words in string.
   * @param {string} str String to be modified
   * @param {boolean=false} lower Whether all other letters should be lowercased
   * @return {string}
   * @usage
   *   capitalize('fix this string');     // -> 'Fix This String'
   *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
   *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
   */
  export function capitalize(str: string = '', lower = true) {
    return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
  }
  
  /**
   * Crea una mascara de un string, util para mostrar claves en los logs..
   * @param str
   * @returns
   */
  export function mask(str: string = '') {
    if (str.length <= 3) return '*';
    return str.substring(0, str.length / 3).padEnd(str.length, '*');
  }

  export function normalizeForSearch(str: string | undefined): string {
    if (!str)
      return '';
    return str
      .toLocaleLowerCase()
      .normalize('NFD') // Normaliza los caracteres
      .replace(/[\u0300-\u036f]/g, '') // Elimina los acentos
      .replace(/ñ/g, 'n') // Reemplaza ñ por n
      .replace(/Ñ/g, 'N'); // Reemplaza Ñ por N
  }
  
  export default {
    isEmpty,
    isNotEmpty,
    isBlank,
    isNotBlank,
    normalizeWhiteSpaces,
    toBoolean,
    join,
    val,
    camelToSnakeCase,
    snakeToCamelCase,
    capitalize,
    mask,
    normalizeForSearch
  };
  