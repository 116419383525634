@if (scenario){
    <div class="fec-summary-cost">
        <div class="fec-summary-cost-title">Gastos</div>
        <div class="fec-summary-cost-container">
            @for (cost of scenario.costs; track $index;) {
                <div class="fec-summary-cost-row">
                    <div class="fec-summary-cost-item">
                        <div class="fec-summary-cost-label fec-summary-font-s16-w600-color1">
                            {{cost.costTypeDescription}}
                        </div>
                        <div class="fec-summary-cost-item-row fec-summary-font-s16-w600-color1">
                            <div class="fec-summary-cost-item-col-cab-label">Servicio</div> 
                            <div class="fec-summary-cost-item-col-cab-quantity">Cantidad</div> 
                            <div class="fec-summary-cost-item-col-cab-amount">Valor</div> 
                        </div>
                        @for (item of cost.items; track $index;) {
                            <div class="fec-summary-cost-item-row fec-summary-font-s16-w400-color2">
                                <div class="fec-summary-cost-item-col-label">
                                    {{item.description}}
                                </div> 
                                <div class="fec-summary-cost-item-col-quantity">
                                    {{item.quantity}}
                                </div> 
                                <div class="fec-summary-cost-item-col-amount">
                                    {{item.value  | chileanpeso }}
                                </div> 
                                
                            </div>
                        }
                        <div class="fec-summary-cost-item-row" style="margin-top: 5px;">
                            <div class="fec-summary-cost-item-col-provision">
                                <div class="fec-summary-font-s16-w600-color1">Provisión</div> 
                                <div class="fec-summary-font-s16-w400-color2">
                                    {{cost.provision  | chileanpeso }}
                                </div> 
                            </div> 
                            <div class="fec-summary-cost-item-col-summary">
                                <div class="fec-summary-cost-item-col-summary-row">
                                    <div 
                                        class="fec-summary-cost-item-col-summary-row-label
                                            fec-summary-font-s16-w600-color1">
                                        Valor Total
                                    </div> 
                                    <div 
                                        class="fec-summary-cost-item-col-summary-row-value 
                                            fec-summary-font-s16-w400-color2">
                                        {{cost.total  | chileanpeso }}
                                    </div>
                                </div> 
                                <div class="fec-summary-cost-item-col-summary-row">
                                    <div class="fec-summary-cost-item-col-summary-row-label
                                        fec-summary-font-s16-w600-color1">
                                        Tasa
                                    </div> 
                                    <div class="fec-summary-cost-item-col-summary-row-value
                                        fec-summary-font-s16-w400-color2">
                                        {{cost.rate  | custompercent }} 
                                    </div>
                                </div> 
                            </div> 
                        </div>
                    </div>
                    <div class="fec-summary-cost-item-files">
                        <div class="fec-summary-font-s16-w600-color1">Respaldos</div>
                        <app-list-fileupload
                            [itemFileList]="cost.files ?? []"
                            [readonly]="true"
                        ></app-list-fileupload>
                    </div>
                </div>
                <div class="fec-summary-cost-line"></div>
            }
        </div>
    </div>
}
