import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
  visible: boolean = false;
  @Input()
  public title: string = "";
  @Input()
  public width: number = 945;
  @Input()
  public height: number = 0;
  @Output() 
  public hide = new EventEmitter();

  openModal(): void {
    this.visible = true;
  }

  closeModal(): void {
    this.visible = false;
  }

  onHide(){
    this.hide.emit();
  }
}
