<div
    *ngIf="render"
    [ngClass]="'p-overlaypanel2'"
    [ngStyle]="style"
    [class]="styleClass"
    (click)="onOverlayClick($event)"
    [@animation]="{ value: overlayVisible ? 'open' : 'close', params: { showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions } }"
    (@animation.start)="onAnimationStart($event)"
    (@animation.done)="onAnimationEnd($event)"
    role="dialog"
    [attr.aria-modal]="overlayVisible"
    [attr.aria-label]="ariaLabel"
    [attr.aria-labelledBy]="ariaLabelledBy"
    >
    <div class="p-overlaypanel2-content" (click)="onContentClick($event)" (mousedown)="onContentClick($event)">
        @if(contentTemplate){
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        }
    </div>
</div>
