<app-fake-steps
    [steps]="steps" 
    [activeIndex]="activeIndex"
    (navigate)="onNavigate($event)"
    [type]="stepType"
>
</app-fake-steps>

@if (activeIndex === 0) {
    @if(client.isComercial){
        <app-data-commercial-form
            #dataCommercialComponent
            [client]="client" 
            [mode]="mode"
        />
    }
    @else {
        <app-data-non-commercial-form 
            #dataNonCommercialComponent
            [client]="client" 
            [mode]="mode"
        />
    }
    <div class="grid align-items-center mt-6">
        <div class="col">
            <div class="flex align-items-center justify-content-start">
                @if (showPrevious){
                    <app-button label="Atrás" 
                        (clickButton)="onPrevious()" 
                        type="secondary"
                        [width]="148" 
                        [height]="46" 
                        [fontWeight]="400"
                    ></app-button>
                }
            </div>
        </div>
        <div class="col">
            <div class="flex align-items-center justify-content-end">
                <app-button label="Siguiente" 
                    (clickButton)="onToOticContacts()" 
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        </div>
    </div>
}
@if (activeIndex === 1) {
    @if(client.isPotential){
        <app-otic-contacts-potential-form
            #oticContacsPotentialComponent
            [client]="client" 
            [mode]="mode"
        />
    }
    @else {
        <app-otic-contacts-current-form 
            #oticContacsCurrentComponent
            [client]="client" 
            [mode]="mode"
        />
    }
    <div class="grid align-items-center mt-6">
        <div class="col">
            <div class="flex align-items-center justify-content-start">
                <app-button label="Atrás" 
                    (clickButton)="onToClientData()" 
                    type="secondary"
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        </div>
        <div class="col">
            <div class="flex align-items-center justify-content-end">
                <app-button [label]="labelFinalButton" 
                    (clickButton)="onFinal()" 
                    [width]="148" 
                    [height]="46" 
                    [fontWeight]="400"
                ></app-button>
            </div>
        </div>
    </div>
}


