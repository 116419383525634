import { Injectable } from '@angular/core';
import { UserSession } from '@shared/interfaces/userSession';

const USER_KEY = 'currentUser';//'auth-user';
const TOKEN = 'auth-token';
const KEYCLOAK_TOKEN = 'token';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor() {}

  clean(): void {
    window.localStorage.clear();
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN);
    window.localStorage.setItem(TOKEN, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN);
  }

  public saveKeycloakToken(token: string): void {
    window.localStorage.removeItem(KEYCLOAK_TOKEN);
    window.localStorage.setItem(KEYCLOAK_TOKEN, token);
  }

  public getKeycloakToken(): string | null {
    return window.localStorage.getItem(KEYCLOAK_TOKEN);
  }

  public saveUser(user: UserSession): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): UserSession | undefined {
    let userString = window.localStorage.getItem(USER_KEY);
    if (!userString)
      return undefined;
    return JSON.parse(userString) as UserSession;
  }

  public getUserRoles() {
    return this.getUser()?.roles ?? [];
  }

  public getUsername() {
    return this.getUser()?.username ?? '';
  }

}
