<div style="height: 556px;" class="flex flex-column justify-content-between">
    <div class="body-scenario-summary">
        <app-scenario-description
        [label]="scenarioProvider.getCurrent().description"
        ></app-scenario-description>
        <app-scenario-subtitle label="Resumen"></app-scenario-subtitle>
        <div class="scenario-summary-body">
            @for (item of costProvider.getList(); track $index;) {
                <div class="scenario-resume-container">
                    <div class="mt-2">
                        <app-scenario-subtitle
                            [fontWeight]="700"
                            [fontSize]="16" 
                            [label]="item.costTypeDescription">
                        </app-scenario-subtitle>
                    </div>
                    <div class="flex justify-content-between align-items-end mb-2">
                        <app-text-readonly
                            label="Valor Total"
                            [value]="item.total"
                            type="amount"
                            [width]="220"
                        ></app-text-readonly>
                        <app-text-readonly
                            [label]="'Tasa ' + item.costTypeDescription"
                            type="percentage"
                            [value]="item.rate"
                            [width]="220"
                        ></app-text-readonly>
                        <app-button 
                            [width]="100"
                            [height]="40"
                            [fontWeight]="400"
                            label="Ver Detalle" (clickButton)="onViewDetail(item)"
                        ></app-button>
                    </div>
                </div>
            }
        </div>
        <div class="scenario-summary-body" style="padding: 0 13px 0 41px;">
            <app-scenario-subtitle
                [fontWeight]="700"
                [fontSize]="16" 
                label="Resultados">
            </app-scenario-subtitle>
            <div class="flex align-items-end">
                <app-text-readonly
                    label="Ingreso Real"
                    [value]="processScenario.realIncome"
                    type="amount"
                    [width]="220"
                ></app-text-readonly>
                <app-text-readonly
                    style="margin-left: 53px;"
                    label="Tasa Real"
                    type="percentage"
                    [value]="processScenario.effectiveRate"
                    [width]="220"
                ></app-text-readonly>
            </div>
        </div>
    </div>
    <div class="grid">
        <div class="col flex align-items-center mt-3">
            <app-button label="Atras" type="secondary" (clickButton)="onPrevious()"></app-button>
        </div>
        <div class="col flex align-items-center justify-content-end gap-2 mt-3">
            <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
            <app-button label="Agregar" [disabled]="addButtonDisabled" (clickButton)="onNext()"></app-button>
        </div>
    </div>
</div>


