import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ListFileuploadComponent } from '@shared/components/list-fileupload/list-fileupload.component';
import { Scenario } from '@shared/interfaces/scenario';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';

@Component({
  selector: 'app-fec-summary-cost',
  standalone: true,
  imports: [
    CommonModule,
    ListFileuploadComponent,
    ChileanPesoPipe,
    CustomPercentPipe
  ],
  templateUrl: './fec-summary-cost.component.html',
  styleUrl: './fec-summary-cost.component.scss'
})
export class FecSummaryCostComponent {
  @Input() scenario?: Scenario;
}
