import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';

@Component({
  selector: 'app-label-value',
  standalone: true,
  imports: [
    CommonModule,
    ChileanPesoPipe,
    CustomPercentPipe
  ],
  templateUrl: './label-value.component.html',
  styleUrl: './label-value.component.scss'
})
export class LabelValueComponent {
  @Input()
  public config: LabelValueConfig = {};
}

interface LabelValueConfig {
  border?: string;
  type?: "string" | "amount" | "percentage";
  direction?: "horizontal" | "vertical";
  label?: string;
  value?: string | number;
  width?: number;
  height?: number;
  containerClass?: string;
  labelWidth?: number;
  labelHeight?: number;
  valueWidth?: number;
  valueHeight?: number;
  labelClass?: string;
  valueClass?: string;
  labelPadding?: string;
  valuePadding?: string;
}
