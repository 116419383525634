import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { FormBuilder, FormGroup, ReactiveFormsModule, FormsModule, Validators  } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { TableModule } from 'primeng/table';
import { GridContainerComponent } from '@shared/components/grid-container/grid-container.component';
import { FakeStepsComponent } from '@shared/components/fake-steps/fake-steps.component';
import { Menu, MenuModule } from 'primeng/menu';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { InputMaskModule } from 'primeng/inputmask';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { OticService } from '@shared/services/otic.service';
import { CompanyContact } from '@shared/interfaces/companyContact';
import { MessageProvider } from '@shared/providers/message.provider';
import { ModeEnum } from '@shared/enums/modeEnum';

@Component({
  selector: 'app-grid-client-contacts',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    CardModule,
    InputTextModule,
    InputNumberModule,
    CheckboxModule,
    FormsModule,
    StepbarComponent,
    DropdownModule,
    TableModule,
    ModalComponent,
    GridContainerComponent,
    FakeStepsComponent,
    MenuModule,
    FieldContainerComponent,
    InputMaskModule,
    NgxMaskDirective, 
    NgxMaskPipe
  ],
  providers: [provideNgxMask()],
  templateUrl: './grid-client-contacts.component.html',
  styleUrl: './grid-client-contacts.component.scss'
})
export class GridClientContactsComponent implements OnInit {
  ModeEnum = ModeEnum;
  @ViewChild('menu') menu?: Menu; 
  @ViewChild('modal') modal!: ModalComponent; 
  @Input()
  mode: ModeEnum = ModeEnum.EDIT;
  @Input()
  companyContacts: CompanyContact[] = [];
  nonRepeatContactError?: string;
  selectedIndexForDetail: number = -1;
  indexSelected: number = -1;
  itemSelected: any = null;

  steps = [
    {
      label: 'Datos Empresa',
    },
    {
        label: 'Contactos OTIC',
    }
  ];
  visible: boolean = false;
  
  public form: FormGroup = this.fb.group({
    contactType: ['', [Validators.required]],
    name: ['', [Validators.required]],
    position: [''],
    phone: [''],
    email: ['', [Validators.required, Validators.email]]
  });

  contactType: any = [];

  gridActions = [
    {
        label: 'Ver',
        icon: 'otic-view',
        command: () => {
          this.editContact(this.itemSelected, this.indexSelected);     
        }
    },
    {
        label: 'Editar',
        icon: 'otic-edit',
        command: () => {
          this.editContact(this.itemSelected, this.indexSelected); 
        }
    },
    {
        label: 'Eliminar',
        icon: 'otic-delete',
        command: () => {
          this.deleteContact(this.itemSelected, this.indexSelected);          
        }
    }
  ];

  constructor(
    private fb: FormBuilder, 
    private oticService: OticService,
    private messageProvider: MessageProvider
  ){}

  ngOnInit(): void {
    this.oticService.getListContactType().subscribe(data => this.contactType = data);
    this.form.reset({});
  }

  onHide(){
    this.selectedIndexForDetail = -1;
  }

  openModal(): void {
    this.modal.openModal();
  }

  closeModal(): void {
    this.modal.closeModal();
  }

  addNewContact(): void {
    this.nonRepeatContactError = undefined;
    this.form.reset();
    this.modal.openModal();
  }

  editContact(item: CompanyContact, index: number): void {
    this.nonRepeatContactError = undefined;
    this.selectedIndexForDetail = index;
    this.form.reset(item);
    this.openModal();
  }

  deleteContact(item: any, index: number): void {
    this.messageProvider.showWarningConfirm(
      "Eliminar contacto", 
      "¿Está seguro de eliminar este contacto del cliente?",
      ()=> {
        this.companyContacts.splice(index, 1);
      }
    );
  }

  saveContact(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid || !this.nonRepeatContactValidate(this.form.value)) return;
    this.companyContacts.push({...this.form.value});
    this.closeModal();
  }

  updateContact(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid || !this.nonRepeatContactValidate(this.form.value, this.selectedIndexForDetail)) return;
    this.companyContacts[this.selectedIndexForDetail] =  this.form.value;
    this.closeModal();
  }


  openGridActions(event: any, item: any, index: number){
    this.indexSelected = index;
    this.itemSelected = item;
    this.menu?.toggle(event);  
  }

  getCompanyContacts(){
    return this.companyContacts;
  }

  nonRepeatContactValidate(contact: CompanyContact, index: number = -1){
    let result = this.companyContacts.find((item: CompanyContact, idx) => index!=idx && item.email === contact.email);
    if (result){
      this.nonRepeatContactError = 'El email ya fue agregado';
      return false;
    }
    return true;
  }

}
