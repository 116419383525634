import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() 
  public label?: string;
  @Input() 
  public type: string = "primary";//secondary
  @Input() 
  public disabled: boolean = false;
  @Input() 
  public width: number = 148;
  @Input() 
  public height: number = 46;
  @Input() 
  public fontWeight: number = 700;
  @Output()
  public clickButton = new EventEmitter();
  @Input() 
  public icon?: string;
  @Input() 
  public customStyles: { [key: string]: string } = {};

  onClick(event: any){
    event.preventDefault();
    if (this.disabled)
      return;
    this.clickButton.emit();
  }

}
