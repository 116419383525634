<app-page-container
    title="Potenciales"
    [paths]="paths"
>
    <app-filter-container
        (search)="grid.onSearch({})"
    >
        <form [formGroup]="form" autocomplete="off" class="flex flex-row justify-content-between mb-20px">
            <div class="flex flex-column">
                <label for="rut">RUT</label>
                <input
                    class="otic-form-s-input" 
                    type="text" 
                    pInputText 
                    placeholder="Todos"
                    [maxlength]="50" 
                    formControlName="rut"
                    mask="0.000.000-A || 00.000.000-A"
                />
            </div>
            <div class="flex flex-column">
                <label for="clientName">Nombre Empresa</label>
                <input type="text" pInputText placeholder="Todos" formControlName="clientName" class="otic-form-s-input"/>
            </div>
            <div class="flex flex-column">
                <label for="clientStatus">Estado</label>
                <p-dropdown 
                    styleClass="otic-form-s-input" 
                    formControlName="clientStatus" 
                    [options]="statusList" 
                    optionLabel="name" 
                    optionValue="value"
                    placeholder="Todos"
                    [dropdownIcon]="'fa fa-caret-down'"
                    [showClear]="true"
                >
                </p-dropdown>
            </div>
        </form>
    </app-filter-container>
    <app-grid-potential-client 
        #grid 
        [filter]="getFilter()"
        [mode]="mode"
        (openView)="openView($event)"
        (openEdit)="openEdit($event)"
    />
</app-page-container>
