import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoggerProvider } from './shared/providers/logger.provider';
import { environment } from '../environments/environment';
import { PrimeNGConfig } from 'primeng/api';
import { CommonModule } from '@angular/common';
import { LOCALE_ES } from '@shared/util/localeUtil';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    CommonModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit  {
  title = 'OTIC DMP Front';
  constructor(logger: LoggerProvider, private primengConfig: PrimeNGConfig) {
    logger.info(environment.env)
  }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.primengConfig.setTranslation(LOCALE_ES.es);
  }
}
