<div class="new-fec-year-container" >
    <div class="new-fec-year" >
        <div>Agregar cuadro</div>
            <app-button 
                icon="otic-plus" 
                label="Agregar" 
                (clickButton)="add()"
                [height]="40"
                [width]="110"
            ></app-button>
    </div>
</div>

<app-modal
    title="Agregar Cuadro"
    #detailFecYear
    [width]="945"
    [height]="662"
>
    @if(fecProvider.getCurrent().id){
        <app-detail-fec-year
            [isNew]="true"
            (clickCancel)="detailFecYear.closeModal()"
            (save)="detailFecYear.closeModal()"
        ></app-detail-fec-year>
    }
</app-modal>

