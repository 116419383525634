import { Injectable } from '@angular/core';
import { HttpProvider } from '@shared/providers/http.provider';
import { Observable, map } from 'rxjs';
import { ServiceBase } from './serviceBase';
import { Client } from '@shared/interfaces/client';
import { ClientFilter } from '@shared/interfaces/clientFilter';
import { PagedListFilter } from '@shared/interfaces/pagedListFilter';
import { PagedList } from '@shared/interfaces/pagedList';
    
@Injectable({
    providedIn: 'root'
})
export class ClientService extends ServiceBase {

    constructor(httpProvider: HttpProvider) { 
        super(httpProvider);
    }

    createClient(client: Client){
        return this.post<any>('client', client).pipe(
            map(resp => resp['data'])
        );
    }

    createComercial(client: Client){
        return this.post<any>('client/comercial', client).pipe(
            map(resp => resp['data'])
        );
    }

    updateClient(client: Client){
        return this.put<any>(`client/${client.id}`, client).pipe(
            map(resp => resp['data'])
        );
    }

    getClient(id: string): Observable<Client>{
        return this.get<any>(`client/${id}`).pipe(
            map(resp => resp['data'])
        );
    }

    findClient(filter: ClientFilter): Observable<Client[]>{
        return this.post<any>('client/find', filter).pipe(
            map(resp => resp['data'])
        );
    }

    pagedFindClient(filter: PagedListFilter<ClientFilter>): Observable<PagedList<Client>>{
        return this.post<any>('client/pagedFind', filter).pipe(
            map(resp => resp['data'])
        );
    }

    updateToRegistered(client: Client){
        return this.put<any>(`client/updateToRegistered/${client.id}`, client).pipe(
            map(resp => resp['data'])
        );
    }

    deleteClient(client: Client){
        return this.post<any>('client/delete', client).pipe(
            map(resp => resp['data'])
        );
    }

    deleteByGenerator(filter: ClientFilter){
        return this.post<any>('client/deleteByGenerator', filter).pipe(
            map(resp => resp['data'])
        );
    }
};
