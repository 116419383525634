<div 
    class="otic-button"
    [style.width]="width + 'px'"
    [style.height]="height + 'px'"
    [ngStyle]="customStyles"
    [ngClass]="{
                'otic-button-primary': type === 'primary',
                'otic-button-secondary': type === 'secondary',
                'otic-button-primary-disabled': disabled && type === 'primary',
                'otic-button-secondary-disabled': disabled && type === 'secondary'
               }"            
    (click)="onClick($event)">
    <div 
        class="otic-button-label"
        [style.fontWeight]="fontWeight"
    >
        @if (icon) {
            <div [className]="icon + ' otic-button-icon'"></div>
        }
        {{label}}
    </div>
</div>

