import { Injectable } from '@angular/core';
import { ServiceBase } from './serviceBase';
import { HttpProvider } from '@shared/providers/http.provider';
import { map } from 'rxjs';
import { SendEmailParams } from '@shared/interfaces/sendEmailParams';

@Injectable({
  providedIn: 'root',
})
export class UtilService extends ServiceBase {

    constructor(httpProvider: HttpProvider) { 
        super(httpProvider);
    }

    sendMail(params: SendEmailParams){
        return this.post<any>('sendMail', params).pipe(
            map(resp => resp['data'])
        );
    }
}
