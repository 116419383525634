import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmComponent } from '@shared/components/confirm/confirm.component';
import { Fec } from '@shared/interfaces/fec';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FecService } from '@shared/services/fec.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { generatePdf, buildSummaryContent } from '@shared/util/pdfUtil';
import { EpochDatePipe } from '@shared/pipes/epoch-date.pipe';
import { FecSummaryComponent } from '@pages/fec/fec-summary/fec-summary.component';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FecStatusEnum } from '@shared/enums/fecStatusEnum';
import { MessageProvider } from '@shared/providers/message.provider';
import { ApprovalRole } from '@shared/interfaces/approvalRole';
import { StorageService } from '@shared/services/storage.service';
import { StatusPipe } from '@shared/pipes/status.pipe';

@Component({
  selector: 'app-approval-fec',
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    ConfirmComponent,
    FecSummaryComponent,
    InputTextareaModule,
    PageContainerComponent,
    ButtonComponent,
    StatusPipe
  ],
  providers: [EpochDatePipe],
  templateUrl: './approval-fec.component.html',
  styleUrl: './approval-fec.component.scss'
})

export class ApprovalFecComponent implements OnInit {
  fec?: Fec;
  id?: string;
  observation?: string;
  paths: string [] = ['HOME', 'Aprobación FEC', 'Gestión Aprobaciones'];
  headerInfo: { label: string; value: unknown; }[] = [];
  pendingApproval?: ApprovalRole;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingProvider: LoadingProvider,
    private storageService: StorageService,
    private fecService: FecService,
    private epochDatePipe: EpochDatePipe,
    private messageProvider: MessageProvider,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if (!this.id) {
        this.router.navigate(['/approval-management']);
        return;
      }
      this.loadingProvider.show();
      this.fecService.getFec(this.id).subscribe((resp) => {
        let username = this.storageService.getUsername();
        this.fec = resp;
        this.loadingProvider.hide();
        this.setValueHeaderInfo(resp);
        this.pendingApproval = this.fec?.approval?.find(step => step.status === FecStatusEnum.PENDING && step.users.includes(username));
        if (!this.pendingApproval){
          this.observation = this.fec.lastObservationApprovalFlow ?? 'Sin Observaciones';
        }
      });
    });
  }

  getPdf() {
    const content = buildSummaryContent(this.fec!)
    generatePdf('open', content)
  }

  setValueHeaderInfo(item: Fec) {
    const companyName = item?.clientName;
    const rut = item?.rut;
    const fecDate = item?.createdAt;
    const fecNumber = item?.correlative;
    const executive = item?.client?.followUpContact?.name;

    this.headerInfo = [
      {
        label: 'Nombre Empresa',
        value: companyName ?? '',
      },
      ...(!item.isComercial ? [{
        label: 'RUT',
        value: rut ?? '',
      }] : []),
      {
        label: 'Fecha realización',
        value: this.epochDatePipe.transform(fecDate ?? 0),
      },
      {
        label: 'N° FEC',
        value: fecNumber ?? '',
      },
      {
        label: 'Ejecutivo',
        value: executive ?? '',
      }
    ];
  }

  approveFec() {
    this.decision(FecStatusEnum.APPROVED);
  }

  rejectFec() {
    this.decision(FecStatusEnum.REJECTED);
  }

  decision(decision: FecStatusEnum.APPROVED | FecStatusEnum.REJECTED) {
    this.loadingProvider.show();
    this.fecService.approvalFec(this.fec?.id!, {decision, observation: this.observation}).subscribe({
      next: (resp) => {
        this.loadingProvider.hide()
        this.messageProvider.showSuccess("Éxito", "FEC actualizada correctamente");
        this.router.navigate(['/approval-management']);
      },
      error: (err) => {
        this.loadingProvider.hide()
        const error = err.error.message
        this.messageProvider.showError("Error", error);
      }
    })
  }



}
