<p-table 
    [rows]="10"
    [showCurrentPageReport]="true"
    [value]="records" 
    [tableStyle]="{ 'min-width': '50rem'}" 
    styleClass="p-datatable-striped"
>
    <ng-template pTemplate="header">
        <tr>
            <th scope="col">Nombre</th>
            <th scope="col" class="text-center comparative-th">Aportes</th>
            <th scope="col" class="text-center comparative-th">Tasa de Administración</th>
            <th scope="col" class="text-center comparative-th">Ingresos Adicionales</th>
            <th scope="col" class="text-center comparative-th">Provisiones</th>
            <th scope="col" class="text-center comparative-th">Gastos</th>
            <th scope="col" class="text-center comparative-th">Tasa Real</th>
            <th scope="col" class="text-center comparative-th">Ingreso Real</th>
            <th scope="col" class="text-center comparative-th">Diferencia cierre Anterior</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-index="rowIndex">
        <tr>
            <td class="comparative-td">{{ item.description }}</td>
            <td class="text-center comparative-td">{{ item.contributionCommitment | chileanpeso }}</td>
            <td class="text-center comparative-td">{{ item.finalAdministrationFee | custompercent }}</td>
            <td class="text-center comparative-td">{{ item.additionalInvoice | chileanpeso }}</td>
            <td class="text-center comparative-td">{{ item.provisionPd | chileanpeso }}</td>
            <td class="text-center comparative-td">{{ item.totalCost | chileanpeso }}</td>
            <td class="text-center comparative-td">{{ item.effectiveRate  | custompercent }}</td>
            <td class="text-center comparative-td">{{ item.realIncome | chileanpeso }}</td>
            <td class="text-center comparative-td">{{ item.previousCloseDifference | chileanpeso }}</td>
        </tr>
    </ng-template>
</p-table>
