import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LabelValueComponent } from '@shared/components/label-value/label-value.component';
import { FecYear } from '@shared/interfaces/fecYear';

@Component({
  selector: 'app-fec-summary-one-year-cost',
  standalone: true,
  imports: [
    CommonModule,
    LabelValueComponent
  ],
  templateUrl: './fec-summary-one-year-cost.component.html',
  styleUrl: './fec-summary-one-year-cost.component.scss'
})
export class FecSummaryOneYearCostComponent {
  @Input() fecYear?: FecYear;
}
