import { Injectable, signal } from "@angular/core";
import { FecYear } from "@shared/interfaces/fecYear";
import { Scenario } from "@shared/interfaces/scenario";
import { ScenarioProvider } from "./scenario.provider";
import { replaceElementToList, replaceOrInsertElementToList } from "@shared/util/listUtil";
import { FecYearManager } from "@shared/util/FecYearManager";

@Injectable({
    providedIn: 'root'
})
export class FecYearProvider {
  private list = signal<FecYear[]>([]);
  private current = signal<FecYear>({});
  private scenarioSelected = signal<Scenario>({});

  constructor(
    private scenarioProvider: ScenarioProvider
  ){}

  updateCurrent(item: FecYear){
    this.current.set({...item});
  }

  updateList(list: FecYear[]){
    list = list.sort((item1: FecYear, item2: FecYear) => (item1.year ?? 0) - (item2.year ?? 0));
    this.list.set(list);
  }

  getList(){
    return this.list();
  }

  getCurrent(){
    return this.current();
  }

  updateCurrentAndList(item: FecYear){
    this.updateCurrent(item);
    this.updateListWithCurrent();
  }

  private updateListWithCurrent(){
    let current = this.getCurrent();
    let newList = replaceOrInsertElementToList<FecYear>(
      this.list(),
      current,
      (item) => item.id ===  current.id
    );
    this.updateList(newList);
  }

  updateScenarioSelected(scenario: Scenario){
    this.scenarioSelected.set(scenario);
  }

  getScenarioSelected(){
    return this.scenarioSelected();
  }

  updateWithScenarioSelected(isComercial: boolean, fecYear: FecYear){
    let scenarioSelected = this.getScenarioSelected();
    this.list.set(replaceElementToList(
      this.list(),
      FecYearManager.addScenarioToFecYear(this.scenarioProvider.getList(), fecYear, scenarioSelected),
      item => fecYear.id === item.id 
    ));
  }

  addFecYearToList(item: FecYear){
    this.list.update((arr: FecYear[]) => {
      return [...arr, item];
    });
  }

  deleteScenarioInFecYear(isComercial: boolean, fecYear: FecYear, scenario: Scenario){
    this.list.set(replaceElementToList(
      this.list(),
      FecYearManager.deleteScenarioInFecYearById(this.scenarioProvider.getList(), fecYear, scenario.id!),
      item => fecYear.id === item.id 
    ));
  }

  delete(fecYear: FecYear){
    let items = this.getList().filter((item: FecYear) => fecYear.year === item.year);
    if (items.length > 1){
      let result = this.getList().filter((item: FecYear) => fecYear.id !== item.id);
      this.updateList(result);
    }
  }

  updateFecYearsByScenariosChange(isComercial: boolean){
    let fecYears = FecYearManager.updateListFecYearByScenariosChange(this.scenarioProvider.getList(), this.getList());
    this.updateList(fecYears);
  }

}
