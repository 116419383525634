import { Component, Input } from '@angular/core';
import { DragDropModule } from 'primeng/dragdrop';
import { FecYearComponent } from '../fec-year/fec-year.component';
import { CommonModule } from '@angular/common';
import { FecYearProvider } from '@pages/fec/fecYear.provider';
import { NewFecYearComponent } from '../new-fec-year/new-fec-year.component';
import { FecProvider } from '@pages/fec/fec.provider';

@Component({
  selector: 'app-fec-year-container',
  standalone: true,
  imports: [
    CommonModule,
    DragDropModule,
    FecYearComponent,
    NewFecYearComponent
  ],
  templateUrl: './fec-year-container.component.html',
  styleUrl: './fec-year-container.component.scss'
})
export class FecYearContainerComponent {

  constructor(
    public fecProvider: FecProvider,
    public fecYearProvider: FecYearProvider
  ){}

}
