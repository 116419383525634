@if(scenario){
<div class="fec-summary-income">
    <div class="fec-summary-income-title">Entradas</div>
    <div class="fec-summary-income-container">

        <div class="fec-summary-income-row">
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Compromiso de aportes de año:
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.contributionCommitment | chileanpeso}}
                </div>
            </div>
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Ingresos cuenta no franquiciable:
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.nonFranchisableAccountIncome | chileanpeso}}
                </div>
            </div>
        </div>

        <div class="fec-summary-income-row">
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Tasa Administración final:
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.finalAdministrationFee | custompercent}}
                </div>
            </div>
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Provisión PD:
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.provisionPd | chileanpeso}}
                </div>
            </div>
        </div>

        <div class="fec-summary-income-row">
            <div class="fec-summary-income-item">

            </div>
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Provisión Consultoria:
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.consultancyProvision | chileanpeso}}
                </div>
            </div>
        </div>

        <div class="fec-summary-income-row">
            <div class="fec-summary-income-item">

            </div>
            <div class="fec-summary-income-item">
                <div class="fec-summary-income-label">
                    Facturas adicionales: 
                </div>
                <div class="fec-summary-income-value">
                    {{scenario.additionalInvoice | chileanpeso}}
                </div>
            </div>
        </div>

        <div class="fec-summary-income-summary-row">
            <div class="fec-summary-income-summary-item">
                <div class="fec-summary-income-summary-label">
                    Ingreso contra tasa: 
                </div>
                <div class="fec-summary-income-summary-value">
                    {{scenario.counterRateIncome | chileanpeso}}
                </div>
            </div>
            <div class="fec-summary-income-summary-item">
                <div class="fec-summary-income-summary-label">
                    Ingresos Brutos: 
                </div>
                <div class="fec-summary-income-summary-value">
                    {{scenario.grossIncome| chileanpeso}}
                </div>
            </div>
        </div>
    </div>
</div>

}
