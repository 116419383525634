<form [formGroup]="form" autocomplete="off">
    <div class="formgrid grid col-8 m-auto justify-content-between p-0">
        <h3 class="col-12 p-0">Datos Empresa</h3>
        <div class="flex flex-column otic-form-input field">
            <app-field-container
                [form]="form"
                fieldName="comercialName"
                label="Nombre Cliente Comercial*"
            >
                <input 
                    type="text" 
                    pInputText 
                    [maxlength]="50"
                    placeholder="Nombre Cliente Comercial" 
                    formControlName="comercialName"
                />
            </app-field-container>
            
        </div>
        <div class="flex flex-column field col-12 p-0">
            <label for="category">Rubro</label>
            <p-autoComplete 
                formControlName="category" 
                placeholder="Rubro"
                optionLabel="description"
                [suggestions]="filteredBusinessAreas"
                (completeMethod)="searchCategories($event)"
                [multiple]="true" 
                emptyMessage="No se encontraron resultados"
            />
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="region">Región</label>
            <p-dropdown 
                class="w-full"
                formControlName="region" 
                [options]="region"
                optionLabel="description"
                optionValue="description" 
                placeholder="Seleccione Región"
                [dropdownIcon]="'fa fa-caret-down'"
                
            />
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="observations">Observaciones</label>
            <div class="input-observations-container">
                <input
                    class="w-full"
                    type="text" 
                    [maxlength]="255"
                    pInputText 
                    placeholder="Ingrese Observaciones" 
                    formControlName="observations"
                />
                <small id="observations-characters" class="flex justify-content-end" style="color: #ABA7AF">
                    {{ form.get('observations')?.value?.length ?? 0 }} / 255
                </small>
            </div>
        </div>
        
        <h3 class="col-12 p-0">Condición Empresa</h3>
        <div class="flex flex-column gap-2 field otic-form-input">
            <app-text-readonly
                label="Status cliente durante el año"
                [value]="client.isPotential ? 'Potencial' : 'Cliente Antiguo'"
                width="100%"
            ></app-text-readonly>
        </div>
        <div class="flex flex-column gap-2 field otic-form-input">
            <label for="proveniencia">Proveniencia</label>
            <p-dropdown 
                class="w-full"
                formControlName="proveniencia" 
                [options]="proveniencia" 
                optionLabel="description" 
                optionValue="description" 
                placeholder="Seleccione Proveniencia"
                [dropdownIcon]="'fa fa-caret-down'"
                
            />
        </div>
    </div>

    <app-grid-client-contacts 
        #clientContacts
        [companyContacts] = "client.companyContacts ?? []"
        [mode]="mode"
    />

    <h3 class="col-12 p-0">Datos Financieros</h3>
    <div class="formgrid grid col-12 m-auto">
        <div class="flex flex-column gap-2 field otic-form-input" style="margin-right: 97px;">
            <label for="currentOticServices">1% anual conocido</label>
            <p-inputNumber 
                styleClass="w-full" 
                formControlName="percentageKnownAnnual" 
                mode="currency" 
                currency="CLP"
                locale="es-CL" 
                [maxlength]="12"
                [maxFractionDigits]="0"
                placeholder="%"
                >
            </p-inputNumber>
        </div>
        <div class="flex flex-column gap-2 field otic-form-input" style="margin-right: 111px;">
            <app-field-container
                    [form]="form"
                    fieldName="finalAdministrationFee"
                    label="Tasa Administración Final"
                >
                    <input 
                        type="text" 
                        pInputText 
                        [maxlength]="6" 
                        formControlName="finalAdministrationFee"
                        suffix="%"
                        [decimalMarker]="','" 
                        mask="percent.2"
                    />
            </app-field-container>
        </div>
    </div>

    <app-grid-company-list 
        #companyList
        [comercialChildren] = "client.comercialChildren ?? []"
        [mode]="mode"
    />
</form>
