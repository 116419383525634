import { ClientStatusLabelEnum } from "./clientStatusEnum";

enum CommonStatusLabelEnum  {
  draft = "Borrador",
  rejected = "Rechazada",
};

enum FecStatusLabelEnum {
  pending = "Pendiente",
  approved = "Aprobada",
}

enum UserStatusLabelEnum {
  active = "Activo",
  disabled = "Pendiente"
}

export const StatusLabelEnum = {
  ...CommonStatusLabelEnum,
  ...ClientStatusLabelEnum,
  ...FecStatusLabelEnum,
  ...UserStatusLabelEnum
}
