import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ApprovalFlowChipComponent } from '../approval-flow-chip/approval-flow-chip.component';
import { ApprovalRole } from '@shared/interfaces/approvalRole';

@Component({
  selector: 'approval-flow-chip-container',
  standalone: true,
  imports: [
    CommonModule,
    ApprovalFlowChipComponent
  ],
  templateUrl: './approval-flow-chip-container.component.html',
  styleUrl: './approval-flow-chip-container.component.scss'
})
export class ApprovalFlowChipContainerComponent {

  @Input()
  public approvalFlow: ApprovalRole[] = [];

}
