import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-scenario-description',
  standalone: true,
  imports: [],
  templateUrl: './scenario-description.component.html',
  styleUrl: './scenario-description.component.scss'
})
export class ScenarioDescriptionComponent {
  @Input() 
  public label?: string;
}
