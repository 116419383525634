<div style="min-height: 576px; margin-top: -40px;">
    <app-scenario-description
        [label]="scenarioProvider.getCurrent().description"
    ></app-scenario-description>
    @if(fecProvider.isComercial()){
        <p-tabView [scrollable]="true">
            <p-tabPanel [header]="fecProvider.getComercialName()">
                <app-form-scenario-income 
                    #formScenarioIncome 
                    [readonly]="true" 
                    [scenario]="scenarioProvider.getCurrent()" 
                />
            </p-tabPanel>
            @for (item of fecProvider.getComercialChildren(); track $index;) {
                <p-tabPanel [header]="item.clientName ?? ''">
                    <app-form-scenario-income 
                        #childrenFormScenarioIncome 
                        [comercialChild]="item"
                        [scenario]="scenarioProvider.getCurrentChildScenario(item.rut!)"
                        (change)="childChange()"
                    />
                </p-tabPanel>
            }
        </p-tabView>
    }
    @else {
        <app-form-scenario-income 
            #formScenarioIncome 
            [scenario]="scenarioProvider.getCurrent()" 
        />
    }
    <div class="flex align-items-center justify-content-end gap-2">
        <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
        <app-button label="Guardar" (clickButton)="onNext()"></app-button>
    </div>
</div>
