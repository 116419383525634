import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-steps',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.scss'
})
export class StepsComponent implements OnInit {
  @Input() type: number = 1;
  @Input() steps: Step[] = [];
  @Input() activeIndex: number = 0;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.findIndexActive();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.findIndexActive();
      }
    });
    
  }

  navigate(step: Step, index: number){
    if (!this.allowedNavigation(step, index))
      return;
    this.activeIndex = index;
    this.router.navigate([`${step.routerLink}`], {  ...step.extras, relativeTo: this.route});
  }

  isActive(item: Step) {
      let routerLink = Array.isArray(item.routerLink) ? item.routerLink : [item.routerLink];
      return this.router.isActive(
        this.router.createUrlTree(routerLink, { relativeTo: this.route }).toString(), 
        {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'});
  }

  findIndexActive(){
    for (let index = 0; index < this.steps.length; index++) {
      if (this.isActive(this.steps[index])){
        this.activeIndex = index;
        break;
      }
    }
  }

  allowedNavigation(step: Step, index: number){
    if (!step.allowedNavigation)
      return;
    return (this.activeIndex !== index) && (step && step.allowedNavigation());
  }

}

interface Step {
  label: string;
  routerLink: string;
  extras?: any;
  allowedNavigation?: () => boolean;
}
