import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { ButtonComponent } from '../button/button.component';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonComponent
  ],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.scss'
})

export class ConfirmComponent {
  @Input()
  visible: boolean = false;
  @Input()
  type: 'warning' | 'success' | 'info'| 'error' | 'default' = "default";
  @Input()
  title: string = 'Confirmación';
  @Input()
  message?: string;
  @Input()
  htmlContent?: SafeHtml;
  @Output() 
  public acceptClick = new EventEmitter();
  @Output() 
  public rejectClick = new EventEmitter();
  @Output() 
  public closeClick = new EventEmitter();
  @Input()
  rejectLabel?: string = "Cancelar";
  @Input()
  acceptLabel?: string = "Confirmar";

  private iconNameMap = {
    'warning': 'alert-warning',
    'success': 'alert-success',
    'info': 'info',
    'error' : 'alert-error', // non defined
    'default': 'alert-success'
 };

  showConfirm() {
    this.visible = true;
  }

  reject() {
    this.visible = false;
    this.rejectClick.emit();
  }

  accept() {
    this.visible = false;
    this.acceptClick.emit();
  }

  close() {
    this.visible = false;
    this.closeClick.emit();
  }

  getIcon(): string {
    return this.iconNameMap[this.type] || this.iconNameMap['default'];
  }

  getIconSrc(): string {
    return `../../../../assets/icons/${this.getIcon()}.svg`;
  }

}

