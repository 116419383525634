import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FecSummaryIncomeComponent } from '../fec-summary-income/fec-summary-income.component';
import { FecSummaryCostComponent } from '../fec-summary-cost/fec-summary-cost.component';
import { Scenario } from '@shared/interfaces/scenario';
import { ChileanPesoPipe } from '@shared/pipes/chilean-peso.pipe';
import { CustomPercentPipe } from '@shared/pipes/custom-percent.pipe';

@Component({
  selector: 'app-fec-summary-scenario',
  standalone: true,
  imports: [
    CommonModule,
    FecSummaryIncomeComponent,
    FecSummaryCostComponent,
    ChileanPesoPipe,
    CustomPercentPipe
  ],
  templateUrl: './fec-summary-scenario.component.html',
  styleUrl: './fec-summary-scenario.component.scss'
})
export class FecSummaryScenarioComponent {
  @Input() scenario?: Scenario;
}
