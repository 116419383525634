import { Injectable, signal } from "@angular/core";
import { Scenario } from "@shared/interfaces/scenario";
import { CostProvider } from "./cost.provider";
import { ChildScenario } from "@shared/interfaces/childScenario";
import { replaceOrInsertElementToList } from "@shared/util/listUtil";
import { ScenarioManager } from "@shared/util/ScenarioManager";
import { ChildScenarioManager } from "@shared/util/ChildScenarioManager";

@Injectable({
    providedIn: 'root'
})
export class ScenarioProvider {
  private list = signal<Scenario[]>([]);
  private current = signal<Scenario>({});

  constructor(
    private costProvider: CostProvider
  ){}

  prepareForUpdate(item: Scenario){
    this.current.set({...item});
    this.costProvider.updateList(item.costs ?? []);
  }

  prepareForCreate(){
    this.current.set(ScenarioManager.createScenarioWithId());
    this.costProvider.updateList([]);
  }

  resetCurrent(){
    this.current.set({});
    this.costProvider.updateList([]);
  }

  updateList(list: Scenario[]){
    this.list.set(list);
  }

  getList(){
    return this.list();
  }

  
  getCurrent(){
    return this.current();
  }

  getCurrentChildScenario(rut: string): ChildScenario {
    let children = this.current().childrenScenario;
    let result = children ? children.find(item => item.rut === rut) : undefined;
    if (result){
      return result;
    }
    return ChildScenarioManager.createChildScenario(rut);
  }

  getById(id: string){
    return this.list().find(scenario => scenario.id === id);
  }

  addScenarioToList(item: Scenario){
    this.list.update((arr: Scenario[]) => {
      return [...arr, item];
    });
  }

  deleteScenario(item: Scenario){
    let result = this.getList().filter(scenario => scenario.id !== item.id);
    this.updateList(result);
  }

  updateListWithCurrent(){
    let current = this.getCurrent();
    let newList = replaceOrInsertElementToList<Scenario>(
      this.list(),
      current,
      (item) => item.id ===  current.id
    );
    this.list.set(newList);
  }

  updateCurrentWithDescriptionTab(description: string){
    this.current.update((item: Scenario) => {
      return {
        ...item,
        description
      };
    });
  }

  updateCurrentWithIncomeTab(scenario: Scenario){
    this.current.update((item: Scenario) => {
      return new ScenarioManager(
        {
          ...item,
          ...scenario
        }
      ).getProcessed();
    });
  }

  updateCurrentWithCosts(){
    this.current.update((item: Scenario) => {
      return new ScenarioManager(
        {
          ...item,
          costs: this.costProvider.getList()
        }
      ).getProcessed();
    });
  }

  updateCurrentCostsWithSelection(selectedCosts: string[]){
    this.costProvider.updateListWithSelection(selectedCosts);
    this.updateCurrentWithCosts();
  }

}

