<p-dialog 
    [(visible)]="visible" 
    [modal]="true"
    [closable]="false"
    [style]="{ width: '500px' }"
>
    <p-header class="otic-modal-header">
        <div class="flex flex-row gap-2 align-items-center">
            <img
                [src]="getIconSrc()"
                alt="icono-confirmacion"
                width="40"
                height="40"
            >
            <h3 
                class="m-0"
                [ngClass]="{'otic-modal-header-title-warning': type === 'warning', 
                'otic-modal-header-title-error': type === 'error'}"
            >
            {{title}}
            </h3>
        </div>
        <img src="../../../../assets/icons/x.svg" 
            alt="close-modal" 
            width="18" 
            height="18" 
            (click)="reject()" 
            style="cursor: pointer;">
    </p-header>
    @if (htmlContent) {
        <div [innerHTML]="htmlContent"></div>
    }
    
    @if (message) {
        <p class="m-0">
            {{message}}
        </p>
    }
    <ng-template pTemplate="footer">
        <div class="col">
            <div class="flex align-items-center justify-content-end gap-2">
                @if(type==='error'){
                    <app-button label="Cerrar" 
                        (clickButton)="close()"
                        [width]="146"
                        [height]="46" 
                        [fontWeight]="700"
                    ></app-button>
                }
                @else if (type==='success') {
                    <app-button label="Aceptar" 
                        (clickButton)="close()"
                        [width]="146"
                        [height]="46" 
                        [fontWeight]="700"
                    ></app-button>
                }
                @else {
                    <app-button [label]="rejectLabel || 'Cancelar'" 
                        (clickButton)="reject()"
                        type="secondary"
                        [width]="146"
                        [height]="46" 
                        [fontWeight]="700"
                    ></app-button>
                    <app-button [label]="acceptLabel || 'Confirmar'" 
                        (clickButton)="accept()"
                        [width]="146"
                        [height]="46" 
                        [fontWeight]="700"
                    ></app-button>
                }
            </div>
        </div>
    </ng-template>
</p-dialog>
