import { Component, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Router, RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Client } from '@shared/interfaces/client';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';
import { FilterContainerComponent } from '@shared/components/filter-container/filter-container.component';
import { ClientFilter } from '@shared/interfaces/clientFilter';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { GridPotentialClientComponent } from '../grid-potential-client/grid-potential-client.component';
import { MessageProvider } from '@shared/providers/message.provider';
import { ClientService } from '../../../shared/services/client.service';
import { ClientStatusLabelEnum } from '@shared/enums/clientStatusEnum';
import { ModeEnum } from '@shared/enums/modeEnum';

@Component({
  selector: 'app-potential-list',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    RouterOutlet,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    PageContainerComponent,
    FilterContainerComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
    GridPotentialClientComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './potential-list.component.html',
  styleUrl: './potential-list.component.scss'
})
export class PotentialListComponent  {
  ModeEnum = ModeEnum;
  @ViewChild('grid') grid?: GridPotentialClientComponent; 
  paths: string[] = ["HOME", "Potenciales"];
  public form: FormGroup = this.fb.group({
    rut: [''],
    clientName: [''],
    clientStatus: ['']
  });

  statusList = Object.entries(ClientStatusLabelEnum)
  .map(([key, value]) => ({ name: value, value: key }));

  constructor(
    private router: Router, 
    private fb: FormBuilder,
    private messageProvider: MessageProvider,
    private clientService: ClientService
    ) {
  }

  openNew(){
    this.router.navigate(['/potential-client'], { queryParams: {from: 'potential-list'}});
  }

  openEdit(item: Client) {
    this.router.navigate(['/potential-client'], { queryParams: {id: item.id, from: 'potential-list'}});
  }

  openView(item: Client) {
    this.router.navigate(['/view-potential-client'], { queryParams: {id: item.id, from: 'potential-list'}});
  }

  onDelete(item: Client) {
    this.messageProvider.showWarningConfirm(
      "Eliminar Potencial", 
      "¿Está seguro de eliminar este Potencial cliente?",
      ()=> {
        this.clientService.deleteClient({
          id: item.id
        }).subscribe((data) => {
          this.grid?.onSearch({})
        })
      }
    );
  }

  getFilter(){
    let filter = {
      ...this.form.value
    };
    return filter as ClientFilter;
  }
  
}
