<app-fake-steps
    [steps]="steps" 
    [activeIndex]="activeIndex"
>
</app-fake-steps>

@if (activeContainer === 0) {
    <app-scenario-name
        (clickCancel)="onCancel()"
        (next)="saveName()"
    >
    </app-scenario-name>
}

@if (activeContainer === 1) {
    <app-scenario-income
        (clickCancel)="onCancel()"
        (next)="saveIncome()"
    >
    </app-scenario-income>
}

@if (activeContainer === 2) {
    <app-scenario-select-costs
        (clickCancel)="onCancel()"
        (next)="saveCost()"
    >
    </app-scenario-select-costs>
}

@if (activeContainer === 3) {
    <app-scenario-summary-costs
        (previous)="toCost()"
        (clickCancel)="onCancel()"
        (next)="saveSummary()"
        (viewDetail)="onViewDetailCost()"
    >
    </app-scenario-summary-costs>
}

@if (activeContainer === 4) {
    <app-scenario-detail-cost
        (clickCancel)="toSummary()"
        (next)="saveCostItem()"
    >
    </app-scenario-detail-cost>
}
