import { CommonModule, Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PageContainerComponent } from '@shared/components/page-container/page-container.component';;
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { ClientService } from '@shared/services/client.service';
import { Client } from '@shared/interfaces/client';
import { stringToFullRutFormat } from '@shared/util/rutUtil';
import { MessageProvider } from '@shared/providers/message.provider';
import { ClientFormComponent } from '../client-form/client-form.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { isBlank, isNotBlank } from '@shared/util/stringUtils';

@Component({
  selector: 'app-potential-client',
  standalone: true,
  imports: [
    CommonModule,
    PageContainerComponent,
    ClientFormComponent
  ],
  templateUrl: './potential-client.component.html',
  styleUrl: './potential-client.component.scss'
})
export class PotentialClientComponent implements OnInit {
  @ViewChild('clientFormComponent') clientFormComponent!: ClientFormComponent;
  paths: string[] = ["HOME", "Potenciales", "Registro de Potenciales"];
  client: Client = {};
  id?: string;
  mode: ModeEnum = ModeEnum.EDIT;
  from?: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loadingProvider: LoadingProvider,
    private clientService: ClientService,
    private messageProvider: MessageProvider
  ){}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      if (!isBlank(data['mode'])){
        this.mode = data['mode'];
      }
    });
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      this.from = params['from'];
      this.client = { isPotential: true, isComercial: false };
      if (this.id){
        this.loadingProvider.show();
        this.clientService.getClient(this.id).subscribe({
          next: (resp) => {
            this.client = resp;
            this.loadingProvider.hide();
          },
          error: (err) => {
            this.loadingProvider.hide();
            let msg = err.error?.message || 'Ocurrió un error';
            console.error(msg);
            this.messageProvider.showError("Error", msg, ()=> {
              this.router.navigate(['/potential-list']);
            });
          }
        });
      }
    });
  }

  previous(){
    if (isNotBlank(this.from))
      this.router.navigate([`/${this.from}`]);
  }

  toOticContacts(client: Client){
    if (this.mode === ModeEnum.VIEW){
      this.clientFormComponent.showOticContacts();
      return;
    }
    if (this.id){
      this.updateClient(client, () => {
        this.clientFormComponent.showOticContacts();
      });
    }
    else{
      this.loadingProvider.show();
      this.clientService.createClient({
        isPotential: true,
        isComercial: false,
        ...client
      }).subscribe({
        next: (resp) => {
          this.id = resp.id;
          this.client = resp;
          this.loadingProvider.hide();
          this.router.navigate(
            ['/potential-client'], 
            { queryParams: {id: resp.id, index: 1}}
          );
        },
        error: (err) => {
          this.loadingProvider.hide();
          let msg = err.error?.message || 'Ocurrió un error';
          console.error(msg);
          if(msg === 'El rut corresponde a cliente existente') {
            const rut = stringToFullRutFormat(client.rut!)
            const unsafeHtml = `Estimado usuario: <br> El cliente RUT <b>${rut}</b> es un cliente vigente, ingrese uno diferente.`;
            this.messageProvider.showErrorHtml("Cliente vigente", unsafeHtml);
          } else {
            this.messageProvider.showError("Error", msg);
          }
        }
      });
    }
  }

  toClientData(client: Client){
    this.clientFormComponent.showClientData();
  }

  final(client: Client){
    if (this.mode === ModeEnum.VIEW){
      this.router.navigate(['/potential-list']);
      return;
    }
    this.loadingProvider.show();
    this.clientService.updateToRegistered({
      ...this.client,
      ...client
    }).subscribe({
      next: (resp) => {
        this.client = resp;
        this.loadingProvider.hide();
        this.messageProvider.showSuccess("Confirmación registro Potencial", "El registro ha sido completado correctamente", ()=> {
          this.router.navigate(['/potential-list']);
        });
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });


  }

  private updateClient(client: Client, redirectFn: () => void){
    this.loadingProvider.show();
    this.clientService.updateClient({
      ...this.client,
      ...client
    }).subscribe({
      next: (resp) => {
        this.client = resp;
        this.loadingProvider.hide();
        redirectFn();
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  navigate(index: number){

  }

}
