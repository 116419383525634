import { v4 as uuid } from 'uuid';
import { Scenario } from "@shared/interfaces/scenario";
import { valueToInteger } from "./numberUtil";
import { IncomeScenarioManager } from "./IncomeScenarioManager";
import { arrayIsEmpty } from "./listUtil";
import { ChildScenarioManager } from "./ChildScenarioManager";
import { ScenarioBase } from "@shared/interfaces/scenarioBase";
import { CostManager } from "./CostManager";
import { ScenarioBaseManager } from './ScenarioBaseManager';

export class ScenarioManager {
    private processed: Scenario;
    constructor(private scenario: Scenario){
        if (arrayIsEmpty(scenario.childrenScenario))
            this.processed = this.process(scenario);
        else 
            this.processed = this.processComercial(scenario);
    }

    private process(scenario: ScenarioBase){
        let processed = {
            ...scenario,
            ...IncomeScenarioManager.processIncomeScenario(scenario)
        };
        processed.costs = processed.costs?.map(cost => (new CostManager(processed, cost)).getProcessed());
        processed.totalCost = this.calculateTotalCosts(processed);
        processed.realIncome = this.calculateRealIncome(processed);
        processed.effectiveRate = this.calculateEffectiveRate(processed);
        return processed;
    }

    private processComercial(scenario: Scenario){
        let processed: Scenario = {
            ...scenario,
            ...IncomeScenarioManager.processSummaryFromListIncome(scenario.childrenScenario ?? [])
        };
        processed = this.process(processed);
        processed.childrenScenario = processed.childrenScenario?.map(child => this.process((new ChildScenarioManager(processed, child)).getProcessed()));
        return processed;
    }
    
    private calculateTotalCosts(scenario: Scenario): number {
        return scenario.costs?.reduce((sum, cost) => sum + valueToInteger(cost.total), 0) ?? 0;
    }

    private calculateRealIncome(scenario: Scenario): number {
        return valueToInteger(scenario.grossIncome) -  valueToInteger(scenario.totalCost);
    }

    private calculateEffectiveRate(scenario: Scenario): number {
        let realIncome = valueToInteger(scenario.realIncome);
        let contributionCommitment = valueToInteger(scenario.contributionCommitment);
        if (realIncome === 0 || contributionCommitment === 0)
          return 0;
        return (realIncome/contributionCommitment)*100;
    }

    static createScenarioWithId(): Scenario {
        return {
          id: uuid(),
          ...ScenarioBaseManager.createScenarioBase()
        };
    }

    getProcessed(){
        return this.processed;
    }
}

