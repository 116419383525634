import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { ScenarioDescriptionComponent } from '../scenario-description/scenario-description.component';
import { FecProvider } from '@pages/fec/fec.provider';
import { FormScenarioIncomeComponent } from '../form-scenario-income/form-scenario-income.component';
import { TabViewModule } from 'primeng/tabview';
import { IncomeScenarioManager } from '@shared/util/IncomeScenarioManager';

@Component({
  selector: 'app-scenario-income',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TabViewModule,
    ScenarioDescriptionComponent,
    FormScenarioIncomeComponent
  ],
  templateUrl: './scenario-income.component.html',
  styleUrl: './scenario-income.component.scss'
})
export class ScenarioIncomeComponent {
  @ViewChild('formScenarioIncome') formScenarioIncome!: FormScenarioIncomeComponent;
  @ViewChildren('childrenFormScenarioIncome') childrenformChildScenarioIncome!: QueryList<FormScenarioIncomeComponent>;
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public next = new EventEmitter();

  constructor(
    public fecProvider: FecProvider,
    public scenarioProvider: ScenarioProvider
  ){  }

  onCancel(){
    this.clickCancel.emit();
  }

  onNext(){
    if (!this.validate())
      return;
    this.scenarioProvider.updateCurrentWithIncomeTab({
      ...this.formScenarioIncome.getData(),
      childrenScenario: this.getChildrenScenario()
    });
    this.next.emit();
  }

  validate(){
    if (this.fecProvider.isComercial()){
      let resultItem = true;
      for (let item of this.getComponents()){
        if (!item.validate()){
          resultItem = false;
        }
      }
      return resultItem;
    }
    else {
      return this.formScenarioIncome.validate();
    }
  }

  private getComponents(){
    return this.childrenformChildScenarioIncome.toArray();
  }

  private getChildrenScenario(){
    return this.getComponents().map(component => component.getData());
  }

  childChange(){
    let summary =IncomeScenarioManager.processCompleteSummaryFromListIncome(this.getChildrenScenario())
    this.formScenarioIncome.setData(summary);
  }

}
