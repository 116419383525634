<p-dialog 
    [(visible)]="visible" 
    [modal]="true"
    [closable]="false"
    [style]="{ width: width? width + 'px' : '', height: height ? height + 'px' : '' }"
    (onHide)="onHide()"
    styleClass="p-0 m-0"
    header="Header"
>
    <ng-template pTemplate="header">
            <div class="flex-1">
                <div class="modal-title">{{title}}</div>
            </div>
            <div class="justify-content-end">
                <img 
                class="justify-content-end"
                src="../../../../assets/icons/x.svg" 
                alt="close-modal" width="18" height="18" 
                (click)="closeModal()" style="cursor: pointer;">
            </div>
    </ng-template>
    <ng-content></ng-content>
</p-dialog>
