<div 
    [class]="'label-value-container ' +  config.containerClass"
    [style.border]="config.border ? config.border + 'px' : ''"
    [style.width]="config.width ? config.width + 'px' : ''"
    [style.height]="config.height ? config.height + 'px' : ''"
    [style.flexDirection]="config.direction === 'horizontal' ? 'column' : 'row'"
>
    @if(config.label){
        <div
            [className]="config.labelClass"
            [style.width]="config.labelWidth ? config.labelWidth + 'px' : ''"
            [style.height]="config.labelHeight ? config.labelHeight + 'px' : ''"
            [style.padding]="config.labelPadding ?? ''"
        >
            {{config.label}}  
        </div>
    }
    @if(config.value !== undefined){
        <div
            [className]="config.valueClass"
            [style.width]="config.valueWidth ? config.valueWidth + 'px' : ''"
            [style.height]="config.valueHeight ? config.valueHeight + 'px' : ''"
            [style.padding]="config.valuePadding ?? ''"
        > 
            @if(config.type === 'amount'){
                {{config.value | chileanpeso}}
            }
            @else if(config.type === 'percentage'){
                {{config.value | custompercent }}
            }
            @else {
                {{config.value}}
            }
        </div>
    }
</div>

