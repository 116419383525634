<app-page-container
    title="Evaluaciones Comerciales"
    [paths]="paths"
>
    <div class="flex flex-column">
        <h3 class="p-0">Buscar Empresa - Cliente Potencial</h3>

        <p-tabView [(activeIndex)]="activeIndexTab">
            <p-tabPanel header="Nombre">
                <app-search-box
                    label="Ingrese Nombre Empresa"
                    (search)="onSearchByName($event)"
                />
            </p-tabPanel>
            <p-tabPanel header="RUT">
                <app-search-box
                    label="Ingrese RUT de la Empresa"
                    mask="0.000.000-A || 00.000.000-A"
                    (search)="onSearchByRut($event)"
                />
            </p-tabPanel>
            <p-tabPanel header="Cliente Comercial">
                <app-search-box
                    label="Ingrese Nombre Cliente Comercial"
                    (search)="onSearchByComercialName($event)"
                />
            </p-tabPanel>
        </p-tabView>

        <app-grid-container
            title="Listado Empresas"
        >
            <p-table 
                [paginator]="true"
                [lazy]="true"
                currentPageReportTemplate="{first} - {last} de {{totalRecords}}"
                [rowsPerPageOptions]="[10, 25, 20]"
                [rows]="5"
                [showCurrentPageReport]="true"
                (onLazyLoad)="onLazySearch($event)"
                [totalRecords]="totalRecords"
                [loading]="loading"
                [value]="records" 
                [tableStyle]="{ 'min-width': '50rem' }" 
                styleClass="p-datatable-striped"
                [expandedRowKeys]="expandedRows"
                dataKey="id" 
            >
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" style="width: 5rem"></th>
                        <th scope="col">Nombre</th>
                        <th style="width:130px" scope="col">RUT</th>
                        <th scope="col">Cliente Comercial</th>
                        <th scope="col" class="text-center">Ejecutivo Mantención</th>
                        <th scope="col" class="text-center">Opciones</th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item let-index="rowIndex" let-expanded="expanded">
                    <tr>
                        <td>
                            @if(item.isComercial){
                                @if(expanded){
                                    <span 
                                        class="pi pi-minus"
                                        [pRowToggler]="item"
                                    ></span>
                                }
                                @else {
                                    <span 
                                        class="pi pi-plus"
                                        [pRowToggler]="item"
                                    ></span>
                                }
                            }
                        </td>
                        <td>{{ item.clientName }}</td>
                        <td>{{ item.rut }}</td>
                        <td>{{ item.comercialName }}</td>
                        <td class="text-center">{{ item.client?.maintainerContact.name | uppercase }}</td>
                        <td>
                            <div class="grid align-items-center">
                                <div class="col">
                                    <div class="flex align-items-center justify-content-center">
                                        <span
                                            (click)="openGridActions($event, item)" 
                                            (keydown)="openGridActions($event, item)"
                                            class="otic-actions"
                                        >
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-template>

                <ng-template pTemplate="rowexpansion" let-item>
                    <tr>
                        <td colspan="6">
                            <div class="p-3">
                                <p-table [value]="item.comercialChildren" dataKey="rut">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Nombre</th>
                                            <th>RUT</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-child>
                                        <tr>
                                            <td>{{ child.clientName }}</td>
                                            <td>{{ child.rut}}</td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </td>
                    </tr>
                </ng-template>

            </p-table>
        </app-grid-container>
    </div>
</app-page-container>    
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>
<app-modal
    [title]="fecSelected?.clientName ?? fecSelected?.comercialName ?? 'FEC'"
    #fecSummaryModal
    [width]="930"
>
    @if(fecSelected){
        <app-fec-summary
            [fec]="fecSelected"
        ></app-fec-summary>
    }
    @else {
        <h1>No tiene FEC's</h1>
    }
</app-modal>
