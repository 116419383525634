import { Routes } from '@angular/router';
import { LayoutComponent } from '@shared/components/layout/layout.component';
import { HomeComponent } from '@shared/components/home/home.component';
import { LoginComponent } from '@shared/components/login/login.component';
import { PotentialListComponent } from '@pages/client/potential-list/potential-list.component';
import { FecListComponent, FecListComponentModeEnum } from '@pages/fec/fec-list/fec-list.component';
import { FecDetailComponent } from '@pages/fec/fec-detail/fec-detail.component';
import { EconomicEvaluationComponent } from '@pages/fec/economic-evaluation/economic-evaluation.component';
import { ClientDetailComponent } from '@pages/fec/client-detail/client-detail.component';
import { authGuard } from '@shared/guards/auth.guard';
import { UserAdminComponent } from '@pages/users-admin/user-admin.component';
import { FecPreviewComponent } from '@pages/fec/fec-preview/fec-preview.component';
import { DevAdminComponent } from '@pages/dev-tools/dev-admin/dev-admin.component';
import { SearchPotentialClientComponent } from '@pages/fec/search-potential-client/search-potential-client.component';
import { SearchCurrentClientComponent } from '@pages/fec/search-current-client/search-current-client.component';
import { FecTrackingComponent } from '@pages/fec/fec-tracking/fec-tracking.component';
import { roleGuard } from '@shared/guards/role.guard';
import { MENU } from '@shared/util/roleUtil';
import { PotentialBaseComponent } from '@pages/client/potential-base/potential-base.component';
import { ApprovalManagementComponent } from '@pages/fec/approval-management/approval-management.component';
import { ApprovalFecComponent } from '@pages/fec/approval-management/approval-fec/approval-fec.component';
import { ReadOnlyFecComponent } from '@pages/fec/read-only-fec/read-only-fec.component';
import { PotentialClientComponent } from '@pages/client/potential-client/potential-client.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { RoleEnum } from '@shared/enums/roleEnum';
import { ParameterAdminComponent } from '@pages/dev-tools/parameter-admin/parameter-admin.component';
import { ParameterDetailComponent } from '@pages/dev-tools/parameter-admin/parameter-detail/parameter-detail.component';

export const routes: Routes = [
    {
        path: 'login', 
        component: LoginComponent 
    },
    {
        path: '', component: LayoutComponent,
        canActivate: [ authGuard ],
        children: [
            { 
                path: 'home', 
                component: HomeComponent
            },
            {
                path: 'potential-client', 
                component: PotentialClientComponent,
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.CREATE_POTENTIAL.roles },
            },
            { 
                path: 'potential-list', 
                component: PotentialListComponent,
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.POTENTIAL_PORTFOLIO.roles },
            },
            { 
                path: 'potential-base', 
                component: PotentialBaseComponent,
                canActivate: [ roleGuard ],
                data: { expectedRoles: [
                    {
                        role: RoleEnum.ROL_JOS,
                        mode: ModeEnum.ONLY_MY_RECORDS
                    },
                    {
                        role: RoleEnum.ROL_JCG,
                        mode: ModeEnum.VIEW
                    },
                    RoleEnum.ROL_SGC,
                    {
                        role: RoleEnum.ROL_GDCH,
                        mode: ModeEnum.VIEW
                    }
                ] }
            },
            {
                path: 'view-potential-client', 
                component: PotentialClientComponent,
                canActivate: [ roleGuard ],
                data: { mode: ModeEnum.VIEW }
            },
            { 
                path: 'search-potential-client', 
                component: SearchPotentialClientComponent,
                canActivate: [ roleGuard ],
                data: { expectedRoles: [
                    {
                        role: RoleEnum.ROL_EC,
                        mode: ModeEnum.ONLY_MY_RECORDS
                    },
                    {
                        role: RoleEnum.ROL_JO,
                        mode: ModeEnum.ONLY_MY_RECORDS
                    },
                    {
                        role: RoleEnum.ROL_JOS,
                        mode: ModeEnum.ONLY_MY_RECORDS
                    },
                    {
                        role: RoleEnum.ROL_AZ,
                        mode: ModeEnum.ONLY_MY_RECORDS
                    },
                    {
                        role: RoleEnum.ROL_JCG,
                        mode: ModeEnum.EDIT
                    }
                ] },
            },
            { 
                path: 'search-current-client', 
                component: SearchCurrentClientComponent,
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.CREATE_FEC_CLIENT_CURRENT.roles },
            },
            { 
                path: 'fec-detail', 
                component: FecDetailComponent,
                children: [
                    { path: '', redirectTo: 'client-detail', pathMatch: 'full' },
                    { 
                        path: 'client-detail', 
                        component: ClientDetailComponent
                    },
                    { 
                        path: 'economic-evaluation', 
                        component: EconomicEvaluationComponent
                    },
                    {
                        path: 'fec-preview',
                        component: FecPreviewComponent
                    }
                ]
            },
            { 
                path: 'fec-list', 
                canActivate: [ roleGuard ],
                data: { expectedRoles: [
                    RoleEnum.ROL_EC,
                    {
                        role: RoleEnum.ROL_JOS,
                        mode: FecListComponentModeEnum.VIEW_AND_NOT_MY_FECS
                    },
                    RoleEnum.ROL_JO,
                    RoleEnum.ROL_AZ,
                    {
                        role: RoleEnum.ROL_ACG,
                        mode: FecListComponentModeEnum.NOT_MY_FECS
                    },
                    RoleEnum.ROL_JCG,
                    {
                        role: RoleEnum.ROL_SGC,
                        mode: FecListComponentModeEnum.NOT_MY_FECS
                    },
                    {
                        role: RoleEnum.ROL_GDCH,
                        mode: FecListComponentModeEnum.NOT_MY_FECS
                    }
                ] },
                component: FecListComponent
            },
            
            { 
                path: 'user-manager', 
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.USER_ADM.roles },
                component: UserAdminComponent
            },
            {
                path: 'fec-tracking',
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.MY_FEC.roles },
                component: FecTrackingComponent
            },
            { 
                path: 'read-only-fec',
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.MY_FEC.roles },
                component: ReadOnlyFecComponent
            },
            {
                path: 'approval-management',
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.APPROVAL_MANAGEMENT.roles },
                component: ApprovalManagementComponent,
            },
            { 
                path: 'approval-fec',
                canActivate: [ roleGuard ],
                data: { expectedRoles: MENU.APPROVAL_MANAGEMENT.roles },
                component: ApprovalFecComponent
            },
            { 
                path: 'dev', 
                component: DevAdminComponent
            },
            { 
                path: 'parameter-admin',
                component: ParameterAdminComponent
            },
            { 
                path: 'parameter-detail',
                component: ParameterDetailComponent
            },
            { 
                path: '**', 
                redirectTo: 'home'
            }
        ]
    },
    { path: '**', redirectTo: 'login' },
];


