<div class="contacts-contianer flex flex-column">
    <h3>Contactos OTIC</h3>
    <app-search-hunter 
        #hunterComponent 
        [value]="client.hunterContact" 
        label="Cazador" 
        [readonly]="mode === ModeEnum.VIEW"
    />
    <app-search-executive 
        #affiliatorComponent 
        [value]="client.affiliatorContact" 
        label="Nombre Ejecutivo Afiliación" 
        [readonly]="mode === ModeEnum.VIEW"
    />
    <app-search-executive 
        #maintainerComponent 
        [value]="client.maintainerContact" 
        label="Ejecutivo Mantención"
        [readonly]="mode === ModeEnum.VIEW" 
    />
</div>
