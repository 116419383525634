<app-page-container
    [title]="parameter?.description ?? ''"
    [paths]="paths"
>
    <app-grid-container
        title="Listado"
        addTitle="Agregar Valor"
    >
        <p-table 
            [paginator]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[10, 25, 20]"
            [rows]="10"
            [showCurrentPageReport]="true"
            [value]="items" 
            [tableStyle]="{ 'min-width': '50rem' }" 
            styleClass="p-datatable-striped"
            dataKey="id"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="description" style="width:75%">
                        Descripción <p-sortIcon field="description" />
                    </th>
                    @if (parameter?.metadata) {
                        @for (itemMeta of parameter?.metadata; track $index;) {
                            <th scope="col">{{itemMeta.description}}</th>
                        }
                    }
                </tr>
                <tr>
                    <th>
                        <p-columnFilter type="text" field="description" [showClearButton]="true" />
                    </th>
                    @if (parameter?.metadata) {
                        @for (itemMeta of parameter?.metadata; track $index;) {
                            <th></th>
                        }
                    }
                </tr>        
            </ng-template>
            <ng-template pTemplate="body" let-editing="editing" let-item let-index="rowIndex">
                <tr>
                    <td [pEditableColumn]="item.description" pEditableColumnField="description">
                        <p-cellEditor>
                            <ng-template pTemplate="input">
                                <input 
                                    pInputText 
                                    type="text" 
                                    class="w-full"
                                    [(ngModel)]="item.description" 
                                />
                            </ng-template>
                            <ng-template pTemplate="output">
                                {{ item.description }}
                            </ng-template>
                        </p-cellEditor>
                    </td>

                    @if (parameter?.metadata) {
                        @for (itemMeta of parameter?.metadata; track $index;) {
                            <td [pEditableColumn]="item[itemMeta.fieldname]" [pEditableColumnField]="itemMeta.fieldname">
                                @if (itemMeta.type === "amount") {
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input 
                                                pInputText type="text" 
                                                [(ngModel)]="item[itemMeta.fieldname]" 
                                                (keydown.enter)="onEdit($event)" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ item[itemMeta.fieldname] | currency : 'USD' }}
                                        </ng-template>
                                    </p-cellEditor>
                                }
                                @if (itemMeta.type === "email") {
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input 
                                                pInputText type="text" 
                                                [(ngModel)]="item[itemMeta.fieldname]" 
                                                (keydown.enter)="onEdit($event)" />
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ item[itemMeta.fieldname] }}
                                        </ng-template>
                                    </p-cellEditor>
                                }
                            </td>
                        }
                    }

                </tr>
            </ng-template>
        </p-table>
    </app-grid-container>

</app-page-container>
