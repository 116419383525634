import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FakeStep, FakeStepsComponent } from '@shared/components/fake-steps/fake-steps.component';
import { Client } from '@shared/interfaces/client';
import { ButtonComponent } from '@shared/components/button/button.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { tabClientDataIsComplete, tabOticContactsIsComplete } from '@shared/util/oticValidationUtils';
import { DataNonCommercialFormComponent } from './data-non-comercial-form/data-non-commercial-form.component';
import { DataCommercialFormComponent } from './data-commercial-form/data-commercial-form.component';
import { OticContactsPotentialFormComponent } from './otic-contacts-potential-form/otic-contacts-potential-form.component';
import { OticContactsCurrentFormComponent } from './otic-contacts-current-form/otic-contacts-current-form.component';
import { ActivatedRoute, Router } from '@angular/router';
import { isNotBlank } from '@shared/util/stringUtils';

@Component({
  selector: 'app-client-form',
  standalone: true,
  imports: [
    CommonModule,
    FakeStepsComponent,
    ButtonComponent,
    DataNonCommercialFormComponent,
    DataCommercialFormComponent,
    OticContactsPotentialFormComponent,
    OticContactsCurrentFormComponent
  ],
  templateUrl: './client-form.component.html'
})
export class ClientFormComponent implements OnInit, OnChanges {
  ModeEnum = ModeEnum;
  @ViewChild('dataNonCommercialComponent') dataNonCommercialComponent!: DataNonCommercialFormComponent;
  @ViewChild('dataCommercialComponent') dataCommercialComponent!: DataCommercialFormComponent;
  @ViewChild('oticContacsPotentialComponent') oticContacsPotentialComponent!: OticContactsPotentialFormComponent;
  @ViewChild('oticContacsCurrentComponent') oticContacsCurrentComponent!: OticContactsCurrentFormComponent;
  @Input()
  mode: ModeEnum = ModeEnum.EDIT;
  @Input()
  fromFec: boolean = false;
  @Input()
  showPrevious: boolean = true;
  @Output()
  public navigate = new EventEmitter<number>();
  @Output()
  public previous = new EventEmitter();
  @Output()
  public final = new EventEmitter<Client>();
  @Output()
  public toOticContacts = new EventEmitter<Client>();
  @Output()
  public toClientData = new EventEmitter<Client>();
  activeIndex: number = 0;
  labelFinalButton: string = "Guardar";
  stepType: number = 1;
  steps = [
    {
      label: 'Datos Empresa',
      allowedNavigation: () => true
    },
    {
        label: 'Contactos OTIC',
        allowedNavigation: () => tabClientDataIsComplete(this.client ?? {})
    }
  ];
  @Input() client: Client = {};

  constructor(
    private router: Router, 
    private route: ActivatedRoute
  ){}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let index = parseInt(params['index']) ?? 0;
      let id = params['id'];
      if (index === 1 && isNotBlank(id))
        this.activeIndex = 1;
      else {
        this.activeIndex = 0;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.fromFec){
      this.labelFinalButton = "Siguiente";
      this.stepType = 2;
    }
    else {
      this.labelFinalButton = (this.mode === ModeEnum.EDIT ? 'Guardar' : 'Salir');
    }
  }

  onPrevious(){
    this.previous.emit();
  }

  private dataClientValidate(){
    if (this.client.isComercial)
      return this.dataCommercialComponent.validate();
    else 
      return this.dataNonCommercialComponent.validate();
  }

  private getDataClient(){
    if (this.client.isComercial)
      return this.dataCommercialComponent.getData();
    else
      return this.dataNonCommercialComponent.getData();
  }

  private oticContactsValidate(){
    if (this.client.isPotential)
      return this.oticContacsPotentialComponent.validate();
    else 
      return this.oticContacsCurrentComponent.validate();
  }

  private getOticContacts(){
    if (this.client.isPotential)
      return this.oticContacsPotentialComponent.getData();
    else
      return this.oticContacsCurrentComponent.getData();
  }

  onToOticContacts(){
    if (this.mode === ModeEnum.VIEW || this.dataClientValidate())
      this.toOticContacts.emit(this.getDataClient());
  }

  onToClientData(){
    this.toClientData.emit(this.getOticContacts());
  }

  onFinal(){
    if (this.mode === ModeEnum.VIEW || this.oticContactsValidate())
      this.final.emit(this.getOticContacts());
  }

  showOticContacts(){
    this.activeIndex = 1;
    this.updateUrlParam();
  }

  showClientData(){
    this.activeIndex = 0;
    this.updateUrlParam();
  }

  onNavigate(step: FakeStep){
    this.activeIndex = step.index ?? 0;
    this.updateUrlParam();
    this.navigate.emit(this.activeIndex);
  }

  updateUrlParam() {
    const queryParams = { ...this.route.snapshot.queryParams };
    queryParams['index'] = this.activeIndex;
    // Navegar a la misma ruta con los nuevos parámetros, sin recargar la página
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // mantiene los otros parámetros
      replaceUrl: true // reemplaza la URL en lugar de agregar una entrada en el historial
    });
  }

}
