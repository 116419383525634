import { Pipe, PipeTransform } from '@angular/core';
import { dateToStringDDMMYYYY } from '@shared/util/dateUtil';

@Pipe({
  name: 'epochToDate',
  standalone: true
})
export class EpochDatePipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): unknown {
    if (!value) return '';
    return dateToStringDDMMYYYY(new Date(value));
  }

}
