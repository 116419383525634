import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'app-grid-container',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent
  ],
  templateUrl: './grid-container.component.html',
  styleUrl: './grid-container.component.scss'
})
export class GridContainerComponent {

  @Input() 
  public title?: string;

  @Input() 
  public addTitle?: string; 

  @Input() 
  public showAddButton: boolean = true; 

  @Output() 
  public add = new EventEmitter(); 

  public onAdd(){
    this.add.emit();
  }
}
