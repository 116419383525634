import { EnvironmentInterface } from "./environment.interface";

export const environment: EnvironmentInterface = {
  env: 'qa',
  API_CLIENT_ID: 'dmp_front',
  API_CLIENT_SECRET: '20c161fe717da21c732554e68c87fc79',
  API_URL: 'https://cgc-api.0s.cl',
  sso: {
    url: 'https://sso-dev.0s.cl/auth',
    realm: 'OTIC',
    clientId: 'oticdmp',
    credentials: {
      secret: 'MvlR2ceGrSMzLxrnkauttTDrWRwnBzq6'
    }
  }
};
