import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { SearchExecutiveComponent } from '@shared/components/search-executive/search-executive.component';
import { SearchHunterComponent } from '@shared/components/search-hunter/search-hunter.component';
import { ModeEnum } from '@shared/enums/modeEnum';
import { Client } from '@shared/interfaces/client';

@Component({
  selector: 'app-otic-contacts-potential-form',
  standalone: true,
  imports: [
    CommonModule,
    SearchHunterComponent,
    SearchExecutiveComponent
  ],
  templateUrl: './otic-contacts-potential-form.component.html'
})
export class OticContactsPotentialFormComponent {
  ModeEnum = ModeEnum;
  @ViewChild('hunterComponent') hunterComponent!: SearchHunterComponent;
  @ViewChild('followUpComponent') followUpComponent!: SearchExecutiveComponent;
  @Input() client: Client = {};
  @Input() mode: ModeEnum = ModeEnum.EDIT;

  validate(){
    return this.hunterComponent.validate() && this.followUpComponent.validate();
  }

  getData(){
    return {
      hunterContact: this.hunterComponent.getData(),
      followUpContact: this.followUpComponent.getData()
    }
  }
}
