<app-client-form
    #clientFormComponent
    (previous)="previous()"
    (toOticContacts)="toOticContacts($event)"
    (toClientData)="toClientData($event)"
    (final)="final($event)"
    [client]="client"
    (navigate)="navigate($event)"
    [mode]="mode"
    [fromFec]="true"
/>
