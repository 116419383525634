<div class="flex flex-column h-full">
    <div class="formgrid grid">
        <div class="subtitle flex-column field col-12 p-0 m-0">
            Seleccione los Accesos para el usuario: <b>{{selectedUser?.fullName}}</b>
        </div>
        <div class="flex flex-column field col-12 p-0 m-0">
            <div *ngFor="let role of roles" class="field-checkbox">
                <p-checkbox class="p-button-label" [label]="role.name" name="group" [value]="role" [(ngModel)]="selectedRoles"></p-checkbox>
            </div>
        </div>
    </div>
    <div class="flex flex-row justify-content-end gap-3" style="margin-top: auto;">
        <app-button label="Cancelar" type="secondary" (clickButton)="closeModal.emit()"></app-button>
        <app-button label="Aceptar" (clickButton)="saveRoles()"></app-button>
    </div>
</div>