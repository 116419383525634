import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ButtonComponent } from '@shared/components/button/button.component';

@Component({
  selector: 'app-stepbar',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    RouterOutlet
  ],
  templateUrl: './stepbar.component.html',
  styleUrl: './stepbar.component.scss'
})
export class StepbarComponent {

  @Output() 
  public nextClick = new EventEmitter(); 

  @Output() 
  public previousClick = new EventEmitter();

  @Output() 
  public finalClick = new EventEmitter();

  @Input() 
  public nextClickDisabled: boolean = false;

  @Input() 
  public showPrevious: boolean = false;

  @Input() 
  public showNext: boolean = false;

  @Input() 
  public showFinal: boolean = false;

  @Input() 
  public finalLabel: string = 'Guardar';

  constructor(private router: Router, private route: ActivatedRoute) {

  }

  onPrevious(){
    this.previousClick.emit();
  }

  onNext(){
    this.nextClick.emit();
  }

  onClickFinal(){
    this.finalClick.emit();
  }

}
