<div>
    <div class="fec-summary-multi-year-cost-title font-montserrat-s18-w600-color3">Gastos</div>
    <div class="flex flex-row">
        @for (fecYear of summary?.fecYears; track $index;) {
            <app-fec-summary-one-year-cost
                [fecYear]="fecYear"
            />
        }
    </div>
    <div class="flex flex-row">
        @for (fecYear of summary?.fecYears; track $index;) {
            <div class="flex flex-column fec-summary-multi-year-cost-year-summary">
                <app-label-value
                    [config]="{
                        type: 'amount',
                        label: 'Ingreso Real',
                        value: fecYear.summary?.realIncome,
                        labelClass: 'font-nunito-s16-w600-color4',
                        valueClass: 'font-nunito-s16-w400-color4',
                        width: 273,
                        height: 29,
                        labelWidth: 120,
                        labelPadding: '5px 0px 0px 5px',
                        valuePadding: '5px 0px 0px 5px'
                    }"
                />
                <app-label-value
                    [config]="{
                        type: 'percentage',
                        label: 'Tasa Real',
                        value: fecYear.summary?.effectiveRate,
                        labelClass: 'font-nunito-s16-w600-color4',
                        valueClass: 'font-nunito-s16-w400-color4',
                        width: 273,
                        height: 29,
                        labelWidth: 120,
                        labelPadding: '5px 0px 0px 5px',
                        valuePadding: '5px 0px 0px 5px'
                    }"
                />
            </div>
        }
    </div>
    <div class="fec-summary-multi-year-cost-title font-montserrat-s18-w600-color3">Consolidado</div>
    <div class="flex flex-row fec-summary-multi-year-cost-summary">
        <div class="fec-summary-multi-year-cost-summary-col1">
            @for (cost of summary?.costs; track $index;) {
                <app-label-value
                    [config]="{
                        type: 'amount',
                        label: cost.costTypeDescription,
                        value: cost.total,
                        labelClass: 'font-nunito-s16-w600-color1',
                        valueClass: 'font-nunito-s16-w400-color1',
                        width: 273,
                        labelWidth: 120,
                        labelPadding: '0px 0px 0px 5px',
                        valuePadding: '0px 0px 0px 5px'
                    }"
                />
            }
        </div>
        <div class="fec-summary-multi-year-cost-summary-col2">
            <app-label-value
                [config]="{
                    type: 'amount',
                    label: 'Ingreso Real',
                    value: summary?.realIncome,
                    containerClass: 'font-nunito-s20-w600-color4',
                    width: 273,
                    height: 29,
                    labelWidth: 150,
                    labelPadding: '5px 0px 0px 5px',
                    valuePadding: '5px 0px 0px 5px'
                }"
            />
            <app-label-value
                [config]="{
                    type: 'percentage',
                    label: 'Tasa Real',
                    value: summary?.effectiveRate,
                    containerClass: 'font-nunito-s20-w600-color4',
                    width: 273,
                    height: 29,
                    labelWidth: 150,
                    labelPadding: '5px 0px 0px 5px',
                    valuePadding: '5px 0px 0px 5px'
                }"
            />
        </div>
    </div>
</div>


