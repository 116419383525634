import { Injectable, signal } from "@angular/core";
import { Fec } from "@shared/interfaces/fec";
import { ScenarioProvider } from "./scenario.provider";
import { FecYearProvider } from "./fecYear.provider";
import { Scenario } from "@shared/interfaces/scenario";
import { clientIsComplete, tabClientDataIsComplete, tabOticContactsIsComplete } from "@shared/util/oticValidationUtils";
import { arrayIsNotEmpty } from "@shared/util/listUtil";

@Injectable({
    providedIn: 'root'
})
export class FecProvider {
  private current = signal<Fec>({});

  constructor(
    private scenarioProvider: ScenarioProvider,
    private fecYearProvider: FecYearProvider
  ){}

  updateCurrent(fec: Fec){
    this.current.set(fec);
    this.scenarioProvider.resetCurrent();
    this.fecYearProvider.updateCurrent({});
    this.scenarioProvider.updateList(fec.scenarios ?? []);
    this.fecYearProvider.updateList(fec.fecYears ?? []);
  }

  getCurrent(){
    return this.current();
  }

  addScenario(){
    this.scenarioProvider.updateListWithCurrent();
    this.fecYearProvider.updateFecYearsByScenariosChange(this.isComercial());
  }

  deleteScenario(item: Scenario){
    this.scenarioProvider.deleteScenario(item);
    this.fecYearProvider.updateFecYearsByScenariosChange(this.isComercial());
  }

  clientDataComplete(){
    return tabClientDataIsComplete(this.getCurrent().client ?? {});
  } 

  oticContactsComplete(){
    return tabOticContactsIsComplete(this.getCurrent().client ?? {});
  }

  clientComplete(){
    return clientIsComplete(this.getCurrent().client ?? {});
  }

  getClient(){
    return this.getCurrent().client;
  }

  getComercialChildren(){
    return this.getClient()?.comercialChildren ?? [];
  }

  isComercial(){
    return this.getClient()?.isComercial === true;
  }

  getComercialName(): string{
    return this.getClient()?.comercialName ?? '';
  }

  getPreviousFecSummary(){
    return this.getCurrent().previousFecSummary;
  }

  getCurrentFecSummary(){
    return this.getCurrent().currentFecSummary;
  }

  hasPeriod(){
    return arrayIsNotEmpty(this.getCurrent().fecYears);
  }

}
