import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonComponent } from '@shared/components/button/button.component';
import { UserService } from '@shared/services/user.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { User } from '@shared/interfaces/user';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { RoleEnum, RoleLabelEnum } from '@shared/enums/roleEnum';

@Component({
  selector: 'app-user-roles',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FieldContainerComponent,
    FormsModule
  ],
  providers: [provideNgxMask()],
  templateUrl: './user-roles.component.html',
  styleUrl: './user-roles.component.scss'
})
export class UserRolesComponent {

  selectedRoles: any[] = []
  selectedUser: any

  @Input() records: any = {}
  @Input() selectedIndex: number = 0

  @Input() set user(value: User) {
    const selected = value?.roles ?? []
    this.selectedUser = value
    this.selectedRoles = this.roles.filter(role => selected.includes(role.value));
  }

  @Output()
  public closeModal = new EventEmitter();

  roles = Object.entries(RoleLabelEnum)
    .map(([key, value]) => ({ name: value, value: key })).filter(item => item.value != RoleEnum.ROL_ADM);

  constructor(private fb: FormBuilder,
    private userService: UserService,
    private loadingProvider: LoadingProvider) { }

  saveRoles() {
    const roles = this.selectedRoles.map((role: { value: string }) => role.value)
    this.loadingProvider.show()
    this.userService.updateUser({
      rut: this.selectedUser.rut,
      roles: roles
    }).subscribe({
      next: (res) => {
        this.records[this.selectedIndex].roles = res.roles
        this.loadingProvider.hide()
        this.closeModal.emit()
      },
      error: (err) => {
        console.log(err)
        this.loadingProvider.hide()
      }
    });
  }

}
