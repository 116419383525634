@if (scenario){
    <div class="fec-summary-scenario-container">
        <app-fec-summary-income
            [scenario] = "scenario"
        ></app-fec-summary-income>
        <app-fec-summary-cost
            [scenario] = "scenario"
        ></app-fec-summary-cost>
        <div class="fec-summary-scenario-totals">
            <div class="fec-summary-scenario-totals-col">
                Ingreso Real: {{scenario.realIncome | chileanpeso}}
            </div>
            <div class="fec-summary-scenario-totals-col">
                Tasa Real: {{scenario.effectiveRate | custompercent}}
            </div>
        </div>
    </div>
}

