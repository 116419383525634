@if (processed){
    @if(processed.inOneTaxYear === true){
        <app-fec-summary-scenario
            [scenario] = "processed"
        />
    }
    @else{
        <p-tabView [(activeIndex)]="activeIndex" [scrollable]="true">
            <p-tabPanel header="Consolidado">
                <div class="fec-summary-multi-year-tab">
                    <app-fec-summary-income
                        [scenario] = "processed"
                    />
                    <app-fec-summary-multi-year-cost
                        [summary]="processed"
                    />
                </div>
            </p-tabPanel>
            @for (fecYear of processed.fecYears; track $index;) {
                <p-tabPanel [header]="'Periodo ' + fecYear.year">
                    <app-fec-summary-scenario
                        [scenario]="fecYear.summary"
                    />
                </p-tabPanel>
            }
        </p-tabView>
    }
}


