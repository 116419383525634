export enum FecStatusEnum {
  DRAFT = "draft",
  PENDING = "pending",
  APPROVED = "approved",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
}

export enum FecStatusLabelEnum {
  draft = "Borrador",
  pending = "Pendiente",
  approved = "Aprobada",
  rejected = "Rechazada",
  cancelled = "Cancelada",
}
