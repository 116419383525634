<input 
    #fileUpload
    class="list-fileupload-input"
    type="file" 
    accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
    text/plain, application/pdf, image/*" (cancel)="onFileCancel($event)"
    (change)="onFileSelected($event)" 
/>
@if (!readonly){
    <div 
        [ngClass]="{
            'list-fileupload-files-invalid': errorMessage
        }" 
    >
        @if (loading) {
            <div class="list-fileupload-files-progress">
                <p-progressBar [value]="progress" />
            </div>
        }
        @else {
            <app-button 
                label="Escoge un archivo..."
                icon="pi pi-fw pi-upload" 
                (clickButton)="fileUpload.click()" 
                [width]="188" 
                [height]="30" 
                [fontWeight]="400"
            ></app-button>
        }
    </div>
    @if (errorMessage){
        <div class="list-fileupload-files-error">
            {{ errorMessage }}
        </div>
    }
}
<div class="list-fileupload-files-container">
    @for (item of itemFileList; track $index;) {
        <div class="list-fileupload-files-item">
            <div class="otic-clip"></div>
            @if(readonly || indexSelected !== $index){
                <div
                    class="list-fileupload-files-item-description"
                    [title]="item.description"
                    (click)="donwloadFile(item)">{{item.description}}</div>
                @if (!readonly){
                    <div 
                        style="height: 16px; margin-right: 2px;" 
                        class="otic-edit" 
                        (click)="editFile(item, $index)">
                    </div>
                    <div 
                        style="height: 16px; margin-right: 2px;" 
                        class="otic-delete" 
                        (click)="deleteFile(item, $index)" >
                    </div>
                }
            }
            @else {
                <div class="list-fileupload-files-item-input">
                    <input 
                        type="text" 
                        [(ngModel)]="item.description" style="width: 320px;" 
                        (keydown.enter)="finishEdit(item, $index)"
                    />
                </div>
            }
        </div>
    }
</div>
