<app-page-container
    title="Aprobación FEC"
    [paths]="paths"
>
    <app-filter-container (search)="onSearch({})">
        <form [formGroup]="form" autocomplete="off" class="flex flex-column mb-20px gap-2">
            <div class="flex flex-column" style="width: 856px;" >
                <label
                    id="comercialNameFilter"
                    for="comercialName"
                >
                Cliente Comercial
                </label>
                <input
                    type="text"
                    pInputText
                    placeholder="Todos"
                    formControlName="comercialName"
                    class="w-full"
                    (keyup.enter)="onSearch({})"
                />
            </div>
            <div class="flex flex-row justify-content-between">
                <div class="flex flex-column">
                    <label for="rut">RUT</label>
                    <input
                        class="otic-form-s-input"
                        type="text" 
                        pInputText 
                        placeholder="Todos"
                        [maxlength]="50" 
                        formControlName="rut"
                        mask="0.000.000-A || 00.000.000-A"
                        (keyup.enter)="onSearch({})"
                    />
                </div>
                <div class="flex flex-column">
                    <label for="clientName">Nombre Cliente</label>
                    <input
                        class="otic-form-s-input"
                        type="text"
                        pInputText
                        placeholder="Todos"
                        formControlName="clientName"
                        (keyup.enter)="onSearch({})"
                    />
                </div>
                <div class="flex flex-column">
                    <label for="fecStatus">Estado</label>
                    <p-dropdown 
                        styleClass="otic-form-s-input" 
                        formControlName="fecStatus" 
                        [options]="statusList" 
                        optionLabel="name" 
                        optionValue="value"
                        placeholder="Todos"
                        [dropdownIcon]="'fa fa-caret-down'"
                        [showClear]="true"
                    >
                    </p-dropdown>
                </div>
            </div>
        </form>
    </app-filter-container>

    <app-grid-container
        title="Listado Evaluaciones Comerciales"
    >
        <p-table 
            [paginator]="true"
            [lazy]="true"
            currentPageReportTemplate="{first} - {last} de {totalRecords}"
            [rowsPerPageOptions]="[10, 25, 20]"
            [rows]="10"
            [showCurrentPageReport]="true"
            (onLazyLoad)="onSearch($event)"
            [totalRecords]="totalRecords"
            [loading]="loading"

            [value]="records" 
            [tableStyle]="{ 'min-width': '50rem' }" 
            styleClass="p-datatable-striped"
        >
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:130px" scope="col">RUT</th>
                    <th scope="col">Nombre Cliente</th>
                    <th scope="col">Cliente Comercial</th>
                    <th style="width:100px" scope="col" class="text-center">N° FEC</th>
                    <th scope="col" class="text-center">Fecha Realización</th>
                    <th scope="col" class="text-center">Estado</th>
                    <th scope="col" class="text-center">Tasa de Administración</th>
                    <th scope="col" class="text-center">Tasa Real</th>
                    <th scope="col" class="text-center">Ingreso Real</th>
                    <th scope="col" class="text-center">Opciones</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex">
                <tr>
                    <td>{{ item.rut }}</td>
                    <td>{{ item.clientName }}</td>
                    <td>{{ item.comercialName }}</td>
                    <td class="text-center">{{ item.correlative }}</td>
                    <td class="text-center">{{ item.createdAt | date: 'dd/MM/yy' }}</td>
                    <td class="flex justify-content-center"><app-status-chip [status]="item.fecStatus"></app-status-chip></td>
                    <td class="text-center">{{ item.summary?.finalAdministrationFee | custompercent }}</td>
                    <td class="text-center">{{ item.summary?.effectiveRate | custompercent }}</td>
                    <td class="text-center">{{ item.summary?.realIncome | chileanpeso }}</td>
                    <td class="text-center">
                        <p
                            (click)="onRowCheckPreview(item, index)" 
                            (keydown)="onRowCheckPreview(item, index)"
                            class="m-0 p-0 cursor-pointer font-nunito-s16-w400-color4"
                        >
                            Ver más
                        </p>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </app-grid-container>
</app-page-container>

