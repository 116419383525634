import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KeycloakAuthenticationService } from '@shared/services/authentication.service';
import { StorageService } from '@shared/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private _authenticationService: KeycloakAuthenticationService,
    private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this._authenticationService.currentUserValue;
    const token = this.storageService.getKeycloakToken();
    const isLoggedIn = currentUser && token;

    let agregaTokenURL = true;
    const txtUrlsExcluidas = [
      '.s3.us-east-2.amazonaws.com',
      '.s3.amazonaws.com',
    ];

    for (const txt of txtUrlsExcluidas) {       
      if (req.url.split(txt).length > 1) {
        agregaTokenURL = false;
        break;
      }
    }
    
    if (agregaTokenURL) {
      if (isLoggedIn) {
        req = req.clone({
          setHeaders: {
            'Authorization-Keycloak': `Bearer ${token}`
          }
        });
      }
    }
    return next.handle(req);
  }

}
