import { DateTime } from 'luxon';

export const dateStringISOToDDMMYYYY = (str: string) => {
    let date = new Date(str);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

export const dateToStringYYYYMMDD = (date: Date) => {
    return DateTime.fromJSDate(date).toFormat('yyyy/MM/dd');
}

export const dateToStringDDMMYYYY = (date: Date) => {
    return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy');
}

export const stringYYYYMMDDToDate = (str: string) => {
    return DateTime.fromFormat(str, 'yyyy/MM/dd').toJSDate();
}

export const dateToStringDDMMYYYYHHmmss = (date: Date) => {
    return DateTime.fromJSDate(date).toFormat('dd/MM/yyyy HH:mm:ss');
}
