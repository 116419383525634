import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ScenarioItemComponent } from '../scenario-item/scenario-item.component';
import { Scenario } from '@shared/interfaces/scenario';
import { DragDropModule } from 'primeng/dragdrop';
import { ScenarioComponent } from '@pages/fec/scenario/scenario.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { FecProvider } from '@pages/fec/fec.provider';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { FecYearProvider } from '@pages/fec/fecYear.provider';
import { ComparativeTableComponent } from '../comparative-table/comparative-table.component';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FecSummaryComercialScenarioComponent } from '@pages/fec/fec-summary/fec-summary-comercial-scenario/fec-summary-comercial-scenario.component';

@Component({
  selector: 'app-scenario-list',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ScenarioItemComponent,
    DragDropModule,
    ScenarioComponent,
    ModalComponent,
    ComparativeTableComponent,
    ButtonComponent,
    FecSummaryComercialScenarioComponent
  ],
  templateUrl: './scenario-list.component.html',
  styleUrl: './scenario-list.component.scss'
})
export class ScenarioListComponent {
  scenarioSelected: Scenario = {};
  @ViewChild('scenarioModal') scenarioModal!: ModalComponent;
  @ViewChild('comparativeModal') comparativeModal!: ModalComponent;
  @ViewChild('scenarioSummaryModal') scenarioSummaryModal!: ModalComponent;
  @ViewChild('scenarioItem') scenarioItem!: ScenarioComponent;
  
  constructor(
    public fecProvider: FecProvider,
    public scenarioProvider: ScenarioProvider,
    public fecYearProvider: FecYearProvider
  ){}

  openAddScenario(){
    this.scenarioProvider.prepareForCreate();
    this.scenarioModal.openModal();
  }

  openEditScenario(item: Scenario, index: number): void {
    this.scenarioProvider.prepareForUpdate(item);
    this.scenarioModal.openModal();
  }

  openViewScenario(item: Scenario, index: number): void {
    this.scenarioSelected = item;
    this.scenarioSummaryModal.openModal();
  }

  deleteScenario(item: Scenario, index: number): void {
    this.fecProvider.deleteScenario(item);
  }

  saveScenario(){
    this.fecProvider.addScenario();
    this.closeScenarioModal();
  }

  closeScenarioModal(): void {
    this.scenarioModal.closeModal();
  }

  onScenarioHide(){
    this.scenarioItem.reset();
  }

  onDragStartScenario(scenario: Scenario){
    this.fecYearProvider.updateScenarioSelected(scenario);
  }

  openComparative(){
    this.comparativeModal.openModal();
  }

  closeComparativeModal(): void {
    this.comparativeModal.closeModal();
  }

}
