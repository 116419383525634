import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { KeycloakAuthenticationService } from "@shared/services/authentication.service";
import { AuthService } from "@shared/services/auth.service";
import { UserSession } from "@shared/interfaces/userSession";
import { StorageService } from "@shared/services/storage.service";
import { processRoleMenu } from "@shared/util/roleUtil";
import { MessageProvider } from "@shared/providers/message.provider";
import { StatusEnum } from "@shared/enums/statusEnum";
import { LoadingComponent } from "../loading/loading.component";
import { CommonModule } from "@angular/common";
import { LoadingProvider } from "@shared/providers/loading.provider";


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    LoadingComponent
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {
  constructor(
    private authenticationService: KeycloakAuthenticationService,
    private route: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private messageProvider: MessageProvider,
    public loadingProvider: LoadingProvider
  ) {
  }
  
  ngOnInit() {    
    if (this.authenticationService.isTokenNoValid()) {
      localStorage.clear();
      this.redirectToLogin();
    }

    this.authenticationService.getUser().then((res:any) => {
      const userToStore: UserSession = {
        firstName: res.firstName,
        lastName:  res.lastName,
        userId: res.id,
        username: res.email,
        roles: [],
        menu: [],
        isAdmin: false
      }
      this.storageService.saveUser(userToStore);
      this.storageService.saveKeycloakToken(res.token);
      this.authService.login(res.email).subscribe({
        next: (user) => {
            if (user && user.status === StatusEnum.ACTIVE){
              userToStore.roles = user.roles ?? [];
              userToStore.isAdmin = user.isAdmin === true;
              this.storageService.saveUser(processRoleMenu(userToStore));
            }
            else {
              this.messageProvider.showError("Error", "El usuario no esta registrado o esta desactivado en el Módulo FEC");
            }
            this.route.navigate(['home'])
        },
        error: (err) => {
          console.log(err)
          this.messageProvider.showError("Error", "Ocurrio un error al cargar el usuario");
          this.route.navigate(['home'])
        }
      });
    })
  }

  public redirectToLogin() {
    this.loadingProvider.show();
    const url = this.authenticationService.login();
    console.log("url", url);
    window.location.href = url;
  }
}
