<div style="height: 566px;" class="flex flex-column justify-content-between">
    <div class="add-cost-body">
        <app-scenario-description
            [label]="scenarioProvider.getCurrent().description"
        ></app-scenario-description>
        <h3 class="m-0 mb-2">Gastos</h3>
        <app-scenario-subtitle
            [fontWeight]="700"
            [fontSize]="16" 
            [label]="costProvider.getCostTypeDescription()">
        </app-scenario-subtitle>

        @for (item of costItemScenario; track $index;) {
            @if(type === 1){
                <app-cost-type-1
                    [showLabel]="$index===0"
                    [data]="item"
                    (change)="change()"
                ></app-cost-type-1>
            }
            @if(type === 2){
                <app-cost-type-2
                    [showLabel]="$index===0"
                    [data]="item"
                    (change)="change()"
                ></app-cost-type-2>
            }
        }
        <form [formGroup]="form" autocomplete="off">
            <div class="formgrid grid justify-content-between col pt-0">
                <div class="field">
                    <app-text-readonly
                        label="Valor Total"
                        type="amount"
                        [value]="processCost.total"
                        [width]="220"
                    ></app-text-readonly>
                </div>

                <div class="field">
                    <app-text-readonly
                        [label]="'Tasa ' + costProvider.getCostTypeDescription()"
                        type="percentage"
                        [value]="processCost.rate"
                        [width]="220"
                    ></app-text-readonly>
                </div>

                <div class="field" style="width: 260px;">
                    <app-field-container
                        [form]="form"
                        fieldName="provision"
                        [label]="'Provisión ' + costProvider.getCostTypeDescription()"
                    >
                    <p-inputNumber 
                        [style]="{ width: '220px' }"
                        formControlName="provision"
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                    > 
                    </p-inputNumber>
                    </app-field-container>
                </div>
            </div>
        </form>
        <app-button label="Agregar Servicio" (clickButton)="addItem()"></app-button>

        <div class="field col-12 md:col-12 p-0" style="margin-top: 10px;">
            <label for="files">Respaldo {{costProvider.getCostTypeDescription()}} *</label>
            <app-list-fileupload
                id="files"
                #listFileuploadComponent
                [itemFileList]="costProvider.getCurrent().files ?? []"
                [required]="true"
            ></app-list-fileupload>
        </div>

    </div>
    <div class="flex flex-row align-items-center justify-content-end gap-2 mb-2">
        <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
        <app-button label="Guardar" (clickButton)="confirm()"></app-button>
    </div>
</div>
