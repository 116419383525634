import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'chileanpeso',
})
export class ChileanPesoPipe implements PipeTransform {
  transform(value: number | string | undefined, showDecimals: boolean = false): string {
    if (!value)
      return '$0';
    if (typeof value === 'string')
      value = parseInt(value);
    if (value === 0)
      return '$0';
    const formattedValue = showDecimals ? value.toFixed(2) : value.toFixed(0);

    const parts = formattedValue.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return `$${parts.join(',')}`;
  }
}

