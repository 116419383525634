import { Injectable, signal } from "@angular/core";
import { CostScenario } from "@shared/interfaces/costScenario";
import { CostManager } from "@shared/util/CostManager";
import { replaceOrInsertElementToList } from "@shared/util/listUtil";

@Injectable({
    providedIn: 'root'
})
export class CostProvider {
  private list = signal<CostScenario[]>([]);
  private current = signal<CostScenario>({});

  updateCurrent(item: CostScenario){
    this.current.set(item);
  }

  updateList(list: CostScenario[]){
    this.list.set(list);
  }

  getCurrent(){
    return this.current();
  }

  getList(){
    return this.list();
  }

  getCostTypeDescription(): string{
    return this.getCurrent().costTypeDescription ?? '';
  }

  getCostTypes(): string[]{
    return this.list()?.map(item => item.costType ?? '') ?? [];
  }


  updateListWithSelection(selectedCosts: string[]){
    let newCosts: CostScenario[] = [];
    let currentCosts = this.getList();
    /* mantenemos los tipos de costos que siguen seleccionados en caso de tratarse de una
      modificación, y agregamos los que no encontramos 
    */
    for(let costType of selectedCosts){
      let cost = currentCosts.find(item => item.costType === costType);
      if (cost)
        newCosts.push(cost);
      else 
        newCosts.push(CostManager.createCostScenario(costType));
    }
    this.updateList(newCosts);
  }

  updateCurrentAndList(cost: CostScenario){
    this.updateCurrent(cost);
    this.updateListWithCurrent();
  }

  private updateListWithCurrent(){
    let current = this.getCurrent();
    let newList = replaceOrInsertElementToList<CostScenario>(
      this.list(),
      current,
      (item) => item.costType ===  current.costType
    );
    this.list.set(newList);
  }

  hasEmptyCost(){
    return this.getList().find(cost => !cost.total || cost.total === 0)!= null;
  }

}
