import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { Fec } from '@shared/interfaces/fec';
import { EpochDateTimePipe } from '@shared/pipes/epoch-datetime.pipe';
import { TableModule } from 'primeng/table';

@Component({
  selector: 'app-fec-approval-detail',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    EpochDateTimePipe,
    TextReadonlyComponent
  ],
  templateUrl: './fec-approval-detail.component.html',
  styleUrl: './fec-approval-detail.component.scss'
})
export class FecApprovalDetailComponent {
  @Input()
  fec?: Fec;

  hasCostDo(){
    let result = this.fec?.summary?.costs?.find(cost => cost.costType === 'do');
    return (result!=null).toString();
  }
}
