<div>
    <form [formGroup]="form" autocomplete="off">
        <div class="formgrid grid justify-content-between col">
            <div class="field">
                <app-field-container
                    [form]="form"
                    fieldName="description"
                    [label]="showLabel ? 'Gasto Asociado' : ''"
                >
                    <input 
                        type="text"
                        [style]="{ width: '380px' }"
                        pInputText
                        placeholder=""
                        formControlName="description"
                    />
                </app-field-container>
            </div>
            <div class="field">
                <app-field-container
                    [form]="form"
                    fieldName="value"
                    [label]="showLabel ? 'Valor' : ''"
                >
                    <p-inputNumber 
                        [inputStyle]="{ width: '220px' }"
                        formControlName="value" 
                        mode="currency" 
                        currency="CLP"
                        locale="es-CL" 
                        [maxFractionDigits]="0"
                        >
                    </p-inputNumber>
                </app-field-container>
            </div>
            <div class="field" style="width: 140px;">
                <app-field-container
                    [form]="form"
                    fieldName="quantity"
                    [label]="showLabel ? 'Cantidad' : ''"
                >
                    <p-inputNumber 
                        [inputStyle]="{ width: '100px' }"
                        formControlName="quantity"
                        [maxFractionDigits]="0"
                        mode="decimal"
                    > 
                    </p-inputNumber>
                </app-field-container>
            </div>
        </div>
    </form>
</div>
