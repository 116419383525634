import { RoleLabelEnum } from "@shared/enums/roleEnum";

type RoleCodeDictionary = Record<string, {peckingOrder: number, code: string}>;

export const roleCodesDictionary: RoleCodeDictionary = {
    "ROL_EC" : {
        peckingOrder: 1,
        code: "EC",
    },
    "ROL_JO" : {
        peckingOrder: 2,
        code: "JO",
    },
    "ROL_JOS" : {
        peckingOrder: 3,
        code: "JO",
    },
    "ROL_AZ" : {
        peckingOrder: 4,
        code: "AZ",
    },
    "ROL_ACG" : {
        peckingOrder: 5,
        code: "CG",
    },
    "ROL_JCG" : {
        peckingOrder: 6,
        code: "CG",
    },
    "ROL_SGC" : {
        peckingOrder: 7,
        code: "SC",
    },
    "ROL_GDCH" : {
        peckingOrder: 8,
        code: "G",
    }
}

export const getRoleLabel = (role: string): string =>  {
    return RoleLabelEnum[role as keyof typeof RoleLabelEnum] || role;
}

export const getApprovalRoleLabel = (code: string): string => {
    for (const key in roleCodesDictionary) {
        if (roleCodesDictionary[key].code === code) {
            return key;
        }
    }
    return code;
}