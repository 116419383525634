import { Scenario } from './../../../../shared/interfaces/scenario';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { ButtonComponent } from '@shared/components/button/button.component';
import { TextReadonlyComponent } from '@shared/components/text-readonly/text-readonly.component';
import { ScenarioDescriptionComponent } from '../scenario-description/scenario-description.component';
import { ScenarioSubtitleComponent } from '../scenario-subtitle/scenario-subtitle.component';
import { ListFileuploadComponent } from '@shared/components/list-fileupload/list-fileupload.component';
import { disableAllField } from '@shared/util/formUtil';
import { Client } from '@shared/interfaces/client';
import { IncomeScenario } from '@shared/interfaces/incomeScenario';
import { ChildScenario } from '@shared/interfaces/childScenario';
import { ItemFile } from '@shared/interfaces/itemFile';
import { IncomeScenarioManager } from '@shared/util/IncomeScenarioManager';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { InputTextModule } from 'primeng/inputtext';
import { FINAL_ADMINISTRATION_FEE_MAX } from '@shared/constants/otic.constants';

@Component({
  selector: 'app-form-scenario-income',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputNumberModule,
    InputTextModule,
    FieldContainerComponent,
    CalendarModule,
    ButtonComponent,
    TextReadonlyComponent,
    ScenarioDescriptionComponent,
    ScenarioSubtitleComponent,
    ListFileuploadComponent,
    NgxMaskDirective, 
    NgxMaskPipe,
  ],
  providers: [provideNgxMask()],
  templateUrl: './form-scenario-income.component.html',
  styleUrl: './form-scenario-income.component.scss'
})
export class FormScenarioIncomeComponent implements OnInit {
  @ViewChild('listFileuploadComponent') listFileuploadComponent!: ListFileuploadComponent;
  @Input() readonly: boolean = false;
  @Input() comercialChild?: Client;
  @Input() scenario?: IncomeScenario | ChildScenario;
  processScenario: IncomeScenario = {};
  itemFileList: ItemFile[] = []

  @Output()
  public change = new EventEmitter();

  public form: FormGroup = this.fb.group({
    contributionCommitment: [0, [Validators.required, Validators.min(1)]],
    finalAdministrationFee: [0, [Validators.required, Validators.min(0), Validators.max(FINAL_ADMINISTRATION_FEE_MAX)]],
    nonFranchisableAccountIncome: [0, [Validators.min(0)]],
    provisionPd: [0, [Validators.min(0)]],
    consultancyProvision: [0, [Validators.min(0)]],
    additionalInvoice: [0, [Validators.min(0)]],
    paymentAgreement: [],
  });

  constructor(
    private fb: FormBuilder
  ){} 

  ngOnInit(): void {
    if (this.scenario){
      this.setData(this.scenario);
    }
    if (this.readonly)
      disableAllField(this.form, []);
    this.form.valueChanges.subscribe(values => { 
      this.processScenario = IncomeScenarioManager.processIncomeScenario({...values});
      this.change.emit();
    });
  }

  validate(){
    this.form.markAllAsTouched();
    if (!this.listFileuploadComponent.validate()){
      return false;
    }
    return this.form.valid;
  }

  setData(scenario: Scenario){
    this.form.reset(scenario);
    this.itemFileList = scenario.files ?? [];
    this.processScenario = IncomeScenarioManager.processIncomeScenario({...scenario});
  }

  getData(): IncomeScenario | ChildScenario {
    let data = {
      ...this.form.value,
      files: this.listFileuploadComponent.getData()
    };
    if (this.comercialChild){
      data.rut = this.comercialChild.rut;
      data.clientName = this.comercialChild.clientName;
    }
    return data;
  }
}
