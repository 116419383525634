import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FecSummaryIncomeComponent } from '../fec-summary-income/fec-summary-income.component';
import { FecSummaryMultiYearCostComponent } from '../fec-summary-multi-year-cost/fec-summary-multi-year-cost.component';
import { TabViewModule } from 'primeng/tabview';
import { FecSummaryScenarioComponent } from '../fec-summary-scenario/fec-summary-scenario.component';
import { FecSummary } from '@shared/interfaces/fecSummary';
import { Scenario } from '@shared/interfaces/scenario';

@Component({
  selector: 'app-fec-summary-year',
  standalone: true,
  imports: [
    CommonModule,
    FecSummaryIncomeComponent,
    FecSummaryMultiYearCostComponent,
    TabViewModule,
    FecSummaryScenarioComponent
  ],
  templateUrl: './fec-summary-year.component.html',
  styleUrl: './fec-summary-year.component.scss'
})
export class FecSummaryYearComponent implements OnChanges {
  activeIndex: number = 0;
  @Input() summary?: FecSummary
  @Input() rut?: string;
  processed?: FecSummary;

  ngOnChanges(changes: SimpleChanges) {
    this.activeIndex = 0;
    this.processed = this.processSummary(this.summary ?? {}, this.rut);
  }

  getScenarioByRut(scenario: Scenario, rut: string){
    return scenario.childrenScenario?.find(item => item.rut === rut) ?? {};
  }

  processSummary(summary: FecSummary, rut?: string){
    let processed: FecSummary = {};
    if (!rut){
      return summary;
    }
    processed = this.getScenarioByRut(summary, rut);
    processed.inOneTaxYear = summary.inOneTaxYear;
    processed.fecYears = summary.fecYears?.map(fecYear => {
      return {
        year: fecYear.year,
        summary: this.getScenarioByRut(fecYear.summary ?? {}, rut)
      };
    }) ?? [];
    return processed;
  }
}

