import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-filter-container',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  templateUrl: './filter-container.component.html',
  styleUrl: './filter-container.component.scss'
})
export class FilterContainerComponent {
  @Output() 
  public search = new EventEmitter(); 

  @Input({ transform: booleanAttribute }) hideButton: boolean = false

  @Input() title = 'Filtrar'

  onSearch(){
    this.search.emit();
  }
}
