import { Injectable } from '@angular/core';
import { ServiceBase } from './serviceBase';
import { HttpProvider } from '@shared/providers/http.provider';
import { map } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class S3Service extends ServiceBase {

    constructor(httpProvider: HttpProvider,
                private httpProviderClean: HttpProvider) {
        super(httpProvider);
    }

    getHttpClientClean(){
        return this.httpProviderClean.getHttpClient();
    }

    getItem(presignedUrl: string) {
        return this.httpProviderClean.get(presignedUrl)
    }

    putItem(presignedUrl: string, file: File) {
        return this.httpProviderClean.put(presignedUrl, file)
    }

    generateGetObjectSignedUrl(key: string) {
        return this.post<any>('signedurl', { key, action: 'get'}).pipe(
            map(resp => resp['signedUrl'])
        )
    }

    generatePutObjectSignedUrl(key: string) {
        return this.post<any>('signedurl', { key, action: 'put'}).pipe(
            map(resp => resp['signedUrl'])
        )
    }
}
