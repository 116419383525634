<form [formGroup]="form" autocomplete="off">
    <div class="formgrid grid" style="padding-left: 36px;">
        <h3 class="flex flex-column field col-12 p-0" style="margin-top: 42px;">{{label}}</h3>
        <div class="flex flex-column field" style="margin-right: 100px;">
            <app-field-container
                [form]="form"
                fieldName="rut"
                label="RUT *"
                class="otic-form-l-input"
                [errorMessage]="searchNotFoundError"
            >
                <input 
                    type="text" 
                    pInputText 
                    placeholder="1.234.567-8" 
                    [maxlength]="50" 
                    formControlName="rut"
                    (keydown.enter)="findExecutive()"
                    mask="0.000.000-A || 00.000.000-A"
                />
            </app-field-container>
        </div>
        <div class="flex flex-column field otic-form-l-input" style="margin-right: 100px;">
            <label for="name">Nombre {{label}}</label>
            <input 
                type="text" 
                pInputText 
                [placeholder]="'Nombre ' + label" 
                formControlName="name"
            />
        </div>
        <div class="flex flex-column field">
            <app-field-container
                [form]="form"
                fieldName="email"
                label="Email"
                class="otic-form-l-input"
            >
                <input 
                    type="text" 
                    pInputText 
                    placeholder="Email" 
                    [maxlength]="50" 
                    formControlName="email"
                />
            </app-field-container>
        </div>
        <div class="flex flex-column field otic-form-l-input" style="margin-right: 100px;">
            <app-field-container
                [form]="form"
                fieldName="phone"
                label="Teléfono"
                class="otic-form-l-input"
            >
                <input
                    type="text" 
                    pInputText 
                    placeholder="12345678"
                    formControlName="phone"
                    mask="00000000||000000000"
                />
            </app-field-container>
        </div>
        <div class="flex flex-column field otic-form-l-input" style="margin-right: 100px;">
            <app-field-container
                [form]="form"
                fieldName="office"
                label="Oficina"
                class="otic-form-l-input"
            >
                <p-dropdown 
                    class="w-full"
                    formControlName="office" 
                    [options]="offices"
                    optionLabel="name" 
                    optionValue="name" 
                    placeholder="Oficina"
                    [dropdownIcon]="'fa fa-caret-down'"
                    (onChange)="changeOffice($event)"
                />
            </app-field-container>
            
        </div>
        <div class="flex flex-column field otic-form-l-input">
            <label for="zone">Zona</label>
            <input
                type="text" 
                pInputText 
                placeholder="Zona"
                formControlName="zone"
            />
        </div>
    </div>
</form>

