import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { FecService } from '@shared/services/fec.service';
import { TabViewModule } from 'primeng/tabview';
import { FecSummaryYearComponent } from './fec-summary-year/fec-summary-year.component';
import { Fec } from '@shared/interfaces/fec';

@Component({
  selector: 'app-fec-summary',
  standalone: true,
  imports: [
    CommonModule,
    FecSummaryYearComponent,
    TabViewModule
  ],
  templateUrl: './fec-summary.component.html',
  styleUrl: './fec-summary.component.scss'
})
export class FecSummaryComponent implements OnChanges {
  activeIndex: number = 0;
  @Input() fec?: Fec;

  private _fecId?: string;
  @Input() set fecId(value: string) {
    this._fecId = value;
    this.load();
  }

  constructor(
    private loadingProvider: LoadingProvider,
    private fecService: FecService
  ){}

  ngOnChanges(changes: SimpleChanges) {
    this.activeIndex = 0;
  }

  load(): void {
    if (!this._fecId){
      return;
    }
    this.loadingProvider.show();
    this.fecService.getFec(this._fecId).subscribe((resp) => {
      this.fec = resp;
      this.loadingProvider.hide();
    });
  }

}
