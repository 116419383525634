import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { KeycloakService } from "./keycloak.service";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
export class KeycloakAuthenticationService {

    public currentUser: Observable<any>;
    private currentUserSubject: BehaviorSubject<any>;

    constructor(
        private keycloak: KeycloakService,
        private storageService: StorageService
    ) {
        this.currentUserSubject = new BehaviorSubject<any>(
            this.storageService.getUser()
        );
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    isTokenNoValid() {
        try {
            return this.keycloak.isTokenExpired();
        } catch (e) {
            return true;
        }
    }

    isTokenNoValidWithToken() {
        return this.keycloak.isTokenExpiredWithToken();
    }

    login() {
        let opciones = { idpHint: "google" };
        return this.keycloak.createLoginUrl(opciones);
    }

    getUser() {
        return new Promise((resolve, reject) => {
            this.keycloak.loadUserInfo().then((userGET: any) => {
                let user = userGET;
                user.token = this.keycloak.getToken();
                if (user && user.token) {
                    this.currentUserSubject.next(user);
                    resolve(user)
                } else {
                    reject(user);
                }
            });
        });
    }

    getToken() {
        return this.keycloak.getToken();
    }

    refreshToken() {
        return this.keycloak.refreshToken();
    }

    async logout() {
        try {
            // remove user from local storage to log user out
            this.storageService.clean();
            if (await this.keycloak.loadUserInfo()) {
                this.keycloak.logout();
            }
            // notify
            this.currentUserSubject.next(null);
        } catch (e) {
            console.error('KeycloakAuthenticationService.logout',e);
            this.currentUserSubject.next(null);
            window.location.reload();
        }
    }
}
