import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakAuthenticationService } from '../services/authentication.service';


export const authGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,) => {
  const authenticationService: KeycloakAuthenticationService = inject(KeycloakAuthenticationService);
  if (authenticationService.currentUserValue) {
    return true;
  }

  const router: Router = inject(Router);
  
  return router.createUrlTree(['login']);
}
