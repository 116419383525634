import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { FieldContainerComponent } from '../field-container/field-container.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-search-box',
  standalone: true,
  imports: [
    CommonModule,
    NgxMaskDirective, 
    NgxMaskPipe,
    ReactiveFormsModule,
    InputTextModule,
    FieldContainerComponent,
    ButtonComponent
  ],
  providers: [provideNgxMask()],
  templateUrl: './search-box.component.html',
  styleUrl: './search-box.component.scss'
})
export class SearchBoxComponent {
  @Input()
  public label: string = "";
  @Input()
  public mask: string = "";
  @Output()
  public search = new EventEmitter<string>();
  public form: FormGroup = this.fb.group({
    searchTerm: [''],
  });

  constructor(private fb: FormBuilder) {
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  onSearch(){
    if (!this.validate())
      return;
    this.search.emit(this.form.value['searchTerm']);
  }
}
