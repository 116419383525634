<div class="flex flex-column h-full">
    <form [formGroup]="form" autocomplete="off" class="formgrid grid justify-content-between">
        <div class="formgrid grid">
            <div class="flex-column field col-12 p-0 m-0">
                <label for="isAdmin">Administrador</label>
                <div class="field-checkbox">
                    <p-checkbox [binary]="true" [label]="'No tiene permisos de Administrador'"
                        formControlName="isntAdmin"></p-checkbox>
                </div>
            </div>
            <div class="field col">
                <app-field-container [form]="form" fieldName="fullName" label="Nombre">
                    <input type="text" pInputText formControlName="fullName"
                        class="otic-form-xl-input" />
                </app-field-container>
            </div>
            <div class="field col">
                <app-field-container [form]="form" fieldName="rut" label="Rut">
                    <input (disabled)="update" type="text" pInputText formControlName="rut" class="otic-form-xl-input"
                        mask="0.000.000-A || 00.000.000-A" />
                </app-field-container>
            </div>
            <div class="field col">
                <app-field-container [form]="form" fieldName="email" label="Email">
                    <input type="text" pInputText formControlName="email" class="otic-form-xl-input" />
                </app-field-container>
            </div>
            <div class="field col">
                <app-field-container [form]="form" fieldName="number" label="Número">
                    <input maxlength="10" (keypress)="validarNumero($event)" pInputText formControlName="number" class="otic-form-xl-input" />
                </app-field-container>
            </div>
            <div class="field col flex flex-wrap gap-3">
                <div class="field-checkbox">
                    <input type="radio" formControlName="status" value="active" checked>
                    <label class="ml-2">Activar</label>
                </div>
                <div class="field-checkbox">
                    <input type="radio" formControlName="status" value="disabled">
                    <label class="ml-2">Desactivar</label>
                </div>
            </div>
        </div>
    </form>
    <div class="flex flex-row justify-content-end gap-3" style="margin-top: auto;">
        <app-button label="Cancelar" type="secondary" (clickButton)="closeModal.emit()"></app-button>
        <app-button label="Aceptar" (clickButton)="update ? updateUser(): saveUser()"></app-button>
    </div>
</div>