export enum RoleEnum {
    ROL_ADM = "ROL_ADM",
    ROL_EC = "ROL_EC",
    ROL_JO = "ROL_JO",
    ROL_JOS = "ROL_JOS",
    ROL_AZ = "ROL_AZ",
    ROL_ACG = "ROL_ACG",
    ROL_JCG = "ROL_JCG",
    ROL_SGC = "ROL_SGC",
    ROL_GDCH = "ROL_GDCH"
}

export enum RoleLabelEnum {
    ROL_ADM = "Administrador",
    ROL_EC = "Ejecutivo Comercial",
    ROL_JO = "Jefe de Oficina",
    ROL_JOS = "Jefe de Oficina Sin Cartera",
    ROL_AZ = "Agente Zonal",
    ROL_ACG = "Analista Control de Gestión",
    ROL_JCG = "Jefe Control de Gestión",
    ROL_SGC = "Sub Gerente Comercial",
    ROL_GDCH = "Gerente Desarrollo Capital Humano"
}
 