import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, effect } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ScenarioProvider } from '@pages/fec/scenario.provider';
import { ButtonComponent } from '@shared/components/button/button.component';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-scenario-name',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    FieldContainerComponent,
    ButtonComponent
  ],
  templateUrl: './scenario-name.component.html',
  styleUrl: './scenario-name.component.scss'
})
export class ScenarioNameComponent {
  @Output()
  public clickCancel = new EventEmitter();
  @Output()
  public next = new EventEmitter();

  public form: FormGroup = this.fb.group({
    description: ['', [Validators.required, Validators.maxLength(50)]]
  });

  constructor(
    private fb: FormBuilder,
    public scenarioProvider: ScenarioProvider
  ){
    effect(() => {
      this.form.reset(this.scenarioProvider.getCurrent());
    });
  }

  validate(){
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  onCancel(){
    this.clickCancel.emit();
  }

  onNext(){
    if (!this.validate())
      return;
    this.scenarioProvider.updateCurrentWithDescriptionTab(this.form.value['description']);
    this.next.emit();
  }

}
