import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldContainerComponent } from '@shared/components/field-container/field-container.component';
import { ModalComponent } from '@shared/components/modal/modal.component';
import { Fec } from '@shared/interfaces/fec';
import { FecService } from '@shared/services/fec.service';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ScenarioListComponent } from './scenario-list/scenario-list.component';
import { FecYearContainerComponent } from './fec-year-container/fec-year-container.component';
import { StepbarComponent } from '@shared/components/stepbar/stepbar.component';
import { FecProvider } from '../fec.provider';
import { ScenarioProvider } from '../scenario.provider';
import { FecYearProvider } from '../fecYear.provider';
import { LoadingProvider } from '@shared/providers/loading.provider';
import { dateToStringYYYYMMDD } from '@shared/util/dateUtil';
import { ButtonComponent } from '@shared/components/button/button.component';
import { NotificationComponent } from '@shared/components/notification/notification.component';
import { CommonModule } from '@angular/common';
import { fecYearsSelectedComplete } from '@shared/util/oticValidationUtils';
import { FecManager } from '@shared/util/FecManager';
import { MessageProvider } from '@shared/providers/message.provider';

@Component({
  selector: 'app-economic-evaluation',
  standalone: true,
  imports: [
    ModalComponent,
    FieldContainerComponent,
    ReactiveFormsModule,
    ButtonModule,
    CalendarModule,
    ScenarioListComponent,
    FecYearContainerComponent,
    StepbarComponent,
    ButtonComponent,
    NotificationComponent,
    CommonModule
  ],
  templateUrl: './economic-evaluation.component.html',
  styleUrl: './economic-evaluation.component.scss'
})

export class EconomicEvaluationComponent implements OnInit {
  id?: string;
  @ViewChild('modal') modal!: ModalComponent;
  public form: FormGroup = this.fb.group({
    startDate: ['', [Validators.required]],
    finishDate: ['', [Validators.required]]
  });
  showNotification: boolean = false;
  minStartDate: Date = new Date(new Date().getFullYear(), 0, 1);
  minFinishDate: Date = new Date();
  notificacionText: string = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private loadingProvider: LoadingProvider,
    private messageProvider: MessageProvider,
    public fecProvider: FecProvider,
    public scenarioProvider: ScenarioProvider,
    public fecYearProvider: FecYearProvider,
    private fecService: FecService
  ) {

  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      if (!this.id) {
        this.messageProvider.showError("Error", "No se encuentra la FEC", () => {
          this.router.navigate(['/fec-list']);
        });
        return;
      }
      this.loadingProvider.show();
      this.fecService.getFec(this.id).subscribe({
        next: (resp) => {
          this.fecProvider.updateCurrent(resp);
          this.loadingProvider.hide();
          if (this.fecProvider.hasPeriod()) {
            this.form.reset({
              startDate: resp.startDate,
              finishDate: resp.finishDate
            });
          }
        },
        error: (err) => {
          this.loadingProvider.hide();
          let msg = err.error?.message || 'Ocurrió un error';
          console.error(msg);
          this.messageProvider.showError("Error", msg, () => {
            this.router.navigate(['/fec-list']);
          });
        }
      });
    });

    this.form.get('startDate')?.valueChanges.subscribe((value) => {
      this.form.get('finishDate')?.setValue(null);
      this.checkDates();
      if (value) {
        this.minFinishDate = new Date(value);
      }
    });
    this.form.get('finishDate')?.valueChanges.subscribe(() => this.checkDates());
  }

  openModal(): void {
    this.modal.openModal();
  }

  closeModal(): void {
    this.modal.closeModal();
  }

  closeInfo(): void {
    this.showNotification = false;
  }

  checkDates() {
    const startDateControl = this.form.get('startDate');
    const finishDateControl = this.form.get('finishDate');

    if (startDateControl && finishDateControl) {
      const startDate = new Date(startDateControl.value);
      const finishDate = new Date(finishDateControl.value);

      const diff = finishDate.getFullYear() - startDate.getFullYear() + 1;

      if (startDate.getFullYear() === finishDate.getFullYear()) {
        this.notificacionText = `Este Periodo contempla 1 año tributario.<br>
        Por defecto se generará 1 Cuadro consolidado por el año tributario.`;
        this.showNotification = true;
      } else if (startDate.getFullYear() !== finishDate.getFullYear() && diff > 0) {
        this.notificacionText = `Este Periodo contempla ${diff} años tributarios.<br>
        Por defecto se generarán ${diff} Cuadros consolidados por cada año tributario. `;
        this.showNotification = true;
      }

    }
  }

  validate() {
    if (!this.id) {
      return false;
    }
    this.form.markAllAsTouched();
    return this.form.valid;
  }

  savePeriod() {
    if (!this.validate()) return;
    this.loadingProvider.show();
    let data = {
      ...this.fecProvider.getCurrent(),
      id: this.id,
      startDate: dateToStringYYYYMMDD(this.form.value['startDate']),
      finishDate: dateToStringYYYYMMDD(this.form.value['finishDate'])
    };
    this.fecService.updatePeriod(data).subscribe({
      next: (resp) => {
        this.fecProvider.updateCurrent(resp);
        this.loadingProvider.hide();
        this.closeModal();
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  onPrevious() {
    let fec: Fec = this.getFecForSave();
    this.updateFec(new FecManager(fec).getProcessed(), () => {
      this.router.navigate(
        ['/fec-detail'],
        { queryParams: { id: fec.id, index: 1 } }
      );
    });
  }

  onNext() {
    const years = this.fecYearProvider.getList();
    for (const record of years) {
      if (!record.scenarios || record.scenarios.length === 0) {
        this.messageProvider.showError("Error", "Los cuadros deben tener al menos un escenario");
        return;
      }
    }

    if (!this.validate()) return;

    let fec: Fec = this.getFecForSave();
    if (!fecYearsSelectedComplete(fec)) {
      this.messageProvider.showError("Error", "Debe seleccionar un cuadro por cada año");
      return;
    }
    this.updateFec(new FecManager(fec).getProcessed(), () => {
      this.router.navigate(
        ['/fec-detail/fec-preview'],
        { queryParams: { id: fec.id } }
      );
    });
  }

  private updateFec(fec: Fec, redirectFn: () => void) {
    this.loadingProvider.show();
    this.fecService.updateFec(fec).subscribe({
      next: (resp) => {
        this.fecProvider.updateCurrent(resp);
        this.loadingProvider.hide();
        redirectFn();
      },
      error: (err) => {
        this.loadingProvider.hide();
        let msg = err.error?.message || 'Ocurrió un error';
        console.error(msg);
        this.messageProvider.showError("Error", msg);
      }
    });
  }

  getFecForSave() {
    return {
      ...this.fecProvider.getCurrent(),
      id: this.id,
      scenarios: this.scenarioProvider.getList(),
      fecYears: this.fecYearProvider.getList(),
    };
  }
}
