<div style="height: 556px;" class="flex flex-column justify-content-between">
    <div class="body-scenario-cost">
        <app-scenario-description
        [label]="scenarioProvider.getCurrent().description"
        ></app-scenario-description>
        <app-scenario-subtitle label="Gastos"></app-scenario-subtitle>
        <div class="flex flex-column field col-12 p-0 m-0">
            <label for="item">Seleccione Servicios</label>
            <div *ngFor="let item of costs" class="field-checkbox">
                <p-checkbox [label]="item.name" [value]="item.value" [(ngModel)]="selectedCosts"></p-checkbox>
            </div>
            <div class="field-container-error">
                {{ getError() }}
            </div>
        </div>
    </div>
    <div class="flex align-items-center justify-content-end gap-2">
        <app-button label="Cancelar" type="secondary" (clickButton)="onCancel()"></app-button>
        <app-button label="Confirmar" (clickButton)="onNext()"></app-button>
    </div>
</div>
