<h3 class="font-nunito-s18-w600-color1">Vista Previa</h3>

<div class="grid header">
    <div class="col">
        <div class="text-center p-3">
            <span class="font-nunito-s16-w600-color1">Nombre Empresa:</span> {{fec?.clientName}}
        </div>
    </div>
    <div class="col">
        <div class="text-center p-3">
            <span class="font-nunito-s16-w600-color1">Rut:</span> {{fec?.rut}}
        </div>
    </div>
    <div class="col">
        <div class="text-center p-3">
            <span class="font-nunito-s16-w600-color1">Fecha realización:</span> {{fec?.createdAt | epochToDate}}
        </div>
    </div>
    <div class="col">
        <div class="text-center p-3">
            <span class="font-nunito-s16-w600-color1">N° FEC:</span> {{fec?.correlative}}
        </div>
    </div>
    <div class="col">
        <div class="text-center p-3">
            <span class="font-nunito-s16-w600-color1">Ejecutivo:</span> {{ fec?.client?.followUpContact?.name }}
        </div>
    </div>
</div>
<div class="grid" style="margin-top: 30px;">
    <div class="col-8">
        <app-fec-summary [fec]="fec"></app-fec-summary>
        <div class="download-container" >
            <div class="otic-download cursor-pointer" (click)="getPdf()"></div>
            <div class="font-nunito-s16-w600-color4 cursor-pointer" (click)="getPdf()"> Descargar</div>
        </div>
    </div>
    <div class="flex flex-column col-4">
        <div class="font-nunito-s16-w600-color1" style="margin-top: 50px;">Observaciones</div>
        <textarea
            class="w-full"
            rows="5"
            cols="50"
            pInputTextarea
            [autoResize]="true"
            [(ngModel)]="observation"
            placeholder="Ingrese observaciones"
            [maxlength]="255">
        </textarea>
        <small id="observations-characters" class="flex justify-content-end" style="color: #ABA7AF">
            {{ observation?.length ?? 0 }} / 255
         </small>
    </div>
</div>

<app-stepbar #stepBar [showPrevious]="true" (previousClick)="clickPrevious()" [showFinal]="true"
    (finalClick)="clickSend()" [finalLabel]="'Enviar'"></app-stepbar>
