<app-modal
    (hide)="onHide()"
    title="Datos Empresa"
    #modal
>
    <app-fake-steps
        [type]="2"
        [steps]="steps" 
    >
    </app-fake-steps>

    <div class="grid align-items-center">
        <div class="col">
            <h3>Contacto Empresa</h3>
        </div>
    </div>
    <form [formGroup]="form" autocomplete="off">
        <div class="formgrid grid">
            <div class="flex align-items-center">
                <div class="formgrid grid" style="padding-left: 10px;">
                    <div class="flex flex-column field col-12 gap-2 p-0">
                        <app-field-container
                            [form]="form"
                            fieldName="contactType"
                            label="Tipo de Contacto *"
                        >
                            <p-dropdown 
                                class="otic-form-input" 
                                appendTo="body" 
                                [options]="contactType" 
                                formControlName="contactType" 
                                placeholder="Seleccione Tipo de Contacto" 
                                optionLabel="description"
            
                            ></p-dropdown>
                        </app-field-container>
                    </div>
                    <div class="flex flex-column field otic-form-input" style="margin-right: 116px;">
                        <app-field-container
                            [form]="form"
                            fieldName="name"
                            label="Nombre Completo *"
                        >
                            <input 
                                type="text" 
                                pInputText
                                [maxlength]="50" 
                                placeholder="Nombre Completo" 
                                formControlName="name"
                            />
                        </app-field-container>
                    </div>
                    <div class="flex flex-column field otic-form-input">
                        <label for="position">Cargo</label>
                        <input type="text" pInputText placeholder="Cargo" formControlName="position"/>
                    </div>
                    <div class="flex flex-column field otic-form-input" style="margin-right: 116px;">
                        <label for="phone">Teléfono</label>
                        <input
                            pInputText
                            type="text"
                            class="w-full"
                            [maxlength]="50" 
                            placeholder="12345678"
                            formControlName="phone"
                            mask="00000000" 
                        />
                    </div>
                    <div class="flex flex-column field otic-form-input">
                        <app-field-container
                            [form]="form"
                            fieldName="email"
                            label="Mail *"
                            [errorMessage]="nonRepeatContactError"
                        >
                            <input 
                                type="text" 
                                pInputText
                                [maxlength]="50" 
                                placeholder="Mail" 
                                style="text-transform:lowercase" 
                                formControlName="email"
                            />
                        </app-field-container>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="col">
        <div class="flex align-items-center justify-content-end gap-2">
            <app-button 
                label="Cancelar" 
                (clickButton)="closeModal()" 
                type="secondary"
                [width]="140" 
                [height]="46" 
                [fontWeight]="700"
            ></app-button>
            @if(selectedIndexForDetail === -1){
                <app-button 
                    label="Confirmar" 
                    (clickButton)="saveContact()" 
                    [width]="140" 
                    [height]="46" 
                    [fontWeight]="700"
                ></app-button>
            }
            @else {
                <app-button 
                    label="Confirmar" 
                    (clickButton)="updateContact()" 
                    [width]="140" 
                    [height]="46" 
                    [fontWeight]="700"
                ></app-button>
            }
            
        </div>
    </div>
</app-modal>

<app-grid-container
        title="Contactos Empresa"
        addTitle="Agregar Contacto"
        (add)="addNewContact()"
        [showAddButton]="mode != ModeEnum.VIEW"
    >
    <p-table [value]="companyContacts" [tableStyle]="{ 'min-width': '50rem', 'padding': '12px' }">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Tipo</th>
                <th scope="col">Cargo</th>
                <th scope="col">Teléfono</th>
                <th scope="col">Mail</th>
                @if (mode !== ModeEnum.VIEW){
                    <th scope="col">Opciones</th>
                }
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-index="rowIndex">
            <tr>
                <td>{{ item.name }}</td>
                <td>{{ item.contactType?.description | titlecase }}</td>
                <td>{{ item.position | titlecase }}</td>
                <td>{{ item.phone }}</td>
                <td>{{ item.email | lowercase }}</td>
                @if (mode !== ModeEnum.VIEW){
                    <td class="flex gap-2">
                        <div 
                            (click)="openGridActions($event, item, index)" 
                            class="otic-actions"
                        >
                        </div>
                    </td>
                }
            </tr>
        </ng-template>
    </p-table>
</app-grid-container>
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>




