<app-modal
    title="Periodo del Servicio"
    [height]="662"
    #modal
>
    <div class="flex flex-column h-full">
        <form [formGroup]="form" autocomplete="off" class="formgrid grid justify-content-between">
            <div class="tax-year-container" *ngIf="showNotification">
                <div class="tax-year-icon">
                    <img src="../../../../assets/icons/info.svg" alt="mensaje-notificacion" height="40" width="40">
                </div>
                <div class="tax-year-content">
                    <h4 class="tax-year-content-title">
                        <span>¡Notificación!</span>
                        <img
                            src="../../../../assets/icons/x.svg"
                            alt="cerrar"
                            width="12"
                            height="12"
                            style="cursor: pointer;"
                            (click)="closeInfo()"
                            (keypress)="closeInfo()"
                        >
                    </h4>
                    <p class="tax-year-content-message" [innerHTML]="notificacionText">
                    </p>
                </div>
            </div>
            <h4 class="mt-0 mb-3 col-12">Periodicidad</h4>
            <div class="flex flex-column field col-6">
                <app-field-container
                    [form]="form"
                    fieldName="startDate"
                    label="Fecha Inicio"
                >
                    <p-calendar 
                        formControlName="startDate"
                        view="month" 
                        dateFormat="mm/yy" 
                        [readonlyInput]="true"
                        [showIcon]="true"
                        [iconDisplay]="'input'"
                        placeholder="mm/aaaa"
                        [minDate]="minStartDate"
                    ></p-calendar>
                </app-field-container>
            </div>
            <div class="flex flex-column field col-6">
                <app-field-container
                    [form]="form"
                    fieldName="finishDate"
                    label="Fecha Término"
                >
                    <p-calendar 
                        formControlName="finishDate"
                        view="month" 
                        dateFormat="mm/yy" 
                        [readonlyInput]="true"
                        [showIcon]="true"
                        [iconDisplay]="'input'"
                        placeholder="mm/aaaa"
                        [minDate]="minFinishDate"
                    ></p-calendar>
                </app-field-container>
            </div>
        </form>
        <div class="flex flex-row justify-content-end gap-3" style="margin-top: auto;">
            <app-button label="Cancelar" type="secondary" (clickButton)="closeModal()"></app-button>
            <app-button label="Aceptar" (clickButton)="savePeriod()"></app-button>
        </div>
    </div>
</app-modal>

<div class="grid-container">
    @if (!fecProvider.hasPeriod()) {
        <div class="dashed-board">
            <h3 class="dashed-board-title">Agregar periodo</h3>
            <p class="dashed-board-message mt-0">Indique la periodicidad de la propuesta económica</p>
            <app-button 
                icon="otic-plus" 
                label="Agregar" 
                (clickButton)="openModal()"
                [height]="40"
                [width]="110"
            >
            </app-button>
        </div>
    }
    @else {
        <div class="grid align-items-stars">
            <div class="col-fixed" style="width:336px">
                <app-scenario-list></app-scenario-list>
            </div>
            <div class="col">
                <app-fec-year-container></app-fec-year-container>
            </div>
        </div>
    }
    
</div>

<app-stepbar 
    #stepBar
    [showPrevious]="true"
    (previousClick)="onPrevious()"
    [showNext]="true"
    (nextClick)="onNext()"
></app-stepbar>

