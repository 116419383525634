<div class="scenario-section-body">
    <div 
        class="scenario-item"
        [ngClass]="{
            'scenario-item-antecedent': type !== 'normal'
           }" 
    >
        <div class="scenario-item-label">{{ getDescription() }}</div>
        <div class="scenario-item-actions">
            <div 
                class="otic-actions" 
                (click)="openGridActions($event)" 
            ></div>
        </div>
    </div>
</div>
<p-menu #menu [model]="gridActions" [popup]="true"></p-menu>
