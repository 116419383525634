import { RoleEnum } from "@shared/enums/roleEnum";
import { MenuItem } from "@shared/interfaces/menuItem";
import { UserSession } from "@shared/interfaces/userSession";
import { arrayIsEmpty } from "./listUtil";
import { FecListComponentModeEnum } from "@pages/fec/fec-list/fec-list.component";

export const MENU = {
  HOME: {
    label: 'Home',
    routerLink: '/home',
    icon: 'otic-home',
    roles: []
  },
  USER_ADM: {
    label: 'Administrador de usuarios',
    routerLink: '/user-manager',
    icon: 'otic-user-admin',
    roles: [
      RoleEnum.ROL_ADM
    ]
  },
  MY_FEC: {
    label: 'Mis FEC',
    routerLink: '/fec-tracking',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_JCG
    ]
  },
  APPROVAL_MANAGEMENT: {
    label: 'Gestión Aprobaciones',
    routerLink: '/approval-management',
    roles: [
      RoleEnum.ROL_JOS,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_ACG,
      RoleEnum.ROL_JCG,
      RoleEnum.ROL_SGC,
      RoleEnum.ROL_GDCH
    ]
  },
  COMMERCIAL_EVALUATIONS: {
    label: 'Evaluaciones Comerciales',
    routerLink: '/fec-list',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JOS,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_ACG,
      RoleEnum.ROL_JCG,
      RoleEnum.ROL_SGC,
      RoleEnum.ROL_GDCH
    ]
  },
  CREATE_FEC_CLIENT_CURRENT: {
    label: 'Crear FEC Cliente Vigente',
    routerLink: '/search-current-client',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_JCG
    ]
  },
  CREATE_FEC_CLIENT_POTENTIAL: {
    label: 'Crear FEC Cliente Potencial',
    routerLink: '/search-potential-client',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_JCG
    ]
  },
  CREATE_POTENTIAL: {
    label: 'Registrar Potencial',
    routerLink: '/potential-client',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JOS,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ,
      RoleEnum.ROL_SGC
    ]
  },
  POTENTIAL_PORTFOLIO: {
    label: 'Cartera Potenciales',
    routerLink: '/potential-list',
    roles: [
      RoleEnum.ROL_EC,
      RoleEnum.ROL_JO,
      RoleEnum.ROL_AZ
    ]
  },
  POTENTIAL_BASE: {
    label: 'Base Potenciales',
    routerLink: '/potential-base',
    roles: [
      RoleEnum.ROL_JOS,
      RoleEnum.ROL_JCG,
      RoleEnum.ROL_SGC,
      RoleEnum.ROL_GDCH
    ]
  },
  VIEW_POTENTIAL: {
    label: 'Ver Potencial',
    routerLink: '/view-potential-detail',
    roles: [
      RoleEnum.ROL_GDCH
    ]
  }
}
  
const MENU_GROUP: any[] = [
    MENU.HOME,
    MENU.USER_ADM,
    {
      label: 'Potenciales',
      icon: 'otic-potential',
      items: [
        MENU.CREATE_POTENTIAL,
        MENU.POTENTIAL_PORTFOLIO,
        MENU.POTENTIAL_BASE
      ]
    },
    {
      label: 'FEC',
      icon: 'otic-fec',
      items: [
        MENU.CREATE_FEC_CLIENT_CURRENT,
        MENU.CREATE_FEC_CLIENT_POTENTIAL,
        MENU.COMMERCIAL_EVALUATIONS
      ]
    },
    {
      label: 'Aprobación FEC',
      icon: 'otic-fec-check',
      items: [
        MENU.MY_FEC,
        MENU.APPROVAL_MANAGEMENT
      ]
    }
];

const allowMenu = (menuItem: MenuItem, roles: string[]): boolean => {
  const menuItemRoles = menuItem.roles ?? [];
  if (menuItemRoles.length === 0)
    return true;
  return menuItemRoles.find(item => roles.includes(item))!=null;
}

const processMenuGroup = (menuGroup: MenuItem, roles: string[]): MenuItem | undefined => {
  let items = menuGroup.items ?? [];
  if (items.length === 0){
    if (allowMenu(menuGroup, roles))
      return menuGroup;
    return undefined;
  }
  let allowedItems = items.filter(subItem => allowMenu(subItem, roles));
  if (arrayIsEmpty(allowedItems))
    return undefined;
  return {
    ...menuGroup,
    items: allowedItems
  };
}

export const processRoleMenu = (user: UserSession) : UserSession => {
  const roles = user.isAdmin ? [RoleEnum.ROL_ADM, ...user.roles] : user.roles;
  let list: MenuItem[] = [];
  for (const menuGroup of MENU_GROUP) {
    let proccesed = processMenuGroup(menuGroup, roles);
    if (proccesed)
      list.push(proccesed);
  }
  user.roles = roles;
  user.menu = list;
  return user;
}  

export const getRoleMatch = (routeRoles: any[], userRoles: any[]) => {
  return routeRoles.find(item => {
    if (typeof item === 'string' && userRoles.includes(item))
      return true;
    if (userRoles.includes(item.role))
      return true;
    return false;
  });
}

export const getModeMatch = (routeRoles: any[], userRoles: any[]) => {
  let match = getRoleMatch(routeRoles, userRoles);
  return match?.mode ? match.mode : undefined;
}
